import React, { useEffect } from "react";
import SideMenu from "../General/SideMenu";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import PartySearch from "../Transaction/PartySearch";
import PartyCreation from "../Transaction/PartyCreation";
import VouchernoSetting from "../Setting/VouchernoSetting";
import ReactTable from "../General/ReactTable";
import {
  Add_Service,
  AddLess_LostFocus,
  AddLess_Onchange,
  Clear_Service,
  Clear_Transaction,
  Discount_onchanged,
  Input_Onchange,
  Modify_Service,
  setExpenseIncomeDefault,
  settableCol,
  ShowHide_Popup,
  Update_DiscountCalculation,
  Vendore_TypeOnchange,
} from "../ReduxStore/Transactionstates";
import TransactionButton from "../Transaction/TransactionButton";
import {
  Hidetab_Container,
  KeyUpDown,
  Showtab_Container,
} from "../Transaction/Transaction";
import TransactionView from "../Transaction/TransactionView";
import ServiceSearch from "../Transaction/ServiceSearch";
import PrintScreen from "../Print/PrintScreen";
import PrinterSetting from "../Setting/PrinterSetting";

function ExpenseIncome() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _Disc = useSelector((store) => store.states.Discount);
  const _Sum = useSelector((store) => store.states.Summary);
  const _AL = useSelector((store) => store.states.AddLess);
  const _tabOrder = JSON.parse(JSON.stringify(_Common.arrtaborder));
  const _tabShow = JSON.parse(JSON.stringify(_Common.Headertab));
  const iScrId = 66;

  useEffect(() => {
    Load_ExpenseIncome();
  }, []);
  const Load_ExpenseIncome = async () => {
    try {
      const objTrans = await {
        Controller: "ExpenseIncome",
        strScrType: "ExpenseIncome",
        iScrId: iScrId,
        strScrName: "Expense Income",
        iPartyScrId: 3,
        iPartyId: 0,
        iVenType: 2,
        strSearch: "",
        strColName: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
      };
      await dispatch(setload_Screen(true));
      await api.post(
        `ExpenseIncome/Load_ExpenseIncome`,
        objTrans
      ).then(async (res) => {
        if (res.data.strStatus === "Success") {
          res.data.Trans = objTrans;
          let Index = 0;
          let arrtabbtn = JSON.parse(JSON.stringify(_Common.Headertab));
          await arrtabbtn.map((dt) => {
            if (dt.TabId === 0 || dt.TabId === 6) {
              dt.Show = true;
              dt.Index = Index;
              Index += 1;
            }
            return dt;
          });
          res.data.tabBtn = arrtabbtn;
          await dispatch(setExpenseIncomeDefault(res.data));
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Save_Expense = async () => {
    try {
      if (_HT.iBillTypeId === 0) {
        AlertMessage("Bill Type is Required", "error");
      } else if (_DT.arrEntDT.length === 0) {
        AlertMessage("Atleast One Item is Required", "error");
      } else {
        await dispatch(setload_Screen(true));
        const objParems = {
          iEIId: _HT.iVouchId,
          iTitleTag: _Common.iScrId,
          iPartyId: _HT.iPartyId,
          iNumSeqNo: _HT.iNumSeqNo,
          strPrefix: _HT.strPrefix,
          strCompCode: _HT.strCompCode,
          strFinanFrom: _HT.strFinanFrom,
          strTransNo: _HT.strTransNo,
          strVouchNo: _HT.strVouchNo,
          strVouchDate: _HT.strVouchDate,
          iBillTypeId: _HT.iBillTypeId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iTaxTypeId: _HT.iTaxRelId,
          iVenTypeId: Number(_Common.VenTypeId),
          iEITypeId: _HT.iEITypeId,
          strRemark1: _HT.strRemark1,
          strRemark2: _HT.strRemark2,
          strRemark3: _HT.strRemark3,
          strRemark4: _HT.strRemark4,
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dTBillDiscValue: Number(_Disc.dBillDisValue),

          dNetAmt: Number(_Sum.NetAmt),
          dTotGoodval: Number(_Sum.GoodsValue),
          dTotDiscount: Number(_Sum.Discount),
          dTotTax: Number(_Sum.Tax),
          dRoundOff: Number(_Sum.RoundOff),
        };
        await api.post(`ExpenseIncome/InsUpd_ExpenseIncome`, {
          objEntSI: objParems,
          objBaseTransDT: _DT.arrEntDT,
          objEntTax: _DT.arrEntTax,
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
        }).then((res) => {
          if (res.data.strStatus === "Success") {
            Clear_Click();
            AlertMessage(res.data.strMessage, "success");
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Click = async () => {
    try {
      await dispatch(Clear_Transaction());
      await Load_ExpenseIncome();
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Add_tableItem = async () => {
    try {
      const objParem = {
        bUpdateStatus: _AL.bUpdateStatus,
        iRowIndex: _AL.iRowIndex,
        iScrId: iScrId,
        iTaxRelId: _HT.iTaxRelId,
        iPartyId: _HT.iPartyId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,
        iSerId: Number(_AL.iSerId),
        dPriceRate: Number(_AL.dRate),
        dDiscountPer: _AL.IsDiscPer ? Number(_AL.dDisc) : 0,
        dDiscountAmt: _AL.IsDiscPer ? 0 : Number(_AL.dDisc),
        objBaseTransDT: _DT.arrEntDT,
        objEntAddLess: [],
        objEntConRow: [],
        objEntTax: [],
        objItemRemark: _AL.Remarks,
        objPenCon: [],
        objRootCalc: _AL.Calculation,
        strColumn: "",
        strJobId: "",
        strProAttDTId: "",

        strReason: "",
        strSearch: "",
        strSetCurrentStatus: "",
        strStoAttDTId: "",
        strTraAttDTId: "",
        iYearId: Number(localStorage.FYId),
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
      };
      await dispatch(setload_Screen(true));
      await api.post(
        `ExpenseIncome/AddUpdate_Service/`,
        objParem
      ).then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(Add_Service(res.data));
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Remove_TableService = async (iIndex) => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(`ExpenseIncome/Remove_Service/`, {
        bUpdateStatus: _AL.bUpdateStatus,
        iRowIndex: iIndex,
        iScrId: iScrId,
        iTaxRelId: _HT.iTaxRelId,
        iPartyId: _HT.iPartyId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,
        iSerId: Number(_AL.iSerId),
        dPriceRate: Number(_AL.dRate),
        dDiscountPer: _AL.IsDiscPer ? Number(_AL.dDisc) : 0,
        dDiscountAmt: _AL.IsDiscPer ? 0 : Number(_AL.dDisc),
        objBaseTransDT: _DT.arrEntDT,
        objEntAddLess: [],
        objEntConRow: [],
        objEntTax: [],
        objItemRemark: _AL.Remarks,
        objPenCon: [],
        objRootCalc: _AL.Calculation,
        strColumn: "",
        strJobId: "",
        strProAttDTId: "",

        strReason: "",
        strSearch: "",
        strSetCurrentStatus: "",
        strStoAttDTId: "",
        strTraAttDTId: "",
        iYearId: Number(localStorage.FYId),
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
      }).then((res) => {
        if (res.data.strStatus === "Success") {
          dispatch(Add_Service(res.data));
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const KeyDown = (e) => {
    if (e.key === "Enter" && e.key === "ArrowUp" && e.key === "ArrowDown") {
      KeyUpDown(e, _Common, _DT);
    }
  };
  const HeaderInput_Change = async (e) => {
    await dispatch(
      Input_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const BillDisc_OnChange = async (Value, Disc) => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(`ExpenseIncome/CalculateDiscount`, {
        Disc: Disc,
        iScrId: _Common.iScrId,
        ScrName: _Common.strScrName,
        iTaxTypeId: _HT.iTaxRelId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,
        iAddLessBillDiscId: _Common.iALBillDiscId,
        iPartyId: _HT.iPartyId,
        dTBillDiscPer:
          Disc === "BillDicPer" ? Number(Value) : Number(_Disc.dBillDisPer),
        dTBillDiscAmt:
          Disc === "BillDicAmt" ? Number(Value) : Number(_Disc.dBillDisAmt),
        dTBillDiscValue: Number(_Disc.dBillDisValue),
        dTAddLessDiscPer:
          Disc === "ALBillDicPer" ? Number(Value) : Number(_Disc.dALBillDisPer),
        dTAddLessDiscAmt:
          Disc === "ALBillDicAmt" ? Number(Value) : Number(_Disc.dALBillDisAmt),
        dTAddLessDiscValue: Number(_Disc.dALBillDisValue),
        objBaseTransDT: _DT.arrEntDT,
        objEntALDT: _DT.arrEntAL,
        strCompanyDB: localStorage.db,
      }).then((Res) => {
        if (Res.data.strStatus === "Success") {
          dispatch(Update_DiscountCalculation({ Data: Res.data, Disc: Disc }));
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Disc_Onchange = (e) => {
    dispatch(
      AL_Onchange({
        Name: e.name,
        Value: e.value,
      })
    );
  };
  const AL_Onchange = (e) => {
    dispatch(
      AddLess_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  useEffect(() => {
    async function Table_Creation() {
      let arrtransper = JSON.parse(JSON.stringify(_Common.arrtransper));
      var TransPermission = await arrtransper.filter(
        (Data) => Data.isShowInScr === true
      );
      TransPermission = await TransPermission.sort((a, b) => {
        return a.inOrderTO - b.inOrderTO;
      });
      let EntCol = [];

      for (let i = 0; i < TransPermission.length; i++) {
        var ColName,
          SmallLetter,
          Header,
          TextAlign = "";
        var MinWidth = 0;
        ColName = TransPermission[i].columnName;
        if (ColName.startsWith("Sal")) ColName = ColName.slice(3);
        if (ColName.startsWith("Pur")) ColName = ColName.slice(3);
        SmallLetter = ColName[0].toLowerCase();
        ColName = SmallLetter + ColName.slice(1);
        Header = TransPermission[i].aliasName;
        TextAlign = "center";
        MinWidth = Header.length > 7 ? 120 : 70;
        if (ColName.endsWith("Per")) {
          TextAlign = "right";
          MinWidth = 100;
        }
        if (ColName.endsWith("Amt")) TextAlign = "right";
        if (ColName.endsWith("Value")) TextAlign = "right";
        if (ColName.endsWith("Tax")) TextAlign = "right";

        if (ColName.startsWith("sNo")) MinWidth = 40;
        if (ColName.endsWith("proName")) {
          TextAlign = "Left";
          MinWidth = 200;
        }
        const col = {
          Header: Header,
          accessor: ColName,
          maxWidth: 200,
          minWidth: MinWidth,
          style: { justifyContent: TextAlign },
        };
        EntCol.push(col);
      }
      var Option = "";
      Option = {
        Header: "Delete",
        id: "proId",
        minWidth: 40,
        style: { justifyContent: "center" },
        cell: ({ rowinfo }) => (
          <i
            onClick={() => Remove_TableService(rowinfo.sNo)}
            className="far fa-trash-alt"
          ></i>
        ),
      };
      await EntCol.push(Option);
      await dispatch(settableCol(EntCol));
    }
    Table_Creation();
  }, [_Common.arrtransper, _DT.arrEntDT]);

  const tab_click = (tabindex) => {
    Showtab_Container(
      "tab-line",
      "trans-buttons",
      tabindex,
      _tabShow,
      "transht",
      dispatch
    );
  };
  const hide_header = () => {
    Hidetab_Container(
      "transh-hide",
      "trans-buttons",
      _tabShow,
      "transht",
      dispatch
    );
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="fg-card shadow-base">
              <div className="trans-tab">
                <div className="trans-buttons">
                  <label onClick={(e) => tab_click(0)} className="active">
                    Party
                  </label>
                  {_tabShow[6].Show && (
                    <label onClick={(e) => tab_click(_tabShow[6].Index)}>
                      Remarks
                    </label>
                  )}
                  <div id="tab-line"></div>
                </div>
                <i
                  className="bx bx-caret-down transh-hide"
                  onClick={(e) => hide_header()}
                ></i>{" "}
              </div>
              {_tabShow[0].tabShow && (
                <div className="row p-2">
                  <div className="col-md-3 col-sm-6 mt-1">
                    <label>
                      Vendor Type <span className="danger">*</span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow"
                      showoption={_selecrparem.isoptionShow}
                      placeholder="Vendor Type"
                      select_value={(val) =>
                        dispatch(Vendore_TypeOnchange(val))
                      }
                      btnname="Vendor Type"
                      btnshow={false}
                      show_popup=""
                      valueonly={true}
                      defaultval={_Common.VenTypeId}
                      displayName="strVenTypeName"
                      disvalue="iVenTypeId"
                      arrydata={_Common.VendorType}
                      disabled={_HT.iVouchId > 0 ? true : false}
                      EmptVal=" Vendor Type"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <label>
                      Name <span className="danger">*</span>
                    </label>
                    <div className="custom-select">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type Party Name"
                        tabIndex={_tabOrder.txtPartyName}
                        onKeyDown={(e) => {
                          if (_HT.strTransNo.length > 0) {
                            if (e.key === "Enter") {
                              dispatch(
                                ShowHide_Popup({
                                  isshow: "IsVenSearch",
                                  IsSerVendor: true,
                                })
                              );
                            } else KeyDown(e);
                          }
                        }}
                        onClick={(e) => {
                          if (_HT.strTransNo.length > 0) {
                            dispatch(
                              ShowHide_Popup({
                                isshow: "IsVenSearch",
                                IsSerVendor: true,
                              })
                            );
                          }
                        }}
                        id="txtPartyName"
                        spellCheck={false}
                        autoComplete="off"
                        defaultValue={_HT.strPartyName}
                        disabled={_HT.iVouchId > 0 ? true : false}
                        autoFocus
                      />
                      <i
                        className="fa-solid fa-caret-down"
                        style={{
                          position: "absolute",
                          right: "10px",
                          color: "darkgreen",
                          transform: _Common.IsVenSearch
                            ? "rotate(-180deg)"
                            : "rotate(0deg)",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 mt-1">
                    <label>
                      Vocher no <span className="danger">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Voucher Number"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        id="txtVoucherNo"
                        name="strVouchNo"
                        tabIndex={_tabOrder.txtInvNo}
                        onChange={(e) => {
                          HeaderInput_Change(e);
                        }}
                        disabled={_HT.iVouchId === 0 ? _HT.isManualCode : true}
                        value={_HT.strVouchNo}
                        onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      />
                      <span
                        className="inv-no"
                        onClick={(e) => {
                          if (_HT.iVouchId === 0)
                            dispatch(ShowHide_Popup("IsVouchNo"));
                        }}
                      >
                        <i className="bx bx-cog"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 mt-1">
                    <label>
                      Date <span className="danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="strVouchDate"
                      tabIndex={_tabOrder.dpInvDate}
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      className="form-control"
                      value={_HT.strVouchDate}
                      onChange={(e) => {
                        HeaderInput_Change(e);
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mt-1">
                    <label>
                      Tax Type <span className="danger">* </span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow2"
                      showoption={_selecrparem.isoptionShow2}
                      placeholder="Tax Type"
                      select_value={(val) => {}}
                      taborder={_tabOrder.cbTaxType}
                      keydown={(e) => {
                        if (e.key === "ArrowUp" || e.key === "ArrowDown");
                        else KeyUpDown(e, _Common, _DT);
                      }}
                      btnname="Tax Type"
                      btnshow={false}
                      show_popup=""
                      valueonly={true}
                      defaultval={_HT.iTaxRelId}
                      displayName="strTaxType"
                      disvalue="iTaxRelId"
                      arrydata={_Common.TaxType}
                      disabled={true}
                      EmptVal="Tax Type"
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mt-1" hidden>
                    <label>
                      Trans No
                      <span className="danger">*</span>
                    </label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      id="txtTransNo"
                      name="strTransNo"
                      disabled
                      value={_HT.strTransNo}
                      tabIndex={_tabOrder.txtTransNo}
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mt-1">
                    <label>
                      Bill Type <span className="danger">* </span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow1"
                      showoption={_selecrparem.isoptionShow1}
                      placeholder="Bill Type"
                      select_value={(val) =>
                        HeaderInput_Change({
                          target: { name: "iBillTypeId", value: val },
                        })
                      }
                      taborder={_tabOrder.cbBillType}
                      keydown={(e) => {
                        if (e.key === "ArrowUp" || e.key === "ArrowDown");
                        else KeyUpDown(e, _Common, _DT);
                      }}
                      btnname="Bill Type"
                      btnshow={false}
                      show_popup=""
                      valueonly={true}
                      defaultval={_HT.iBillTypeId}
                      displayName="strBillType"
                      disvalue="iBT_Id"
                      arrydata={_Common.BillType}
                      disabled={false}
                      EmptVal="Bill Type"
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mt-1">
                    <label>
                      Expense Income Type <span className="danger">* </span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow3"
                      showoption={_selecrparem.isoptionShow3}
                      placeholder="Expense Income Type "
                      select_value={(val) =>
                        HeaderInput_Change({
                          target: { name: "iEITypeId", value: val },
                        })
                      }
                      btnname="Expense Income Type"
                      btnshow={false}
                      show_popup=""
                      valueonly={true}
                      defaultval={_HT.iEITypeId}
                      displayName="strEITypeName"
                      disvalue="iEITypeId"
                      arrydata={_Common.ExpenseType}
                      disabled={false}
                      EmptVal="Expense Income Type"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <label>Billing Address</label>
                    <textarea
                      style={{
                        height: "100px",
                        whiteSpace: "break-spaces",
                      }}
                      placeholder="Address of Party"
                      className="form-control "
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      value={_HT.strPartyAdd}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                </div>
              )}
              {_tabShow[6].Show && _tabShow[6].tabShow && (
                <div className="row p-2">
                  <div className="col-md-3 col-sm-12 mt-1">
                    <label>Remark 1</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Remark1"
                      name="strRemark1"
                      spellCheck={false}
                      autoComplete="off"
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      tabIndex={_tabOrder.txtRemark1}
                      onChange={(e) => {
                        HeaderInput_Change(e);
                      }}
                      value={_HT.strRemark1}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <label>Remark 2</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      spellCheck={false}
                      autoComplete="off"
                      placeholder="Enter Remark2"
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      tabIndex={_tabOrder.txtRemark2}
                      name="strRemark2"
                      onChange={(e) => {
                        HeaderInput_Change(e);
                      }}
                      value={_HT.strRemark2}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <label>Remark 3</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      spellCheck={false}
                      autoComplete="off"
                      placeholder="Enter Remark3"
                      tabIndex={_tabOrder.txtRemark3}
                      name="strRemark3"
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      onChange={(e) => {
                        HeaderInput_Change(e);
                      }}
                      value={_HT.strRemark3}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <label>Remark 4</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                      placeholder="Enter Remark4"
                      spellCheck={false}
                      name="strRemark4"
                      autoComplete="off"
                      tabIndex={_tabOrder.txtRemark4}
                      onChange={(e) => {
                        HeaderInput_Change(e);
                      }}
                      value={_HT.strRemark4}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="fg-card shadow-base p-1">
              <div className="row p-2">
                <div className="col-sm-12 col-md-4">
                  <label>Service Name</label>
                  <div className="custom-select">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type Party Name"
                      onKeyDown={(e) => {
                        if (_HT.iPartyId > 0)
                          dispatch(ShowHide_Popup("IsSerSearch"));
                      }}
                      onClick={(e) => {
                        if (_HT.iPartyId > 0)
                          dispatch(ShowHide_Popup("IsSerSearch"));
                      }}
                      spellCheck={false}
                      autoComplete="off"
                      defaultValue={_AL.SerName}
                      disabled={_HT.iPartyId === 0 ? true : false}
                    />
                    <i
                      className="fa-solid fa-caret-down"
                      style={{
                        position: "absolute",
                        right: "10px",
                        color: "darkgreen",
                        transform: _Common.IsSerSearch
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                      }}
                    ></i>
                  </div>
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Rate W/O Tax</label>
                  <input
                    type="number"
                    name="dRate"
                    className="form-control"
                    value={_AL.dRate}
                    onChange={(e) => AL_Onchange(e)}
                    onClick={(e) => e.target.select()}
                    onBlur={(e) => {
                      AddLess_LostFocus(e);
                    }}
                    autoComplete="off"
                  />
                </div>
                {_Common.TransPer.IsItemDisc && !_Common.IsJobWorkDc && (
                  <div className="col-sm-6 col-md-1 mt-2">
                    <label>Discount</label>
                    <div className="trancdisc">
                      <input
                        type="number"
                        className="form-control"
                        name="dDisc"
                        id="txtproItemDis"
                        value={_AL.dDisc}
                        autoComplete="off"
                        onClick={(e) => e.target.select()}
                        onChange={(e) => AL_Onchange(e)}
                        tabIndex={_tabOrder.txtDiscount}
                        onKeyDown={(e) => KeyDown(e)}
                        onBlur={(e) => {
                          e.target.value =
                            e.target.value === ""
                              ? (0).toFixed(2)
                              : e.target.value;
                          AL_Onchange(e);
                        }}
                      />
                      <button
                        className="btn-fabgreen active"
                        onClick={(e) => {
                          $(".trancdisc .btn-fabgreen:eq(0)").toggleClass(
                            "active"
                          );
                          Disc_Onchange({
                            name: "IsDiscPer",
                            value: !_AL.IsDiscPer,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                            $(".trancdisc .btn-fabgreen:eq(0)").toggleClass(
                              "active"
                            );
                            Disc_Onchange({
                              name: "IsDiscPer",
                              value: !_AL.IsDiscPer,
                            });
                          } else KeyDown(e);
                        }}
                      >
                        {_AL.IsDiscPer ? "%" : "₹"}
                      </button>
                    </div>
                  </div>
                )}
                <div className="col-sm-6 col-md-2 mt-4">
                  <div className="btn-sub-section">
                    <button
                      className="btn-fabgreen"
                      onClick={() => Add_tableItem()}
                      tabIndex={_tabOrder.btnAdd}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") Add_tableItem();
                        else KeyDown(e);
                      }}
                    >
                      Add&nbsp;<i className="bx bx-layer-plus"></i>
                    </button>
                    <button
                      className="btn-fabgreen"
                      onClick={() => dispatch(Clear_Service())}
                    >
                      Clear&nbsp;<i className="fas fa-eraser"></i>
                    </button>
                  </div>
                </div>
                <ReactTable
                  columns={_DT.arrEntDTCol}
                  data={_DT.arrEntDT}
                  minRows={_Common.MinRow}
                  row_click={(ServInfo, Index) => {}}
                  row_doubleclick={async (ServInfo, Index) => {
                    await dispatch(
                      Modify_Service({ ServInfo: ServInfo, Index: Index })
                    );
                  }}
                  background={true}
                  className="full-table"
                  tabIndex={_tabOrder.DataTable}
                  KeyDown={(e) => KeyDown(e)}
                />
              </div>
            </div>
            <div className="fg-card shadow-base p-1">
              <div className="row p-0">
                <div className="col-sm-12 col-md-4">
                  <table className="tax-table">
                    <thead>
                      <tr>
                        <th>Summary</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Goods Value</td>
                        <td style={{ textAlign: "right" }}>
                          {Number(_Sum.GoodsValue).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td style={{ textAlign: "right" }}>
                          {Number(_Sum.Discount).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tax</td>
                        <td style={{ textAlign: "right" }}>
                          {Number(_Sum.Tax).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Round Off</td>
                        <td style={{ textAlign: "right" }}>
                          {Number(_Sum.RoundOff).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Net Amount</td>
                        <td style={{ fontFamily: "arial", textAlign: "right" }}>
                          {new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "INR",
                          }).format(Number(_Sum.NetAmt).toFixed(2))}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="tax-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Tax %</th>
                          <th>Taxable Amount</th>
                          <th>Tax Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_DT.arrEntTax.map((Tax, Index) => (
                          <tr key={Index}>
                            <td style={{ textAlign: "left" }}>{Tax.taxDesc}</td>
                            <td style={{ textAlign: "Right" }}>
                              {Number(Tax.taxableAmt).toFixed(2)}
                            </td>
                            <td style={{ textAlign: "Right" }}>
                              {Number(Tax.taxAmt).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={1}>Tax Amount</td>
                          <td></td>
                          <td
                            style={{
                              fontFamily: "arial",
                              textAlign: "right",
                            }}
                          >
                            {Number(_Sum.Tax).toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <label>Bill Discount Per</label>
                      <input
                        type="number"
                        aria-label="First name"
                        className="form-control "
                        style={{ textAlign: "right" }}
                        value={_Disc.dBillDisPer}
                        name="dBillDisPer"
                        onClick={(e) => e.target.select()}
                        tabIndex={_tabOrder.txtBillDiscPer}
                        onChange={(e) => {
                          dispatch(
                            Discount_onchanged({
                              IsChanged: true,
                              Value: e,
                            })
                          );
                        }}
                        onKeyDown={(e) => KeyDown(e, _Common, _DT)}
                        onBlur={(e) => {
                          if (_Disc.isDiscChanged)
                            BillDisc_OnChange(e.target.value, "BillDicPer");
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <label>Bill Discount Amt</label>
                      <input
                        type="number"
                        className="form-control"
                        style={{ textAlign: "right" }}
                        value={_Disc.dBillDisAmt}
                        name="dBillDisAmt"
                        tabIndex={_tabOrder.txtBillDiscAmt}
                        onKeyDown={(e) => KeyDown(e, _Common, _DT)}
                        onClick={(e) => e.target.select()}
                        onChange={(e) =>
                          dispatch(
                            Discount_onchanged({
                              IsChanged: true,
                              Value: e,
                            })
                          )
                        }
                        onBlur={(e) => {
                          if (_Disc.isDiscChanged)
                            BillDisc_OnChange(e.target.value, "BillDicAmt");
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <label>Bill Discount</label>
                      <input
                        className="form-control"
                        value={new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "INR",
                        }).format(Number(_Disc.dBillDisValue).toFixed(2))}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {_Common.IsVenSearch && <PartySearch />}
            {_Common.IsSerSearch && <ServiceSearch />}
            {_Common.IsTransView && <TransactionView />}
            {_Common.IsVenCreation && <PartyCreation />}
            {_Common.IsVouchNo && <VouchernoSetting />}
            {_Common.IsPrintShow && <PrintScreen />}
            {_Common.IsPrintSett && <PrinterSetting />}
            <Messagedialogbox />
          </div>
          <TransactionButton
            Save={() => Save_Expense()}
            Clear={() => Clear_Click()}
          />
        </div>
      </div>
    </>
  );
}
export default ExpenseIncome;
