import React from "react";
import { useEffect, useState } from "react";

import api from "../Admin/Config";
import { Buffer } from "buffer";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import $ from "jquery";
function PrintImage(props) {
  const dispatch = useDispatch();
  const [ImageByteArray, setImageByteArray] = useState([]);
  const [strLogoName, setstrLogoName] = useState("");
  const [ImageType, setImageType] = useState("");
  const [iLogoId, setiLogoId] = useState(0);
  const [dImageWidth, setdImageWidth] = useState(0);
  const [dImageHeight, setdImageHeight] = useState(0);
  const [strImage, setstrImage] = useState("");
  const [ListEntLogo, setListEntLogo] = useState([]);
  useEffect(() => {
    Load_Logo();
  }, []);
  const Load_Logo = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get(`PrintMaster/View_Logo/0/${localStorage.db}`)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setListEntLogo(Res.data.objEntLogo);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Delete_Logo = async (LogoId) => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post("PrintMaster/Delete_Logo/" + LogoId + "/" + localStorage.db)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            Clear_Logo();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Save_Logo = async () => {
    try {
      var Image = document.getElementById("default-btn").value;
      var idxDot = Image.lastIndexOf(".") + 1;
      var ImgType =
        Image.length > 0
          ? Image.substr(idxDot, Image.length).toLowerCase()
          : ImageType;
      if (ImgType === "jpg" || ImgType === "jpeg" || ImgType === "png") {
        await dispatch(setload_Screen(true));
        const UpdateImage = {
          iLogoId: iLogoId,
          strLogoName: strLogoName,
          dLogoWidth: dImageWidth,
          dLogoHeight: dImageHeight,
          strImgCompLogoTag: "",
          strPathTag: "",
          ImgData: ImageByteArray,
          strCompanyDB: localStorage.db,
        };
        await api.post("PrintMaster/InsUpd_Logo/", UpdateImage).then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            Clear_Logo();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      } else {
        AlertMessage("Only jpg/jpeg and png files are allowed!", "error");
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Logo = (logo) => {
    $(".img-wrapper").addClass("active"); // For Image Active
    setiLogoId(logo.iLogoId);
    setdImageWidth(logo.dLogoWidth);
    setdImageHeight(logo.dLogoHeight);
    setstrLogoName(logo.strLogoName);
    setImageByteArray(logo.imgData);
    setImageType(logo.strLogoName.split(".")[1]);
    if (logo.imgData.length > 0) {
      var UnitBitArray = new Uint8Array(logo.imgData);
      var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
      var Image = "data:image/png;base64," + ArrayToString;
      setstrImage(Image);
    }
  };
  const Clear_Logo = () => {
    setiLogoId(0);
    setImageType("");
    setstrImage("");
    setImageByteArray([]);
    setstrLogoName("");
    setdImageHeight("");
    setdImageWidth("");
    setListEntLogo([]);
    $(".img-wrapper").removeClass("active");
    Load_Logo();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ActivatDeafultBtn = () => {
    const defaultBtn = document.getElementById("default-btn");
    // eslint-disable-next-line no-useless-escape
    let regExp = /[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();

        const Bytreader = new FileReader();
        reader.readAsDataURL(file);
        Bytreader.readAsArrayBuffer(file);

        reader.onload = function () {
          setstrImage(reader.result);
        };
        Bytreader.onload = function () {
          var fileByteArray = [];
          var array = new Uint8Array(Bytreader.result);
          for (var i = 0; i < array.length; i++) fileByteArray.push(array[i]);
          setImageByteArray(fileByteArray);
        };
        if (this.value) {
          setstrLogoName(this.value.match(regExp)[0]);
        }
        $(".img-wrapper").addClass("active"); // For Image Active
      }
    });
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Image Gallary</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="data-list">
                {ListEntLogo.map((logo, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = ListEntLogo.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Logo(logo);
                      }
                    }}
                  >
                    <label onClick={(e) => View_Logo(logo)}>
                      {logo.strLogoName}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12 pt-1">
              <div className="row">
                <center>
                  <div className="img-container">
                    <div className="img-content">
                      <div className="img-wrapper">
                        <div className="image">
                          {strImage !== "" && <img src={strImage} alt="" />}
                        </div>
                        <div className="content">
                          <div id="custom-btn" onClick={ActivatDeafultBtn}>
                            <i className="fas fa-cloud-upload-alt"></i>
                          </div>
                        </div>
                        <div
                          id="cancel-btn"
                          onClick={(e) => {
                            setstrImage("");
                            setImageByteArray([]);
                            setstrLogoName("");
                            $(".img-wrapper").removeClass("active");
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </div>
                        <div className="file-name">{strLogoName}</div>
                      </div>
                      <input
                        id="default-btn"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        hidden
                      />
                    </div>
                  </div>
                </center>
                <div className="col-md-12 col-sm-12 p-0">
                  <label>
                    Image Height <span className="danger">*</span>
                  </label>
                  <input
                    autoFocus
                    type="number"
                    className="form-control"
                    placeholder="Enter Image Height"
                    value={dImageHeight}
                    onChange={(e) => setdImageHeight(e.target.value)}
                    onClick={(e) => e.target.select()}
                  />
                </div>
                <div className="col-md-12 col-sm-12 p-0">
                  <label>
                    Image Width<span className="danger">*</span>
                  </label>
                  <input
                    onClick={(e) => e.target.select()}
                    type="number"
                    className="form-control"
                    spellCheck={false}
                    placeholder="Enter Image Width"
                    value={dImageWidth}
                    onChange={(e) => setdImageWidth(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: Save_Logo,
                    Question: "Do You Want to Save Image?",
                    isdialog: true,
                  })
                );
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                dispatch(
                  Show_MsgBox({
                    Button: Delete_Logo,
                    Question: "Do You Want to Delete Image?",
                    isdialog: true,
                    Id: iLogoId,
                  })
                )
              }
            >
              Delete&nbsp; <i className="far fa-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={(e) => Clear_Logo()}>
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PrintImage;
