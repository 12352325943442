import React, { useRef } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import Print_DesignCaller from "../General/Print_DesignCaller";
function PrintScreen(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      let VoucherId = props.iVouchId ? props.iVouchId : 0;
      let TempId = props.iFormateId > 0 ? props.iFormateId : 0;
      Print_DesignCaller(
        props.iScrId,
        "",
        "Transaction",
        VoucherId,
        TempId,
        "Print",
        dispatch,
        false
      );
    } catch (err) {
      AlertMessage(err.strMessage, "info");
    }
  }, []);
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const PrintRef = useRef();
  const Print = useReactToPrint({
    content: () => PrintRef.current,
    pageStyle: "@page {size: A4}",
  });
  return (
    <div className="Popup-Screen">
      <div
        className="Popup-Screen-Box"
        style={{ zIndex: "1", marginTop: "50px" }}
      >
        <div className="Popup-Screen-Container" style={{ minHeight: "600px" }}>
          <div className="Popup-Header">
            <p> PRINT PREVIEW</p>
            <div className="d-flex justify-content-between">
              <div className="Rpt-Btn">
                <div className="TTip">
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="Rbtn" href="##">
                      <i className="fa-solid fa-print" onClick={Print}></i>
                    </a>
                  }
                  <div className="tooltiptext">Print</div>
                </div>
                <div className="TTip">
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="Rbtn" href="#">
                      <i
                        className="fa-regular fa-rectangle-xmark"
                        onClick={props.Close}
                      ></i>
                    </a>
                  }
                  <div className="tooltiptext">Close</div>
                </div>
              </div>
            </div>
          </div>
          <div id="PrintPdf" ref={PrintRef}></div>
        </div>
      </div>
    </div>
  );
}
export default PrintScreen;
