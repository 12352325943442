import React, { useEffect, useState } from "react";
import Admintable from "./Admintable";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";

function ErrorLogs() {
  const dispatch = useDispatch();
  const [arrErrorlog, setarrErrorlog] = useState([]);

  useEffect(() => {
    Load_Errorlog();
  }, []);

  const Load_Errorlog = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Errorlog").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrErrorlog(res.data.EntLogs);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Truncate_Errorlog = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post("Admin/Truncate_Errorlog").then(async (res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          await Load_Errorlog();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const DBColumns = [
    {
      Name: "Company",
      ColName: "companyName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "DataBase",
      ColName: "dbName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Screen",
      ColName: "screenName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Controller",
      ColName: "controller",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Method",
      ColName: "method",
      Color: "black",
      Width: "100",
      textAlign: "left",
    },
    {
      Name: "Time",
      ColName: "transTime",
      Color: "black",
      Width: "100",
      textAlign: "center",
    },
    {
      Name: "Data",
      ColName: "transDate",
      Color: "black",
      Width: "100",
      textAlign: "center",
    },
    {
      Name: "Error",
      ColName: "errorMsg",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },

    {
      Name: "User",
      ColName: "userId",
      Color: "black",
      Width: "30",
      textAlign: "center",
    },
  ];
  return (
    <div className="Dash-content">
      <div className="Dash-card1">
        <div className="dash-header">
          <button
            onClick={(e) => {
              dispatch(
                Show_MsgBox({
                  Button: Truncate_Errorlog,
                  Question: "Do You Want to Delete All Error Log?",
                  isdialog: true,
                })
              );
            }}
          >
            Delete Error Log&nbsp;<i className="bx bx-trash-alt"></i>
          </button>
        </div>
        <div className="Dash-wrapper pr-0">
          <Admintable
            Columns={DBColumns}
            Data={arrErrorlog}
            View_Data={() => {}}
            IsBackground={false}
          />
        </div>
      </div>
    </div>
  );
}

export default ErrorLogs;
