import React, { useEffect } from "react";
import $ from "jquery";
import api from "../Admin/Config";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  AddProduct,
  Clear_Product,
  JobWorkInput_Onchange,
  Modify_Product,
  ProductInput_Onchange,
  ProductRate_LostFocus,
  ProductRate_Onchange,
  Remove_product,
  Select_Unit,
  ShowHide_Popup,
  settableCol,
} from "../ReduxStore/Transactionstates";
import ReactTable from "../General/ReactTable";
import { KeyUpDown } from "./Transaction";
function TransactionInput() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _Product = useSelector((store) => store.states.Product);
  const _JobWork = useSelector((store) => store.states.JobWork);
  const _Disc = useSelector((store) => store.states.Discount);

  useEffect(() => {
    const Table_Creation = async () => {
      let arrtransper = JSON.parse(JSON.stringify(_Common.arrtransper));
      var TransPermission = await arrtransper.filter(
        (Data) => Data.isShowInScr === true
      );
      TransPermission = await TransPermission.sort((a, b) => {
        return a.inOrderTO - b.inOrderTO;
      });
      let EntCol = [];

      for (let i = 0; i < TransPermission.length; i++) {
        var ColName,
          SmallLetter,
          Header,
          TextAlign = "";
        var MinWidth = 0;
        ColName = TransPermission[i].columnName;
        if (ColName.startsWith("Sal")) ColName = ColName.slice(3);
        if (ColName.startsWith("Pur")) ColName = ColName.slice(3);
        SmallLetter = ColName[0].toLowerCase();
        ColName = SmallLetter + ColName.slice(1);
        Header = TransPermission[i].aliasName;
        TextAlign = "center";
        MinWidth = 100;
        if (ColName.endsWith("Per")) TextAlign = "right";
        if (ColName.endsWith("Amt")) TextAlign = "right";
        if (ColName.endsWith("Value")) TextAlign = "right";
        if (ColName.endsWith("Tax")) TextAlign = "right";
        if (ColName.startsWith("sNo")) MinWidth = 40;
        if (ColName.endsWith("proName")) {
          TextAlign = "Left";
          MinWidth = 200;
        }
        const col = {
          Header: Header,
          accessor: ColName,
          minWidth: MinWidth,
          style: { justifyContent: TextAlign },
        };
        EntCol.push(col);
      }
      var Option = "";
      Option = {
        Header: "Delete",
        id: "proId",
        minWidth: 40,
        style: { justifyContent: "center" },
        cell: ({ rowinfo }) => (
          <i
            onClick={() => {
              Remove_tableProduct(
                rowinfo.sNo,
                _Common,
                _HT,
                _DT,
                _Disc,
                dispatch
              );
            }}
            className="far fa-trash-alt"
          ></i>
        ),
      };
      await EntCol.push(Option);
      await dispatch(settableCol(EntCol));
    };
    Table_Creation();
  }, [_Common.arrtransper, _DT.arrEntDT]);

  const View_ProductUnit = async (ProInfo, Index) => {
    try {
      dispatch(setload_Screen(true));
      api.get(
        `Transaction/View_ProductUnit/${ProInfo.proId}/${_Common.strScrType}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success")
          dispatch(
            Modify_Product({
              ProInfo: ProInfo,
              Unit: res.data.objUnit,
              Index: Index,
            })
          );
        else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AddProductClick = async () => {
    try {
      if (_Product.strProName.length === 0) {
        $("#txtproName").on("focus");
        AlertMessage("Please Select Product", "info");
      } else if (Number(_Product.dQty) === 0) {
        AlertMessage("Qty Range should not be Zero(0)", "info");
      } else if (
        Number(_Product.dRate) === 0 &&
        Number(_Product.dRatewithTax) === 0 &&
        !_Common.IsJobWorkDc
      ) {
        $("#txtproRate").on("focus");
        AlertMessage("Rate should not be Zero if Item has Qty", "info");
      } else {
        await dispatch(setload_Screen(true));
        const objParams = {
          bUpdateStatus: _Product.bUpdateStatus,
          iScrId: _Common.iScrId,
          ScrName: _Common.strScrName,
          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iVouchId: _HT.iVouchId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iProId: _Product.iProId,
          strJobId: _JobWork.JobId,
          iSerId: 0,
          iRowIndex: _Product.iRowIndex,
          iUnitId: Number(_Product.iUnitId),
          dQty: Number(_Product.dQty),
          FQty: Number(_Product.FQty),
          dPriceRate: Number(_Product.dRate),
          dPriceRateWithTax: Number(_Product.dRatewithTax),
          dDiscountPer: _Product.isItemDiscPer ? Number(_Product.dItemDisc) : 0,
          dDiscountAmt: _Product.isItemDiscPer ? 0 : Number(_Product.dItemDisc),
          dCashDiscountPer:
            _Product.isCashDiscPer === false ? Number(_Product.dCashDisc) : 0,
          dCashDiscountAmt:
            _Product.isCashDiscPer === true ? Number(_Product.dCashDisc) : 0,
          strProAttDTId: _Product.strProAttDTId,
          strStoAttDTId: _Product.strStoAttDTId,
          strTraAttDTId: _Product.strTraAttDTId,
          iALBillDiscId: _Common.iALBillDiscId,
          objBaseTransDT: _DT.arrEntDT,
          objItemRemark: _Product.Remarks,
          objRootCalc: _Product.Calculation,
          objPenCon: _DT.arrEntPenCon,
          objEntAddLess: _DT.arrEntAL,
          objEntConRow: [],
          objEntTax: [],
          strSearch: "",
          strColumn: "",
          strReason: "",
          strSetCurrentStatus: "",
          dAddLessDiscValue: 0,
          iYearId: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iUserId: Number(localStorage.UserId),
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
        };
        await api.post(
          `Transaction/AddUpd_Product/`,
          objParams
        ).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(AddProduct(Res.data));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Unit_Onchange = (UnitId) => {
    api.get(
      
        "Transaction/Unit_Onchange/" +
        Number(UnitId) +
        "/" +
        _Product.iProId +
        "/" +
        _HT.iPartyId +
        "/" +
        _HT.iTaxRelId +
        "/" +
        _Common.strScrType +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        dispatch(
          Select_Unit({ dPriceRate: res.data.dPriceRate, UnitId: UnitId })
        );
      } else {
        AlertMessage(res.data.message, "error");
      }
    });
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const JobInput_Onchange = (e) => {
    dispatch(
      JobWorkInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_Onchange = (e) => {
    dispatch(
      ProductRate_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_LostFocus = (e) => {
    dispatch(
      ProductRate_LostFocus({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Disc_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.name,
        Value: e.value,
      })
    );
  };
  const KeyDown = (e) => {
    if (e.key === "Enter" && e.key === "ArrowUp" && e.key === "ArrowDown") {
      KeyUpDown(e, _Common, _DT);
    }
  };
  const Remove_tableProduct = async (iIndex) => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(`Transaction/Delete_tableProduct/`, {
        iRowIndex: iIndex,
        iScrId: _Common.iScrId,
        ScrName: _Common.strScrName,
        iALBillDiscId: _Common.iALBillDiscId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,

        iTaxRelId: _HT.iTaxRelId,
        iPartyId: _HT.iPartyId,
        iVouchId: _HT.iVouchId,

        iProId: 0,
        iUnitId: 0,
        dQty: 0,
        dPriceRate: 0,
        dDiscountPer: 0,
        dDiscountAmt: 0,

        strSearch: "",
        strColumn: "",
        strReason: "",
        strJobId: "0",
        strSetCurrentStatus: "",

        strProAttDTId: "",
        strStoAttDTId: "",
        strTraAttDTId: "",
        objItemRemark: {},
        objRootCalc: {},

        dAddLessDiscValue: _Disc.dALBillDisValue,
        dTBillDiscPer: Number(_Disc.dBillDisPer),
        dTBillDiscAmt: Number(_Disc.dBillDisAmt),

        objPenCon: _DT.arrEntPenCon,
        objBaseTransDT: _DT.arrEntDT,
        objEntAddLess: _DT.arrEntAL,
        objEntConRow: [],
        objEntTax: _DT.arrEntTax,

        iYearId: Number(localStorage.FYId),
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
      }).then((res) => {
        if (res.data.strStatus === "Success") {
          dispatch(Remove_product(res.data));
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      await AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Disable_Product = () => {
    switch (Number(_Common.iScrId)) {
      case 73:
      case 75:
      case 76:
        return true;
      default:
        if (Number(_HT.iPartyId) === 0) return true;
        else return false;
    }
  };
  return (
    <div className="fg-card shadow-base">
      <div className="row pl-2 pr-2 pb-2">
        {_Common.TransPer.IsProCode && (
          <div className="col-sm-6 col-md-4 mt-2">
            <label>Product Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Press Enter to Select Product"
              style={{ minWidth: "150px" }}
              value={_Product.strProCode}
              readOnly
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (_Common.iScrId === 12 || _Common.iScrId === 18)
                    dispatch(ShowHide_Popup("IsReturnPro"));
                  dispatch(ShowHide_Popup("IsProSearch"));
                } else KeyDown(e);
              }}
            />
          </div>
        )}
        <div className="col-sm-12 col-md-3 mt-2">
          <label>Product Name</label>
          <div style={{ position: "relative" }}>
            <div className="custom-select">
              <input
                type="text"
                className="form-control"
                id="txtProName"
                name="strProName"
                spellCheck={false}
                autoComplete="off"
                placeholder="Press Enter to Select Product "
                value={_Product.strProName}
                tabIndex={_Common.arrtaborder.txtProductName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (_Common.iScrId === 12 || _Common.iScrId === 18)
                      dispatch(ShowHide_Popup("IsReturnPro"));
                    else dispatch(ShowHide_Popup("IsProSearch"));
                  }
                }}
                onClick={(e) => {
                  if (_Common.iScrId === 12 || _Common.iScrId === 18) {
                    dispatch(ShowHide_Popup("IsReturnPro"));
                  } else dispatch(ShowHide_Popup("IsProSearch"));
                }}
                onInput={(e) => {
                  if (_Common.iScrId === 12 || _Common.iScrId === 18)
                    dispatch(ShowHide_Popup("IsReturnPro"));
                  else dispatch(ShowHide_Popup("IsProSearch"));
                }}
                disabled={Disable_Product()}
              />
              <i
                className="fa-solid fa-caret-down"
                style={{
                  position: "absolute",
                  right: "10px",
                  color: "darkgreen",
                  transform: _Common.IsProSearch
                    ? "rotate(-180deg)"
                    : "rotate(0deg)",
                }}
              ></i>
            </div>
            {_Product.strProName.length > 0 && (
              <div className="tran-msgicon">
                <i
                  className="fa-solid fa-message"
                  onClick={(e) => dispatch(ShowHide_Popup("IsProRemark"))}
                ></i>
              </div>
            )}
            {_Product.strProName.length > 0 && (
              <div className="tran-calicon">
                <i
                  className="fa-solid fa-calculator"
                  onClick={(e) => dispatch(ShowHide_Popup("IsProCal"))}
                ></i>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6 col-md-1 mt-2">
          <label>Qty</label>
          <input
            type="number"
            className="form-control"
            name="dQty"
            autoComplete="off"
            value={_Product.dQty}
            onClick={(e) => e.target.select()}
            onChange={(e) => {
              Input_Onchange(e);
            }}
            tabIndex={_Common.arrtaborder.txtQty}
            onKeyDown={(e) => KeyDown(e)}
          />
        </div>
        {!_Common.IsHideJob && (
          <div className="col-sm-12 col-md-3 mt-2">
            <label>Job Name</label>
            <CustomizedSelectoption
              optionparem="isoptionShow16"
              showoption={_selecrparem.isoptionShow16}
              placeholder="Select JobWork"
              taborder={_Common.arrtaborder.cbJob}
              select_value={(val) =>
                JobInput_Onchange({
                  target: { name: "JobId", value: val },
                })
              }
              btnname="JobWork"
              btnshow={true}
              show_popup={(e) =>
                dispatch(
                  ShowHide_Popup({ Scrtype: "Job", isshow: "IsVenCreation" })
                )
              }
              valueonly={true}
              defaultval={_JobWork.JobId}
              displayName="strName"
              disvalue="iId"
              arrydata={_JobWork.arrJobWork}
              EmptVal="Job Work"
              disabled={_Product.strProName.length > 0 ? false : true}
            />
          </div>
        )}
        <div className="col-sm-6 col-md-2 mt-2">
          <label>Unit</label>
          <CustomizedSelectoption
            optionparem="isoptionShow3"
            showoption={_selecrparem.isoptionShow3}
            placeholder="Select Unit"
            select_value={(val) => Unit_Onchange(val)}
            taborder={_Common.arrtaborder.cbUnit}
            keydown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown");
              else KeyDown(e);
            }}
            btnname="Unit"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_Product.iUnitId}
            displayName="strUnitName"
            disvalue="iUnitId"
            arrydata={_Product.Unit}
            disabled={_Product.strProName.length === 0 ? true : false}
            EmptVal="Unit"
          />
        </div>
        <div className="col-sm-6 col-md-2 mt-2">
          <label>Rate Without Tax</label>
          <input
            type="number"
            aria-label="First name"
            className="form-control"
            name="dRate"
            autoComplete="off"
            id="txtproRate"
            value={_Product.dRate}
            onClick={(e) => e.target.select()}
            onChange={(e) => Rate_Onchange(e)}
            tabIndex={_Common.arrtaborder.txtRate}
            onKeyDown={(e) => KeyDown(e)}
            onBlur={(e) => Rate_LostFocus(e)}
          />
        </div>
        <div className="col-sm-6 col-md-1 mt-2" hidden={_Common.IsJobWorkDc}>
          <label>Rate With Tax</label>
          <input
            type="number"
            aria-label="First name"
            className="form-control"
            name="dRatewithTax"
            id="dPriceRatewithTax"
            autoComplete="off"
            value={_Product.dRatewithTax}
            onClick={(e) => e.target.select()}
            onChange={(e) => Rate_Onchange(e)}
            tabIndex={_Common.arrtaborder.txtRate}
            onKeyDown={(e) => KeyDown(e)}
            onBlur={(e) => Rate_LostFocus(e)}
          />
        </div>
        {_Common.TransPer.IsItemDisc && !_Common.IsJobWorkDc && (
          <div className="col-sm-6 col-md-1 mt-2">
            <label>Discount</label>
            <div className="trancdisc">
              <input
                type="number"
                className="form-control"
                name="dItemDisc"
                id="txtproItemDis"
                value={_Product.dItemDisc}
                autoComplete="off"
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                tabIndex={_Common.arrtaborder.txtDiscount}
                onKeyDown={(e) => KeyDown(e)}
                onBlur={(e) => {
                  e.target.value =
                    e.target.value === "" ? (0).toFixed(2) : e.target.value;
                  Input_Onchange(e);
                }}
              />
              <button
                className="btn-fabgreen active"
                onClick={(e) => {
                  $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                  Disc_Onchange({
                    name: "isItemDiscPer",
                    value: !_Product.isItemDiscPer,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                    $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                    Disc_Onchange({
                      name: "isItemDiscPer",
                      value: !_Product.isItemDiscPer,
                    });
                  } else KeyDown(e);
                }}
              >
                {_Product.isItemDiscPer ? "%" : "₹"}
              </button>
            </div>
          </div>
        )}
        {_Common.TransPer.IsCashDisc && !_Common.IsJobWorkDc && (
          <div className="col-sm-6 col-md-1 mt-2">
            <label>Cash Discount</label>
            <div className="trancdisc">
              <input
                type="number"
                className="form-control"
                name="dCashDisc"
                id="dCashDisc"
                autoComplete="off"
                value={_Product.dCashDisc}
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                tabIndex={_Common.arrtaborder.txtDiscount}
                onKeyDown={(e) => KeyDown(e)}
                onBlur={(e) => {
                  e.target.value =
                    e.target.value === "" ? (0).toFixed(2) : e.target.value;
                  Input_Onchange(e);
                }}
              />
              <button
                className="btn-fabgreen active"
                onClick={(e) => {
                  $(".trancdisc .btn-fabgreen:eq(1)").toggleClass("active");
                  Disc_Onchange({
                    name: "isCashDiscPer",
                    value: !_Product.isCashDiscPer,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                    $(".trancdisc .btn-fabgreen:eq(1)").toggleClass("active");
                    Disc_Onchange({
                      name: "isCashDiscPer",
                      value: !_Product.isCashDiscPer,
                    });
                  } else KeyDown(e);
                }}
              >
                {_Product.isCashDiscPer ? "%" : "₹"}
              </button>
            </div>
          </div>
        )}
        {_Common.TransPer.IsFQty && !_Common.IsJobWorkDc && (
          <div className="col-sm-6 col-md-1 mt-2">
            <label>FQTY</label>
            <input
              type="number"
              aria-label="First name"
              className="form-control"
              name="FQty"
              id="FQty"
              autoComplete="off"
              value={_Product.FQty}
              onClick={(e) => e.target.select()}
              onChange={(e) => Input_Onchange(e)}
              tabIndex={_Common.arrtaborder.txtDiscount}
              onKeyDown={(e) => KeyDown(e)}
              onBlur={(e) => {
                e.target.value = e.target.value === "" ? 0 : e.target.value;
                Input_Onchange(e);
              }}
            />
          </div>
        )}
        <div className="col-sm-4 col-md-2 mt-4">
          <div className="btn-sub-section">
            <button
              className="btn-fabgreen"
              onClick={() => AddProductClick()}
              tabIndex={_Common.arrtaborder.btnAdd}
              onKeyDown={(e) => {
                if (e.key === "Enter") AddProductClick();
                else KeyDown(e);
              }}
              disabled={_Product.strProName.length === 0 ? true : false}
            >
              Add&nbsp;<i className="bx bx-layer-plus"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() => dispatch(Clear_Product())}
              disabled={_Product.strProName.length === 0 ? true : false}
            >
              Clear&nbsp;<i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
      <ReactTable
        columns={_DT.arrEntDTCol}
        data={_DT.arrEntDT}
        minRows={_Common.MinRow}
        row_click={(ProInfo, Index) => {}}
        row_doubleclick={(ProInfo, Index) => View_ProductUnit(ProInfo, Index)}
        background={true}
        className="full-table"
        tabIndex={_Common.arrtaborder.DataTable}
        KeyDown={(e) => KeyDown(e)}
      />
    </div>
  );
}
export default TransactionInput;
