import { useState, useEffect } from "react";

import ExportImport from "./ExportImport";
import MasterList from "./MasterListItem";
import ShippingAddressPopup from "../Transaction/ShippingAddressPopup";
import api from "../Admin/Config";
import SideMenu from "../General/SideMenu";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import MasterCodeSetting from "./MasterCodeSetting";
import CountryMaster from "./CountryMaster";
import StateMaster from "./StateMaster";
function SupplierMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);

  const _ScrId = 3;
  const strSuppCodeTag = "Enter Supplier Code";
  const [ManualCode, setManualCode] = useState(true);

  const [iSuppId, setiSuppId] = useState(0);
  const [strSuppCode, setstrSuppCode] = useState("");
  const [strSuppName, setstrSuppName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [strOfficeNo, setstrOfficeNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strCountryId, setstrCountryId] = useState(1);
  const [strStateId, setstrStateId] = useState(32);
  const [strPhone, setstrPhone] = useState("");
  const [strPhone1, setstrPhone1] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strMobile1, setstrMobile1] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strTinNo, setstrTinNo] = useState("");
  const [iStatusId, setiStatusId] = useState(1);
  const [dOpBal, setdOpBal] = useState((0.0).toFixed());
  const [CreditTerm, setCreditTerm] = useState("30 Days");
  const [iVenTypeId, setiVenTypeId] = useState(1);
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [strFilterColumn, setstrFilterColumn] = useState("All");
  const [strFilter, setstrFilter] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [strGst, setstrGst] = useState("");
  const [ExpImpOpen, setExpImpOpen] = useState(false);
  const [iSupplierCount, setiSupplierCount] = useState(0);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntFillter, setlistEntFillter] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listEntCountry, setlistEntCountry] = useState([]);
  const [listEntState, setlistEntState] = useState([]);
  const [listEntVentTyp, setlistEntVentTyp] = useState([]);
  const [listEntBalTyp, setlistEntBalTyp] = useState([]);
  const [listEntSupList, setlistEntSupList] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [BtnExport, setBtnExport] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);

  const [bIsPhone1, setbIsPhone1] = useState(false);
  const [bIsMobile1, setbIsMobile1] = useState(false);
  const [bIsRemark, setbIsRemark] = useState(false);
  const [bIsStatus, setbIsStatus] = useState(false);
  const [bIsTinNo, setbIsTinNo] = useState(false);
  const [bIsBalType, setbIsBalType] = useState(false);
  const [bIsOpenBal, setbIsOpenBal] = useState(false);
  const [bIsCreditTerm, setbIsCreditTerm] = useState(false);
  const [bIsNewAddress, setbIsNewAddress] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [IsCountry, setIsCountry] = useState(false);
  const [IsState, setIsState] = useState(false);
  useEffect(() => {
    Load_SupplierMaster();
  }, []);
  const Load_SupplierMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: _ScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await api.post(
        `SupplierMaster/Load_SupplierMaster`,
        objParems
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setlistEntFillter(Res.data.objEntFilter);
          setlistEntStatus(Res.data.objEntStatus);
          setlistEntCountry(Res.data.objEntCountry);
          setlistEntState(Res.data.objEntState);
          setlistEntVentTyp(Res.data.objEntVenTyp);
          setlistEntBalTyp(Res.data.objEntBalTyp);
          setlistEntSupList(Res.data.objEntSupplierList);
          setlistCodeFormat(Res.data.objCodeFormat);
          setstrSuppCode(Res.data.objCodeFormat[0].strCode);
          setManualCode(!Res.data.objCodeFormat[0].isManualCode);
          var objEntField = Res.data.FieldPer;
          setbIsPhone1(objEntField[0].bIsVissible);
          setbIsMobile1(objEntField[1].bIsVissible);
          setbIsStatus(objEntField[2].bIsVissible);
          setbIsTinNo(objEntField[3].bIsVissible);
          setbIsRemark(objEntField[4].bIsVissible);
          setbIsBalType(objEntField[5].bIsVissible);
          setbIsOpenBal(objEntField[6].bIsVissible);
          setbIsCreditTerm(objEntField[7].bIsVissible);
          setListValidateProperties(Res.data.Transtab);
          TabOrderAssign(Res.data.Transtab);
          ButtonPermission(JSON.parse(localStorage.Menu));
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    try {
      dispatch(setload_Screen(false));
      setiSuppId(0);
      setstrSuppCode("");
      setstrSuppName("");
      setstrAliasName("");
      setstrOfficeNo("");
      setstrStreet("");
      setstrCity("");
      setstrArea("");
      setstrPinCode("");
      setstrCountryId(1);
      setstrStateId(32);
      setstrPhone("");
      setBtnDeleteDisable(false);
      setBtnSaveDisable(false);
      setstrPhone1("");
      setstrMobile("");
      setstrMobile1("");
      setstrEmail("");
      setstrRemarks("");
      setstrTinNo("");
      setiStatusId(1);
      setdOpBal((0.0).toFixed());
      setCreditTerm("30 Days");
      setiVenTypeId(1);
      setiBalTypeId(3);
      setstrFilterColumn("All");
      setstrFilter("");
      setstrSearch("");
      setstrGst("");
      setiSupplierCount(0);
      Load_SupplierMaster();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const ExportImportPopup = () => {
    try {
      setExpImpOpen(!ExpImpOpen);
      ClearClick();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const listEntSupInsUpd = {
        iSuppId: iSuppId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strSuppCode: strSuppCode,
        strSuppName: strSuppName,
        strAliasName: strAliasName,
        strAdd1: strOfficeNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPincode: strPinCode,
        iCountry: Number(strCountryId),
        iState: Number(strStateId),
        strPhone1: strPhone,
        strPhone2: strPhone1,
        strMobile1: strMobile,
        strMobile2: strMobile1,
        strEmail: strEmail,
        strRemarks: strRemarks,
        strGST: strGst,
        strTinNo: strTinNo,
        iStatusId: Number(iStatusId),
        dOpBal: Number(dOpBal),
        iBalTypeId: Number(iBalTypeId),
        iVenTypeId: Number(iVenTypeId),
        CreditTerm: CreditTerm,
        strSearch: strSearch,
        strFilter: strFilter,
        strFilterColumn: strFilterColumn,
        iTitleTag: _ScrId,
        strSuppCodeTag: strSuppCodeTag,
        strCompanyDB: localStorage.db,
      };
      await api.post(
        `SupplierMaster/InsUpd_Supplier`,
        listEntSupInsUpd
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        `SupplierMaster/Delete_Supplier/${iSuppId}/${iVenTypeId}/${_ScrId}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Supplier = async (iSuppId) => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(
        `SupplierMaster/View_Supplier/${iSuppId}/${_ScrId}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setiSuppId(Res.data.objEntSupp[0].iSuppId);
          setstrSuppCode(Res.data.objEntSupp[0].strSuppCode);
          setstrSuppName(Res.data.objEntSupp[0].strSuppName);
          setstrAliasName(Res.data.objEntSupp[0].strAliasName);
          setstrOfficeNo(Res.data.objEntSupp[0].strAdd1);
          setstrStreet(Res.data.objEntSupp[0].strAdd2);
          setstrArea(Res.data.objEntSupp[0].strAdd3);
          setstrCity(Res.data.objEntSupp[0].strAdd4);
          setstrPinCode(Res.data.objEntSupp[0].strPincode);

          setstrPhone(Res.data.objEntSupp[0].strPhone1);
          setstrPhone1(Res.data.objEntSupp[0].strPhone2);
          setstrMobile(Res.data.objEntSupp[0].strMobile1);
          setstrMobile1(Res.data.objEntSupp[0].strMobile2);
          setstrEmail(Res.data.objEntSupp[0].strEmail);
          setstrGst(Res.data.objEntSupp[0].strGST);
          setstrTinNo(Res.data.objEntSupp[0].strTinNo);
          setiStatusId(Res.data.objEntSupp[0].iStatusId);
          setstrRemarks(Res.data.objEntSupp[0].strRemarks);
          setiBalTypeId(Res.data.objEntSupp[0].iBalTypeId);
          setdOpBal(Res.data.objEntSupp[0].dOpBal);
          setiVenTypeId(Res.data.objEntSupp[0].iVenTypeId);
          setCreditTerm(Res.data.objEntSupp[0].creditTerm);
          setlistEntState(Res.data.objEntState);
          setstrCountryId(Res.data.objEntSupp[0].iCountry);
          setstrStateId(Res.data.objEntSupp[0].iState);
          ButtonPermission(
            JSON.parse(localStorage.Menu),
            Res.data.objEntSupp[0].iSuppId
          );
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const CountryOnChange = async (CountryId) => {
    try {
      setstrCountryId(CountryId);
      const parems = {
        iCountryId: Number(CountryId),
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await api.post(`CountryState/Load_State`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setlistEntState(res.data.EntState);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Search_Supplier = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strColName: "All",
        iScrId: _ScrId,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(
         "SupplierMaster/Search_Supplier/",
        objSearch
      ).then((Res) => {
        if (Res.data.strStatus === "Success")
          setlistEntSupList(Res.data.EntSupplier);
        else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      SupCodeTab: "",
      AliasTab: "",
      NameTab: "",
      OfficeNoTab: "",
      StreetTab: "",
      AreaTab: "",
      CityTab: "",
      PinCodeTab: "",
      CountryTab: "",
      StateTab: "",
      Phone1Tab: "",
      Phone2Tab: "",
      Mobile1Tab: "",
      Mobile2Tab: "",
      Emailtab: "",
      GstTab: "",
      TinNoTab: "",
      RemarksTab: "",
      CreditLimitTab: "",
      CreditTermTab: "",
      StatusTab: "",
      OpeningBalTab: "",
      BalTypeTab: "",
      VendorTypeTab: "",
      PriceGrpTab: "",
      SupplierListTab: "",
      SupplierFIlterTab: "",
      SupplierSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
      btnCountry: "",
      btnPriceGrp: "",
      btnState: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtCode":
          TabOrder[0].SupCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtName":
          TabOrder[0].NameTab = EntProperties[i].iTabIndex;
          break;
        case "txtAlias":
          TabOrder[0].AliasTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd1":
          TabOrder[0].OfficeNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd2":
          TabOrder[0].StreetTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd3":
          TabOrder[0].AreaTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd4":
          TabOrder[0].CityTab = EntProperties[i].iTabIndex;
          break;
        case "cbCountry":
          TabOrder[0].CountryTab = EntProperties[i].iTabIndex;
          break;
        case "txtPinCode":
          TabOrder[0].PinCodeTab = EntProperties[i].iTabIndex;
          break;
        case "cbState":
          TabOrder[0].StateTab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone1":
          TabOrder[0].Phone1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone2":
          TabOrder[0].Phone2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile1":
          TabOrder[0].Mobile1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile2":
          TabOrder[0].Mobile2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtEmail":
          TabOrder[0].Emailtab = EntProperties[i].iTabIndex;
          break;
        case "txtTin":
          TabOrder[0].TinNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtGst":
          TabOrder[0].GstTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarksTab = EntProperties[i].iTabIndex;
          break;
        case "txtOpBalance":
          TabOrder[0].OpeningBalTab = EntProperties[i].iTabIndex;
          break;
        case "cbBalType":
          TabOrder[0].BalTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditLimit":
          TabOrder[0].CreditLimitTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditTerm":
          TabOrder[0].CreditTermTab = EntProperties[i].iTabIndex;
          break;
        case "cbVenType":
          TabOrder[0].VendorTypeTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "btnCountry":
          TabOrder[0].btnCountry = EntProperties[i].iTabIndex;
          break;
        case "btnPriceGrp":
          TabOrder[0].btnPriceGrp = EntProperties[i].iTabIndex;
          break;
        case "btnState":
          TabOrder[0].btnState = EntProperties[i].iTabIndex;
          break;
        case "cbPriceGroup":
          TabOrder[0].PriceGrpTab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].SupplierFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].SupplierSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbSupplierName":
          TabOrder[0].SupplierListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].SupplierListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].SupplierListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].SupplierListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].SupplierListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtName":
          ErrorMsg = strSuppName === "" ? false : true;
          break;
        case "txtAlias":
          ErrorMsg = strAliasName === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = strOfficeNo === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = strStreet === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = strArea === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = strCity === "" ? false : true;
          break;
        case "txtPinCode":
          ErrorMsg = strPinCode === "" ? false : true;
          break;
        case "txtPhone1":
          ErrorMsg = strPhone === "" ? false : true;
          break;
        case "txtPhone2":
          ErrorMsg = strPhone1 === "" ? false : true;
          break;
        case "txtMobile1":
          ErrorMsg = strMobile === "" ? false : true;
          break;
        case "txtMobile2":
          ErrorMsg = strMobile1 === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = strEmail === "" ? false : true;
          break;
        case "txtTin":
          ErrorMsg = strTinNo === "" ? false : true;
          break;
        case "txtGst":
          ErrorMsg = strGst === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "txtOpBalance":
          ErrorMsg = dOpBal === "" ? false : true;
          break;
        case "txtCreditTerm":
          ErrorMsg = CreditTerm === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const ButtonPermission = (ListMenu) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === _ScrId);
    if (ListMenu.length > 0) {
      if (Number(iSuppId) > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (Number(iSuppId) === 0) {
        if (ListMenu[0].isExport === false) {
          setBtnExport(true);
        } else {
          setBtnExport(false);
        }
        if (ListMenu[0].isNew === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
      }
    }
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={_ScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntFillter}
          EntList={listEntSupList}
          GetDetails={View_Supplier}
          Count={iSupplierCount}
          Search={Search_Supplier}
          ScreenID={_ScrId}
          accessor="strSuppName"
          accessorid="iSuppId"
          ListTab={ListTabOrder[0].SupplierListTab}
          FilterTab={ListTabOrder[0].SupplierFIlterTab}
          SearchTab={ListTabOrder[0].SupplierSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strSuppName}
                  onChange={(e) => setstrSuppName(e.target.value)}
                  autoFocus
                  placeholder="Enter Supplier Name"
                  spellCheck={false}
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].name}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={strSuppCode}
                    autoComplete="off"
                    readOnly={ManualCode}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].SupCodeTab}
                    onChange={(e) => setstrSuppCode(e.target.value)}
                    name="strProCode"
                  />{" "}
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Alias Name</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strAliasName}
                  onChange={(e) => setstrAliasName(e.target.value)}
                  placeholder="Enter Alias Name"
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].AliasTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Office /Door No </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strOfficeNo}
                  onChange={(e) => setstrOfficeNo(e.target.value)}
                  placeholder="Enter Office /Door No"
                  onKeyDown={(e) => TabIndex(e)}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].OfficeNoTab}
                />
                {iSuppId > 0 && (
                  <span
                    className="btn-shipadd"
                    onClick={(e) => setbIsNewAddress(!bIsNewAddress)}
                  >
                    <i className="fa-solid fa-location-dot ml-2"></i>
                    Add Secondary Address
                  </span>
                )}
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Street/Village</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strStreet}
                  onChange={(e) => setstrStreet(e.target.value)}
                  placeholder="Enter Street/Village"
                  onKeyDown={(e) => TabIndex(e)}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].StreetTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Area/Town</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strArea}
                  onChange={(e) => setstrArea(e.target.value)}
                  placeholder="Enter Area/Town"
                  onKeyDown={(e) => TabIndex(e)}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].AreaTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>City</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strCity}
                  onChange={(e) => setstrCity(e.target.value)}
                  placeholder="Enter City Name"
                  onKeyDown={(e) => TabIndex(e)}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].CityTab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Pincode</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  value={strPinCode}
                  onChange={(e) => setstrPinCode(e.target.value)}
                  placeholder="Enter PinCode"
                  onKeyDown={(e) => TabIndex(e)}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].PinCodeTab}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Country"
                  select_value={(val) => CountryOnChange(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].CountryTab}
                  btnname="Country"
                  btnshow={true}
                  show_popup={() => setIsCountry(!IsCountry)}
                  valueonly={true}
                  defaultval={strCountryId}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={listEntCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select State"
                  select_value={(val) => setstrStateId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StateTab}
                  btnname="State"
                  btnshow={true}
                  show_popup={() => setIsState(!IsState)}
                  valueonly={true}
                  defaultval={strStateId}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={listEntState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Phone </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  value={strPhone}
                  onChange={(e) => setstrPhone(e.target.value)}
                  placeholder="Enter Phone No"
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Phone1Tab}
                />
              </div>
              {bIsPhone1 && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Phone 1</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={strPhone1}
                    autoComplete="off"
                    onChange={(e) => setstrPhone1(e.target.value)}
                    placeholder="Enter Phone No"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Phone2Tab}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Mobile</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  value={strMobile}
                  autoComplete="off"
                  onChange={(e) => setstrMobile(e.target.value)}
                  placeholder="Enter Mobile No"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Mobile1Tab}
                />
              </div>
              {bIsMobile1 && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Mobile 1</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={strMobile1}
                    autoComplete="off"
                    onChange={(e) => setstrMobile1(e.target.value)}
                    placeholder="Enter Mobile No"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Mobile2Tab}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>E-Mail </label>
                <input
                  type="email"
                  aria-label="First name"
                  className="form-control"
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                  placeholder="Enter Email"
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Emailtab}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>GST</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strGst}
                  onChange={(e) => setstrGst(e.target.value)}
                  placeholder="Enter GST No"
                  onKeyDown={(e) => TabIndex(e)}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].GstTab}
                />
              </div>
              {bIsTinNo && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>TIN</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strTinNo}
                    onChange={(e) => setstrTinNo(e.target.value)}
                    placeholder="Enter TIN No"
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].TinNoTab}
                  />
                </div>
              )}
              {bIsStatus && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_selecrparem.isoptionShow2}
                    placeholder="Select Status"
                    select_value={(val) => setiStatusId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].StatusTab}
                    btnname="Status"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStatusId}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={listEntStatus}
                    disabled={false}
                    EmptVal="Status "
                  />
                </div>
              )}
              {bIsRemark && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Remarks</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strRemarks}
                    onChange={(e) => setstrRemarks(e.target.value)}
                    placeholder="Enter Remark"
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].RemarksTab}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Ventor Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Vendor Type"
                  select_value={(val) => setiVenTypeId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].VendorTypeTab}
                  btnname="Status"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iVenTypeId}
                  displayName="strVenTypeName"
                  disvalue="iVenTypeId"
                  arrydata={listEntVentTyp}
                  disabled={false}
                  EmptVal="Vendor Type   "
                />
              </div>
              {bIsOpenBal && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Opening Balance</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={dOpBal}
                    onChange={(e) => setdOpBal(e.target.value)}
                    placeholder="Enter Opening Balance"
                    onKeyDown={(e) => TabIndex(e)}
                    autoComplete="off"
                    tabIndex={ListTabOrder[0].OpeningBalTab}
                  />
                </div>
              )}
              {bIsBalType && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Balance Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Select Balance Type"
                    select_value={(val) => setiBalTypeId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].BalTypeTab}
                    btnname="Balance Type"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iBalTypeId}
                    displayName="strBalType"
                    disvalue="iBalTypeId"
                    arrydata={listEntBalTyp}
                    disabled={false}
                    EmptVal="Balance Type "
                  />
                </div>
              )}
              {bIsCreditTerm && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Credit Term</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    autoComplete="off"
                    value={CreditTerm}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].CreditTermTab}
                    onChange={(e) => setCreditTerm(e.target.value)}
                  />
                </div>
              )}
            </div>
            {ExpImpOpen && (
              <ExportImport
                Close={(e) => {
                  setExpImpOpen(!ExpImpOpen);
                  ClearClick();
                }}
                iScrId={_ScrId}
              />
            )}
            {bIsNewAddress && (
              <ShippingAddressPopup
                PartyId={iSuppId}
                PartyName={strSuppName}
                Close={(e) => setbIsNewAddress(!bIsNewAddress)}
                ScreentType="Purchase"
                iScrId={_ScrId}
                Update={(e) => setbIsNewAddress(!bIsNewAddress)}
              />
            )}
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={_ScrId}
              />
            )}
            {IsCountry && (
              <CountryMaster Close={() => setIsCountry(!IsCountry)} />
            )}
            {IsState && <StateMaster Close={() => setIsState(!IsState)} />}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              disabled={BtnSaveDisable}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnSavtab}
              onClick={(e) => {
                if (PropertiesValidation()) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Supplier?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={ExportImportPopup}
              disabled={BtnExport}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnExporttab}
            >
              Export&nbsp;<i className="bx bxs-file-export"></i>
            </button>
            <button
              className="btn-fabgreen"
              disabled={BtnDeleteDisable}
              onClick={(e) => {
                if (iSuppId === 0) {
                  AlertMessage("Please Select Supplier", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Supplier?",
                      isdialog: true,
                    })
                  );
                }
              }}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnDeletetab}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={ClearClick}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnCleartab}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href =
                  "/Dashboard")
              }
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnClosetab}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default SupplierMaster;
