import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";

import api from "../Admin/Config";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import UserRole from "./UserRole";
import ReactTable from "../General/ReactTable";
function UserAccount() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);

  const [bIsNew, setbIsNew] = useState(false);
  const [bIsView, setbIsView] = useState(false);
  const [bIsModify, setbIsModify] = useState(false);
  const [bIsExport, setbIsExport] = useState(false);
  const [bIsDelete, setbIsDelete] = useState(false);
  const [bIsPrint, setbIsPrint] = useState(false);
  const [bIsNoAccess, setbIsNoAccess] = useState(false);
  const [bIsSelect, setbIsSelect] = useState(false);
  const [bIsShow, setbIsShow] = useState(false);

  const [isUserRole, setisUserRole] = useState(false);
  const [IsMaster, setIsMaster] = useState(true);
  const [IsTrans, setIsTrans] = useState(false);
  const [IsCompany, setIsCompany] = useState(false);
  const [IsReport, setIsReport] = useState(false);
  const [IsCompanyList, setIsCompanyList] = useState(false);

  const [iUserId, setiUserId] = useState(0);
  const [strName, setstrName] = useState("");
  const [dpDOB, setdpDOB] = useState(new Date().toISOString().split("T")[0]);
  const [dpDOJ, setdpDOJ] = useState(new Date().toISOString().split("T")[0]);
  const [iGender, setiGender] = useState(1);
  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strConfirmPassword, setstrConfirmPassword] = useState("");
  const [iRoleId, setiRoleId] = useState(1);
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [strPincode, setstrPincode] = useState("");
  const [iCountry, setiCountry] = useState(1);
  const [iState, setiState] = useState(32);
  const [strPhone, setstrPhone] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [iStatus, setiStatus] = useState(1);
  const [strRemarks, setstrRemarks] = useState("");

  const [iScrCatId, setiScrCatId] = useState(1);
  const [arrEntUser, setarrEntUser] = useState([]);
  const [arrEntUserRole, setarrEntUserRole] = useState([]);
  const [arrEntScr, setarrEntScr] = useState([]);
  const [arrEntState, setarrEntState] = useState([]);
  const [arrEntCountry, setarrEntCountry] = useState([]);
  const [arrEntStatus, setarrEntStatus] = useState([]);
  const [arrEntGender, setarrEntGender] = useState([]);
  const [arrEntPermission, setarrEntPermission] = useState([]);

  useEffect(() => {
    Load_UserMaster();
  }, []);

  const Load_UserMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get("UserMaster/Load_UserMaster/" + localStorage.Msdb)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntUser(Res.data.EntUser);
            setarrEntUserRole(Res.data.EntUserRole);
            setarrEntScr(Res.data.EntScrType);
            setarrEntState(Res.data.EntState);
            setarrEntCountry(Res.data.EntCountry);
            setarrEntStatus(Res.data.EntStatus);
            setarrEntGender(Res.data.EntGender);
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(JSON.stringify(err), "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_User = (User) => {
    try {
      setiUserId(Number(User.iUserId));
      setiRoleId(Number(User.iUserRole));
      setiGender(Number(User.iGender));
      setstrName(User.strName);
      setdpDOB(User.dpDOB.split("T")[0]);
      setdpDOJ(User.dpDOJ.split("T")[0]);
      setstrUserName(User.strUserName);
      setstrPassword(User.strPassword);
      setstrConfirmPassword(User.strPassword);
      setstrAdd1(User.strAdd1);
      setstrAdd2(User.strAdd2);
      setstrAdd3(User.strAdd3);
      setstrAdd4(User.strAdd4);
      setstrPincode(User.strPincode);
      setiCountry(User.iCountry);
      setiState(User.istate);
      setstrPhone(User.strPhone1);
      setstrMobile(User.strMobile1);
      setstrEmail(User.strEmail);
      setiStatus(Number(User.iStatus));
      setstrRemarks(User.strRemarks);
      Load_UserPermssion(1, User.iUserId);
    } catch (err) {
      AlertMessage(JSON.stringify(err), "info");
    }
  };
  const Load_UserPermssion = async (CatId, UId) => {
    try {
      await setiScrCatId(Number(CatId));
      await View_PermColumn(Number(CatId));
      await dispatch(setload_Screen(true));
      await api
        .get(
          `UserMaster/Load_UserPermission/${UId}/${CatId}/${localStorage.Msdb}`
        )
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            setarrEntPermission(res.data.EntPermiss);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Update_User = async () => {
    try {
      if (strUserName === "") {
        AlertMessage("Pleaser Enter Valid User Name", "info");
      } else if (strPassword === "") {
        AlertMessage("Pleaser Enter Valid Password", "info");
      } else if (strPassword === !strConfirmPassword) {
        AlertMessage("Password doesn't Match", "info");
      } else {
        const UserModel = {
          iUserId: iUserId,
          strName: strName,
          iGender: Number(iGender),
          dpDOB: dpDOB,
          strAdd1: strAdd1,
          strAdd2: strAdd2,
          strAdd3: strAdd3,
          strAdd4: strAdd4,
          strPincode: strPincode,
          iCountry: Number(iCountry),
          istate: Number(iState),
          strPhone1: strPhone,
          strPhone2: "",
          strMobile1: strMobile,
          strMobile2: "",
          strEmail: strEmail,
          iStatus: Number(iStatus),
          strRemarks: strRemarks,
          strUserName: strUserName,
          strPassword: strPassword,
          dpDOJ: dpDOJ,
          iUserRole: iRoleId,
          iClientId: Number(localStorage.ClientId),
          strMasterDB: localStorage.Msdb,
        };
        const objUser = {
          iUserId: iUserId,
          iRoleId: iRoleId,
          iCurrUserId: Number(localStorage.UserId),
          iScrCategId: iScrCatId,
          strMasterDB: localStorage.Msdb,
          objUser: UserModel,
          objEntMPM: arrEntPermission,
          objEntSPM: arrEntPermission,
          objEntTPM: arrEntPermission,
          objEntRPM: arrEntPermission,
          objEntCMPM: arrEntPermission,
          objEntCLPM: arrEntPermission,
        };
        api.post("UserMaster/InsUpd_UserMaster", objUser).then((Res) => {
          if (Res.data.strStatus === "Success") {
            if (iUserId === Number(localStorage.UserId))
              localStorage.Menu = JSON.stringify(Res.data.objMenu);
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const ClearClick = () => {
    setiUserId(0);
    setiScrCatId(1);
    setiRoleId(1);
    setiGender(1);
    setstrName("");
    setdpDOB(new Date().toISOString().split("T")[0]);
    setdpDOJ(new Date().toISOString().split("T")[0]);
    setstrUserName("");
    setstrPassword("");
    setstrConfirmPassword("");
    setstrAdd1("");
    setstrAdd2("");
    setstrAdd3("");
    setstrAdd4("");
    setstrPincode("");
    setiCountry(1);
    setiState(32);
    setstrPhone("");
    setstrMobile("");
    setstrEmail("");
    setiStatus(1);
    setstrRemarks("");
    setarrEntUser([]);
    setarrEntUserRole([]);
    setarrEntScr([]);
    setarrEntState([]);
    setarrEntCountry([]);
    setarrEntStatus([]);
    setarrEntGender([]);
    setarrEntPermission([]);
    Load_UserMaster();
  };
  const View_State = async (CountryId) => {
    try {
      setiCountry(CountryId);
      await api
        .get(`UserMaster/Load_State/${CountryId}/${localStorage.Msdb}`)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            setarrEntState(res.data.EntState);
          } else {
            AlertMessage(res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const RegTabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 13)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const DeleteUser = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post(
          `UserMaster/Delete_User/${Number(localStorage.ClientId)}/${Number(
            iUserId
          )}/${localStorage.Msdb}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const PermissionAll_OnChange = (Value, ValueFor) => {
    let UpdatePermission = arrEntPermission;
    switch (ValueFor) {
      case "NEW":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isNew = Value;
          return Data;
        });
        break;
      case "VIEW":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isView = Value;
          return Data;
        });
        break;
      case "MODIFY":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isModify = Value;
          return Data;
        });
        break;
      case "DELETE":
        UpdatePermission = UpdatePermission.map((Data) => {
          if (Data.isDelete === undefined) {
            Data.isCancel = Value;
          } else if (Data.isCancel === undefined) {
            Data.isDelete = Value;
          }
          return Data;
        });
        break;
      case "PRINT":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isPrint = Value;
          return Data;
        });
        break;
      case "ACCESS":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isNOAccess = Value;
          return Data;
        });
        break;
      case "SELECT":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.bIsVisible = Value;
          return Data;
        });
        break;
      case "EXPORT":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isExport = Value;
          return Data;
        });
        break;
      case "SHOW":
        UpdatePermission = UpdatePermission.map((Data) => {
          if (Data.bShowReport === undefined) Data.bShowCompany = Value;
          else if (Data.bShowCompany === undefined) Data.bShowReport = Value;
          return Data;
        });
        break;
      default:
        break;
    }
    setarrEntPermission(UpdatePermission);
  };
  const PermissionColumns = [
    {
      Header: "S.No",
      accessor: "sNo",
      style: { justifyContent: "center" },
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: "SCREEN NAME",
      accessor: "strScrName",
      style: { justifyContent: "left" },
      show: IsMaster || IsTrans,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsNew}
            onChange={(e) => {
              setbIsNew(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "NEW");
            }}
          />
          <span>New</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isNew}
          onChange={(e) => {
            rowinfo.isNew = !rowinfo.isNew;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsView}
            onChange={(e) => {
              setbIsView(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "VIEW");
            }}
          />
          <span>View</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isView}
          onChange={(e) => {
            rowinfo.isView = !rowinfo.isView;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsModify}
            onChange={(e) => {
              setbIsModify(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "MODIFY");
            }}
          />
          <span>Modify</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          checked={rowinfo.isModify}
          onChange={(e) => (rowinfo.isModify = !rowinfo.isModify)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsExport}
            onChange={(e) => {
              setbIsExport(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "EXPORT");
            }}
          />
          <span>Export</span>
        </div>
      ),
      isHeaderCell: true,
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
      show: IsMaster,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isExport}
          onChange={(e) => (rowinfo.isExport = !rowinfo.isExport)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsDelete}
            onChange={(e) => {
              setbIsDelete(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "DELETE");
            }}
          />
          <span>DELETE</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={IsTrans ? rowinfo.isCancel : rowinfo.isDelete}
          onChange={(e) => {
            if (IsTrans) rowinfo.isCancel = !rowinfo.isCancel;
            else rowinfo.isDelete = !rowinfo.isDelete;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsPrint}
            onChange={(e) => {
              setbIsPrint(!bIsPrint);
              PermissionAll_OnChange(!bIsPrint, "PRINT");
            }}
          />
          <span>Print</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      show: IsTrans,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isPrint}
          onChange={(e) => {
            rowinfo.isPrint = !rowinfo.isPrint;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsNoAccess}
            onChange={(e) => {
              setbIsNoAccess(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "ACCESS");
            }}
          />
          <span>No Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <div>
          <input
            type="checkbox"
            className="form-check-input"
            defaultChecked={rowinfo.isNOAccess}
            onChange={(e) => (rowinfo.isNOAccess = !rowinfo.isNOAccess)}
          />
        </div>
      ),
    },
    {
      Header: "Access",
      accessor: "strAccessName",
      show: IsCompany,
      style: { justifyContent: "left" },
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsSelect}
            onChange={(e) => {
              setbIsSelect(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "SELECT");
            }}
          />
          <span>Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsCompany,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bIsVisible}
          onChange={(e) => {
            rowinfo.bIsVisible = !rowinfo.bIsVisible;
          }}
        />
      ),
    },
    {
      Header: "REPORT NAME",
      accessor: "strRptName",
      style: { justifyContent: "left" },
      show: IsReport,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsShow}
            onChange={(e) => {
              setbIsShow(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "SHOW");
            }}
          />
          <span>Show</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsReport,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bShowReport}
          onChange={(e) => {
            rowinfo.bShowReport = !rowinfo.bShowReport;
          }}
        />
      ),
    },
    {
      Header: "COMPANY NAME",
      accessor: "strCompName",
      style: { justifyContent: "left" },
      show: IsCompanyList,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsShow}
            onChange={(e) => {
              setbIsShow(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "SHOW");
            }}
          />
          <span>Show</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsCompanyList,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bShowCompany}
          onChange={(e) => {
            rowinfo.bShowCompany = !rowinfo.bShowCompany;
          }}
        />
      ),
    },
  ];
  const View_PermColumn = (ScrId) => {
    setIsReport(false);
    setIsCompanyList(false);
    setIsCompany(false);
    setIsTrans(false);
    setIsMaster(false);
    switch (ScrId) {
      case 2:
      case 3:
      case 5:
      case 6:
        setIsTrans(true);
        break;
      case 7:
      case 8:
        setIsCompany(true);
        break;
      case 10:
        setIsReport(true);
        break;
      case 11:
        setIsCompanyList(true);
        break;
      default:
        setIsMaster(true);
        break;
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={107} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="data-list">
            {arrEntUser.map((user, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = arrEntUser.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list  [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list  [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list  [tabindex=${arrlength}]`).focus();
                    else $(`.data-list  [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_User(user);
                  }
                }}
              >
                <label onClick={(e) => View_User(user)}>{user.strName}</label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-3  mt-1">
                <label>User Role</label> <span className="danger">*</span>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select User Role"
                  select_value={(val) => setiRoleId(val)}
                  btnname="User Role"
                  btnshow={true}
                  show_popup={(e) => setisUserRole(!isUserRole)}
                  valueonly={true}
                  defaultval={iRoleId}
                  displayName="strName"
                  disvalue="iRoleId"
                  arrydata={arrEntUserRole}
                  EmptVal="User Role"
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Name</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Name"
                  tabIndex={1}
                  value={strName}
                  onChange={(e) => setstrName(e.target.value)}
                  onClick={(e) => e.target.select()}
                  onKeyDown={(e) => RegTabIndex(e)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  tabIndex={8}
                  value={dpDOB}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdpDOB(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Date of Joining</label>
                <input
                  type="date"
                  className="form-control"
                  tabIndex={8}
                  value={dpDOJ}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdpDOJ(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Gender</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select Gender"
                  select_value={(val) => setiGender(val)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iGender}
                  displayName="strGenderName"
                  disvalue="iGenderId"
                  arrydata={arrEntGender}
                  disabled={false}
                  EmptVal="Gender"
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>User Name</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Name"
                  tabIndex={2}
                  value={strUserName}
                  onChange={(e) => setstrUserName(e.target.value)}
                  onClick={(e) => e.target.select()}
                  onKeyDown={(e) => RegTabIndex(e)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Password</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Password"
                  tabIndex={3}
                  value={strPassword}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPassword(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Confirm Password</label>
                <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Password"
                  tabIndex={4}
                  value={strConfirmPassword}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrConfirmPassword(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value !== strPassword) {
                      AlertMessage("Password Doesn't Match", "info");
                    }
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Door/Office No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Door/Office No"
                  tabIndex={5}
                  value={strAdd1}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd1(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Street/Area</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Street/Area"
                  tabIndex={6}
                  value={strAdd2}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd2(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Village/Town</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Village/Town"
                  tabIndex={7}
                  value={strAdd3}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd3(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City"
                  tabIndex={8}
                  value={strAdd4}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd4(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Pincode</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Pincode"
                  tabIndex={8}
                  value={strPincode}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPincode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Select Country"
                  select_value={(val) => View_State(val)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iCountry}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={arrEntCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select State"
                  select_value={(val) => setiState(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iState}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={arrEntState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile No"
                  tabIndex={8}
                  value={strMobile}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrMobile(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone No"
                  tabIndex={8}
                  value={strPhone}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPhone(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={8}
                  value={strEmail}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrEmail(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Remark"
                  tabIndex={8}
                  value={strRemarks}
                  onKeyDown={(e) => RegTabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_selecrparem.isoptionShow4}
                  placeholder="Select Status"
                  select_value={(val) => setiStatus(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatus}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={arrEntStatus}
                  disabled={false}
                  EmptVal="Status "
                />
              </div>
              <div className="col-md-6 col-sm-6 mt-1">
                <label>Screen</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow5"
                  showoption={_selecrparem.isoptionShow5}
                  placeholder="Select Screen"
                  select_value={(val) => Load_UserPermssion(val, iUserId)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iScrCatId}
                  displayName="strScrCatName"
                  disvalue="iScrCatId"
                  arrydata={arrEntScr}
                  EmptVal="Screen"
                />
              </div>
            </div>
            <ReactTable
              columns={PermissionColumns}
              data={arrEntPermission}
              minRows={5}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {}}
              background={true}
              className="full-table"
            />
            <Messagedialogbox />
            {isUserRole && (
              <UserRole Close={() => setisUserRole(!isUserRole)} />
            )}
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              disabled={Number(iUserId) === 0 ? true : false}
              onClick={(e) => {
                if (Number(iRoleId) === 0) {
                  AlertMessage("Please Enter UserRole", "info");
                } else if (strUserName === "") {
                  AlertMessage("Please Enter UserName", "info");
                } else if (strPassword === "") {
                  AlertMessage("Please Enter Password", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Update_User,
                      Question: "Do You Want to Update User?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Update User&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iUserId) === 1)
                  AlertMessage("Admin User Can't Delete ", "error");
                else if (Number(iUserId) === 0)
                  AlertMessage("Please Select User", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteUser,
                      Question: "Do You Want to Delete User?",
                      isdialog: true,
                    })
                  );
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href = "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default UserAccount;
