import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import MasterList from "./MasterListItem";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function JobWorkMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 51;
  const [iJobCount, setiJobCount] = useState(0);
  const [strJobCode, setstrJobCode] = useState("");
  const [strJobName, setstrJobName] = useState("");
  const [strSACCode, setstrSACCode] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [iStatusId, setiStatusId] = useState(1);
  const [strJobCodeTag, setstrJobCodeTag] = useState(" Enter Job Code");
  const [strJobId, setstrJobId] = useState("0");
  const [iSGSTTaxId, setiSGSTTaxId] = useState(8);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(16);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(21);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("");
  const [strFilterColum, setstrFilterColum] = useState("All");
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntFillter, setlistEntFillter] = useState([]);
  const [listEntJobList, setlistEntJobList] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [listTaxType, setlistTaxType] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);

  useEffect(() => {
    Load_JobWorkMaster();
  }, []);
  const Load_JobWorkMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await api
        .post(`JobWorkMaster/Load_JobWorkMaster/`, objParems)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistEntFillter(Res.data.objEntComBoxVa);
            setlistEntStatus(Res.data.objEntStatus);
            setlistEntJobList(Res.data.objEntJob);
            setiJobCount(Res.data.objEntJob.length);
            setlistTaxType(Res.data.objEntTaxType);
            setlistSGST(Res.data.objEntSGSTTax);
            setlistCGST(Res.data.objEntCGSTTax);
            setlistIGST(Res.data.objEntIGSTTax);
            setlistCodeFormat(Res.data.objCodeFormat);
            setManualCode(!Res.data.objCodeFormat[0].isManualCode);
            setstrJobCode(Res.data.objCodeFormat[0].strCode);
            setListValidateProperties(Res.data.Transtab);
            TabOrderAssign(Res.data.Transtab);
            ButtonPermission(JSON.parse(localStorage.Menu), strJobId);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = async () => {
    setiJobCount(0);
    setstrJobCode("");
    setstrJobName("");
    setstrSACCode("");
    setstrPrintName("");
    setstrRemark("");
    setiStatusId(1);
    setstrJobCodeTag(" Enter Job Code");
    setstrJobId("0");
    setiSGSTTaxId(8);
    setiCGSTTaxId(16);
    setiIGSTTaxId(21);
    setstrSearch("");
    setstrFilter("");
    setstrFilterColum("All");
    setBtnDeleteDisable(false);
    setBtnSaveDisable(false);
    await Load_JobWorkMaster();
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const ObjInsUpdJob = {
        strJobCode: strJobCode,
        strJobName: strJobName,
        strSACCode: strSACCode,
        strPrintName: strPrintName,
        strRemark: strRemark,
        iStatusId: iStatusId,
        strJobCodeTag: strJobCodeTag,
        iTitleTag: iScrId,
        strJobId: strJobId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strSearch: strSearch,
        strFilter: strFilter,
        strFilterColumn: strFilterColum,
        iTaxTypeId: Number(iTaxTypeId),
        iSGSTTaxId: Number(iSGSTTaxId),
        iCGSTTaxId: Number(iCGSTTaxId),
        iIGSTTaxId: Number(iIGSTTaxId),
        strCompanyDB: localStorage.db,
      };
      await api
        .post(`JobWorkMaster/InsUpd_JobWork/`, ObjInsUpdJob)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            ClearClick();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post(
          `JobWorkMaster/Delete_JobWork/${strJobId}/${iScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_JobWork = async (strJobId) => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get(
          `JobWorkMaster/View_JobWork/${strJobId}/${iScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            let EntJob = Res.data.EntJob[0];
            setstrJobCode(EntJob.strJobCode);
            setstrJobName(EntJob.strJobName);
            setstrSACCode(EntJob.strSACCode);
            setstrPrintName(EntJob.strPrintName);
            setstrRemark(EntJob.strRemark);
            setiStatusId(EntJob.iStatusId);
            setstrJobId(EntJob.strJobId);
            setstrJobCode(EntJob.strJobCode);
            setiSGSTTaxId(EntJob.iSGSTTaxId);
            setiCGSTTaxId(EntJob.iCGSTTaxId);
            setiIGSTTaxId(EntJob.iIGSTTaxId);
            ButtonPermission(JSON.parse(localStorage.Menu), EntJob.strJobId);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_JobWork = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strColName: "All",
        iScrId: iScrId,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(`JobWorkMaster/Search_JobWork`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setlistEntJobList(Res.data.EntJob);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxLevelChange = async (TaxLevel) => {
    try {
      await setiTaxTypeId(TaxLevel);
      await api
        .get(`TaxMaster/TaxLevelChange/${Number(TaxLevel)}/${localStorage.db}`)
        .then((Res) => {
          if (Number(TaxLevel) === 1) {
            setlistSGST(Res.data.SGSTTax);
            setlistCGST(Res.data.CGSTTax);
            setlistIGST(Res.data.IGSTTax);
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      JobCodeTab: "",
      JobNameTab: "",
      SACcodeTab: "",
      RemarkTab: "",
      PrintNameTab: "",
      StatusTab: "",
      TaxTypeTab: "",
      CGSTTab: "",
      IGSTTab: "",
      SGSTTab: "",
      TcsTaxTab: "",
      JobListTab: "",
      JobFIlterTab: "",
      JobSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtJobCode":
          TabOrder[0].JobCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtSAC":
          TabOrder[0].SACcodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtJobName":
          TabOrder[0].JobNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNameTab = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder[0].TaxTypeTab = EntProperties[i].iTabIndex;
          break;
        case "cbCgst":
          TabOrder[0].CGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbIgst":
          TabOrder[0].IGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbSgst":
          TabOrder[0].SGSTTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarkTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].JobFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].JobSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbJobName":
          TabOrder[0].JobListTab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].JobListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].JobListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].JobListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].JobListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  //#endregion
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtJobName":
          ErrorMsg = strJobName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtSAC":
          ErrorMsg = strSACCode === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemark === "" ? false : true;
          break;
        case "cbSgst":
          ErrorMsg = iSGSTTaxId === "" ? false : true;
          break;
        case "cbIgst":
          ErrorMsg = iIGSTTaxId === "" ? false : true;
          break;
        case "cbCgst":
          ErrorMsg = iCGSTTaxId === "" ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = iTaxTypeId === "" ? false : true;
          break;

        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const ButtonPermission = (ListMenu, JobId) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === 51);
    if (ListMenu.length > 0) {
      if (Number(JobId) > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (Number(JobId) === 0)
        if (ListMenu[0].isNew === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
    }
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntFillter}
          EntList={listEntJobList}
          GetDetails={View_JobWork}
          Count={iJobCount}
          Search={Search_JobWork}
          ScreenID={iScrId}
          accessor="strJobName"
          accessorid="strJobId"
          ListTab={ListTabOrder[0].JobListTab}
          FilterTab={ListTabOrder[0].JobFIlterTab}
          SearchTab={ListTabOrder[0].JobSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strJobName}
                  placeholder="Please Enter Job Name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].JobNameTab}
                  onChange={(e) => setstrJobName(e.target.value)}
                  autoFocus
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-describedby="button-addon2"
                  id="txtProductCode"
                  readOnly={ManualCode}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].JobCodeTab}
                  value={strJobCode}
                  onChange={(e) => setstrJobCode(e.target.value)}
                  name="strProCode"
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>SAC</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="SAC Code"
                  value={strSACCode}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].SACcodeTab}
                  onChange={(e) => setstrSACCode(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Print Name </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strPrintName}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Please Enter Print Name"
                  tabIndex={ListTabOrder[0].PrintNameTab}
                  onChange={(e) => setstrPrintName(e.target.value)}
                  spellCheck={false}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strRemark}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Please Enter Remark"
                  tabIndex={ListTabOrder[0].RemarkTab}
                  onChange={(e) => setstrRemark(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>Tax Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_parem.isoptionShow4}
                  placeholder="Select Tax Type"
                  taborder={ListTabOrder[0].Taxtytab}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => TaxLevelChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTaxTypeId}
                  displayName="strTaxLvlType"
                  disvalue="iTaxLvlId"
                  arrydata={listTaxType}
                  EmptVal="Tax Type "
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>SGST</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow5"
                  showoption={_parem.isoptionShow5}
                  placeholder="Select SGST Tax"
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iSGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listSGST}
                  EmptVal="SGST "
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>CGST</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow6"
                  showoption={_parem.isoptionShow6}
                  placeholder="Select CGST Tax"
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iCGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listCGST}
                  EmptVal="CGST "
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>IGST</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow7"
                  showoption={_parem.isoptionShow7}
                  placeholder="Select IGST Tax"
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iIGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listIGST}
                  EmptVal="IGST "
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Status"
                  taborder={ListTabOrder[0].StatusTab}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => setiStatusId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatusId}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={listEntStatus}
                  EmptVal="Status "
                />
              </div>
            </div>
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              disabled={BtnSaveDisable}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnSavtab}
              onClick={(e) => {
                if (PropertiesValidation()) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Jobwork ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onKeyDown={(e) => TabIndex(e)}
              disabled={BtnDeleteDisable}
              tabIndex={ListTabOrder[0].BtnDeletetab}
              onClick={(e) => {
                if (Number(strJobId) === 0) {
                  AlertMessage("Please Select a Job", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Jobwork ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnCleartab}
              onClick={ClearClick}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/Dashboard")}
              onKeyDown={(e) => TabIndex(e)}
              tabIndex={ListTabOrder[0].BtnClosetab}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default JobWorkMaster;
