import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import api, { Backup_Data } from "../Admin/Config";
import $ from "jquery";
import CustomizedSelectoption from "./CustomizedSelectoption";
import { Buffer } from "buffer";
import { useDispatch, useSelector } from "react-redux";
import Messagedialogbox from "./Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import CompanyProfile from "./CompanyProfile";
function CompanyMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [iViewCompId, setiViewCompId] = useState(0);
  const [iActiveCompId, setiActiveCompId] = useState(0);
  const [iActiveFyId, setiActiveFyId] = useState(0);

  const [iCompId, setiCompId] = useState(0);
  const [strCompanyName, setstrCompanyName] = useState("");
  const [strImage, setstrImage] = useState("");
  const [strAddress1, setstrAddress1] = useState("");
  const [iPinCode, setiPinCode] = useState("");
  const [strMobileNo, setstrMobileNo] = useState("");
  const [strGSTNo, setstrGSTNo] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strSMTPHost, setstrSMTPHost] = useState("");
  const [iSMTPPort, setiSMTPPort] = useState("");
  const [isPortHostReadOnly, setisPortHostReadOnly] = useState(false);
  const [isemailchanged, setisemailchanged] = useState(false);
  const [iState, setiState] = useState(32);
  const [strCompanyCode, setstrCompanyCode] = useState("");
  const [iStockType, setiStockType] = useState(2);
  const [strStreet, setstrStreet] = useState("");
  const [strTown, setstrTown] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [strPhoneNo, setstrPhoneNo] = useState("");
  const [strTinNo, setstrTinNo] = useState("");
  const [strLicenseNo, setstrLicenseNo] = useState("");
  const [ListState, setListState] = useState([]);
  const [ListStockType, setListStockType] = useState([]);
  const [ImageByteArray, setImageByteArray] = useState([]);
  const [ImageName, setImageName] = useState("");
  const [strFinanFrom, setstrFinanFrom] = useState("");
  const [strFinanTo, setstrFinanTo] = useState("");
  const [isMoreinfo, setisMoreinfo] = useState(false);

  const [isMyCompany, setisMyCompany] = useState(true);
  const [isDelete, setisDelete] = useState(false);
  const [isCreate, setisCreate] = useState(true);
  const [isModify, setisModify] = useState(true);
  const [isNewFy, setisNewFy] = useState(false);
  const [isShowModify, setisShowModify] = useState(false);
  const [arrEntCompany, setarrEntCompany] = useState([]);
  const [arrEntfyyear, setarrEntfyyear] = useState([]);

  useEffect(() => {
    View_btnPermission();
    tab_click(0, "MyCompany");
  }, []);

  const Open_tab = async (TabItem) => {
    try {
      await dispatch(setload_Screen(true));
      setarrEntfyyear([]);
      setiCompId(0);
      $(`.dt-button`).removeClass("active");
      if (TabItem === "NewCompany")
        await api.get("Home/Load_Company/" + localStorage.Msdb).then((Res) => {
          if (Res.data.strStatus === "Success") {
            setListState(Res.data.objState);
            setListStockType(Res.data.objStockType);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      else {
        ClearClick();
        await api
          .get(
            `Home/Load_CompanyMaster/${Number(localStorage.RoleId)}/${
              localStorage.Msdb
            }`
          )
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              setarrEntCompany(Res.data.objCompanyList);
            } else AlertMessage(Res.data.strMessage, "info");
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const tab_click = (tabindex, tabname) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.common-tab label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    setisMyCompany(tabindex === 0 ? true : false);
    $(`.common-tab label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");

    Open_tab(tabname);
  };
  const View_btnPermission = () => {
    setiActiveCompId(Number(localStorage.ComId));
    setiActiveFyId(Number(localStorage.FYId));

    if (localStorage.Menu !== undefined) {
      let EntMenu = JSON.parse(localStorage.Menu);
      if (EntMenu.objEntCLPM.length > 0) {
        const Menu = EntMenu.objEntCLPM;
        setisCreate(filter_array(Menu, "Create"));
        setisDelete(filter_array(Menu, "Delete"));
        setisModify(filter_array(Menu, "Modify"));
        let IsShowFy = filter_array(Menu, "Create");

        var currentyear = new Date().toISOString().split("T")[0].split("-");
        var Year = Number(currentyear[0]);
        var Month = Number(currentyear[1]);
        if (Month >= 1 && Month <= 3) {
          Year = Year - 1;
        }
        var FROM = Year + "-04-01";
        var TO = Year + 1 + "-03-31";
        setstrFinanFrom(FROM);
        setstrFinanTo(TO);

        var currentDate = new Date().toISOString().split("T")[0];
        if (localStorage.NxtFY !== undefined) {
          const x = new Date(currentDate);
          const y = new Date(localStorage.NxtFY);
          if (y < x && IsShowFy) setisNewFy(true);
        }
      }
    }
  };
  const filter_array = (arr, Col) => {
    return arr.filter((dt) => dt.strAccessName === Col)[0].bIsVisible;
  };
  const View_Company = async (CompId) => {
    try {
      setiCompId(CompId);
      await api
        .get("Home/Load_FinancialYear/" + CompId + "/" + localStorage.Msdb)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            Res.data.objFinancialYear.map((Data) => {
              Data.strBackupOn = Data.strBackupOn.split(" ")[0];
              return Data;
            });
            setarrEntfyyear(Res.data.objFinancialYear);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const View_Financialyear = async (Yearid, CompId) => {
    if (Yearid === 0) {
      AlertMessage("Please Select Financial Year", "error");
    } else {
      await dispatch(setload_Screen(true));
      await api
        .get(
          `Home/Open_FinancialYear/${CompId}/${Yearid}/${Number(
            localStorage.UserId
          )}/${localStorage.ClientId}/${localStorage.Msdb}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            //#region  Company Info
            // Company Detailes
            var EntCompInfo = Res.data.CompanyInfo[0];
            var Year = Res.data.EntFinYear.filter(
              (DT) => DT.iYearId === Number(Yearid)
            )[0];
            localStorage.ComId = EntCompInfo.iCompId;
            localStorage.OrgName = EntCompInfo.strCompName;

            localStorage.FYId = Year.iYearId;
            localStorage.NxtFY = Res.data.EntFinYear[0].strTo;
            localStorage.CurrFinancialYear = Year.strFrom
              .split("-")
              .reverse()
              .join("-");
            localStorage.CurrFY = Year.strFromTo;
            localStorage.db = Year.strDbName;
            // Company Logo
            var Image = "";
            if (EntCompInfo.imgData.length > 0) {
              var UnitBitArray = new Uint8Array(EntCompInfo.imgData);
              var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
              Image = "data:image/png;base64," + ArrayToString;
            }
            localStorage.Logo = Image;
            //#endregion

            window.location.href = `/Dashboard`;
          } else AlertMessage(Res.data.strMessage, "error");
        });
      await dispatch(setload_Screen(false));
    }
  };
  const Insert_Company = async () => {
    try {
      var Image = document.getElementById("default-btn").value;
      var idxDot = Image.lastIndexOf(".") + 1;
      var ImgType = Image.substr(idxDot, Image.length).toLowerCase();
      if (
        ImgType === "jpg" ||
        ImgType === "jpeg" ||
        ImgType === "png" ||
        ImageByteArray.length === 0
      ) {
        var FromDate = "";
        var ToDate = "";
        var FromTo = "";
        dispatch(setload_Screen(true));
        FromDate = strFinanFrom.split("-");
        FromTo = FromDate[0];
        FromDate = FromDate[1] + "-" + FromDate[2] + "-" + FromDate[0];
        ToDate = strFinanTo.split("-");
        FromTo = FromTo + "-" + ToDate[0];
        ToDate = ToDate[1] + "-" + ToDate[2] + "-" + ToDate[0];
        const CreateCompany = {
          iTitleTag: 0,
          iCompId: 0,
          strCompName: strCompanyName,
          strCompCode: strCompanyCode,
          strAdd1: strAddress1,
          strAdd2: strStreet,
          strAdd3: strTown,
          strAdd4: strCity,
          strPincode: iPinCode,
          State: iState,
          strPhone: strPhoneNo,
          strMobile: strMobileNo,
          strEmail: strEmail,
          strPassword: strPassword,
          strSmtpHost: strSMTPHost,
          iSmtpPort: Number(iSMTPPort),
          strTIN: strTinNo,
          strGST: strGSTNo,
          strLicense: strLicenseNo,
          strRemarks: strRemark,
          strFromTo: FromTo,
          strFinanFrom: FromDate,
          strFinanTo: ToDate,
          iStockMethod: iStockType,
          ImgData: ImageByteArray,
          strDBName: "",
          strImgName: ImageName,
          strImgType: ImgType,
          strStatus: "Active",
          strMasterDB: localStorage.Msdb,
          iUploadOnNet: 0,
          iUploadType: 0,
          iMinutes: 0,
          strFTPWebsite: "",
          strUserId: "",
          strOnlinePassword: "",
          strOffLinePass: "",
          iClientId: Number(localStorage.ClientId),
          iRoleId: Number(localStorage.RoleId),
        };
        await dispatch(setload_Screen(true));
        const BackupData = await Backup_Data({
          dispatch: dispatch,
          Msdb: localStorage.Msdb,
        });
        if (BackupData) {
          await api
            .post("Home/Insert_Company", {
              _objEntComp: CreateCompany,
              strMasterDB: localStorage.Msdb,
              iUserId: Number(localStorage.UserId),
            })
            .then((Res) => {
              if (Res.data.strStatus === "Success") {
                ClearCompanyCreation();
                tab_click(0, "MyCompany");
              } else {
                AlertMessage(Res.data.strMessage, "error");
              }
            })
            .catch((e) => {
              console.error(e);
              AlertMessage(e.message, "error");
            });
        }
      } else AlertMessage("Only jpg/jpeg and png files are allowed!", "error");
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Create_Financialyear = async (Company) => {
    try {
      await dispatch(setload_Screen(true));
      const BackupData = await Backup_Data({
        dispatch: dispatch,
        Msdb: localStorage.Msdb,
      });
      if (BackupData) {
        await api
          .post(
            `Home/Create_Financialyear/${localStorage.Msdb}/${
              Company.iCompId
            }/${Number(localStorage.UserId)}`
          )
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              AlertMessage(Res.data.strMessage, "success");
              tab_click(0, "MyCompany");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          })
          .catch((e) => {
            console.error(e);
            AlertMessage(e.message, "error");
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Delete_Financialyear = async (year) => {
    try {
      await dispatch(setload_Screen(true));
      const BackupData = await Backup_Data({
        dispatch: dispatch,
        Msdb: localStorage.Msdb,
      });
      if (BackupData) {
        await api
          .post(
            `Home/Delete_FinancialYear/${year.iCompId}/${year.iYearId}/${year.strDbName}/${localStorage.Msdb}`
          )
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              AlertMessage(Res.data.strMessage, "success");
              tab_click(0, "MyCompany");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          })
          .catch((e) => {
            console.error(e);
            AlertMessage(e.message, "error");
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const CompanyNameChange = (value) => {
    var SplitName = value.split(" ");
    var CompanyCode = "";
    if (SplitName.length > 1) {
      CompanyCode = "";
      for (var i = 0; i < SplitName.length; i++) {
        CompanyCode = CompanyCode.concat(SplitName[i].substring(0, 1));
      }
      CompanyCode = CompanyCode + "001";
      setstrCompanyCode(CompanyCode);
    } else {
      CompanyCode = SplitName[0].substring(0, 3);
      CompanyCode = CompanyCode + "001";
      setstrCompanyCode(CompanyCode.toUpperCase());
    }
  };
  const ClearClick = () => {
    setListStockType([]);
    setListState([]);
    setstrCompanyName("");
    setstrAddress1("");
    setiPinCode("");
    setstrMobileNo("");
    setstrGSTNo("");
    setstrEmail("");
    setiState(32);
    setstrCompanyCode("");
    setstrPassword("123456");
    setiStockType(2);
    setstrStreet("");
    setstrTown("");
    setstrCity("");
    setstrRemark("");
    setstrPhoneNo("");
    setstrTinNo("");
    setstrLicenseNo("");
    setstrImage("");
    setImageName("");
  };
  const ClearCompanyCreation = () => {
    api.get("Home/Load_LastSavedCompany/" + localStorage.Msdb).then((Res) => {
      if (Res.data.strStatus === "Success") {
        View_Financialyear(Res.data.FinyearId, Res.data.CompanyId);
      }
      dispatch(setload_Screen(false));
    });
  };
  const ActivatDeafultBtn = () => {
    const defaultBtn = document.getElementById("default-btn");
    // eslint-disable-next-line no-useless-escape
    let regExp = /[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        const Bytreader = new FileReader();
        reader.readAsDataURL(file);
        Bytreader.readAsArrayBuffer(file);

        reader.onload = function () {
          setstrImage(reader.result);
        };
        Bytreader.onload = function () {
          var fileByteArray = [];
          var array = new Uint8Array(Bytreader.result);
          for (var i = 0; i < array.length; i++) fileByteArray.push(array[i]);
          setImageByteArray(fileByteArray);
        };
        if (this.value) {
          setImageName(this.value.match(regExp)[0]);
        }
        $(".img-wrapper").addClass("active"); // For Image Active
      }
    });
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  const LogoutClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "";
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Modify_Company = (company) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.common-tab label`);
    let fromleft = lable[0].offsetLeft;
    let width = lable[0].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    setisShowModify(!isShowModify);
    setiViewCompId(company.iCompId);
  };
  const Email_Onchange = async () => {
    setisemailchanged(false);
    let domain = strEmail.split("@")[1];
    domain = domain.toLowerCase();
    if (strEmail.length > 1 && !strEmail.includes(".com")) {
      setstrSMTPHost("");
      setiSMTPPort(0);
      setisPortHostReadOnly(false);
      AlertMessage("Please Enter Valid Email", "info");
    } else if (domain.length < 5) {
      setstrSMTPHost("");
      setiSMTPPort(0);
      setisPortHostReadOnly(false);
      AlertMessage("Please Enter Valid Email", "info");
    } else {
      switch (domain) {
        case "gmail.com":
          setstrSMTPHost("smtp.gmail.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "yahoo.com":
          setstrSMTPHost("smtp.mail.yahoo.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "outlook.com":
          setstrSMTPHost("smtp.office365.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "zoho.com":
          setstrSMTPHost("smtp.zoho.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "aol.com":
          setstrSMTPHost("smtp.aol.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "protonmail.ch":
          setstrSMTPHost("smtp.protonmail.ch");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        default:
          setstrSMTPHost(`smtp.`);
          setiSMTPPort(0);
          setisPortHostReadOnly(false);
          break;
      }
    }
  };
  return (
    <div className="Company-container">
      <div className="Company-content">
        <div className="common-tab">
          <label
            onClick={(e) => {
              if (!isShowModify) tab_click(0, "MyCompany");
            }}
            className="active"
          >
            <i className="bx bx-buildings"></i>&nbsp;My Company
          </label>
          {isCreate && !isShowModify && (
            <label onClick={(e) => tab_click(1, "NewCompany")}>
              <i className="bx bx-building"></i>&nbsp;Create Company
            </label>
          )}
          <div id="tab-line"></div>
        </div>
        {isMyCompany && !isShowModify && (
          <div className="company-wrapper">
            <div className="dt-company">
              {arrEntCompany.map((company, Index) => (
                <div
                  key={Index}
                  className={
                    iActiveCompId === company.iCompId
                      ? "dt-button selected"
                      : "dt-button"
                  }
                >
                  <h1
                    onClick={(e) => {
                      let isactive = $(`.dt-button:eq(${Index})`).hasClass(
                        "active"
                      );
                      if (isactive) {
                        setiCompId(0);
                        setarrEntfyyear([]);
                      } else View_Company(company.iCompId);
                      $(`.dt-button:eq(${Index})`)
                        .toggleClass("active")
                        .siblings()
                        .removeClass("active");
                    }}
                  >
                    {company.strCompName}
                    <div className="cmp-btns">
                      <i className="fa-solid fa-caret-down"></i>
                    </div>
                  </h1>
                  {iCompId === company.iCompId && (
                    <div className="fyear-container">
                      <h3 className="ht-fyearh">
                        <span>Financial Year</span>
                      </h3>
                      <h3 className="ht-fyear">
                        <span>Year</span>
                        <span>From</span>
                        <span>To</span>
                        <span>Last Backup</span>
                      </h3>
                      <div className="dt-fyear">
                        {arrEntfyyear.map((year, Index) => (
                          <div
                            key={Index}
                            className={
                              iActiveFyId === year.iYearId
                                ? "fy-button selected"
                                : "fy-button"
                            }
                          >
                            <span> {year.strFromTo}</span>
                            <span> {year.strFrom}</span>
                            <span> {year.strTo}</span>
                            <span> {year.strBackupOn}</span>
                            <span className="fy-btn">
                              <span
                                onClick={(e) =>
                                  View_Financialyear(year.iYearId, iCompId)
                                }
                              >
                                <i className="fa-regular fa-folder-open"></i>
                              </span>
                              {isDelete && (
                                <span
                                  onClick={(e) => {
                                    dispatch(
                                      Show_MsgBox({
                                        Button: Delete_Financialyear,
                                        Question:
                                          "Do You Want to Delete Financial Year?",
                                        isdialog: true,
                                        Id: year,
                                      })
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </span>
                              )}
                              {isModify && (
                                <span>
                                  <i onClick={() => Modify_Company(company)}>
                                    <i className="bx bxs-pencil"></i>
                                  </i>
                                </span>
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <span className="btn-section">
                    {isCreate && isNewFy && (
                      <button
                        className="btn-fabgreen"
                        onClick={(e) => {
                          if (!isNewFy) {
                            AlertMessage(
                              "Please Contact Customer Support +91-9629325653",
                              "info"
                            );
                          } else
                            dispatch(
                              Show_MsgBox({
                                Button: Create_Financialyear,
                                Question:
                                  "Do You Want to Create Financial Year?",
                                isdialog: true,
                                Id: company,
                              })
                            );
                        }}
                      >
                        New Year&nbsp;<i className="bx bx-calendar-alt"></i>
                      </button>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {isMyCompany && !isShowModify && (
          <div className="btn-section">
            <button onClick={LogoutClick} className="btn-fabgreen">
              <i className="bx bx-log-out"></i>Sign Out
            </button>
            <button
              className="btn-fabgreen"
              onClick={() => {
                window.location.href = "/Dashboard";
              }}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        )}
        {isCreate && !isMyCompany && (
          <>
            <div className="company-wrapper">
              <div className="row">
                <div className="col-md-3 col-sm-12 p-2">
                  <center>
                    <div className="img-container">
                      <div className="img-content">
                        <div className="img-wrapper">
                          <div className="image">
                            {strImage !== "" && <img src={strImage} alt="" />}
                          </div>
                          <div className="content">
                            <div id="custom-btn" onClick={ActivatDeafultBtn}>
                              <i className="fas fa-cloud-upload-alt"></i>
                            </div>
                          </div>
                          <div
                            id="cancel-btn"
                            onClick={(e) => {
                              setstrImage("");
                              setImageByteArray([]);
                              setImageName("");
                              $(".img-wrapper").removeClass("active");
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                          <div className="file-name">{ImageName}</div>
                        </div>
                        <input
                          id="default-btn"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          hidden
                        />
                      </div>
                    </div>
                  </center>
                </div>
                <div className="col-md-9 col-sm-12">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 mt-1">
                      <label>Company Name</label>
                      <span className="danger">*</span>
                      <input
                        autoFocus
                        type="text"
                        onKeyDown={(e) => TabIndex(e)}
                        className="form-control"
                        placeholder="Enter Company Name"
                        spellCheck={false}
                        tabIndex={1}
                        onClick={(e) => e.target.select()}
                        value={strCompanyName}
                        onBlur={(e) => CompanyNameChange(e.target.value)}
                        onChange={(e) => setstrCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-1">
                      <label>Door/Office No</label>
                      <span className="danger">*</span>
                      <input
                        onClick={(e) => e.target.select()}
                        type="text"
                        className="form-control"
                        onKeyDown={(e) => TabIndex(e)}
                        spellCheck={false}
                        placeholder="Enter Door/Office No"
                        tabIndex={2}
                        value={strAddress1}
                        onChange={(e) => setstrAddress1(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-1">
                      <label>PIN Code</label> <span className="danger">*</span>
                      <input
                        onKeyDown={(e) => TabIndex(e)}
                        onClick={(e) => e.target.select()}
                        type="number"
                        className="form-control"
                        placeholder="Enter PIN Code"
                        maxLength={6}
                        tabIndex={3}
                        value={iPinCode}
                        onChange={(e) => setiPinCode(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-1">
                      <label>Mobile No</label> <span className="danger">*</span>
                      <input
                        onKeyDown={(e) => TabIndex(e)}
                        onClick={(e) => e.target.select()}
                        type="number"
                        maxLength={12}
                        className="form-control"
                        placeholder="Enter Mobile No"
                        tabIndex={4}
                        value={strMobileNo}
                        onChange={(e) => setstrMobileNo(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-1">
                      <label>GST No</label>
                      <input
                        onKeyDown={(e) => TabIndex(e)}
                        onClick={(e) => e.target.select()}
                        type="text"
                        className="form-control"
                        spellCheck={false}
                        placeholder="Enter GST No"
                        tabIndex={5}
                        value={strGSTNo}
                        onChange={(e) => setstrGSTNo(e.target.value)}
                        maxLength={15}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6  mt-1">
                      <label>State</label> <span className="danger">*</span>
                      <CustomizedSelectoption
                        optionparem="isoptionShow"
                        showoption={_selecrparem.isoptionShow}
                        placeholder="Select State"
                        select_value={(val) => setiState(val)}
                        btnname=""
                        btnshow={false}
                        show_popup={""}
                        valueonly={true}
                        defaultval={iState}
                        displayName="strSName"
                        disvalue="iStateId"
                        arrydata={ListState}
                        EmptVal="State"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label>Stock Method</label>
                      <CustomizedSelectoption
                        optionparem="isoptionShow1"
                        showoption={_selecrparem.isoptionShow1}
                        placeholder="Select Stock Method"
                        select_value={(val) => setiStockType(val)}
                        btnname=""
                        btnshow={false}
                        show_popup={""}
                        valueonly={true}
                        defaultval={iStockType}
                        displayName="strStkType"
                        disvalue="iStkId"
                        arrydata={ListStockType}
                        EmptVal="Stock Method"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <label className="btn-More">
                <i className="fa-solid fa-circle-plus mr-1"></i>
                <span
                  onClick={(e) => {
                    setisMoreinfo(!isMoreinfo);
                  }}
                >
                  Show Addditional Information
                </span>
              </label>
              {isMoreinfo && (
                <div className="row pb-2">
                  <div className="col-md-4 col-sm-12">
                    <label>Street/Area</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Street/Area"
                      tabIndex={8}
                      value={strStreet}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => setstrStreet(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label>Town/Village</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Town Name"
                      tabIndex={9}
                      value={strTown}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => setstrTown(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label>City</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      placeholder="Enter City Name"
                      tabIndex={10}
                      onClick={(e) => e.target.select()}
                      value={strCity}
                      onChange={(e) => setstrCity(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label>Phone No</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone No"
                      tabIndex={11}
                      onClick={(e) => e.target.select()}
                      value={strPhoneNo}
                      onChange={(e) => setstrPhoneNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label>License No</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      placeholder="Enter License No"
                      onClick={(e) => e.target.select()}
                      tabIndex={12}
                      value={strLicenseNo}
                      onChange={(e) => setstrLicenseNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>TIN No</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      onClick={(e) => e.target.select()}
                      placeholder="Enter TIN No"
                      tabIndex={13}
                      value={strTinNo}
                      onChange={(e) => setstrTinNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Year From</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="date"
                      className="form-control"
                      placeholder="Enter Email"
                      tabIndex={17}
                      value={strFinanFrom}
                      onChange={(e) => setstrFinanFrom(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Year To</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="date"
                      className="form-control"
                      placeholder="Enter Email"
                      tabIndex={18}
                      value={strFinanTo}
                      onChange={(e) => setstrFinanTo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Email</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      spellCheck={false}
                      className="form-control"
                      placeholder="Enter Email"
                      tabIndex={6}
                      value={strEmail}
                      onChange={(e) => {
                        setisemailchanged(true);
                        setstrEmail(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (isemailchanged) Email_Onchange();
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Email Password/App Key</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      spellCheck={false}
                      className="form-control"
                      placeholder="Enter Password"
                      tabIndex={19}
                      value={strPassword}
                      onChange={(e) => setstrPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>SMTP Host</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      spellCheck={false}
                      className="form-control"
                      placeholder="Enter Mail Host Provider"
                      tabIndex={20}
                      value={strSMTPHost}
                      onChange={(e) => setstrSMTPHost(e.target.value)}
                      readOnly={isPortHostReadOnly}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>SMTP Port</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      spellCheck={false}
                      className="form-control"
                      placeholder="Enter Mail Server Port No"
                      tabIndex={21}
                      value={iSMTPPort}
                      onChange={(e) => setiSMTPPort(e.target.value)}
                      readOnly={isPortHostReadOnly}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label>Remark</label>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Remark"
                      tabIndex={14}
                      value={strRemark}
                      onChange={(e) => setstrRemark(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="btn-section">
              <button
                tabIndex={20}
                className="btn-fabgreen"
                onClick={(e) => {
                  if (strCompanyName === "") {
                    AlertMessage("Please Enter Company Name", "error");
                  } else if (strCompanyCode === "") {
                    AlertMessage("Please Enter Company Code", "error");
                  } else if (strAddress1 === "") {
                    AlertMessage("Please Enter Address No ", "error");
                  } else if (strMobileNo === "") {
                    AlertMessage("Please Enter Mobile No ", "error");
                  } else {
                    dispatch(
                      Show_MsgBox({
                        Button: Insert_Company,
                        Question: "Do You Want to Save Company?",
                        isdialog: true,
                      })
                    );
                  }
                }}
              >
                Save&nbsp; <i className="bx bxs-save"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={() => {
                  ClearClick();
                  tab_click(1, "NewCompany");
                }}
              >
                Clear&nbsp; <i className="fas fa-eraser"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={() => setisMyCompany(!isMyCompany)}
              >
                Close&nbsp;<i className="fas fa-times"></i>
              </button>
            </div>
          </>
        )}
        {isShowModify && (
          <CompanyProfile
            Close={() => {
              setisShowModify(!isShowModify);
              setiViewCompId(0);
              tab_click(0, "MyCompany");
            }}
            CompId={iViewCompId}
          />
        )}
        <Messagedialogbox />
      </div>
    </div>
  );
}

export default CompanyMaster;
