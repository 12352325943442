import React, { useEffect, useState } from "react";
function Button(props) {
  const [BtnExportDisplay, setBtnExportDisplay] = useState("none");
  const [BtnExport, setBtnExport] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);

  useEffect(() => {
    if (props.ScrId === undefined || props.ScrId === 1 || props.ScrId === 2)
      setBtnExportDisplay("");
    else setBtnExportDisplay("none");
    if (props.ScrId > 0) ButtonPermission(JSON.parse(localStorage.Menu));
  }, [props]);

  const ButtonPermission = (ListMenu) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === props.ScrId);
    if (ListMenu.length > 0) {
      if (props.Iid > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (props.Iid === 0) {
        if (ListMenu[0].isExport === false) {
          setBtnExport(true);
        } else {
          setBtnExport(false);
        }
        if (ListMenu[0].isNew === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
      }
    }
  };
  return (
    <div className="btn-section">
      <button
        disabled={BtnSaveDisable}
        className="btn-fabgreen"
        onClick={props.Save}
        tabIndex={props.SaveIndex}
        onKeyDown={props.onKeyDown}
      >
        Save&nbsp;<i className="bx bxs-save"></i>
      </button>
      <button
        disabled={BtnDeleteDisable}
        className="btn-fabgreen"
        onClick={props.Delete}
        onKeyDown={props.onKeyDown}
        tabIndex={props.DeleteIndex}
      >
        Delete&nbsp;<i className="bx bx-trash-alt"></i>
      </button>
      <button
        style={{ display: BtnExportDisplay }}
        disabled={BtnExport}
        className="btn-fabgreen"
        tabIndex={props.ExportIndex}
        onKeyDown={props.onKeyDown}
        onClick={props.Export}
      >
        Export&nbsp;<i className="bx bxs-file-export"></i>
      </button>
      <button
        className="btn-fabgreen"
        onClick={(e) => {
          setBtnSaveDisable(false);
          setBtnDeleteDisable(false);
          setBtnExport(false);
          props.Clear();
        }}
        tabIndex={props.ClearIndex}
        onKeyDown={props.onKeyDown}
      >
        Clear&nbsp;<i className="fa-solid fa-broom"></i>
      </button>
      <button
        className="btn-fabgreen"
        onKeyDown={props.onKeyDown}
        onClick={(e) =>
          (window.location.href = "/Dashboard")
        }
        tabIndex={props.CloseIndex}
        disabled={Boolean(Number(localStorage.IsDirect))}
      >
        Close&nbsp;<i className="fas fa-times"></i>
      </button>
    </div>
  );
}
export default Button;
