import { createSlice } from "@reduxjs/toolkit";
export const MessageSlice = createSlice({
  name: "Parems",
  initialState: {
    Msg_Parem: {
      Message: "",
      Type: "",
      isMsg: false,
      Button: "",
      Question: "",
      isdialog: false,
      isloading: true,
      Id: 0,
    },
  },
  reducers: {
    Show_MsgBox: (state, action) => {
      if (action.payload.isMsg) {
        state.Msg_Parem.Message = action.payload.Message;
        state.Msg_Parem.Type = action.payload.Type;
        state.Msg_Parem.isMsg = action.payload.isMsg;
      } else {
        state.Msg_Parem.Button = action.payload.Button;
        state.Msg_Parem.Question = action.payload.Question;
        state.Msg_Parem.isdialog = action.payload.isdialog;
        state.Msg_Parem.Id = action.payload.Id;
      }
    },
    Hide_DialogMsgBox: (state) => {
      state.Msg_Parem = {
        isMsg: false,
        Button: "",
        Question: "",
        isdialog: false,
        val: 0,
      };
    },
    Hide_AlertMsgBox: (state) => {
      state.Msg_Parem = {
        Message: "",
        Type: "",
        isMsg: false,
      };
    },
    setload_Screen: (state, action) => {
      state.Msg_Parem.isloading = action.payload;
    },
  },
});
export const {
  Show_MsgBox,
  Hide_DialogMsgBox,
  Hide_AlertMsgBox,
  setload_Screen,
} = MessageSlice.actions;
export default MessageSlice.reducer;
