import React, { useEffect } from "react";

import api from "../Admin/Config";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  AddLess_LostFocus,
  AddLess_Onchange,
  AddUpdate_AddLess,
  Clear_Service,
  Discount_onchanged,
  Modify_AddLess,
  Remove_AddLess,
  ShowHide_Popup,
  Update_DiscountCalculation,
} from "../ReduxStore/Transactionstates";
import { Hidetab_Container, KeyUpDown, Showtab_Container } from "./Transaction";
function TransactionSummary() {
  const dispatch = useDispatch();

  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _Disc = useSelector((store) => store.states.Discount);
  const _Sum = useSelector((store) => store.states.Summary);
  const _AL = useSelector((store) => store.states.AddLess);
  const _tabShow = JSON.parse(JSON.stringify(_Common.Summarytab));
  const _tabOrder = JSON.parse(JSON.stringify(_Common.arrtaborder));

  useEffect(() => {}, [
    _Common.Summarytab,
    _Sum.NetAmt,
    _AL.iSerId,
    _AL.arrEntVendore,
    _AL.arrEntService,
    _AL.arrEntAL,
  ]);

  const AddServiceClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const ServExist = _DT.arrEntAL.filter(
        (dt) =>
          dt.serId === Number(_AL.iSerId) && dt.venId === Number(_AL.iSerVenId)
      );
      if (ServExist.length > 0 && !_AL.bUpdateStatus) {
        AlertMessage("Service Already Exists ", "error");
      } else {
        await api
          .post("Transaction/AddService/", {
            iScrId: _Common.iScrId,
            iTaxCalcOnId: _Common.iTaxCalcOnId,
            iALBillDiscId: _Common.iALBillDiscId,

            iPartyId: _HT.iPartyId,
            iTaxRelId: _HT.iTaxRelId,
            bModifyStatus: false,

            iVenId: Number(_AL.iSerVenId),
            iSerId: Number(_AL.iSerId),
            dPriceRate: Number(_AL.dRate),
            strVenCode: _AL.VenCode,
            strVenName: _AL.VenName,
            bUpdateStatus: _AL.bUpdateStatus,
            iRowIndex: _AL.iRowIndex,

            objEntAddLess: _DT.arrEntAL,
            objBaseTransDT: _DT.arrEntDT,

            iVouchId: 0,
            iProId: 0,
            iUnitId: 0,
            dQty: 0,
            dDiscountPer: 0,
            dDiscountAmt: 0,

            strJobId: "0",
            strProAttDTId: "0",
            strStoAttDTId: "0",
            strTraAttDTId: "0",
            objItemRemark: {},
            objPenCon: [],
            objRootCalc: {},
            objEntConRow: [],
            objEntTax: [],
            strSearch: "",
            strColumn: "",
            strReason: "",
            strSetCurrentStatus: "",
            dAddLessDiscValue: 0,
            iYearId: Number(localStorage.FYId),
            strCompanyDB: localStorage.db,
            strMasterDB: localStorage.Msdb,
            iUserId: Number(localStorage.UserId),
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              dispatch(AddUpdate_AddLess(Res.data));
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const RemoveAddLess = async (AddLessIndex) => {
    const AddLess = JSON.parse(JSON.stringify(_DT.arrEntAL));
    let EntAL = await AddLess.filter((Data, Index) => Index !== AddLessIndex);
    try {
      await dispatch(setload_Screen(true));
      await api
        .post(`Transaction/RemoveAddLess`, {
          bUpdateStatus: _AL.bUpdateStatus,
          iRowIndex: _AL.iRowIndex,
          iScrId: _Common.iScrId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iALBillDiscId: _Common.iALBillDiscId,

          iPartyId: _HT.iPartyId,
          iTaxRelId: _HT.iTaxRelId,
          bModifyStatus: false,

          iVenId: Number(_AL.iSerVenId),
          iSerId: Number(_AL.iSerId),
          dPriceRate: Number(_AL.dServAmt),
          strVenCode: "",
          strVenName: "",

          objEntAddLess: EntAL,
          objBaseTransDT: _DT.arrEntDT,

          iVouchId: 0,
          iProId: 0,
          iUnitId: 0,
          dQty: 0,
          dDiscountPer: 0,
          dDiscountAmt: 0,

          strJobId: "0",
          strProAttDTId: "0",
          strStoAttDTId: "0",
          strTraAttDTId: "0",
          objItemRemark: {},
          objPenCon: [],
          objRootCalc: {},
          objEntConRow: [],
          objEntTax: [],
          strSearch: "",
          strColumn: "",
          strReason: "",
          strSetCurrentStatus: "",
          dAddLessDiscValue: 0,
          iYearId: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iUserId: Number(localStorage.UserId),
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            dispatch(Remove_AddLess(Res.data));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Disc_OnChange = async (Value, Disc) => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post(`Transaction/CalculateDiscount`, {
          Disc: Disc,
          iScrId: _Common.iScrId,
          ScrName: _Common.strScrName,
          iTaxTypeId: _HT.iTaxRelId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iAddLessBillDiscId: _Common.iALBillDiscId,
          iPartyId: _HT.iPartyId,
          dTBillDiscPer:
            Disc === "BillDicPer" ? Number(Value) : Number(_Disc.dBillDisPer),
          dTBillDiscAmt:
            Disc === "BillDicAmt" ? Number(Value) : Number(_Disc.dBillDisAmt),
          dTBillDiscValue: Number(_Disc.dBillDisValue),
          dTAddLessDiscPer:
            Disc === "ALBillDicPer"
              ? Number(Value)
              : Number(_Disc.dALBillDisPer),
          dTAddLessDiscAmt:
            Disc === "ALBillDicAmt"
              ? Number(Value)
              : Number(_Disc.dALBillDisAmt),
          dTAddLessDiscValue: Number(_Disc.dALBillDisValue),
          objBaseTransDT: _DT.arrEntDT,
          objEntALDT: _DT.arrEntAL,
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            dispatch(
              Update_DiscountCalculation({ Data: Res.data, Disc: Disc })
            );
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AL_Onchange = (e) => {
    dispatch(
      AddLess_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const tab_click = (tabindex) => {
    Showtab_Container(
      "tabs-line",
      "transs-buttons",
      tabindex,
      _tabShow,
      "transsum",
      dispatch
    );
  };
  const hide_summary = () => {
    Hidetab_Container(
      "transs-hide",
      "transs-buttons",
      _tabShow,
      "transsum",
      dispatch
    );
  };
  const KeyDown = (e) => {
    if (e.key === "Enter" && e.key === "ArrowUp" && e.key === "ArrowDown") {
      KeyUpDown(e, _Common, _DT);
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="fg-card shadow-base">
      <div className="trans-tab">
        <div className="transs-buttons">
          <label onClick={(e) => tab_click(0)} className="active">
            Summary
          </label>
          <label hidden={_Common.IsJobWorkDc} onClick={(e) => tab_click(1)}>
            Discount & Tax
          </label>
          <label hidden={_Common.IsJobWorkDc} onClick={(e) => tab_click(2)}>
            AddLess
          </label>
          <div id="tabs-line"></div>
        </div>
        <i
          className="bx bx-caret-down transs-hide"
          onClick={(e) => hide_summary()}
        ></i>
      </div>
      {_tabShow[0] && (
        <div className="row p-2">
          <div className="col-md-9 col-sm-12">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <label>Total Qty</label>
                <input
                  id="txtTotQty"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.Qty).toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <label>Total Goods Value</label>
                <input
                  id="txtTotGoodsVal"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.GoodsValue).toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12" hidden={_Common.IsJobWorkDc}>
                <label>Total Dis(-)</label>
                <input
                  id="txtTotDis"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.Discount).toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12" hidden={_Common.IsJobWorkDc}>
                <label>Total Tax(+)</label>
                <input
                  id="txtTotTax"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.Tax).toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12" hidden={_Common.IsJobWorkDc}>
                <label>Add/Less (+)</label>
                <input
                  id="txtTotAL"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.AddLess).toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12" hidden={_Common.IsJobWorkDc}>
                <label>Total TCS Tax(+)</label>
                <input
                  id="txtTotTCS"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.TCSTax).toFixed(2)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <label>Round Off</label>
                <input
                  id="txtTotRF"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  disabled
                  value={Number(_Sum.RoundOff).toFixed(2)}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 mt-2">
            <div className="net-amount">
              <h1>NET AMOUNT</h1>
              <h2>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.NetAmt).toFixed(2))}
              </h2>
            </div>
          </div>
        </div>
      )}
      {_tabShow[1] && (
        <div className="row p-2">
          <div className="col-sm-12 col-md-6">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label>Bill Discount Per</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control "
                  style={{ textAlign: "right" }}
                  value={_Disc.dBillDisPer}
                  name="dBillDisPer"
                  onClick={(e) => e.target.select()}
                  tabIndex={_tabOrder.txtBillDiscPer}
                  onChange={(e) => {
                    dispatch(
                      Discount_onchanged({
                        IsChanged: true,
                        Value: e,
                      })
                    );
                  }}
                  onKeyDown={(e) => KeyDown(e, _Common, _DT)}
                  onBlur={(e) => {
                    if (_Disc.isDiscChanged)
                      Disc_OnChange(e.target.value, "BillDicPer");
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>Bill Discount Amt</label>
                <input
                  type="number"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  value={_Disc.dBillDisAmt}
                  name="dBillDisAmt"
                  tabIndex={_tabOrder.txtBillDiscAmt}
                  onKeyDown={(e) => KeyDown(e, _Common, _DT)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) =>
                    dispatch(
                      Discount_onchanged({
                        IsChanged: true,
                        Value: e,
                      })
                    )
                  }
                  onBlur={(e) => {
                    if (_Disc.isDiscChanged)
                      Disc_OnChange(e.target.value, "BillDicAmt");
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>Bill Discount</label>
                <input
                  className="form-control"
                  value={new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(_Disc.dBillDisValue).toFixed(2))}
                  readOnly
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>AddLess Discount Per</label>
                <input
                  type="number"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  onChange={(e) =>
                    dispatch(
                      Discount_onchanged({
                        IsChanged: true,
                        Value: e,
                      })
                    )
                  }
                  value={_Disc.dALBillDisPer}
                  name="dALBillDisPer"
                  onClick={(e) => e.target.select()}
                  tabIndex={_tabOrder.txtAddLessDiscPer}
                  onKeyDown={(e) => KeyDown(e, _Common, _DT)}
                  onBlur={(e) => {
                    if (_Disc.isDiscChanged)
                      Disc_OnChange(e.target.value, "ALBillDicPer");
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>Add/Less Discount Amt</label>
                <input
                  type="number"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  onChange={(e) =>
                    dispatch(
                      Discount_onchanged({
                        IsChanged: true,
                        Value: e,
                      })
                    )
                  }
                  value={_Disc.dALBillDisAmt}
                  name="dALBillDisAmt"
                  tabIndex={_tabOrder.txtAddLessDiscAmt}
                  onKeyDown={(e) => KeyDown(e, _Common, _DT)}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (_Disc.isDiscChanged)
                      Disc_OnChange(e.target.value, "ALBillDisAmt");
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>Add/Less Discount</label>
                <input
                  className="form-control"
                  value={new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(_Disc.dALBillDisValue).toFixed(2))}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="tax-table">
              <table>
                <thead>
                  <tr>
                    <th>Tax %</th>
                    <th>Taxable Amount</th>
                    <th>Tax Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {_DT.arrEntTax.map((Tax, Index) => (
                    <tr key={Index}>
                      <td style={{ textAlign: "left" }}>{Tax.taxDesc}</td>
                      <td style={{ textAlign: "Right" }}>
                        {Number(Tax.taxableAmt).toFixed(2)}
                      </td>
                      <td style={{ textAlign: "Right" }}>
                        {Number(Tax.taxAmt).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={1}>Tax Amount</td>
                    <td></td>
                    <td style={{ fontFamily: "arial", textAlign: "right" }}>
                      {Number(_Sum.Tax).toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      )}
      {_tabShow[2] && (
        <div className="row p-2">
          <div className="col-sm-12 col-md-8">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label>Vendor Name</label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Service Vendor"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(
                          ShowHide_Popup({
                            isshow: "IsVenSearch",
                            IsSerVendor: true,
                          })
                        );
                      } else KeyDown(e);
                    }}
                    onClick={(e) =>
                      dispatch(
                        ShowHide_Popup({
                          isshow: "IsVenSearch",
                          IsSerVendor: true,
                        })
                      )
                    }
                    id="txtPartyName"
                    spellCheck={false}
                    autoComplete="off"
                    defaultValue={_AL.VenName}
                    disabled={_Sum.Qty > 0 ? false : true}
                    autoFocus
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform:
                        _Common.IsVenSearch && _Common.IsSerVendor
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Service Name</label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Service"
                    onKeyDown={(e) => {
                      if (_AL.iSerVenId > 0)
                        dispatch(ShowHide_Popup("IsSerSearch"));
                    }}
                    onClick={(e) => {
                      if (_AL.iSerVenId > 0)
                        dispatch(ShowHide_Popup("IsSerSearch"));
                    }}
                    spellCheck={false}
                    autoComplete="off"
                    defaultValue={_AL.SerName}
                    disabled={_AL.iSerVenId === 0 ? true : false}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: _Common.IsSerSearch
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <label>Rate W/O Tax</label>
                <input
                  type="number"
                  name="dRate"
                  className="form-control"
                  value={_AL.dRate}
                  onChange={(e) => AL_Onchange(e)}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    AddLess_LostFocus(e);
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-3 mt-4">
                <div className="btn-sub-section ml-1">
                  <button
                    className="btn-fabgreen"
                    onClick={AddServiceClick}
                    disabled={Number(_AL.iSerId) === 0 ? true : false}
                  >
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                  <button
                    className="btn-fabgreen"
                    onClick={() => dispatch(Clear_Service())}
                    disabled={Number(_AL.iSerId) === 0 ? true : false}
                  >
                    Clear&nbsp;<i className="fas fa-eraser"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 mt-1">
            <div className="tax-table">
              <table>
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Cost Rate</th>
                    <th>Tax</th>
                    <th>Item Amt</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {_DT.arrEntAL.map((AL, Index) => (
                    <tr key={Index}>
                      <td style={{ textAlign: "left" }}>{AL.serName}</td>
                      <td style={{ textAlign: "Right" }}>
                        {Number(AL.rateWithoutTax).toFixed(2)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {Number(AL.taxPer).toFixed(2)}
                      </td>
                      <td style={{ textAlign: "Right" }}>
                        {Number(AL.itemAmt).toFixed(2)}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <i
                          className="fa-solid fa-pen mt-2"
                          onClick={(e) =>
                            dispatch(Modify_AddLess({ Data: AL, Index: Index }))
                          }
                        ></i>
                        <i
                          className="far fa-trash-alt mt-2"
                          onClick={(e) => RemoveAddLess(Index)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Other Charges</td>
                    <td style={{ fontFamily: "arial", textAlign: "right" }}>
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "INR",
                      }).format(Number(_Sum.AddLess).toFixed(2))}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default TransactionSummary;
