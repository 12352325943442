import React, { useState, useEffect } from "react";

import api from "../Admin/Config";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  Select_Product,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import PopupSearch from "./PopupSearch";
function ProductSearch(props) {
  const dispatch = useDispatch();

  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);

  const [arrProduct, setarrProduct] = useState([]);
  const [arrFilterCol, setarrFilterCol] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [strCol, setstrCol] = useState("ProName");
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_StockbyProduct();
  }, []);
  const Load_StockbyProduct = async () => {
    try {
      await dispatch(setload_Screen(true));
      setstrSearch("");
      await api
        .post(`Transaction/Load_StockbyProduct/`, {
          iScrId: props.Close ? props.ScreenId : _Common.iScrId,
          strSearchBasedOn: strCol,
          strAttDTList: "",
          iPartyId: 0,
          iAttGroupId: 0,
          iBillId: 0,
          strSearch: "",
          iProId: 0,
          strScreen: props.Close ? props.ScreentType : _Common.strScrType,
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrFilterCol(Res.data.EntFilterCol);
            setarrProduct(Res.data.EntProduct);
            setarrEntTableCol(Res.data.EntTablDesign);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_StockbyProduct = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      await api
        .post(`Transaction/Search_StockbyProduct/`, {
          iScrId: props.Close ? props.ScreenId : _Common.iScrId,
          strSearchBasedOn: strCol,
          strAttDTList: "",
          iPartyId: 0,
          iAttGroupId: 0,
          iBillId: 0,
          strSearch: strtxt,
          iProId: 0,
          strScreen: props.Close ? props.ScreentType : _Common.strScrType,
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrProduct(Res.data.EntProduct);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Product = async (ProInfo) => {
    try {
      const parems = {
        iScrId: _Common.iScrId,
        iPartyId: props.Close ? props.PartyId : _HT.iPartyId,
        iTaxRelId: props.Close ? 1 : _HT.iTaxRelId,
        iProId: ProInfo.proId,
        strScreen: _Common.strScrType,
        strSearchBasedOn: strCol,
        strSearch: strSearch,
        strAttDTList: "",
        strCompanyDB: localStorage.db,
      };
      await dispatch(setload_Screen(true));
      await api
        .post("Transaction/View_ProductAttribute/", parems)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(
              Select_Product({
                ProInfo: ProInfo,
                Data: Res.data,
              })
            );
            if (Res.data.IsAttPro) {
              await dispatch(ShowHide_Popup("IsAttSearch"));
            } else dispatch(ShowHide_Popup("IsProSearch"));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <PopupSearch
      ScrName="PRODUCT SEARCH "
      FilterCol={arrFilterCol}
      FilterVal={strCol}
      FilterShow={true}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrProduct}
      UpdateFilterCol={(e) => setstrCol(e)}
      Search_Data={Search_StockbyProduct}
      View_Data={(e) => View_Product(e)}
      Close={() => dispatch(ShowHide_Popup("IsProSearch"))}
      IsProduct={true}
    />
  );
}
export default ProductSearch;
