import React from "react";
import { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";

import api from "../Admin/Config";
import ReportTable from "./ReportTable";
function AccountsReport() {
  const dispatch = useDispatch();
  const [iRptId, setiRptId] = useState(0);
  const [iTotalRows, setiTotalRows] = useState(0);
  const [RowPerPage, setRowPerPage] = useState(0);
  const [CurrPage, setCurrPage] = useState(1);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strLedger, setstrLedger] = useState("");
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [strReportName, setstrReportName] = useState("Accounts Reports");
  const [strRptSearch, setstrRptSearch] = useState("");
  const [strFilterColName, setstrFilterColName] = useState("");

  const [IsLedgOption, setIsLedgOption] = useState(false);

  const [arrLedger, setarrLedger] = useState([]);
  const [arrLedgerOption, setarrLedgerOption] = useState([]);
  const [arrReports, setarrReports] = useState([]);
  const [arrReportDesing, setarrReportDesing] = useState([]);
  const [arrReportData, setarrReportData] = useState([]);
  const [arrSummarydata, setarrSummarydata] = useState([]);
  useEffect(() => {
    Load_Reports("");
  }, []);
  const Load_Reports = async (strtxt) => {
    try {
      await dispatch(setload_Screen(true));
      setstrRptSearch(strtxt);
      const objSearch = {
        iScrId: 0,
        iPartyId: 0,
        strColName: "AccountsReport",
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await api.post(`Reports/Load_Reports/`, objSearch).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await setarrReports(Res.data.objEntRpt);
        } else {
          await AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      await AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Report = async (iId, strName, LedgerName, Fromdate, ToDate) => {
    try {
      await dispatch(setload_Screen(true));
      setstrReportName(strName);
      setiRptId(Number(iId));
      const objSearch = {
        iRptId: iId,
        dpFrom: Fromdate,
        dpTo: ToDate,
        strLedger: LedgerName,
        strColName: "",
        strSearch: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
        CurrtPage: 1,
      };
      await api
        .post(`Reports/Load_AccountsReport/`, objSearch)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            let RptDesign = await SortArray(Res.data.EntDesign);
            let RptData = JsontoArray(Res.data.EntReport);
            setarrReportDesing(RptDesign);
            setarrReportData(RptData);
            setstrFromDate(Res.data.strFromDate);
            setstrFilterColName(Res.data.strSearchCol);
            setiTotalRows(Res.data.TotalRows);
            setRowPerPage(Res.data.RowPerPage);
            await validatereportsummary(Res.data.EntSummary);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Clear_Report = () => {
    setstrLedger("");
    setstrFilterColName("");
    setIsLedgOption(false);
    setstrFromDate(new Date().toISOString().split("T")[0]);
    setstrToDate(new Date().toISOString().split("T")[0]);
    setarrLedger([]);
    setarrLedgerOption([]);
    setarrReportData([]);
  };
  const Search_Report = async (strtxt, strFilterCol) => {
    try {
      const objSearch = {
        iRptId: Number(iRptId),
        dpFrom: strFromDate,
        dpTo: strToDate,
        strLedger: strLedger,
        strColName: strFilterCol,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
        CurrtPage: 1,
      };
      await api
        .post(`Reports/Load_AccountsReport/`, objSearch)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrReportData(JsontoArray(Res.data.EntReport));
            await validatereportsummary(Res.data.EntSummary);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const JsontoArray = (DataArray) => {
    return DataArray !== null ? JSON.parse(DataArray) : [];
  };
  const View_PageReport = async (Page) => {
    try {
      await dispatch(setload_Screen(true));
      setCurrPage(Page);
      const objSearch = {
        iRptId: Number(iRptId),
        dpFrom: strFromDate,
        dpTo: strToDate,
        strLedger: strLedger,
        strColName: strFilterColName,
        strSearch: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
        CurrtPage: Page,
      };
      await api
        .post(`Reports/Load_AccountsReport/`, objSearch)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrReportData(JsontoArray(Res.data.EntReport));
            await validatereportsummary(Res.data.EntSummary);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Load_Ledger = async () => {
    const objSearch = {
      iScrId: 0,
      iPartyId: 0,
      strColName: "Ledger",
      strSearch: strRptSearch,
      strCompanyDB: localStorage.db,
      strMasterDB: localStorage.Msdb,
      iRoleId: Number(localStorage.RoleId),
    };
    await api.post("Reports/Load_Reports/", objSearch).then((Res) => {
      if (Res.data.strStatus === "Success") {
        setarrLedger(Res.data.objEntRpt);
        setarrLedgerOption(Res.data.objEntRpt);
      } else AlertMessage(Res.data.strMessage, "error");
    });
  };
  const SortArray = (EntArr) => {
    let RptDesign = EntArr.sort((a, b) => a.inOrderTo - b.inOrderTo);
    RptDesign = RptDesign.filter((rdt) => rdt.isShowInReport);
    return RptDesign;
  };
  const validatereportsummary = (arrSumm) => {
    if (arrSumm === undefined) setarrSummarydata([]);
    else setarrSummarydata(JSON.parse(arrSumm));
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={102} />
      <ReportTable
        ReportData={arrReportData}
        ReportDesing={arrReportDesing}
        EntReports={arrReports}
        EntLedger={arrLedger}
        EntLedgerOption={arrLedgerOption}
        View_Report={View_Report}
        Load_Reports={Load_Reports}
        Load_Ledger={Load_Ledger}
        Search_Report={Search_Report}
        EntSum={arrSummarydata}
        iRptId={iRptId}
        strReportName={strReportName}
        FilterColName={strFilterColName}
        setstrFilterColName={(e) => setstrFilterColName(e)}
        IsAccounts={true}
        strFromDate={strFromDate}
        strToDate={strToDate}
        strLedger={strLedger}
        IsLedOption={IsLedgOption}
        setIsLedOption={(e) => setIsLedgOption(e)}
        setarrLedgerOption={(e) => setarrLedgerOption(e)}
        setstrLedger={(e) => setstrLedger(e)}
        Clear_Report={Clear_Report}
        TotalRows={iTotalRows}
        RowPerPage={RowPerPage}
        View_PageReport={View_PageReport}
        CurrPage={CurrPage}
      />
    </>
  );
}

export default AccountsReport;
