import React from "react";
import { useEffect, useState } from "react";
import api from "../Admin/Config";
import $ from "jquery";
import { Buffer } from "buffer";
import CustomizedSelectoption from "./CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";

function CompanyProfile(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [iCompId, setiCompId] = useState(0);
  const [strCompanyName, setstrCompanyName] = useState("");
  const [strImage, setstrImage] = useState("");
  const [strAddress1, setstrAddress1] = useState("");
  const [iPinCode, setiPinCode] = useState("");
  const [strMobileNo, setstrMobileNo] = useState("");
  const [strGSTNo, setstrGSTNo] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strSMTPHost, setstrSMTPHost] = useState("");
  const [iSMTPPort, setiSMTPPort] = useState("");
  const [isPortHostReadOnly, setisPortHostReadOnly] = useState(false);
  const [isemailchanged, setisemailchanged] = useState(false);
  const [iState, setiState] = useState(32);
  const [strCompanyCode, setstrCompanyCode] = useState("");
  const [iStockType, setiStockType] = useState(2);
  const [strStreet, setstrStreet] = useState("");
  const [strTown, setstrTown] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [strPhoneNo, setstrPhoneNo] = useState("");
  const [strTinNo, setstrTinNo] = useState("");
  const [strLicenseNo, setstrLicenseNo] = useState("");
  const [FieldReadOnly, setFieldReadOnly] = useState(false);
  const [isMoreinfo, setisMoreinfo] = useState(false);
  const [ListState, setListState] = useState([]);
  const [ListStockType, setListStockType] = useState([]);
  const [ImageByteArray, setImageByteArray] = useState([]);
  const [ImageName, setImageName] = useState("");
  const [ImageType, setImageType] = useState("");
  const [strDBName, setstrDBName] = useState("");
  const [strFinanFrom, setstrFinanFrom] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strFinanTo, setstrFinanTo] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strFromTo, setstrFromTo] = useState("");
  const [isSaveShow, setisSaveShow] = useState(false);
  useEffect(() => {
    Load_Company();
    fn_Savebtn_Show();
  }, []);

  const Load_Company = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get("Home/Load_Company/" + localStorage.Msdb)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            setListState(Res.data.objState);
            setListStockType(Res.data.objStockType);
            await View_Company();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Company = async () => {
    try {
      await api
        .get(`Home/View_Company/${Number(props.CompId)}/${localStorage.Msdb}`)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            $(".img-wrapper").addClass("active"); // For Image Active
            const EntCompInfo = Res.data.objCompanyInfo[0];
            setImageType(EntCompInfo.strImgType);
            setImageName(EntCompInfo.strImgName);
            setiCompId(EntCompInfo.iCompId);
            setFieldReadOnly(true);
            setstrCompanyName(EntCompInfo.strCompName);
            setstrAddress1(EntCompInfo.strAdd1);
            setiPinCode(EntCompInfo.strPincode);
            setstrMobileNo(EntCompInfo.strMobile);
            setstrGSTNo(EntCompInfo.strGST);
            setstrEmail(EntCompInfo.strEmail);
            setstrPassword(EntCompInfo.strPassword);
            setstrSMTPHost(EntCompInfo.strSmtpHost);
            setiSMTPPort(EntCompInfo.iSmtpPort);

            setiState(EntCompInfo.state);
            setstrCompanyCode(EntCompInfo.strCompCode);
            setiStockType(EntCompInfo.iStockMethod);
            setstrStreet(EntCompInfo.strAdd2);
            setstrTown(EntCompInfo.strAdd3);
            setstrCity(EntCompInfo.strAdd4);
            setstrRemark(EntCompInfo.strRemarks);
            setstrPhoneNo(EntCompInfo.strPhone);
            setstrTinNo(EntCompInfo.strTIN);
            setstrLicenseNo(EntCompInfo.strLicense);
            setstrDBName(EntCompInfo.strDBName);
            setstrFinanFrom(EntCompInfo.strFinanFrom);
            setstrFinanTo(EntCompInfo.strFinanTo);
            setstrFromTo(EntCompInfo.strFromTo);
            setImageByteArray(EntCompInfo.imgData);

            if (EntCompInfo.imgData.length > 0) {
              var UnitBitArray = new Uint8Array(EntCompInfo.imgData);
              var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
              var Image = "data:image/png;base64," + ArrayToString;
              setstrImage(Image);
            }
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const fn_Savebtn_Show = () => {
    if (localStorage.Menu !== undefined) {
      let ListMenu = JSON.parse(localStorage.Menu);
      if (ListMenu.objEntCLPM.length > 0) {
        ListMenu = ListMenu.objEntCLPM.filter(
          (Data) => Data.strAccessName === "Modify"
        )[0];
        setisSaveShow(ListMenu.bIsVisible);
      }
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const CompanyNameChange = (value) => {
    var SplitName = value.split(" ");
    var CompanyCode = "";
    if (SplitName.length > 1) {
      CompanyCode = "";
      for (var i = 0; i < SplitName.length; i++) {
        CompanyCode = CompanyCode.concat(SplitName[i].substring(0, 1));
      }
      CompanyCode = CompanyCode + "001";
      setstrCompanyCode(CompanyCode);
    } else {
      CompanyCode = SplitName[0].substring(0, 3);
      CompanyCode = CompanyCode + "001";
      setstrCompanyCode(CompanyCode);
    }
  };
  const ClearClick = () => {
    setListStockType([]);
    setListState([]);
    setFieldReadOnly(false);
    setstrCompanyName("");
    setstrAddress1("");
    setiPinCode("");
    setstrMobileNo("");
    setstrGSTNo("");
    setstrEmail("");
    setiState(32);
    setstrCompanyCode("");
    setstrPassword("");
    setstrSMTPHost("");
    setiSMTPPort("");
    setisemailchanged(false);
    setisPortHostReadOnly(false);
    setiStockType(2);
    setstrStreet("");
    setstrTown("");
    setstrCity("");
    setstrRemark("");
    setstrPhoneNo("");
    setstrTinNo("");
    setstrLicenseNo("");
    Load_Company();
  };
  const Update_Company = async () => {
    try {
      var bIsSave = true;
      if (strSMTPHost.length > 0 && Number(iSMTPPort) === 0)
        AlertMessage("Please Enter Valid Port No");
      else {
        var Image = document.getElementById("default-btn").value;
        var idxDot = Image.lastIndexOf(".") + 1;
        var ImgType =
          Image.length > 0
            ? Image.substr(idxDot, Image.length).toLowerCase()
            : ImageType;
        if (ImageByteArray.length > 0) {
          if (ImgType === "jpg" || ImgType === "jpeg" || ImgType === "png")
            bIsSave = true;
          else bIsSave = false;
        }
        if (ImageByteArray.length === 0) localStorage.Logo = "";
        if (bIsSave) {
          var FromDate = strFinanFrom.split("-");
          FromDate = FromDate[1] + "-" + FromDate[2] + "-" + FromDate[0];
          var ToDate = strFinanTo.split("-");
          ToDate = ToDate[1] + "-" + ToDate[2] + "-" + ToDate[0];
          setImageType(ImageType);
          await dispatch(setload_Screen(true));
          const UpdateComp = {
            iTitleTag: 1,
            iCompId: iCompId,
            strCompName: strCompanyName,
            strCompCode: strCompanyCode,
            strAdd1: strAddress1,
            strAdd2: strStreet,
            strAdd3: strTown,
            strAdd4: strCity,
            strPincode: iPinCode,
            State: iState,
            strPhone: strPhoneNo,
            strMobile: strMobileNo,
            strEmail: strEmail,
            strPassword: strPassword,
            strSmtpHost: strSMTPHost,
            iSmtpPort: Number(iSMTPPort),
            strTIN: strTinNo,
            strGST: strGSTNo,
            strLicense: strLicenseNo,
            strRemarks: strRemark,
            strFromTo: strFromTo,
            strFinanFrom: FromDate,
            strFinanTo: ToDate,
            iStockMethod: iStockType,
            ImgData: ImageByteArray,
            strDBName: strDBName,
            strImgName: ImageName,
            strImgType: ImgType,
            strStatus: "Active",
            strMasterDB: localStorage.Msdb,
            iUploadOnNet: 0,
            iUploadType: 0,
            iMinutes: 0,
            strFTPWebsite: "",
            strUserId: "",
            strOnlinePassword: "",
            strOffLinePass: "",
          };
          await api.post("Home/Update_Company/", UpdateComp).then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
            dispatch(setload_Screen(false));
          });
        } else {
          AlertMessage("Only jpg/jpeg and png files are allowed!", "error");
        }
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ActivatDeafultBtn = () => {
    const defaultBtn = document.getElementById("default-btn");
    // eslint-disable-next-line no-useless-escape
    let regExp = /[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        const Bytreader = new FileReader();
        reader.readAsDataURL(file);
        Bytreader.readAsArrayBuffer(file);

        reader.onload = function () {
          setstrImage(reader.result);
        };
        Bytreader.onload = function () {
          var fileByteArray = [];
          var array = new Uint8Array(Bytreader.result);
          for (var i = 0; i < array.length; i++) fileByteArray.push(array[i]);
          setImageByteArray(fileByteArray);
        };
        if (this.value) {
          setImageName(this.value.match(regExp)[0]);
        }
        $(".img-wrapper").addClass("active"); // For Image Active
      }
    });
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  const Email_Onchange = async () => {
    setisemailchanged(false);
    let domain = strEmail.split("@")[1];
    domain = domain.toLowerCase();
    if (strEmail.length > 1 && !strEmail.includes(".com")) {
      setstrSMTPHost("");
      setiSMTPPort(0);
      setisPortHostReadOnly(false);
      AlertMessage("Please Enter Valid Email", "info");
    } else if (domain.length < 5) {
      setstrSMTPHost("");
      setiSMTPPort(0);
      setisPortHostReadOnly(false);
      AlertMessage("Please Enter Valid Email", "info");
    } else {
      switch (domain) {
        case "gmail.com":
          setstrSMTPHost("smtp.gmail.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "yahoo.com":
          setstrSMTPHost("smtp.mail.yahoo.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "outlook.com":
          setstrSMTPHost("smtp.office365.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "zoho.com":
          setstrSMTPHost("smtp.zoho.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "aol.com":
          setstrSMTPHost("smtp.aol.com");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        case "protonmail.ch":
          setstrSMTPHost("smtp.protonmail.ch");
          setiSMTPPort(587);
          setisPortHostReadOnly(true);
          break;
        default:
          setstrSMTPHost(`smtp.`);
          setiSMTPPort(0);
          setisPortHostReadOnly(false);
          break;
      }
    }
  };
  return (
    <>
      <div className="company-wrapper">
        <div className="row">
          <div className="row">
            <div className="col-md-3 col-sm-12 p-2">
              <center>
                <div className="img-container">
                  <div className="img-content">
                    <div className="img-wrapper">
                      <div className="image">
                        {strImage !== "" && <img src={strImage} alt="" />}
                      </div>
                      <div className="content">
                        <div id="custom-btn" onClick={ActivatDeafultBtn}>
                          <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                      </div>
                      <div
                        id="cancel-btn"
                        onClick={(e) => {
                          setstrImage("");
                          setImageByteArray([]);
                          setImageName("");
                          $(".img-wrapper").removeClass("active");
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </div>
                      <div className="file-name">{ImageName}</div>
                    </div>
                    <input
                      id="default-btn"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      hidden
                    />
                  </div>
                </div>
              </center>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <label>Company Name</label>
                  <span className="danger">*</span>
                  <input
                    autoFocus
                    type="text"
                    onKeyDown={(e) => TabIndex(e)}
                    className="form-control"
                    placeholder="Enter Company Name"
                    tabIndex={1}
                    spellCheck={false}
                    value={strCompanyName}
                    onChange={(e) => setstrCompanyName(e.target.value)}
                    onBlur={(e) => {
                      if (iCompId === 0) CompanyNameChange(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Door/Office No</label>
                  <span className="danger">*</span>
                  <input
                    type="text"
                    className="form-control"
                    onKeyDown={(e) => TabIndex(e)}
                    spellCheck={false}
                    placeholder="Enter Door/Office No"
                    tabIndex={2}
                    value={strAddress1}
                    onChange={(e) => setstrAddress1(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>PIN Code</label> <span className="danger">*</span>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter PIN Code"
                    tabIndex={3}
                    value={iPinCode}
                    onChange={(e) => setiPinCode(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Mobile No</label> <span className="danger">*</span>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile No"
                    tabIndex={4}
                    value={strMobileNo}
                    onChange={(e) => setstrMobileNo(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>GST No</label> <span className="danger">*</span>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter GST No"
                    spellCheck={false}
                    tabIndex={5}
                    value={strGSTNo}
                    onChange={(e) => setstrGSTNo(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>State</label> <span className="danger">*</span>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_selecrparem.isoptionShow}
                    placeholder="Select State"
                    select_value={(val) => setiState(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iState}
                    displayName="strSName"
                    disvalue="iStateId"
                    arrydata={ListState}
                    EmptVal="State"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Stock Method</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Select Stock Method"
                    select_value={(val) => setiStockType(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStockType}
                    displayName="strStkType"
                    disvalue="iStkId"
                    arrydata={ListStockType}
                    EmptVal="Stock Method"
                  />
                </div>
              </div>
            </div>
          </div>
          <label className="btn-More">
            <i className="fa-solid fa-circle-plus mr-1"></i>
            <span
              onClick={(e) => {
                setisMoreinfo(!isMoreinfo);
              }}
            >
              Show Addditional Information
            </span>
          </label>
          {isMoreinfo && (
            <div className="row mb-2">
              <div className="col-md-4 col-sm-12">
                <label>Street/Area</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Street/Area"
                  tabIndex={8}
                  value={strStreet}
                  onChange={(e) => setstrStreet(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Town/Village</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Town Name"
                  tabIndex={9}
                  value={strTown}
                  onChange={(e) => setstrTown(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>City</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter City Name"
                  tabIndex={10}
                  value={strCity}
                  onChange={(e) => setstrCity(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Phone No</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone No"
                  tabIndex={11}
                  value={strPhoneNo}
                  onChange={(e) => setstrPhoneNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>License No</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter License No"
                  tabIndex={12}
                  value={strLicenseNo}
                  onChange={(e) => setstrLicenseNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>TIN No</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter TIN No"
                  tabIndex={13}
                  value={strTinNo}
                  onChange={(e) => setstrTinNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Company Code</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Company Code"
                  tabIndex={15}
                  readOnly={FieldReadOnly}
                  value={strCompanyCode}
                  onChange={(e) => setstrCompanyCode(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Year From</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="date"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={17}
                  value={strFinanFrom}
                  readOnly
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Year To</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="date"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={18}
                  value={strFinanTo}
                  readOnly
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <label>Email</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  spellCheck={false}
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={6}
                  value={strEmail}
                  onChange={(e) => {
                    setisemailchanged(true);
                    setstrEmail(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (isemailchanged) Email_Onchange();
                  }}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <label>Email Password/App Key</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  spellCheck={false}
                  className="form-control"
                  placeholder="Enter Password"
                  tabIndex={19}
                  value={strPassword}
                  onChange={(e) => setstrPassword(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <label>SMTP Host</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  spellCheck={false}
                  className="form-control"
                  placeholder="Enter Mail Host Provider"
                  tabIndex={20}
                  value={strSMTPHost}
                  onChange={(e) => setstrSMTPHost(e.target.value)}
                  readOnly={isPortHostReadOnly}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <label>SMTP Port</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  spellCheck={false}
                  className="form-control"
                  placeholder="Enter Mail Server Port No"
                  tabIndex={21}
                  value={iSMTPPort}
                  onChange={(e) => setiSMTPPort(e.target.value)}
                  readOnly={isPortHostReadOnly}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Remark</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Remark"
                  tabIndex={14}
                  value={strRemark}
                  onChange={(e) => setstrRemark(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="btn-section">
        <button
          tabIndex={20}
          disabled={!isSaveShow}
          className="btn-fabgreen"
          onClick={(e) =>
            dispatch(
              Show_MsgBox({
                Button: Update_Company,
                Question: "Do You Want to Update Company?",
                isdialog: true,
              })
            )
          }
        >
          Update&nbsp; <i className="fas fa-save"></i>
        </button>
        <button
          tabIndex={20}
          disabled={!isSaveShow}
          className="btn-fabgreen"
          onClick={props.Close}
        >
          Close&nbsp;<i className="fas fa-times"></i>
        </button>
      </div>
    </>
  );
}

export default CompanyProfile;
