import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import api, { Backup_Data } from "./Config";
import Admintable from "./Admintable";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
function RestoreMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [ClientId, setClientId] = useState(0);
  const [strClientName, setstrClientName] = useState("");
  const [strCompanyName, setstrCompanyName] = useState("");
  const [strYear, setstrYear] = useState("");
  const [strCompanyDB, setstrCompanyDB] = useState("");
  const [strMasterDB, setstrMasterDB] = useState("");
  const [FileName, setFileName] = useState("");
  const [File, setFile] = useState("");
  const [dbType, setdbType] = useState("");
  const [MsdbName, setMsdbName] = useState("");
  const [CMPdbName, setCMPdbName] = useState("");
  const [arrEntClients, setarrEntClients] = useState([]);
  const [arrEntCompany, setarrEntCompany] = useState([]);
  const arrScrType = [{ Type: "Master" }, { Type: "Company" }];
  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Clients").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntClients(res.data.EntClients);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Client = async (client) => {
    try {
      await Clear_Click();
      await setstrClientName(client.clientName);
      await setClientId(client.clientId);
      await setstrMasterDB(client.strMasterDB);
      await dispatch(setload_Screen(true));
      await api
        .get(`Admin/Load_ClientCompany/${client.clientId}`)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await setarrEntCompany(res.data.EntCompany);
            await Load_DatabaseName();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Company = (company) => {
    setstrCompanyName("");
    setstrYear("");
    setstrCompanyDB("");
    setstrCompanyName(company.strCompanyName);
    setstrYear(company.strYear);
    setstrCompanyDB(company.strCompanyDB);
  };
  const Load_DatabaseName = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(`Admin/Load_DatabaseName`).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          let DBName = Res.data.EntName[0];
          setMsdbName(DBName.masterDB);
          setCMPdbName(DBName.companyDB);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Validate_Databse = (FileName) => {
    FileName = FileName.toLowerCase();
    let MdbName = MsdbName.toLowerCase();
    let CDbName = CMPdbName.toLowerCase();
    switch (dbType) {
      case "Master":
        if (FileName.startsWith(MdbName)) return true;
        else return false;
      case "Company":
        if (FileName.startsWith(CDbName)) return true;
        else return false;
      default:
        return false;
    }
  };
  const Restore_Database = async () => {
    try {
      if (await Validate_Databse(FileName)) {
        await progress(0);
        const BackupData = await Backup_Data({
          Name: strClientName,
          dispatch: dispatch,
          Msdb: strMasterDB,
        });
        if (BackupData) {
          await dispatch(setload_Screen(true));
          await progress(1);
          const formdata = new FormData();
          formdata.append("FileName", FileName);
          formdata.append("File", File);
          await progress(2);
          await api
            .post("Admin/Restore_Database", formdata, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(async (res) => {
              if (res.data.strStatus === "Success") {
                await progress(3);
                AlertMessage(res.data.strMessage, "success");
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        }
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const progress = (tabindex) => {
    let linecomp = document.getElementById("percentcomp");
    let linepen = document.getElementById("percentpen");
    var stepsparent = $(".steps");
    linepen.style.width = stepsparent.width();
    var steps = $(".steps .step");
    linecomp.style.width = steps[tabindex].offsetLeft + "px";
    $(`.steps .step:eq(${tabindex})`).addClass("completed");
  };
  const Activatebtn = () => {
    const defaultBtn = document.getElementById("uploadsql");
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name.replace(".sql", ""));
    });
  };
  const Clear_Click = () => {
    setClientId(0);
    setstrClientName("");
    setstrCompanyName("");
    setstrYear("");
    setstrCompanyDB("");
    setstrMasterDB("");
    setdbType("");
    setMsdbName("");
    setCMPdbName("");
    setFileName("");
    setFile([]);
    setarrEntCompany([]);
    $(`.steps`).removeClass("completed");
  };
  const ClientColumns = [
    {
      Name: "Client Name",
      ColName: "clientName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
  ];
  const CompanyColumns = [
    {
      Name: "Company Name",
      ColName: "strCompanyName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Financial Year",
      ColName: "strYear",
      Color: "black",
      Width: "100",
      textAlign: "center",
    },
    {
      Name: "DataBase",
      ColName: "strCompanyDB",
      Color: "black",
      Width: "200",
      textAlign: "left",
    },
  ];
  return (
    <div className="Dash-content">
      <div className="Dash-card">
        <Admintable
          Columns={ClientColumns}
          Data={arrEntClients}
          View_Data={View_Client}
          IsBackground={true}
        />
      </div>
      <div className="Dash-card">
        <div className="Dash-wrapper">
          <div className="row p-2">
            <h5 className="restorcon">Restore Organization</h5>
            <div className="resprogress">
              <div className="steps">
                <div className="step">
                  <span>
                    <i className="fa-regular fa-circle-check"></i>
                  </span>
                  Verification
                </div>
                <div className="step">
                  <span>
                    <i className="fa-solid fa-cloud-arrow-down"></i>
                  </span>
                  Backup
                </div>
                <div className="step">
                  <span>
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  Delete
                </div>
                <div className="step">
                  <span>
                    <i className="fa-solid fa-cloud-arrow-up"></i>
                  </span>
                  Restore
                </div>
              </div>
              <div id="percentpen"></div>
              <div id="percentcomp"></div>
            </div>
          </div>
          <div className="row pl-2 mb-2">
            <div className="col-sm-12 col-md-4">
              <label>Script Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_selecrparem.isoptionShow1}
                placeholder="Select Database type"
                select_value={(val) => setdbType(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={dbType}
                displayName="Type"
                disvalue="Type"
                arrydata={arrScrType}
                disabled={false}
                EmptVal="Database type"
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Client Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Client Name"
                defaultValue={strClientName}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Master Database</label>
              <input
                type="text"
                className="form-control"
                placeholder="Master Database"
                defaultValue={strMasterDB}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Comapany Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                defaultValue={strCompanyName}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Financial Year</label>
              <input
                type="text"
                className="form-control"
                placeholder="Financial Year"
                defaultValue={strYear}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Company Database</label>
              <input
                type="text"
                className="form-control"
                placeholder="Company Database"
                defaultValue={strCompanyDB}
                disabled
              />
            </div>
          </div>
          <div className="dash-header">
            <div className="col-sm-12 col-md-4">
              <div className="custom-file">
                <input type="file" id="uploadsql" hidden accept=".sql" />
                <button
                  className="filebtn"
                  onClick={(e) => {
                    if (Number(ClientId === 0))
                      AlertMessage("Please Select Client", "info");
                    else if (dbType.length === 0)
                      AlertMessage("Please Select Database Type", "info");
                    else Activatebtn();
                  }}
                >
                  Browse file&nbsp;<i className="bx bxs-folder"></i>
                </button>
              </div>
            </div>
            <ul className="file_added">
              <li>Database Name</li>
              <li>
                {FileName !== "" && (
                  <a href="##">
                    <i className="fa-solid fa-paperclip"></i>
                    <span>{FileName}</span>
                    <i
                      className="fa-solid fa-xmark"
                      onClick={(e) => {
                        setFileName("");
                        setFile();
                        var file = document.getElementById("uploadsql");
                        file.value = "";
                      }}
                    ></i>
                  </a>
                )}
              </li>
            </ul>
          </div>
          <Admintable
            Columns={CompanyColumns}
            Data={arrEntCompany}
            View_Data={View_Company}
            IsBackground={true}
          />
        </div>
        <div className="Dash-section">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (Number(ClientId) === 0)
                AlertMessage("Please Select Client", "info");
              else if (FileName.length === 0)
                AlertMessage("Please Select Backup", "info");
              else if (dbType.length === 0)
                AlertMessage("Please Select Database Type", "info");
              else if (dbType === "Master" && strMasterDB.length === 0)
                AlertMessage("Please Select Client Database", "info");
              else if (dbType === "Company" && strCompanyDB.length === 0)
                AlertMessage("Please Select Company", "info");
              else if (dbType === "Company" && strCompanyDB !== FileName)
                AlertMessage("Please Select Valid Company", "info");
              else
                dispatch(
                  Show_MsgBox({
                    Button: Restore_Database,
                    Question: "Do You Want to Restore Data?",
                    isdialog: true,
                  })
                );
            }}
          >
            <i className="fa-solid fa-arrows-rotate"></i>Restore Data
          </button>
          <button className="btn-fabgreen" onClick={() => Clear_Click()}>
            Clear Client&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default RestoreMaster;
