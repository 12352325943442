import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Print_DesignCaller from "../General/Print_DesignCaller";
import { Show_MsgBox } from "../ReduxStore/Message";

function AccountsButton(props) {
  const dispatch = useDispatch();
  const [IsbtnHide, setIsbtnHide] = useState(false);
  const [btnSave, setbtnSave] = useState(false);
  const [btnDelete, setbtnDelete] = useState(false);
  const [btnPrint, setbtnPrint] = useState(false);
  const [btnSettlement, setbtnSettlement] = useState(false);
  useEffect(() => {
    if (props.ScrId === 43 || props.ScrId === 44) {
      setIsbtnHide(true);
    }
    ButtonPermission();
  }, [props]);

  const ButtonPermission = () => {
    let Menu = JSON.parse(localStorage.Menu);
    Menu = Menu.objEntAPM.filter((Data) => Data.iScrId === props.ScrId)[0];
    const BtnPerm = {
      btnSave: !Menu.isNew,
      btnDelete: !Menu.isCancel,
      btnPrint: !Menu.isPrint,
    };
    if (Number(props.Iid) > 0) {
      BtnPerm.btnSave =
        !Menu.isModify === false ? props.CancelStatus : !Menu.isModify;
      BtnPerm.btnDelete =
        BtnPerm.btnDelete === false ? props.CancelStatus : BtnPerm.btnDelete;
    }
    setbtnSave(BtnPerm.btnSave);
    setbtnDelete(BtnPerm.btnDelete);
    setbtnPrint(BtnPerm.btnPrint);
    setbtnSettlement(props.CancelStatus);
  };
  const PrintAccounts = () => {
    Print_DesignCaller(
      props.ScrId,
      props.ScrName,
      "Accounts",
      props.Iid,
      0,
      "Print",
      dispatch,
      false
    );
  };
  const Alert_Message = async (Msg, Icon) => {
    await dispatch(Show_MsgBox({ Message: Msg, Type: Icon, isMsg: true }));
  };
  return (
    <div className="btn-section">
      <button
        className="btn-fabgreen"
        onClick={props.Save}
        tabIndex={props.SaveIndex}
        onKeyDown={(e) => props.TabIndex(e)}
        disabled={btnSave}
      >
        Save&nbsp;<i className="bx bxs-save"></i>
      </button>
      <button
        className="btn-fabgreen"
        onClick={props.Cancel}
        disabled={btnDelete}
        onKeyDown={(e) => props.TabIndex(e)}
        tabIndex={props.CancelIndex}
      >
        Cancel&nbsp;<i className="bx bx-block"></i>
      </button>
      <button
        tabIndex={props.SettleIndex}
        disabled={btnSettlement}
        className="btn-fabgreen"
        onKeyDown={(e) => props.TabIndex(e)}
        onClick={() => {
          window.location.href =
            props.ScrId === 41
              ? `/Accounts/PaymentSettlement`
              : `/Accounts/ReceiptSettlement`;
        }}
        hidden={IsbtnHide}
      >
        Settlement&nbsp;<i className="bx bx-wallet"></i>
      </button>
      <button
        className="btn-fabgreen"
        onClick={() => {
          if (Number(props.Iid) === 0)
            Alert_Message("Please Select Voucher", "info");
          else PrintAccounts();
        }}
        disabled={btnPrint}
      >
        Print&nbsp;<i className="bx bx-printer"></i>
      </button>
      <button
        className="btn-fabgreen"
        onClick={(e) => {
          props.Clear();
          setbtnSave(false);
          setbtnDelete(false);
          setbtnSettlement(false);
          setbtnPrint(false);
        }}
        tabIndex={props.ClearIndex}
        onKeyDown={(e) => props.TabIndex(e)}
      >
        Clear&nbsp;<i className="fa-solid fa-broom"></i>
      </button>
      <button
        className="btn-fabgreen"
        tabIndex={props.CloseIndex}
        onKeyDown={(e) => props.TabIndex(e)}
        onClick={() =>
          (window.location.href = "/Dashboard")
        }
      >
        Close&nbsp;<i className="fas fa-times"></i>
      </button>
    </div>
  );
}

export default AccountsButton;
