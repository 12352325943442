import React, { useState } from "react";
import $ from "jquery";
import Messagedialogbox from "../General/Messagedialogbox";
import ClientMaster from "./ClientMaster";
import Usermaster from "./Usermaster";
import DatabaseMaster from "./DatabaseMaster";
import EnquiryMaster from "./EnquiryMaster";
import AvailableDatabase from "./AvailableDatabase";
import ScriptMaster from "./ScriptMaster";
import ErrorLogs from "./ErrorLogs";
import RestoreMaster from "./RestoreMaster";
import NewsMaster from "./NewsMaster";
function ControlPanel() {
  const [arrEntTab, setarrEntTab] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const tab_click = (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");

    let EntTab = arrEntTab.map((dt, Index) => {
      if (tabindex === Index) dt = true;
      else dt = false;
      return dt;
    });
    setarrEntTab(EntTab);
  };
  return (
    <div className="Dash-container">
      <div className="dash-header">
        <h1>Control Panel</h1>
      </div>
      <div className="trans-tab">
        <div className="trans-buttons">
          <label onClick={(e) => tab_click(0)} className="active">
            Active Client
          </label>
          <label onClick={(e) => tab_click(1)}>Active News</label>
          <label onClick={(e) => tab_click(2)}>Active Users</label>
          <label onClick={(e) => tab_click(3)}>Active Database</label>
          <label onClick={(e) => tab_click(4)}>Enquiries</label>
          <label onClick={(e) => tab_click(5)}>Available Database</label>
          <label onClick={(e) => tab_click(6)}>Script</label>
          <label onClick={(e) => tab_click(7)}>Restore Database</label>
          <label onClick={(e) => tab_click(8)}>Error Log</label>
          <div id="tab-line"></div>
        </div>
      </div>
      <Messagedialogbox />
      {arrEntTab[0] && <ClientMaster />}
      {arrEntTab[1] && <NewsMaster />}
      {arrEntTab[2] && <Usermaster />}
      {arrEntTab[3] && <DatabaseMaster />}
      {arrEntTab[4] && <EnquiryMaster />}
      {arrEntTab[5] && <AvailableDatabase />}
      {arrEntTab[6] && <ScriptMaster />}
      {arrEntTab[7] && <RestoreMaster />}
      {arrEntTab[8] && <ErrorLogs />}
    </div>
  );
}

export default ControlPanel;
