import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";
import Admintable from "./Admintable";
function NewsMaster() {
  const dispatch = useDispatch();
  const [iNewsId, setiNewsId] = useState(0);
  const [iClientId, setiClientId] = useState(0);
  const [iUserId, setiUserId] = useState(0);
  const [Status, setStatus] = useState(true);
  const [strNews, setstrNews] = useState("");
  const [strClientName, setstrClientName] = useState("");
  const [strUserName, setstrUserName] = useState("");
  const [arrEntUser, setarrEntUser] = useState([]);
  const [arrEntClients, setarrEntClients] = useState([]);
  const [arrEntNews, setarrEntNews] = useState([]);
  useEffect(() => {
    Load_Clients();
  }, []);

  const Load_Clients = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Clients").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntClients(res.data.EntClients);
          Load_News();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Save_News = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iNewsId: iNewsId,
        strNews: strNews,
        Status: Number(Status),
        UserId: iUserId,
        ClientId: iClientId,
        ClientName: "",
        UserName: "",
      };
      await api.post("Admin/InsUpd_News", parems).then((res) => {
        if (res.data.strStatus === "Success") {
          Clear_Click();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_News = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(`Admin/Delete_News/${Number(iNewsId)}`).then((res) => {
        if (res.data.strStatus === "Success") {
          Clear_Click();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_News = async (news) => {
    setiNewsId(news.iNewsId);
    setStatus(Boolean(news.status));
    setstrNews(news.strNews);
    setstrClientName(news.clientName);
    setiUserId(news.userId);
    setiClientId(news.clientId);
    setstrUserName(news.userName);
    setarrEntUser([]);
  };
  const Load_News = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_News").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntNews(res.data.EntNews);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_ClientUser = async (client) => {
    try {
      await Clear_Click();
      setstrClientName(client.clientName);
      setiClientId(client.clientId);
      await dispatch(setload_Screen(true));
      await api
        .get(`Admin/View_ClientUser/${client.strMasterDB}`)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            setarrEntUser(res.data.EntUser);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_User = async (user) => {
    setiUserId(user.iUserId);
    setstrUserName(user.strName);
    setiNewsId(0);
    setstrNews("");
  };
  const Clear_Click = () => {
    setiNewsId(0);
    setiClientId(0);
    setiUserId(0);
    setStatus(true);
    setstrNews("");
    setstrClientName("");
    setstrUserName("");
    setarrEntUser([]);
    Load_News();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ClientColumns = [
    {
      Name: "Client Name",
      ColName: "clientName",
      Color: "Black",
      Width: "auto",
      textAlign: "left",
    },
  ];
  const UserColumns = [
    {
      Name: "Name",
      ColName: "strName",
      Color: "Black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "   User Name",
      ColName: "strUserName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Password",
      ColName: "strPassword",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Logout Status",
      ColName: "isLogOut",
      Color: "black",
      Width: "100",
      textAlign: "center",
    },
  ];
  const NewColumns = [
    {
      Name: "Client Name",
      ColName: "clientName",
      Color: "Black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "User Name",
      ColName: "userName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Message",
      ColName: "strNews",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Status",
      ColName: "status",
      Color: "black",
      Width: "70",
      textAlign: "center",
    },
  ];
  return (
    <div className="Dash-content">
      <div className="Dash-card">
        <Admintable
          Columns={ClientColumns}
          Data={arrEntClients}
          View_Data={View_ClientUser}
          IsBackground={true}
        />
      </div>
      <div className="Dash-card">
        <div className="Dash-wrapper">
          {arrEntUser.length > 0 && (
            <Admintable
              Columns={UserColumns}
              Data={arrEntUser}
              View_Data={View_User}
              IsBackground={true}
            />
          )}
          <div className="row p-2">
            <div className="col-sm-12 col-md-6 mt-1">
              <label>Client Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Client Name"
                value={strClientName}
                readOnly
              />
            </div>
            <div className="col-sm-12 col-md-3 mt-1">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter User Name"
                tabIndex={2}
                value={strUserName}
                readOnly
              />
            </div>
            <div className="col-sm-12 col-md-3 mt-1">
              <label>Status</label>
              <input
                type="checkbox"
                className="form-check"
                checked={Status}
                onChange={() => setStatus(!Status)}
              />
            </div>
            <div className="col-sm-12 col-md-12 mt-1">
              <label>News</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter News"
                tabIndex={3}
                value={strNews}
                onChange={(e) => setstrNews(e.target.value)}
                style={{ minHeight: "150px" }}
                spellCheck={false}
                autoComplete="off"
              />
            </div>
          </div>
          <Admintable
            Columns={NewColumns}
            Data={arrEntNews}
            View_Data={View_News}
            IsBackground={true}
          />
        </div>
        <div className="Dash-section">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              dispatch(
                Show_MsgBox({
                  Button: Save_News,
                  Question: "Do You Want to Update News?",
                  isdialog: true,
                })
              );
            }}
          >
            Update News&nbsp;<i className="bx bxs-save"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={() => {
              if (iNewsId === 0) AlertMessage("Please Select News", "info");
              else
                dispatch(
                  Show_MsgBox({
                    Button: Delete_News,
                    Question: "Do You Want to Delete News?",
                    isdialog: true,
                  })
                );
            }}
          >
            Delete News&nbsp;<i className="bx bx-trash-alt"></i>
          </button>
          <button className="btn-fabgreen" onClick={() => Clear_Click()}>
            Clear Client&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewsMaster;
