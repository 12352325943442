import React from "react";

function ActivationKeyExpired() {
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Important Alert !</h5>
        </div>
        <div className="popup-body Autobackup">
          <p>
            Dear <strong>{localStorage.OrgName}</strong>,
          </p>
          <p>
            We hope this message finds you well. This is to inform you that your
            current license for JustBill has expired.
          </p>
          <p>
            We value your continued patronage and would like to ensure that your
            access to our services remains uninterrupted.
          </p>
          <p>
            To renew your license, please contact our sales team at
            <br />
            <strong>+91-9629325653</strong>.
          </p>
          <p>
            If you have any questions or need assistance with the renewal
            process, our support team is available to help.
          </p>
          <p className="aub-battom">
            Best regards, <br />
            <strong>Fabgreen Technologies</strong>
            <br />
            Vadavalli,Coimbatore
          </p>
        </div>
      </div>
    </div>
  );
}

export default ActivationKeyExpired;
