import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import api from "../Admin/Config";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import $ from "jquery";
function PrintTemplateCreation(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iPrinterTypeId, setiPrinterTypeId] = useState(0);
  const [iScrId, setiScrId] = useState(0);
  const [iLineFirstId, setiLineFirstId] = useState(0);
  const [iLineAlterId, setiLineAlterId] = useState(0);
  const [iLineMiddleId, setiLineMiddleId] = useState(0);
  const [iLineLastId, setiLineLastId] = useState(0);
  const [iPrintTempId, setiPrintTempId] = useState(0);

  const [iFilterTemplate, setiFilterTemplate] = useState(0);
  const [strSearch, setstrSearch] = useState("");
  const [strTempCode, setstrTempCode] = useState("");
  const [bIsDefaut, setbIsDefaut] = useState(false);
  const [strTempName, setstrTempName] = useState("");

  const [ListTemplate, setListTemplate] = useState([]);
  const [ListFilterTemp, setListFilterTemp] = useState([]);
  const [ListPrinterType, setListPrinterType] = useState([]);
  const [ListScreen, setListScreen] = useState([]);
  const [ListLineFirst, setListLineFirst] = useState([]);
  const [ListLineAlter, setListLineAlter] = useState([]);
  const [ListLineMiddle, setListLineMiddle] = useState([]);
  const [ListLineLast, setListLineLast] = useState([]);
  useEffect(() => {
    Load_PrintTemplate();
  }, []);

  const Load_PrintTemplate = () => {
    try {
      dispatch(setload_Screen(true));
      api.get(
        
          "PrintMaster/Load_PrintTemplate/" +
          localStorage.db +
          "/" +
          localStorage.Msdb
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListTemplate(Res.data.EntTemplate);
          setListPrinterType(Res.data.EntPrinter);
          setListScreen(Res.data.EntScreen);
          setListFilterTemp(Res.data.EntFilter);
          setListLineFirst(Res.data.EntLineFirst);
          setListLineAlter(Res.data.EntLineAlter);
          setListLineMiddle(Res.data.EntLineMiddle);
          setListLineLast(Res.data.EntLineLast);
        } else AlertMessage(Res.data.strMessage, "error");
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const InsUpd_PrintTemplate = async () => {
    try {
      await dispatch(setload_Screen(true));
      const SaveTemp = {
        iPrintTypeId: Number(iPrinterTypeId),
        strPrintTempCode: strTempCode,
        strPrintTempName: strTempName,
        iLineTempIdFirst: Number(iLineFirstId),
        iLineTempIdFirstAlter: Number(iLineAlterId),
        iLineTempIdMiddle: Number(iLineMiddleId),
        iLineTempIdLast: Number(iLineLastId),
        bSetDefault: bIsDefaut,
        iScrId: Number(iScrId),
        iPrintTempId: Number(iPrintTempId),
        strCompanyDB: localStorage.db,
      };
      await api.post(
         "PrintMaster/InsUpd_PrintTemplate/",
        SaveTemp
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          Clear_Template();
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_PrintTemplate = async (TempInfo) => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(
        
          "PrintMaster/View_PrintTemplate/" +
          Number(TempInfo.iPrintTempId) +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let EntPrintTemplate = Res.data.EntPrintTemplate[0];
          setiPrinterTypeId(EntPrintTemplate.iPrintTypeId);
          setiScrId(EntPrintTemplate.iScrId);
          setiPrintTempId(EntPrintTemplate.iPrintTempId);
          setstrTempCode(EntPrintTemplate.strPrintTempCode);
          setstrTempName(EntPrintTemplate.strPrintTempName);
          setiLineFirstId(EntPrintTemplate.iLineTempIdFirst);
          setiLineAlterId(EntPrintTemplate.iLineTempIdFirstAlter);
          setiLineMiddleId(EntPrintTemplate.iLineTempIdMiddle);
          setiLineLastId(EntPrintTemplate.iLineTempIdLast);
          setbIsDefaut(EntPrintTemplate.bSetDefault);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Template = () => {
    setiPrinterTypeId(0);
    setiScrId(0);
    setiPrintTempId(0);
    setiFilterTemplate(0);
    setstrTempCode("");
    setstrTempName("");
    setiLineFirstId(0);
    setiLineAlterId(0);
    setiLineMiddleId(0);
    setiLineLastId(0);
    setstrSearch("");
    setbIsDefaut(false);
    Load_PrintTemplate();
  };
  const Delete_PrintTemplate = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        
          "PrintMaster/Delete_PrintTemplate/" +
          Number(iPrintTempId) +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          Clear_Template();
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Template = (val, valFor) => {
    const objSearch = {
      strSearch: strSearch,
      iItemId: iFilterTemplate,
      strCompanyDB: localStorage.db,
    };
    switch (valFor) {
      case "Text":
        setstrSearch(val);
        objSearch.strSearch = val;
        break;
      case "Filter":
        setiFilterTemplate(val);
        objSearch.itemId = Number(val);
        break;
      default:
        break;
    }
    api.post( "PrintMaster/Search_PrintTemplate/", objSearch).then(
      (Res) => {
        if (Res.data.strStatus === "Success") {
          setListTemplate(Res.data.EntTemplate);
        } else AlertMessage(Res.data.strMessage, "error");
      }
    );
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Print Template</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <CustomizedSelectoption
                optionparem="isoptionShow9"
                showoption={_parem.isoptionShow9}
                placeholder="Select Screen"
                select_value={(val) => Search_Template(Number(val), "Filter")}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iFilterTemplate}
                displayName="strScrName"
                disvalue="iScrId"
                arrydata={ListFilterTemp}
                EmptVal="Screen Name"
              />
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search Template"
                  value={strSearch}
                  onChange={(e) => Search_Template(e.target.value, "Text")}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Template(strSearch, "Text")}
                ></i>
              </div>
              <div className="data-list">
                {ListTemplate.map((template, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = ListTemplate.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_PrintTemplate(template);
                      }
                    }}
                  >
                    <label onClick={(e) => View_PrintTemplate(template)}>
                      {template.strPrintTempName}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Printer Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow10"
                    showoption={_parem.isoptionShow10}
                    placeholder="Select Printer Type"
                    select_value={(val) => setiPrinterTypeId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iPrinterTypeId}
                    displayName="strPrinterTypeName"
                    disvalue="iPrinterTypeId"
                    arrydata={ListPrinterType}
                    EmptVal="Printer Type"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Screen Name</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow11"
                    showoption={_parem.isoptionShow11}
                    placeholder="Select Screen"
                    select_value={(val) => setiScrId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iScrId}
                    displayName="strScrName"
                    disvalue="iScrId"
                    arrydata={ListScreen}
                    EmptVal="Screen Name"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>First Line Template</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow12"
                    showoption={_parem.isoptionShow12}
                    placeholder="Select Template"
                    select_value={(val) => setiLineFirstId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iLineFirstId}
                    displayName="strLineTempName"
                    disvalue="iLineTempId"
                    arrydata={ListLineFirst}
                    EmptVal="Line Template"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Alter Line Template</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow13"
                    showoption={_parem.isoptionShow13}
                    placeholder="Select Template"
                    select_value={(val) => setiLineAlterId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iLineAlterId}
                    displayName="strLineTempName"
                    disvalue="iLineTempId"
                    arrydata={ListLineAlter}
                    EmptVal="Line Template"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Middle Line Template</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow14"
                    showoption={_parem.isoptionShow14}
                    placeholder="Select Template"
                    select_value={(val) => setiLineMiddleId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iLineMiddleId}
                    displayName="strLineTempName"
                    disvalue="iLineTempId"
                    arrydata={ListLineMiddle}
                    EmptVal="Line Template"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Laste Line Template</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow15"
                    showoption={_parem.isoptionShow15}
                    placeholder="Select Template"
                    select_value={(val) => setiLineLastId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iLineLastId}
                    displayName="strLineTempName"
                    disvalue="iLineTempId"
                    arrydata={ListLineLast}
                    EmptVal="Line Template"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Template Name</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Template Name"
                    value={strTempName}
                    onChange={(e) => setstrTempName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Template Code</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Template Name"
                    value={strTempCode}
                    onChange={(e) => setstrTempCode(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Defaut Template</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={bIsDefaut}
                      onChange={(e) => setbIsDefaut(!bIsDefaut)}
                      id="flexCheckDefault"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={InsUpd_PrintTemplate}>
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() => {
                dispatch(
                  Show_MsgBox({
                    Button: Delete_PrintTemplate,
                    Question: "Do You Want to  Delete Template?",
                    isdialog: true,
                  })
                );
              }}
            >
              Delete &nbsp; <i className="far fa-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={Clear_Template}>
              Clear &nbsp; <i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintTemplateCreation;
