import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function TransactionAdjustment() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [objVouchType, setobjVouchType] = useState([]);
  const [objPOVoucher, setobjPOVoucher] = useState([]);
  const [objReason, setobjReason] = useState([]);
  const [arrEntVouchers, setarrEntVouchers] = useState([]);
  const [selected, setselected] = useState(0);
  const [strSearch, setstrSearch] = useState("");
  const [iScrId, setiScrId] = useState(71);
  const [iAdjustTypeId, setiAdjustTypeId] = useState(4);
  const [iPOId, setiPOId] = useState(0);
  const [dQty, setdQty] = useState(0);
  const [Sno, setSno] = useState(0);
  const [strProName, setstrProName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [strTabName, setstrTabName] = useState("Pending");
  const [ispending, setispending] = useState(true);
  const [iIndex, setiIndex] = useState(0);
  useEffect(() => {
    Load_TransactionAdjustment();
  }, []);

  const Load_TransactionAdjustment = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await api.post(
        `TransactionAdjustment/Load_TransactionAdjustment`,
        parems
      ).then((Res) => {
        setobjVouchType(Res.data.objVouchType);
        setobjReason(Res.data.objReason);
        setobjPOVoucher(Res.data.objPOVoucher);
        setarrEntVouchers(Res.data.objPendingData);
        tab_click(iIndex, strTabName, iScrId);
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const UpdateClick = (e) => {
    if (setarrEntVouchers.length === 0) {
      AlertMessage("No rows are found", "info");
    } else {
      var PendingVoucher = arrEntVouchers;
      var AdjustType = objReason.filter(
        (F) => F.iAdjustTypeId === iAdjustTypeId
      );
      var POType = objPOVoucher.filter((F) => F.iPOId === iPOId);
      PendingVoucher.forEach((element) => {
        element.adjustTypeId = iAdjustTypeId;
        element.adjustType = AdjustType.map((a) => a.strAdjustType)[0];
        element.poId = iPOId;
        element.poCode = POType.map((a) => a.strPOName)[0];
      });
      dispatch(setload_Screen(true));
      api.post( "TransactionAdjustment/Update/", {
        objEntTAP: PendingVoucher,
        strCompanyDB: localStorage.db,
      }).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
    }
  };
  const DeleteClick = (iTransAdjId) => {
    dispatch(setload_Screen(true));
    api.post(
      
        "TransactionAdjustment/Delete/" +
        iTransAdjId +
        "/" +
        localStorage.db
    ).then((Res) => {
      if (Res.data.strStatus === "Success") {
        ClearClick();
        AlertMessage(Res.data.strMessage, "success");
      } else {
        AlertMessage(Res.data.strMessage, "error");
      }
      dispatch(setload_Screen(false));
    });
  };
  const ClearClick = () => {
    setselected(0);
    setiScrId(71);
    setiAdjustTypeId(4);
    setiPOId(0);
    setdQty(0);
    setstrProName("");
    tab_click(iIndex, strTabName, iScrId);
    setarrEntVouchers([]);
    Load_TransactionAdjustment();
  };
  const handleClick = (event) => {
    event.target.select();
  };
  const AddClick = (Qty) => {
    let ExistQty = arrEntVouchers[selected].pendingQty;
    if (ExistQty < Qty) {
      AlertMessage("Only " + ExistQty + " Qty is available to adjust.", "info");
    } else {
      var PendingVouchers = JSON.parse(
        JSON.stringify(arrEntVouchers, (k, v) => (v === null ? "" : v))
      );
      dispatch(setload_Screen(true));
      api.post( "TransactionAdjustment/AddQty/", {
        dQty: Qty,
        iRowIndex: selected,
        objPenModel: PendingVouchers,
        Remark: strRemark,
      }).then((Res) => {
        dispatch(setload_Screen(false));
        setdQty(0);
        setstrProName("");
        setarrEntVouchers(Res.data);
      });
    }
  };
  const columnsPendingVoucher = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vendor Name",
      accessor: "venName",
      style: { justifyContent: "left" },
      minWidth: 200,
    },

    {
      Header: "Vouch No",
      accessor: "vouchNo",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Date",
      accessor: "vouchDate",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 250,
      style: { justifyContent: "left" },
    },
    {
      Header: "Actual Qty",
      accessor: "actualQty",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Convert Qty",
      accessor: "convertedQty",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Pending Qty",
      accessor: "pendingQty",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Qty",
      accessor: "qty",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Remark",
      accessor: "remark",
      style: { justifyContent: "left" },
      minWidth: 150,
      maxWidth: 150,
    },
  ];
  const columnsCompVoucher = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vendor Name",
      accessor: "venName",
      style: { justifyContent: "left" },
      minWidth: 200,
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Date",
      accessor: "vouchDate",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Convert Qty",
      accessor: "convertedQty",
      minWidth: 70,
      maxWidth: 70,
      style: { justifyContent: "right" },
    },
    {
      Header: "Unit",
      accessor: "unit",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "AdjustType",
      accessor: "adjustType",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Production Voucher",
      accessor: "poCode",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Remark",
      accessor: "remark",
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: "Options",
      minWidth: 50,
      maxWidth: 50,
      cell: ({ rowinfo }) => (
        <i
          onClick={(e) => {
            dispatch(
              Show_MsgBox({
                Button: DeleteClick,
                Question: "Do You Want to Delete Adjustment?",
                isdialog: true,
                Id: rowinfo.traAdjId,
              })
            );
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const SelectedTab = (TabName, ScrId) => {
    setstrTabName(TabName);
    setiScrId(ScrId);
    const objsearch = {
      strColName: TabName,
      iTypeId: ScrId,
      strSearch: strSearch,
      strCompanyDB: localStorage.db,
    };
    dispatch(setload_Screen(true));
    api.post( "TransactionAdjustment/LoadVouchers/", objsearch).then(
      (Res) => {
        setarrEntVouchers(Res.data);
        dispatch(setload_Screen(false));
      }
    );
  };
  const SearchAdjustMent = (strtxt) => {
    setstrSearch(strtxt);
    const objsearch = {
      strColName: strTabName,
      iTypeId: iScrId,
      strSearch: strtxt,
      strCompanyDB: localStorage.db,
    };
    api.post( "TransactionAdjustment/LoadVouchers/", objsearch).then(
      (Res) => {
        setarrEntVouchers(Res.data);
      }
    );
  };
  const tab_click = (tabindex, tabname, ScrId) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    setiIndex(tabindex);
    setispending(tabindex === 0 ? true : false);
    SelectedTab(tabname, ScrId);
  };
  return (
    <>
      <SideMenu ScrId={77} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="row d-flex justify-content-end">
              <div className="col-md-4 col-sm-12 mt-2">
                <div className="m-hsearch">
                  <input
                    type="text"
                    autoCorrect="off"
                    autoComplete="off"
                    placeholder="Search Forcast"
                    value={strSearch}
                    onChange={(e) => SearchAdjustMent(e.target.value)}
                    autoFocus
                  />
                  <i
                    className="fas fa-search"
                    onClick={(e) => SearchAdjustMent(strSearch)}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mt-2">
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Voucher Type"
                  select_value={(val) => SelectedTab(strTabName, val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iScrId}
                  displayName="strScrName"
                  disvalue="iScrId"
                  arrydata={objVouchType}
                  EmptVal="Voucher Type"
                />
              </div>
            </div>
            <div className="fg-card shadow-base mt-2">
              <div className="trans-tab">
                <div className="trans-buttons">
                  <label
                    onClick={(e) => tab_click(0, "Pending", iScrId)}
                    className="active"
                  >
                    Pending
                  </label>
                  <label onClick={(e) => tab_click(1, "Completed", iScrId)}>
                    Completed
                  </label>
                  <div id="tab-line"></div>
                </div>
              </div>
              {ispending && (
                <div className="row p-2">
                  <div className="col-sm-12 col-md-2 mt-1">
                    <label>Production Voucher</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow2"
                      showoption={_selecrparem.isoptionShow2}
                      placeholder="Select Production Voucher"
                      select_value={(val) => setiPOId(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iPOId}
                      displayName="strPOName"
                      disvalue="iPOId"
                      arrydata={objPOVoucher}
                      EmptVal="Production Voucher"
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-1">
                    <label>Product Name</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      name="strProName"
                      value={strProName}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-12 col-md-1 mt-1">
                    <label>Qty</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      autoComplete="off"
                      name="dQty"
                      id="txtproQty"
                      value={dQty}
                      onChange={(e) => setdQty(Number(e.target.value))}
                      onClick={handleClick}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 mt-1">
                    <label>Remark</label>
                    <input
                      type="text"
                      className="form-control"
                      name="txtRemark"
                      autoComplete="off"
                      id="txtRemark"
                      value={strRemark}
                      onChange={(e) => setstrRemark(e.target.value)}
                      onClick={handleClick}
                      placeholder="Remark"
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 mt-1">
                    <label>Reason</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow1"
                      showoption={_selecrparem.isoptionShow1}
                      placeholder="Select Reason"
                      select_value={(val) => setiAdjustTypeId(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iAdjustTypeId}
                      displayName="strAdjustType"
                      disvalue="iAdjustTypeId"
                      arrydata={objReason}
                      EmptVal="Reason"
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 mt-4">
                    <div className="btn-sub-section">
                      <button
                        className="btn-fabgreen"
                        onClick={(e) => AddClick(dQty, Sno)}
                      >
                        Add&nbsp;<i className="bx bx-layer-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {ispending && (
              <ReactTable
                columns={columnsPendingVoucher}
                data={arrEntVouchers}
                minRows={10}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  setstrProName(rowInfo.proName);
                  setdQty(rowInfo.qty);
                  setSno(rowInfo.sNo);
                }}
                background={true}
                className="full-table"
                containerclass="autotable"
              />
            )}
            {!ispending && (
              <ReactTable
                columns={columnsCompVoucher}
                data={arrEntVouchers}
                minRows={10}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  setstrProName(rowInfo.proName);
                  setdQty(rowInfo.qty);
                  setSno(rowInfo.sNo);
                }}
                background={true}
                className="full-table"
                containerclass="autotable"
              />
            )}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: UpdateClick,
                    Question: "Do You Want to Update Adjustment ?",
                    isdialog: true,
                  })
                );
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() =>
                (window.location.href =
                  "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransactionAdjustment;
