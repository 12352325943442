import React from "react";
import { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import api from "../Admin/Config";

function VoucherNo(props) {
  const dispatch = useDispatch();
  const [strSearch, setstrSearch] = useState("");
  const [VoucherNumberList, setVoucherNumberList] = useState([]);
  useEffect(() => {
    Load_VoucherNumberSetting("");
  }, []);
  const Load_VoucherNumberSetting = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api
        .post(`ConfigurationSetting/Load_VoucherNumberSetting/`, objSearch)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setVoucherNumberList(Res.data.EntVouchSett);
          } else AlertMessage(Res.data.strMessage, "error");
          dispatch(setload_Screen(false));
        });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const Save_VoucherNumSetting = () => {
    try {
      let UpdateVoucherNumber = VoucherNumberList;
      let CheckUserFormate = UpdateVoucherNumber.filter(
        (Data) => !Data.userNoFormat.includes("#N")
      );
      if (CheckUserFormate.length > 0) AlertMessage("#N is Mandatory", "info");
      else {
        api
          .post("ConfigurationSetting/Update_VoucherNumSetting/", {
            objEntVoucherNo: UpdateVoucherNumber,
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              Clear_VoucherSetting();
              AlertMessage(Res.data.strMessage, "success");
            } else AlertMessage(Res.data.strMessage, "error");
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Clear_VoucherSetting = () => {
    setVoucherNumberList([]);
    Load_VoucherNumberSetting("");
  };
  const VoucherColumn = [
    {
      Header: "Screen Name",
      accessor: "scrName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Type",
      accessor: "voucherType",
      minWidth: 70,
      maxWidth: 70,
      style: { justifyContent: "center" },
    },
    {
      Header: "Max Degit",
      minWidth: 100,
      maxWidth: 100,
      accessor: "numSeqMaxDigit",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.numSeqMaxDigit}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.numSeqMaxDigit = e.target.value)}
        />
      ),
    },
    {
      Header: "Next Number",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      accessor: "nextNumber",
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.nextNumber}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.nextNumber = e.target.value)}
        />
      ),
    },
    {
      Header: "Invoice No Formate",
      minWidth: 150,
      maxWidth: 150,
      accessor: "userNoFormat",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.userNoFormat}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.userNoFormat = e.target.value)}
        />
      ),
    },
    {
      Header: "Invoice No",
      accessor: "userNo",
      minWidth: 150,
      style: { justifyContent: "left" },
    },
    {
      Header: "Manual No",
      minWidth: 70,
      accessor: "isManualNumber",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          className="form-check-input"
          type="checkbox"
          checked={rowinfo.isManualNumber}
          onChange={() => (rowinfo.isManualNumber = !rowinfo.isManualNumber)}
        />
      ),
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Voucher Number Configuration</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="p-2 pb-0">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Voucher Screen"
                value={strSearch}
                onChange={(e) => Load_VoucherNumberSetting(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Load_VoucherNumberSetting(strSearch)}
              ></i>
            </div>
          </div>
          <ReactTable
            columns={VoucherColumn}
            data={VoucherNumberList}
            minRows={10}
            row_click={(rowInfo, Index) => {}}
            row_doubleclick={(rowInfo, Index) => {}}
            background={true}
            className="full-table"
          />
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={Save_VoucherNumSetting}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Clear_VoucherSetting}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VoucherNo;
