import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "./components/Admin/AdminLogin";
import UserLogin from "./components/Admin/UserLogin";
import Home from "./components/General/Home";
import PrivateRoutes from "./components/Admin/PrivateRoutes";
import CompanyMaster from "./components/General/CompanyMaster";
import ControlPanel from "./components/Admin/ControlPanel";
import Dashboard from "./components/General/DashBoard";
/* =================Master Screen============================================== */
import ProductMaster from "./components/Master/ProductMaster";
import CustomerMaster from "./components/Master/CustomerMaster";
import SupplierMaster from "./components/Master/SupplierMaster";
import VendorMaster from "./components/Master/VendorMaster";
import JobWorkMaster from "./components/Master/JobWorkMaster";
import ServiceMaster from "./components/Master/ServiceMaster";
import CategoryMaster from "./components/Master/CategoryMaster";
import GSTTaxMaster from "./components/Master/GSTTaxMaster";
import TaxMaster from "./components/Master/TaxMaster";
import PriceMaster from "./components/Master/PriceMaster";
import UnitMaster from "./components/Master/UnitMaster";
import OtherHeadMaster from "./components/Master/OtherHeadMaster";
import BankAccount from "./components/Master/BankAccount";
import PriceGroupMaster from "./components/Master/PriceGroupMaster";
/* =================Sales Screen============================================== */
import SalesInvoice from "./components/Sales/SalesInvoice";
import SalesChallan from "./components/Sales/SalesChallan";
import SalesOrder from "./components/Sales/SalesOrder";
import SalesQuotation from "./components/Sales/SalesQuotation";
import SalesEnquiry from "./components/Sales/SalesEnquiry";
import SalesReturn from "./components/Sales/SalesReturn";
import SalesEstimate from "./components/Sales/SalesEstimate";
/* =================Purchase Screen============================================== */
import PurchaseInvoice from "./components/Purchase/PurchaseInvoice";
import PurchaseChallan from "./components/Purchase/PurchaseChallan";
import PurchaseOrder from "./components/Purchase/PurchaseOrder";
import PurchaseQuotation from "./components/Purchase/PurchaseQuotation";
import PurchaseEnquiry from "./components/Purchase/PurchaseEnquiry";
import PurchaseReturn from "./components/Purchase/PurchaseReturn";
import PurchaseEstimate from "./components/Purchase/PurchaseEstimate";
import OpenStock from "./components/Purchase/OpenStock";
import StockAdjustment from "./components/Purchase/StockAdjustment";
/* =================Groups Screen============================================== */
import Attribute from "./components/Groups/Attribute";
import AttributeGroup from "./components/Groups/AttributeGroup";
import BillOfMaterial from "./components/Groups/BillOfMaterial";
import RouteGroup from "./components/Groups/RouteGroup";
/* =================JobWork Screen============================================== */
import InwardChallan from "./components/JobWork/InwardChallan";
import OutwardChallan from "./components/JobWork/OutwardChallan";
import OutsourceChallan from "./components/JobWork/OutsourceChallan";
import InsourceChallan from "./components/JobWork/InsourceChallan";
import OutwardInvoice from "./components/JobWork/OutwardInvoice";
import InsourceInvoice from "./components/JobWork/InsourceInvoice";
import ProductionOrder from "./components/JobWork/ProductionOrder";
import TransactionAdjustment from "./components/JobWork/TransactionAdjustment";
import Forecast from "./components/JobWork/Forecast";
/* =================Accounts Screen============================================== */
import Receipt from "./components/Accounts/Receipt";
import Payment from "./components/Accounts/Payment";
import CreditNote from "./components/Accounts/CreditNote";
import DebitNote from "./components/Accounts/DebitNote";
import PaymentSettlement from "./components/Accounts/PaymentSettlement";
import ReceiptSettlement from "./components/Accounts/ReceiptSettlement";
import ExpenseIncome from "./components/Accounts/ExpenseIncome";
import JournalEntry from "./components/Accounts/JournalEntry";
import JournalView from "./components/Accounts/JournalView";
/* =================Reports Screen============================================== */
import GeneralReports from "./components/Reports/GeneralReports";
import AccountsReport from "./components/Reports/AccountsReport";
/* =================Tools Screen============================================== */
import Configuration from "./components/Setting/Configuration";
import VoucherNo from "./components/Setting/VoucherNo";
import MasterCode from "./components/Setting/MasterCode";
import ReNameScreen from "./components/Setting/ReNameScreen";
import UserAccount from "./components/Setting/UserAccount";
/* =================Others Screen============================================== */
import PrintMaster from "./components/Print/PrintMaster";
import PrintLineDesign from "./components/Print/PrintLineDesign";
import BarcodeGenerator from "./components/Print/BarcodeGenerator";
import BarcodeSetting from "./components/Print/BarcodeSetting";

import EWayBIlling from "./components/General/EWayBIlling";
import EmailSetting from "./components/General/EmailSetting";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/User-Login" element={<UserLogin />} />
        <Route path="/Admin-Login" element={<AdminLogin />} />
        <Route path="/Admin/Control-Panel" element={<ControlPanel />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/CompanyMaster" element={<CompanyMaster />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          {/* =================Master Screen============================================== */}
          <Route path="/Master/ProductMaster" element={<ProductMaster />} />
          <Route path="/Master/CustomerMaster" element={<CustomerMaster />} />
          <Route path="/Master/SupplierMaster" element={<SupplierMaster />} />
          <Route path="/Master/VendorMaster" element={<VendorMaster />} />
          <Route path="/Master/JobWorkMaster" element={<JobWorkMaster />} />
          <Route path="/Master/ServiceMaster" element={<ServiceMaster />} />
          <Route path="/Master/CategoryMaster" element={<CategoryMaster />} />
          <Route path="/Master/TaxMaster" element={<TaxMaster />} />
          <Route path="/Master/PriceMaster" element={<PriceMaster />} />
          <Route path="/Master/UnitMaster" element={<UnitMaster />} />
          <Route path="/Master/OtherHeadMaster" element={<OtherHeadMaster />} />
          <Route path="/Master/GSTTaxMaster" element={<GSTTaxMaster />} />
          <Route path="/Master/BankAccount" element={<BankAccount />} />
          <Route
            path="/Master/PriceGroupMaster"
            element={<PriceGroupMaster />}
          />
          {/* =================Sales Screen============================================== */}
          <Route path="/Sales/SalesInvoice" element={<SalesInvoice />} />
          <Route path="/Sales/SalesChallan" element={<SalesChallan />} />
          <Route path="/Sales/SalesOrder" element={<SalesOrder />} />
          <Route path="/Sales/SalesQuotation" element={<SalesQuotation />} />
          <Route path="/Sales/SalesEnquiry" element={<SalesEnquiry />} />
          <Route path="/Sales/SalesReturn" element={<SalesReturn />} />
          <Route path="/Sales/SalesEstimate" element={<SalesEstimate />} />
          {/* =================Purchase Screen============================================== */}
          <Route
            path="/Purchase/PurchaseInvoice"
            element={<PurchaseInvoice />}
          />
          <Route
            path="/Purchase/PurchaseChallan"
            element={<PurchaseChallan />}
          />
          <Route path="/Purchase/PurchaseOrder" element={<PurchaseOrder />} />
          <Route
            path="/Purchase/PurchaseQuotation"
            element={<PurchaseQuotation />}
          />
          <Route
            path="/Purchase/PurchaseEnquiry"
            element={<PurchaseEnquiry />}
          />
          <Route path="/Purchase/PurchaseReturn" element={<PurchaseReturn />} />
          <Route
            path="/Purchase/PurchaseEstimate"
            element={<PurchaseEstimate />}
          />
          <Route path="/Purchase/OpenStock" element={<OpenStock />} />
          <Route
            path="/Purchase/StockAdjustment"
            element={<StockAdjustment />}
          />
          {/* =================Groups Screen============================================== */}
          <Route path="/Group/Attribute" element={<Attribute />} />
          <Route path="/Group/AttributeGroup" element={<AttributeGroup />} />
          <Route path="/Group/BillOfMaterial" element={<BillOfMaterial />} />
          <Route path="/Group/RouteGroup" element={<RouteGroup />} />
          {/* =================JobWork Screen============================================== */}
          <Route path="/JobWork/InwardChallan" element={<InwardChallan />} />
          <Route path="/JobWork/OutwardChallan" element={<OutwardChallan />} />
          <Route
            path="/JobWork/OutsourceChallan"
            element={<OutsourceChallan />}
          />
          <Route
            path="/JobWork/InsourceChallan"
            element={<InsourceChallan />}
          />
          <Route path="/JobWork/OutwardInvoice" element={<OutwardInvoice />} />
          <Route
            path="/JobWork/InsourceInvoice"
            element={<InsourceInvoice />}
          />
          <Route
            path="/JobWork/ProductionOrder"
            element={<ProductionOrder />}
          />
          <Route
            path="/JobWork/TransactionAdjustment"
            element={<TransactionAdjustment />}
          />
          <Route path="/JobWork/Forecast" element={<Forecast />} />
          {/* =================Accounts Screen============================================== */}
          <Route path="/Accounts/Receipt" element={<Receipt />} />
          <Route path="/Accounts/Payment" element={<Payment />} />
          <Route path="/Accounts/CreditNote" element={<CreditNote />} />
          <Route path="/Accounts/DebitNote" element={<DebitNote />} />
          <Route
            path="/Accounts/PaymentSettlement"
            element={<PaymentSettlement />}
          />
          <Route
            path="/Accounts/ReceiptSettlement"
            element={<ReceiptSettlement />}
          />
          <Route path="/Accounts/ExpenseIncome" element={<ExpenseIncome />} />
          <Route path="/Accounts/JournalEntry" element={<JournalEntry />} />
          <Route path="/Accounts/JournalView" element={<JournalView />} />
          {/* =================Reports Screen============================================== */}
          <Route path="/Reports/AccountsReport" element={<AccountsReport />} />
          <Route path="/Reports/GeneralReports" element={<GeneralReports />} />
          {/* =================Tools Screen============================================== */}
          <Route path="/Tools/Configuration" element={<Configuration />} />
          <Route path="/Tools/VoucherNo" element={<VoucherNo />} />
          <Route path="/Tools/MasterCode" element={<MasterCode />} />
          <Route path="/Tools/ReNameScreen" element={<ReNameScreen />} />
          <Route path="/Tools/UserAccount" element={<UserAccount />} />
          {/* =================Others Screen============================================== */}{" "}
          <Route
            path="/Others/PrintFormateDesigner"
            element={<PrintMaster />}
          />
          <Route path="/Others/PrintLineDesign" element={<PrintLineDesign />} />
          <Route
            path="/Others/BarcodeGenerator"
            element={<BarcodeGenerator />}
          />
          <Route path="/Others/BarcodeSetting" element={<BarcodeSetting />} />
          <Route path="/EWayBIlling" element={<EWayBIlling />} />
          <Route path="/Others/EmailSetting" element={<EmailSetting />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
