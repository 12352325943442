import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";
import Admintable from "./Admintable";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
function ScriptMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [ClientId, setClientId] = useState(0);
  const [strClientName, setstrClientName] = useState("");
  const [strCompanyName, setstrCompanyName] = useState("");
  const [strYear, setstrYear] = useState("");
  const [strCompanyDB, setstrCompanyDB] = useState("");
  const [strMasterDB, setstrMasterDB] = useState("");
  const [strSScriptType, setstrSScriptType] = useState("");
  const [strCScriptType, setstrCScriptType] = useState("");
  const [IsAll, setIsAll] = useState(false);
  const [FileName, setFileName] = useState("");
  const [File, setFile] = useState([]);
  const [arrEntClients, setarrEntClients] = useState([]);
  const [arrEntCompany, setarrEntCompany] = useState([]);
  const arrSScrType = [{ Type: "Master" }, { Type: "Company" }];
  const arrCScrType = [{ Type: "Master" }, { Type: "Company" }];
  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Clients").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntClients(res.data.EntClients);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Client = async (client) => {
    try {
      await setstrClientName(client.clientName);
      await setClientId(client.clientId);
      await setstrMasterDB(client.strMasterDB);
      await setIsAll(false);
      await setstrCompanyName("");
      await setstrYear("");
      await setstrCompanyDB("");
      await dispatch(setload_Screen(true));
      await api
        .get(`Admin/Load_ClientCompany/${client.clientId}`)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            setarrEntCompany(res.data.EntCompany);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Activatebtn = () => {
    const defaultBtn = document.getElementById("uploadsql");
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    });
  };
  const Update_ServerScript = async () => {
    try {
      await dispatch(setload_Screen(true));
      const formdata = new FormData();
      formdata.append("File", File);
      formdata.append("FileName", FileName);
      formdata.append("ScriptType", strSScriptType);
      await api.post("Admin/Update_ServerScript", formdata).then((resu) => {
        if (resu.data.strStatus === "Success") {
          AlertMessage(resu.data.strMessage, "success");
        } else {
          AlertMessage(resu.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Update_Script = async () => {
    try {
      await dispatch(setload_Screen(true));
      let Database = [];
      if (strCScriptType === "Master") Database.push(strMasterDB);
      else {
        if (IsAll) {
          arrEntCompany.forEach((dt) => {
            Database.push(dt.strCompanyDB);
          });
        } else {
          Database.push(strCompanyDB);
        }
      }
      const formdata = new FormData();
      formdata.append("File", File);
      formdata.append("FileName", FileName);
      formdata.append("ScriptType", strCScriptType);
      formdata.append("Database", Database);
      await api.post("Admin/Update_Script", formdata).then((resu) => {
        if (resu.data.strStatus === "Success") {
          AlertMessage(resu.data.strMessage, "success");
        } else {
          AlertMessage(resu.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Company = (company) => {
    setstrCompanyName("");
    setstrYear("");
    setstrCompanyDB("");
    setstrCompanyName(company.strCompanyName);
    setstrYear(company.strYear);
    setstrCompanyDB(company.strCompanyDB);
  };
  const Clear_Click = () => {
    setClientId(0);
    setIsAll(false);
    setstrClientName("");
    setstrCompanyName("");
    setstrYear("");
    setstrCompanyDB("");
    setstrMasterDB("");
    setstrSScriptType("");
    setstrCScriptType("");
    setFileName("");
    setFile([]);
    setarrEntCompany([]);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ClientColumns = [
    {
      Name: "Client Name",
      ColName: "clientName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
  ];
  const CompanyColumns = [
    {
      Name: "Company Name",
      ColName: "strCompanyName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Financial Year",
      ColName: "strYear",
      Color: "black",
      Width: "100",
      textAlign: "center",
    },
    {
      Name: "DataBase",
      ColName: "strCompanyDB",
      Color: "black",
      Width: "200",
      textAlign: "left",
    },
  ];
  return (
    <div className="Dash-content">
      <div className="Dash-card">
        <Admintable
          Columns={ClientColumns}
          Data={arrEntClients}
          View_Data={View_Client}
          IsBackground={true}
        />
      </div>
      <div className="Dash-card">
        <div className="Dash-wrapper">
          <div className="dash-header script">
            <div className="col-sm-12 col-md-4 p-0">
              <div className="custom-file">
                <input type="file" id="uploadsql" hidden accept=".sql" />
                <button className="filebtn" onClick={(e) => Activatebtn()}>
                  Browse file&nbsp;<i className="bx bxs-folder"></i>
                </button>
              </div>
            </div>
            <div className="file_added">
              {FileName !== "" && (
                <a href="##">
                  <span>{FileName}</span>
                  <i
                    className="fa-solid fa-xmark"
                    onClick={(e) => {
                      setFileName("");
                      setFile();
                      var file = document.getElementById("uploadsql");
                      file.value = "";
                    }}
                  ></i>
                </a>
              )}
            </div>
          </div>
          <div className="dash-header">
            <div className="col-sm-12 col-md-4 p-0">
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Select Server Script type"
                select_value={(val) => setstrSScriptType(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strSScriptType}
                displayName="Type"
                disvalue="Type"
                arrydata={arrSScrType}
                disabled={false}
                EmptVal="Server Script type"
              />
            </div>
            <button
              style={{ minWidth: "250px", marginLeft: "3px" }}
              onClick={(e) => {
                if (FileName.length === 0)
                  AlertMessage("Please Select Script File", "info");
                else if (strSScriptType.length === 0)
                  AlertMessage("Please Select Server Script Type", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: Update_ServerScript,
                      Question: "Do You Want to Update Script?",
                      isdialog: true,
                    })
                  );
              }}
            >
              Update Server Script&nbsp;<i class="bx bx-code-alt"></i>
            </button>
          </div>
          <div className="row pl-2 mb-2">
            <div className="col-md-4 col-sm-12">
              <label>Client Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Client Name"
                defaultValue={strClientName}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Master Database</label>
              <input
                type="text"
                className="form-control"
                placeholder="Master Database"
                defaultValue={strMasterDB}
                disabled
              />
            </div>
            <div
              className={
                strCScriptType === "Company"
                  ? "col-md-2 col-sm-12"
                  : "col-md-4 col-sm-12"
              }
            >
              <label>Script Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_selecrparem.isoptionShow1}
                placeholder="Select Script type"
                select_value={(val) => setstrCScriptType(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strCScriptType}
                displayName="Type"
                disvalue="Type"
                arrydata={arrCScrType}
                disabled={false}
                EmptVal="Script type"
              />
            </div>
            {strCScriptType === "Company" && (
              <div className="col-md-2 col-sm-12">
                <label>All Database</label>
                <input
                  type="checkbox"
                  className="form-check"
                  checked={IsAll}
                  onChange={(e) => setIsAll(!IsAll)}
                />
              </div>
            )}
            <div className="col-md-4 col-sm-12">
              <label>Comapany Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                defaultValue={strCompanyName}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Financial Year</label>
              <input
                type="text"
                className="form-control"
                placeholder="Financial Year"
                defaultValue={strYear}
                disabled
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Company Database</label>
              <input
                type="text"
                className="form-control"
                placeholder="Company Database"
                defaultValue={strCompanyDB}
                disabled
              />
            </div>
          </div>
          <Admintable
            Columns={CompanyColumns}
            Data={arrEntCompany}
            View_Data={View_Company}
            IsBackground={true}
          />
        </div>
        <div className="Dash-section">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (FileName.length === 0)
                AlertMessage("Please Select Script File", "info");
              else if (ClientId === 0)
                AlertMessage("Please Select Client", "info");
              else if (strCScriptType.length === 0)
                AlertMessage("Please Select Client Script Type", "info");
              else if (
                strCScriptType === "Company" &&
                IsAll === false &&
                strCompanyDB.length === 0
              )
                AlertMessage("Please Select Company", "info");
              else
                dispatch(
                  Show_MsgBox({
                    Button: Update_Script,
                    Question: "Do You Want to Update Script?",
                    isdialog: true,
                  })
                );
            }}
          >
            Update Client Script&nbsp;<i class="bx bx-code-alt"></i>
          </button>
          <button className="btn-fabgreen" onClick={() => Clear_Click()}>
            Clear Client&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ScriptMaster;
