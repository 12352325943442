import React, { useEffect } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import {
  Input_Onchange,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import { Hidetab_Container, KeyUpDown, Showtab_Container } from "./Transaction";
function TransactionHeader() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _tabShow = JSON.parse(JSON.stringify(_Common.Headertab));
  const _tabOrder = JSON.parse(JSON.stringify(_Common.arrtaborder));

  useEffect(() => {}, [_Common.Headertab]);

  const tab_click = (tabindex) => {
    Showtab_Container(
      "tab-line",
      "trans-buttons",
      tabindex,
      _tabShow,
      "transht",
      dispatch
    );
  };
  const hide_header = () => {
    Hidetab_Container(
      "transh-hide",
      "trans-buttons",
      _tabShow,
      "transht",
      dispatch
    );
  };
  const Validity_ValueChange = async (e) => {
    let today = new Date(_HT.strVouchDate);
    let diffInMs = 0;
    let diffInDays = 0;
    switch (e.target.name) {
      case "strValidity":
        if (!isNaN(e.target.value)) {
          let validfrom = today.setDate(
            today.getDate() + Number(e.target.value)
          );
          validfrom = new Date(validfrom).toISOString().split("T")[0];
          await dispatch(
            Input_Onchange({
              Name: "dpValDateUpto",
              Value: validfrom,
            })
          );
        }
        Input_ValueChange(e);
        break;
      case "dpValDateUpto":
        diffInMs = new Date(e.target.value) - today;
        diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        await dispatch(
          Input_Onchange({
            Name: "strValidity",
            Value: Math.ceil(diffInDays).toString(),
          })
        );
        Input_ValueChange(e);
        break;
      case "strPayTerm":
        if (!isNaN(e.target.value)) {
          let validfrom = today.setDate(
            today.getDate() + Number(e.target.value)
          );
          validfrom = new Date(validfrom).toISOString().split("T")[0];
          await dispatch(
            Input_Onchange({
              Name: "dpPayTermDateUpto",
              Value: validfrom,
            })
          );
        }
        Input_ValueChange(e);
        break;
      case "dpPayTermDateUpto":
        diffInMs = new Date(e.target.value) - today;
        diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        await dispatch(
          Input_Onchange({
            Name: "strPayTerm",
            Value: Math.ceil(diffInDays).toString(),
          })
        );
        Input_ValueChange(e);
        break;
      case "strDeliTerm":
        if (!isNaN(e.target.value)) {
          let validfrom = today.setDate(
            today.getDate() + Number(e.target.value)
          );
          validfrom = new Date(validfrom).toISOString().split("T")[0];
          await dispatch(
            Input_Onchange({
              Name: "dpDelTermDateUpto",
              Value: validfrom,
            })
          );
        }
        Input_ValueChange(e);
        break;
      case "dpDelTermDateUpto":
        diffInMs = new Date(e.target.value) - today;
        diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        await dispatch(
          Input_Onchange({
            Name: "strDeliTerm",
            Value: Math.ceil(diffInDays).toString(),
          })
        );
        Input_ValueChange(e);
        break;
      default:
        break;
    }
  };
  const Update_Validity = async (VouchDate) => {
    let dpvalidfrom = new Date(VouchDate);
    let dpDelvalidfrom = new Date(VouchDate);
    let dppayvalidfrom = new Date(VouchDate);
    let validfrom = dpvalidfrom.setDate(
      dpvalidfrom.getDate() + Number(_HT.strValidity)
    );
    let Delvalidfrom = dpDelvalidfrom.setDate(
      dpDelvalidfrom.getDate() + Number(_HT.strDeliTerm)
    );
    let payvalidfrom = dppayvalidfrom.setDate(
      dppayvalidfrom.getDate() + Number(_HT.strPayTerm)
    );

    validfrom = new Date(validfrom).toISOString().split("T")[0];
    Delvalidfrom = new Date(Delvalidfrom).toISOString().split("T")[0];
    payvalidfrom = new Date(payvalidfrom).toISOString().split("T")[0];
    await dispatch(
      Input_Onchange({
        Name: "dpValDateUpto",
        Value: validfrom,
      })
    );
    await dispatch(
      Input_Onchange({
        Name: "dpPayTermDateUpto",
        Value: payvalidfrom,
      })
    );
    await dispatch(
      Input_Onchange({
        Name: "dpDelTermDateUpto",
        Value: Delvalidfrom,
      })
    );
  };
  const Input_ValueChange = async (e) => {
    await dispatch(
      Input_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Validate_tabbtn = (Trans, Job) => {
    if (_Common.strScrType === "JobWork") return Job;
    else return Trans;
  };
  const Validation = (colName) => {
    let EntVal = [];
    if (_Common.arrValidation.length > 0) {
      EntVal = JSON.parse(JSON.stringify(_Common.arrValidation));
      EntVal = EntVal.filter((dt) => dt.strCtrlName === colName);
    }
    return EntVal.length > 0 ? EntVal[0].isValidate : false;
  };
  return (
    <div className="fg-card shadow-base">
      <div className="trans-tab">
        <div className="trans-buttons">
          <label onClick={(e) => tab_click(0)} className="active">
            Party
          </label>
          {_tabShow[1].Show && (
            <label onClick={(e) => tab_click(_tabShow[1].Index)}>Voucher</label>
          )}
          {_tabShow[2].Show && (
            <label onClick={(e) => tab_click(_tabShow[2].Index)}>
              Ref Voucher
            </label>
          )}
          {_tabShow[3].Show && (
            <label onClick={(e) => tab_click(_tabShow[3].Index)}>
              Delivery
            </label>
          )}
          {_tabShow[4].Show && (
            <label onClick={(e) => tab_click(_tabShow[4].Index)}>
              Transport
            </label>
          )}
          {_tabShow[5].Show && (
            <label onClick={(e) => tab_click(_tabShow[5].Index)}>
              Validity
            </label>
          )}
          {_tabShow[6].Show && (
            <label onClick={(e) => tab_click(_tabShow[6].Index)}>Remarks</label>
          )}
          <div id="tab-line"></div>
        </div>
        <i
          className="bx bx-caret-down transh-hide"
          onClick={(e) => hide_header()}
        ></i>
      </div>
      {_tabShow[0].tabShow && (
        <div className="row p-2">
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    spellCheck={false}
                    placeholder="Type Party Name"
                    autoComplete="off"
                    tabIndex={_tabOrder.txtPartyName}
                    onKeyDown={(e) => {
                      if (_HT.strTransNo.length > 0) {
                        if (e.key === "Enter") {
                          dispatch(ShowHide_Popup("IsVenSearch"));
                        } else KeyUpDown(e, _Common, _DT);
                      }
                    }}
                    onClick={(e) => {
                      if (_HT.strTransNo.length > 0) {
                        dispatch(
                          ShowHide_Popup({
                            isshow: "IsVenSearch",
                            default: true,
                          })
                        );
                      }
                    }}
                    id="txtPartyName"
                    defaultValue={_HT.strPartyName}
                    disabled={
                      _HT.strTransNo.length === 0
                        ? true
                        : _HT.iVouchId > 0
                        ? true
                        : false
                    }
                    autoFocus
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: _Common.IsVenSearch
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 mt-1">
                <label>
                  Bill No <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Voucher Number"
                    id="txtVoucherNo"
                    name="strVouchNo"
                    autoComplete="off"
                    spellCheck={false}
                    tabIndex={_tabOrder.txtInvNo}
                    onChange={(e) => {
                      Input_ValueChange(e);
                    }}
                    disabled={_HT.iVouchId === 0 ? _HT.isManualCode : true}
                    value={_HT.strVouchNo}
                    onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => {
                      if (_HT.iVouchId === 0)
                        dispatch(ShowHide_Popup("IsVouchNo"));
                    }}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mt-1" hidden>
                <label>
                  Trans No
                  <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  id="txtTransNo"
                  name="strTransNo"
                  disabled
                  value={_HT.strTransNo}
                  tabIndex={_tabOrder.txtTransNo}
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                />
              </div>
              <div
                className="col-md-3 col-sm-6 mt-1"
                hidden={_Common.IsHideBill}
              >
                <label>
                  Bill Type
                  {Validation("cbBillType") && (
                    <span className="danger">* </span>
                  )}
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Bill Type"
                  select_value={(val) =>
                    Input_ValueChange({
                      target: { name: "iBillTypeId", value: val },
                    })
                  }
                  taborder={_tabOrder.cbBillType}
                  keydown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown");
                    else KeyUpDown(e, _Common, _DT);
                  }}
                  btnname="Bill Type"
                  btnshow={false}
                  show_popup=""
                  valueonly={true}
                  defaultval={_HT.iBillTypeId}
                  displayName="strBillType"
                  disvalue="iBT_Id"
                  arrydata={_Common.BillType}
                  disabled={false}
                  EmptVal="Bill Type"
                />
              </div>
              <div
                className="col-md-3 col-sm-6 mt-1"
                hidden={_Common.IsJobWorkDc}
              >
                <label>
                  Tax Type
                  {Validation("cbTaxType") && (
                    <span className="danger">* </span>
                  )}
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Tax Type"
                  select_value={(val) => {}}
                  taborder={_tabOrder.cbTaxType}
                  keydown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown");
                    else KeyUpDown(e, _Common, _DT);
                  }}
                  btnname="Tax Type"
                  btnshow={false}
                  show_popup=""
                  valueonly={true}
                  defaultval={_HT.iTaxRelId}
                  displayName="strTaxType"
                  disvalue="iTaxRelId"
                  arrydata={_Common.TaxType}
                  disabled={true}
                  EmptVal="Tax Type"
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>
                  Date <span className="danger">* </span>
                </label>
                <input
                  type="date"
                  name="strVouchDate"
                  tabIndex={_tabOrder.dpInvDate}
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  className="form-control"
                  value={_HT.strVouchDate}
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  onBlur={(e) => {
                    Update_Validity(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Billing Address</label>
                <textarea
                  style={{
                    height: "100px",
                    whiteSpace: "break-spaces",
                  }}
                  placeholder="Address of Party"
                  className="form-control "
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  value={_HT.strPartyAdd}
                  disabled
                  autoComplete="off"
                />
              </div>
              <div
                className="col-md-6 col-sm-12 mt-1"
                style={{ position: "relative" }}
              >
                <label>Shipping Address</label>
                {_HT.iPartyId > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "-5px",
                    }}
                    className="icon-btn"
                    onClick={(e) => dispatch(ShowHide_Popup("IsShippAdd"))}
                  >
                    <i className="fa-solid fa-location-dot"></i>
                  </span>
                )}
                <textarea
                  style={{
                    height: "100px",
                    whiteSpace: "break-spaces",
                  }}
                  className="form-control mt-1"
                  placeholder="Place of Delivery "
                  name="strShippingAdd"
                  defaultValue={_HT.strShippingAdd}
                  autoComplete="off"
                  spellCheck={false}
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}
      {_tabShow[1].Show && _tabShow[1].tabShow && (
        <div className="row p-2">
          {_Common.arrfieldPer[1].bIsVissible && (
            <div
              className="col-md-3 col-sm-12 mt-1"
              hidden={_Common.IsConvertDc}
            >
              <label> {_Common.arrfieldPer[1].strFieldName}</label>
              <textarea
                style={{ height: "90px" }}
                className="form-control rounded"
                spellCheck={false}
                disabled
                value={_HT.strConvertDCVouch}
              ></textarea>
            </div>
          )}
          {_Common.arrfieldPer[2].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1" hidden={_Common.IsPartyDc}>
              <label> {_Common.arrfieldPer[2].strFieldName}</label>
              <textarea
                style={{ height: "90px" }}
                className="form-control rounded"
                spellCheck={false}
                disabled
                value={_HT.strPartyDCVouch}
              ></textarea>
            </div>
          )}
          <div className="col-md-3 col-sm-12 mt-1" hidden={_Common.IsHideInvDc}>
            <label>Inward Vouchers</label>
            <textarea
              style={{ height: "90px" }}
              className="form-control rounded"
              disabled
              spellCheck={false}
              value={_HT.strInWDCVouchers}
            ></textarea>
          </div>
        </div>
      )}
      {_tabShow[2].Show && _tabShow[2].tabShow && (
        <div className="row p-2">
          {_Common.arrfieldPer[3].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[3].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                autoComplete="off"
                spellCheck={false}
                placeholder="Enter Reference Number1"
                name="strRefNum1"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRefNum1}
                tabIndex={_tabOrder.txtRefNum1}
              />
            </div>
          )}
          {_Common.arrfieldPer[4].bIsVissible && (
            <div className="col-md-2 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[4].strFieldName} </label>
              <input
                type="date"
                name="dpRefNum1Date"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                spellCheck={false}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.dpRefNum1Date}
                tabIndex={_tabOrder.txtRefNum1Date}
              />
            </div>
          )}
          {_Common.arrfieldPer[5].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[5].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Reference Number2"
                name="strRefNum2"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRefNum2}
                tabIndex={_tabOrder.txtRefNum2}
              />
            </div>
          )}
          {_Common.arrfieldPer[6].bIsVissible && (
            <div className="col-md-2 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[6].strFieldName} </label>
              <input
                type="date"
                name="dpRefNum2Date"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.dpRefNum2Date}
                tabIndex={_tabOrder.txtRefNum2Date}
              />
            </div>
          )}
          {_Common.arrfieldPer[7].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[7].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Reference Number3"
                name="strRefNum3"
                autoComplete="off"
                spellCheck={false}
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRefNum3}
                tabIndex={_tabOrder.txtRefNum3}
              />
            </div>
          )}
          {_Common.arrfieldPer[8].bIsVissible && (
            <div className="col-md-2 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[8].strFieldName} </label>
              <input
                type="date"
                name="dpRefNum3Date"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.dpRefNum3Date}
                tabIndex={_tabOrder.txtRefNum3Date}
              />
            </div>
          )}
          {_Common.arrfieldPer[9].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[9].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Reference Number4"
                spellCheck={false}
                name="strRefNum4"
                autoComplete="off"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRefNum4}
                tabIndex={_tabOrder.txtRefNum4}
              />
            </div>
          )}
          {_Common.arrfieldPer[10].bIsVissible && (
            <div className="col-md-2 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[10].strFieldName} </label>
              <input
                type="date"
                name="dpRefNum4Date"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.dpRefNum4Date}
                tabIndex={_tabOrder.txtRefNum4Date}
              />
            </div>
          )}
        </div>
      )}
      {_tabShow[3].Show && _tabShow[3].tabShow && (
        <div className="row p-2">
          <div className="row">
            {_Common.arrfieldPer[11].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label> {_Common.arrfieldPer[11].strFieldName}</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Delivery Mode"
                  select_value={(val) =>
                    Input_ValueChange({
                      target: { name: "iDMId", value: val },
                    })
                  }
                  taborder={_tabOrder.cbDeliMode}
                  keydown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown");
                    else KeyUpDown(e, _Common, _DT);
                  }}
                  btnname="Tax Type"
                  btnshow={false}
                  show_popup=""
                  valueonly={true}
                  defaultval={_HT.iDMId}
                  displayName="strDeliveryMode"
                  disvalue="iDM_Id"
                  arrydata={_Common.DelMode}
                  disabled={false}
                  EmptVal="Delivery Mode"
                />
              </div>
            )}
            {_Common.arrfieldPer[12].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label> {_Common.arrfieldPer[12].strFieldName}</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Reference Name"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  spellCheck={false}
                  autoComplete="off"
                  name="strReference"
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strReference}
                  tabIndex={_tabOrder.txtRefName}
                />
              </div>
            )}
          </div>
          <div className="row">
            {_Common.arrfieldPer[13].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label> {_Common.arrfieldPer[13].strFieldName}</label>
                <input
                  type="text"
                  aria-label="First name"
                  spellCheck={false}
                  className="form-control"
                  placeholder="Enter Agent Name"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  name="strAgent"
                  autoComplete="off"
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strAgent}
                  tabIndex={_tabOrder.txtAgentName}
                />
              </div>
            )}
            {_Common.arrfieldPer[14].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label> {_Common.arrfieldPer[14].strFieldName}</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter the Destination"
                  spellCheck={false}
                  name="strDestination"
                  autoComplete="off"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strDestination}
                  tabIndex={_tabOrder.txtDestination}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {_tabShow[4].Show && _tabShow[4].tabShow && (
        <div className="row p-2">
          {_Common.arrfieldPer[15].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[15].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Transport Type"
                spellCheck={false}
                autoComplete="off"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                name="strTransport"
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strTransport}
                tabIndex={_tabOrder.txtTransport}
              />
            </div>
          )}
          {_Common.arrfieldPer[16].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[16].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                spellCheck={false}
                className="form-control"
                placeholder="Enter Vehicle Number"
                autoComplete="off"
                name="strVehicle"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strVehicle}
                tabIndex={_tabOrder.txtVehicle}
              />
            </div>
          )}
          {_Common.arrfieldPer[17].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[17].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Despatch"
                autoComplete="off"
                spellCheck={false}
                name="strDespatch"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strDespatch}
                tabIndex={_tabOrder.txtDespatch}
              />
            </div>
          )}
          {_Common.arrfieldPer[18].bIsVissible && (
            <div className="col-md-2 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[18].strFieldName} </label>
              <input
                type="date"
                name="dpDespatchDate"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.dpDespatchDate}
                tabIndex={_tabOrder.dpDespatchDate}
              />
            </div>
          )}
          {_Common.arrfieldPer[19].bIsVissible && (
            <div className="col-md-3 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[19].strFieldName}</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter LRNumber"
                name="strLRNNumber"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strLRNNumber}
                tabIndex={_tabOrder.txtLRNumber}
              />
            </div>
          )}
          {_Common.arrfieldPer[20].bIsVissible && (
            <div className="col-md-2 col-sm-12 mt-1">
              <label> {_Common.arrfieldPer[20].strFieldName} </label>
              <input
                type="date"
                name="dpLRNoDate"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.dpLRNoDate}
                tabIndex={_tabOrder.dpLRDate}
              />
            </div>
          )}
        </div>
      )}
      {_tabShow[5].Show && _tabShow[5].tabShow && (
        <div className="row p-2">
          {_Common.arrfieldPer[21].bIsVissible && (
            <div className="col-md-3 col-sm-11 mt-1">
              <label> {_Common.arrfieldPer[21].strFieldName}</label>
              <input
                type="text"
                name="strValidity"
                aria-label="First name"
                className="form-control"
                autoComplete="off"
                spellCheck={false}
                onClick={(e) => e.target.select()}
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                onBlur={(e) => Validity_ValueChange(e)}
                value={_HT.strValidity}
                tabIndex={_tabOrder.txtValidity}
              />
            </div>
          )}
          {_Common.arrfieldPer[22].bIsVissible && (
            <div className="col-md-2 col-sm-11 mt-1">
              <label> {_Common.arrfieldPer[22].strFieldName} </label>
              <input
                type="date"
                name="dpValDateUpto"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                onBlur={(e) => Validity_ValueChange(e)}
                value={_HT.dpValDateUpto}
                tabIndex={_tabOrder.dpValidityUpto}
              />
            </div>
          )}
          {_Common.arrfieldPer[23].bIsVissible && (
            <div className="col-md-3 col-sm-11 mt-1">
              <label> {_Common.arrfieldPer[23].strFieldName}</label>
              <input
                type="text"
                name="strPayTerm"
                aria-label="First name"
                className="form-control"
                spellCheck={false}
                onClick={(e) => e.target.select()}
                autoComplete="off"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                onBlur={(e) => Validity_ValueChange(e)}
                value={_HT.strPayTerm}
                tabIndex={_tabOrder.txtPayTerm}
              />
            </div>
          )}
          {_Common.arrfieldPer[24].bIsVissible && (
            <div className="col-md-2 col-sm-11 mt-1">
              <label> {_Common.arrfieldPer[24].strFieldName}</label>
              <input
                type="date"
                name="dpPayTermDateUpto"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                onBlur={(e) => Validity_ValueChange(e)}
                value={_HT.dpPayTermDateUpto}
                tabIndex={_tabOrder.dpPayTermUpto}
              />
            </div>
          )}
          {_Common.arrfieldPer[25].bIsVissible && (
            <div className="col-md-3 col-sm-11 mt-1">
              <label> {_Common.arrfieldPer[25].strFieldName}</label>
              <input
                type="text"
                spellCheck={false}
                aria-label="First name"
                className="form-control"
                autoComplete="off"
                name="strDeliTerm"
                onClick={(e) => e.target.select()}
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                onBlur={(e) => Validity_ValueChange(e)}
                value={_HT.strDeliTerm}
                tabIndex={_tabOrder.txtDeliTerm}
              />
            </div>
          )}
          {_Common.arrfieldPer[26].bIsVissible && (
            <div className="col-md-2 col-sm-11 mt-1">
              <label> {_Common.arrfieldPer[26].strFieldName}</label>
              <input
                type="date"
                className="form-control"
                name="dpDelTermDateUpto"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                onBlur={(e) => Validity_ValueChange(e)}
                value={_HT.dpDelTermDateUpto}
                tabIndex={_tabOrder.dpDeliTermUpto}
              />
            </div>
          )}
        </div>
      )}
      {_tabShow[6].Show && _tabShow[6].tabShow && (
        <div className="row p-2">
          <div className="row">
            {_Common.arrfieldPer[Validate_tabbtn(27, 21)].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label>
                  {_Common.arrfieldPer[Validate_tabbtn(27, 21)].strFieldName}
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Remark1"
                  name="strRemark1"
                  spellCheck={false}
                  autoComplete="off"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  tabIndex={_tabOrder.txtRemark1}
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strRemark1}
                />
              </div>
            )}
            {_Common.arrfieldPer[Validate_tabbtn(28, 22)].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label>
                  {" "}
                  {_Common.arrfieldPer[Validate_tabbtn(28, 22)].strFieldName}
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  spellCheck={false}
                  autoComplete="off"
                  placeholder="Enter Remark2"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  tabIndex={_tabOrder.txtRemark2}
                  name="strRemark2"
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strRemark2}
                />
              </div>
            )}
          </div>
          <div className="row">
            {_Common.arrfieldPer[Validate_tabbtn(29, 23)].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label>
                  {_Common.arrfieldPer[Validate_tabbtn(29, 23)].strFieldName}
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  spellCheck={false}
                  autoComplete="off"
                  placeholder="Enter Remark3"
                  tabIndex={_tabOrder.txtRemark3}
                  name="strRemark3"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strRemark3}
                />
              </div>
            )}
            {_Common.arrfieldPer[Validate_tabbtn(30, 24)].bIsVissible && (
              <div className="col-md-4 col-sm-12 mt-1">
                <label>
                  {_Common.arrfieldPer[Validate_tabbtn(30, 24)].strFieldName}
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                  placeholder="Enter Remark4"
                  spellCheck={false}
                  name="strRemark4"
                  autoComplete="off"
                  tabIndex={_tabOrder.txtRemark4}
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  value={_HT.strRemark4}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionHeader;
