import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import Button from "./MasterButton";
import MasterList from "./MasterListItem";
import $ from "jquery";
import SearchLedger from "../Accounts/SearchLedger";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";

function ServiceMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iSerId, setiSerId] = useState(0);
  const [strSerCode, setstrSerCode] = useState("");
  const [strSerName, setstrSerName] = useState("");
  const [strSACCode, setstrSACCode] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strPrintName, setstrPrintName] = useState("");

  const [strLedId, setstrLedId] = useState("");
  const [iScrId, setiScrId] = useState(59);
  const [strSerCodeTag, setstrSerCodeTag] = useState("Enter Service Code");
  const [strTransTagLedName, setstrTransTagLedName] = useState("TagLedgerName");
  const [strLedName, setstrLedName] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("");
  const [strFilterColum, setstrFilterColum] = useState("All");
  const [iSGSTTaxId, setiSGSTTaxId] = useState(8);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(16);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(21);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [IsOpen, setIsOpen] = useState(false);
  const [iSerCount, setiSerCount] = useState(0);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntFillter, setlistEntFillter] = useState([]);
  const [listEntSer, setlistEntSer] = useState([]);
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [listTaxType, setlistTaxType] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  useEffect(() => {
    Load_ServiceMaster();
  }, []);
  const Load_ServiceMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await api
        .post(`ServiceMaster/Load_ServiceMaster`, objParems)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistEntFillter(Res.data.objEntComBoxVa);
            setlistEntSer(Res.data.objEntService);
            setiSerCount(Res.data.objEntService.length);
            setlistCodeFormat(Res.data.objCodeFormat);
            setstrSerCode(Res.data.objCodeFormat[0].strCode);
            setlistTaxType(Res.data.objEntTaxType);
            setlistSGST(Res.data.objEntSGSTTax);
            setlistCGST(Res.data.objEntCGSTTax);
            setlistIGST(Res.data.objEntIGSTTax);
            setManualCode(!Res.data.objCodeFormat[0].isManualCode);
            setListValidateProperties(Res.data.Transtab);
            TabOrderAssign(Res.data.Transtab);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiSerId(0);
    setstrSerCode("");
    setstrSerName("");
    setstrSACCode("");
    setstrRemarks("");
    setstrPrintName("");
    setstrLedId("");
    setiScrId(59);
    setstrSerCodeTag("Enter Service Code");
    setstrTransTagLedName("TagLedgerName");
    setstrLedName("");
    setstrSearch("");
    setstrFilter("");
    setstrFilterColum("All");
    setiSGSTTaxId(8);
    setiCGSTTaxId(16);
    setiIGSTTaxId(21);
    setiTaxTypeId(1);
    setIsOpen(false);
    setiSerCount(0);
    Load_ServiceMaster();
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const ObjInsUpdService = {
        strSerCode: strSerCode,
        strSerName: strSerName,
        strSACCode: strSACCode,
        strPrintName: strPrintName,
        strRemarks: strRemarks,
        strSerCodeTag: strSerCodeTag,
        iTitleTag: iScrId,
        iSerId: iSerId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strSearch: strSearch,
        strFilter: strFilter,
        strFilterColumn: strFilterColum,
        strLedId: strLedId.toString(),
        strTransTagLedName: strTransTagLedName,
        strLedName: strLedName,
        iTaxTypeId: Number(iTaxTypeId),
        iSGSTTaxId: Number(iSGSTTaxId),
        iCGSTTaxId: Number(iCGSTTaxId),
        iIGSTTaxId: Number(iIGSTTaxId),
        strCompanyDB: localStorage.db,
      };
      await api
        .post(`ServiceMaster/InsUpd_Service/`, ObjInsUpdService)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            ClearClick();
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post(
          `ServiceMaster/Delete_Service/${iSerId}/${iScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            ClearClick();
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SelectLedger = (Ledger) => {
    try {
      setIsOpen(!IsOpen);
      setstrLedId(Ledger.ledId);
      setstrLedName(Ledger.ledName);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const View_Service = async (iSerId) => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get(
          `ServiceMaster/View_Service/${iSerId}/${iScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            let EntServive = Res.data.EntServive[0];
            setstrSerCode(EntServive.strSerCode);
            setstrSerName(EntServive.strSerName);
            setstrSACCode(EntServive.strSACCode);
            setstrPrintName(EntServive.strPrintName);
            setstrRemarks(EntServive.strRemarks);
            setiSerId(EntServive.iSerId);
            setstrSerCode(EntServive.strSerCode);
            setstrLedId(EntServive.strLedId);
            setstrLedName(EntServive.strLedName);
            setiSGSTTaxId(EntServive.iSGSTTaxId);
            setiCGSTTaxId(EntServive.iCGSTTaxId);
            setiIGSTTaxId(EntServive.iIGSTTaxId);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Service = async (strtxt) => {
    try {
      const objSearch = {
        strColName: "All",
        iScrId: iScrId,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(`ServiceMaster/Search_Service/`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setlistEntSer(Res.data.EntServive);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxLevelChange = async (TaxLevel) => {
    try {
      await setiTaxTypeId(TaxLevel);
      await api
        .get(`TaxMaster/TaxLevelChange/${Number(TaxLevel)}/${localStorage.db}`)
        .then((Res) => {
          if (Number(TaxLevel) === 1) {
            setlistSGST(Res.data.SGSTTax);
            setlistCGST(Res.data.CGSTTax);
            setlistIGST(Res.data.IGSTTax);
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      SerCodeTab: "",
      SerNamTab: "",
      SACcodeTab: "",
      RemarkTab: "",
      PrintNameTab: "",
      LedgerTab: "",
      TaxTypeTab: "",
      CGSTTab: "",
      IGSTTab: "",
      SGSTTab: "",
      ServiceListTab: "",
      ServiceFIlterTab: "",
      ServiceSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtServiceCode":
          TabOrder[0].SerCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtSACCode":
          TabOrder[0].SACcodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtServiceName":
          TabOrder[0].SerNamTab = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder[0].TaxTypeTab = EntProperties[i].iTabIndex;
          break;
        case "cbCgst":
          TabOrder[0].CGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbIgst":
          TabOrder[0].IGSTTab = EntProperties[i].iTabIndex;
          break;
        case "cbSgst":
          TabOrder[0].SGSTTab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarkTab = EntProperties[i].iTabIndex;
          break;
        case "txtLedger":
          TabOrder[0].LedgerTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].ServiceFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].ServiceSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbServiceName":
          TabOrder[0].ServiceListTab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ServiceListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ServiceListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ServiceListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ServiceListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtServiceName":
          ErrorMsg = strSerName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "cbSgst":
          ErrorMsg = iSGSTTaxId === "" ? false : true;
          break;
        case "cbIgst":
          ErrorMsg = iIGSTTaxId === "" ? false : true;
          break;
        case "cbCgst":
          ErrorMsg = iCGSTTaxId === "" ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = iTaxTypeId === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntFillter}
          EntList={listEntSer}
          GetDetails={View_Service}
          Count={iSerCount}
          Search={Search_Service}
          ScreenID={iScrId}
          accessor="strSerName"
          accessorid="iSerId"
          ListTab={ListTabOrder[0].ServiceListTab}
          FilterTab={ListTabOrder[0].ServiceFIlterTab}
          SearchTab={ListTabOrder[0].ServiceSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Service Name"
                  tabIndex={ListTabOrder[0].SerNamTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strSerName}
                  spellCheck={false}
                  onChange={(e) => setstrSerName(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  id="txtProductCode"
                  tabIndex={ListTabOrder[0].SerCodeTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strSerCode}
                  readOnly={ManualCode}
                  onChange={(e) => setstrSerCode(e.target.value)}
                  name="strProCode"
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>SAC</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter SAC Code"
                  tabIndex={ListTabOrder[0].SACcodeTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strSACCode}
                  onChange={(e) => setstrSACCode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Print Name </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].PrintNameTab}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Enter Print Name"
                  spellCheck={false}
                  value={strPrintName}
                  onChange={(e) => setstrPrintName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].RemarkTab}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Enter Remark"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-6 mt-1">
                <label>Ledger</label>
                <div className="custom-select">
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Press Enter to Select Ledger"
                    value={strLedName}
                    tabIndex={ListTabOrder[0].LedgerTab}
                    onInput={(e) => setIsOpen(!IsOpen)}
                    onClick={(e) => setIsOpen(!IsOpen)}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: IsOpen ? "rotate(-180deg)" : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>Tax Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Tax Type"
                  taborder={ListTabOrder[0].Taxtytab}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => TaxLevelChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTaxTypeId}
                  displayName="strTaxLvlType"
                  disvalue="iTaxLvlId"
                  arrydata={listTaxType}
                  EmptVal="Tax Type "
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>SGST</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_parem.isoptionShow4}
                  placeholder="Select SGST Tax"
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iSGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listSGST}
                  EmptVal="SGST "
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>CGST</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow5"
                  showoption={_parem.isoptionShow5}
                  placeholder="Select CGST Tax"
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iCGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listCGST}
                  EmptVal="CGST "
                />
              </div>
              <div className="col-md-3 col-sm-3 mt-1">
                <label>IGST</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow6"
                  showoption={_parem.isoptionShow6}
                  placeholder="Select IGST Tax"
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iIGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listIGST}
                  EmptVal="IGST "
                />
              </div>
            </div>
            {IsOpen && (
              <SearchLedger
                Close={(e) => setIsOpen(!IsOpen)}
                SelectLedger={SelectLedger}
                ColName="JE"
              />
            )}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (PropertiesValidation()) {
                if (strLedId.length === 0)
                  AlertMessage("Pelase Select Ledger", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Service ?",
                      isdialog: true,
                    })
                  );
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iSerId === 0) {
                AlertMessage("Please Select Service", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Service ?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={iScrId}
            Iid={iSerId}
            SaveIndex={ListTabOrder[0].BtnSavtab}
            DeleteIndex={ListTabOrder[0].BtnDeletetab}
            CloseIndex={ListTabOrder[0].BtnClosetab}
            ClearIndex={ListTabOrder[0].BtnCleartab}
            ExportIndex={ListTabOrder[0].BtnExporttab}
            onKeyDown={(e) => TabIndex(e)}
          />
        </div>
      </div>
    </>
  );
}
export default ServiceMaster;
