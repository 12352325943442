import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Show_MsgBox } from "../ReduxStore/Message";
import api from "../Admin/Config";

function MasterCodeSetting(props) {
  const dispatch = useDispatch();
  const [isAutoNo, setisAutoNo] = useState(false);
  const [strCodeFormat, setstrCodeFormat] = useState("");
  const [iNextNo, setiNextNo] = useState(0);
  const [iMaxDegit, setiMaxDegit] = useState(0);
  const [strCode, setstrCode] = useState("");
  const [isChanged, setisChanged] = useState(false);

  useEffect(() => {
    Load_MaterCode(); // Update Master Code if Auto Generated True
  }, []);

  useEffect(() => {
    if (isAutoNo) View_MaterCode(); // Update Master Code if Auto Generated True
    else {
      setstrCode("");
      setstrCodeFormat("");
      setiNextNo(0);
      setiMaxDegit(0);
    }
  }, [isAutoNo]);

  const Load_MaterCode = async () => {
    try {
      const objSearch = {
        iScrId: props.iScrId,
        strCompanyDB: localStorage.db,
      };
      api.post(
         "ConfigurationSetting/View_MasterCode",
        objSearch
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          let VouchNo = res.data.EntCode[0];
          setisAutoNo(!VouchNo.isManualNumber);
          if (!VouchNo.isManualNumber) {
            setstrCode(VouchNo.code);
            setstrCodeFormat(VouchNo.codeFormat);
            setiNextNo(VouchNo.nextNumber);
            setiMaxDegit(VouchNo.numSeqMaxDigit);
          }
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (err) {}
  };
  const View_MaterCode = async () => {
    try {
      const objSearch = {
        iScrId: props.iScrId,
        strCompanyDB: localStorage.db,
      };
      api.post(
         "ConfigurationSetting/View_MasterCode",
        objSearch
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          let VouchNo = res.data.EntCode[0];
          setstrCode(VouchNo.code);
          setstrCodeFormat(VouchNo.codeFormat);
          setiNextNo(VouchNo.nextNumber);
          setiMaxDegit(VouchNo.numSeqMaxDigit);
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Update_MasterCode = async () => {
    try {
      if (isAutoNo && !strCodeFormat.includes("#N"))
        AlertMessage("#N is Mandatory", "info");
      else {
        api.get(
          `ConfigurationSetting/View_NextMasterNumber/${
            props.iScrId
          }/${Number(iNextNo)}/${localStorage.db}`
        ).then(async (Res) => {
          if (Res.data.strStatus === "info") {
            AlertMessage(Res.data.strMessage, "success");
          } else {
            await api.post(
               "ConfigurationSetting/Update_MasterCode/",
              {
                NumSeqMaxDigit: iMaxDegit,
                NextNumber: iNextNo,
                CodeFormat: strCodeFormat,
                ScrId: props.iScrId,
                IsManualNumber: !isAutoNo,
                strCompanyDB: localStorage.db,
              }
            ).then((res) => {
              if (res.data.strStatus === "Success") {
                Load_MaterCode();
                setisChanged(true);
                AlertMessage(res.data.strMessage, "success");
              }
            });
          }
        });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = async (Msg, type) => {
    await dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content config-popup">
        <div className="popup-header">
          <h5>Master Code Configuration</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => {
                if (isChanged) window.location.reload();
                else props.Close();
              }}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <p className="config-text">
              {!isAutoNo &&
                `Your Master Code are set on auto-generate mode to save your time.Are you sure about changing this setting?`}
              {isAutoNo &&
                "You have selected manual Master Code.Do you want us to auto-generate it for you?"}
            </p>
            {isAutoNo && (
              <p className="config-text1">
                <strong>#P-Prefix</strong>
                <strong>#N-No</strong>
              </p>
            )}
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="inv-check">
                  <input
                    type="checkbox"
                    checked={isAutoNo}
                    onChange={(e) => {
                      setisAutoNo(!isAutoNo);
                    }}
                  />
                  <label style={{ fontWeight: isAutoNo ? "bold" : "normal" }}>
                    Continue auto-generating Master Code
                  </label>
                </div>
              </div>
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Max Degit</label>
                  <input
                    type="number"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={iMaxDegit}
                    onChange={(e) => setiMaxDegit(e.target.value)}
                    placeholder="0"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Next No</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={iNextNo}
                    placeholder="000"
                    onChange={(e) => setiNextNo(e.target.value)}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Code Formate</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={strCodeFormat}
                    onChange={(e) => setstrCodeFormat(e.target.value)}
                    placeholder="Inv-"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Inv No</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={strCode}
                    readOnly
                  />
                </div>
              )}
              <div className="col-sm-12 col-md-12 ">
                <div className="inv-check">
                  <input
                    type="checkbox"
                    checked={!isAutoNo}
                    onChange={(e) => setisAutoNo(!isAutoNo)}
                  />
                  <label style={{ fontWeight: !isAutoNo ? "bold" : "normal" }}>
                    Enter Master Code Manually
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => Update_MasterCode()}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={props.Close}>
              Cancel&nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterCodeSetting;
