import React, { useState } from "react";
import PartySearch from "../Transaction/PartySearch";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useSelector } from "react-redux";
import VouchernoSetting from "../Setting/VouchernoSetting";
function DebitCreditBody(props) {
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [IsPartyOpen, setIsPartyOpen] = useState(false);
  const [iScrId, setiScrId] = useState(0);
  const [strFieldVenName, setstrFieldVenName] = useState("");
  const [isVouchNoSett, setisVouchNoSett] = useState(false);
  const PartySearchPopup = () => {
    if (props.iVenTypeId === 4) {
      setiScrId(2);
      setstrFieldVenName("CustName");
    } else if (props.iVenTypeId === 5) {
      setiScrId(4);
      setstrFieldVenName("VenName");
    } else {
      setiScrId(3);
      setstrFieldVenName("SuppName");
    }
    setIsPartyOpen(!IsPartyOpen);
  };
  const View_Party = (Party) => {
    setIsPartyOpen(!IsPartyOpen);
    props.View_Party(Party);
  };

  return (
    <div className="row p-2">
      <div className="col-md-4 col-sm-12 mt-4">
        <label>
          Vocher no <span className="danger">*</span>
        </label>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Voucher Number"
            aria-label="Recipient's username"
            value={props.strVouchNo}
            aria-describedby="button-addon2"
            id="txtVoucherNo"
            tabIndex={props.ListTabOrder[0].VoucherTab}
            onKeyDown={(e) => props.TabIndex(e)}
            disabled={!props.isManualNo}
            name="strVouchNo"
            onChange={(e) => props.OnChangeEvent(e)}
          />
          <span
            className="inv-no"
            onClick={(e) => setisVouchNoSett(!isVouchNoSett)}
          >
            <i className="bx bx-cog"></i>
          </span>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 mt-4">
        <label>
          Trans No <span className="danger">*</span>
        </label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          id="txtTransNo"
          name="strTransNo"
          tabIndex={props.ListTabOrder[0].TransNoTab}
          onKeyDown={(e) => props.TabIndex(e)}
          readOnly
          value={props.strTransNo}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-4 col-sm-12 mt-4">
        <label>
          Date <span className="danger">*</span>
        </label>
        <input
          type="date"
          name="strVouchDate"
          tabIndex={props.ListTabOrder[0].VouchDateTap}
          onKeyDown={(e) => props.TabIndex(e)}
          className="form-control"
          value={props.strVouchDate}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-4 col-sm-12 mt-4">
        <label>
          Vendor Type <span className="danger">*</span>
        </label>
        <CustomizedSelectoption
          optionparem="isoptionShow"
          showoption={_selecrparem.isoptionShow}
          placeholder="Select  Vendor Type "
          taborder={props.ListTabOrder[0].VenTypeTab}
          keydown={(e) => props.TabIndex(e)}
          select_value={(val) =>
            props.OnSelectionChange({
              target: { value: val, name: "iVenTypeId" },
            })
          }
          btnname=""
          btnshow={false}
          show_popup={""}
          valueonly={true}
          defaultval={props.iVenTypeId}
          displayName="strVenTypeName"
          disvalue="iVenTypeId"
          arrydata={props.listVenType}
          EmptVal="Vendor Type "
        />
      </div>
      <div className="col-md-4 col-sm-12 mt-4">
        <label>
          Vendor Name <span className="danger">*</span>
        </label>
        <div className="custom-select">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            placeholder="Enter to Select Vendor"
            value={props.strVenName}
            tabIndex={props.ListTabOrder[0].VenNameTab}
            id="txtstrVenName"
            onKeyDown={(e) => {
              if (e.key === "Enter") PartySearchPopup();
              else props.TabIndex(e);
            }}
            onClick={PartySearchPopup}
            onInput={PartySearchPopup}
            autoFocus
          />
          <i
            className="fa-solid fa-caret-down"
            style={{
              position: "absolute",
              right: "10px",
              color: "darkgreen",
              transform: IsPartyOpen ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          ></i>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 mt-4">
        <label>
          Amount<span className="danger">*</span>
        </label>
        <input
          type="number"
          aria-label="First name"
          className="form-control"
          tabIndex={props.ListTabOrder[0].AmountTab}
          onKeyDown={(e) => props.TabIndex(e)}
          name="dAmount"
          style={{ textAlign: "right" }}
          value={props.dAmount}
          onClick={(e) => e.target.select()}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-4 col-sm-12 mt-4">
        <label>Remarks</label>
        <textarea
          style={{ height: "100px" }}
          className="form-control rounded"
          aria-label="With textarea"
          placeholder="Enter Remarks"
          tabIndex={props.ListTabOrder[0].Remarktab}
          onKeyDown={(e) => props.TabIndex(e)}
          name="strRemark"
          onClick={(e) => e.target.select()}
          value={props.strRemark}
          onChange={(e) => props.OnChangeEvent(e)}
        ></textarea>
      </div>
      {IsPartyOpen && (
        <PartySearch
          Close={() => setIsPartyOpen(!IsPartyOpen)}
          View_Party={View_Party}
          ScrId={iScrId}
          ColumnName={strFieldVenName}
          VenTypeId={props.iVenTypeId}
          Partyname={props.strVenName}
          isNew={false}
        />
      )}
      {isVouchNoSett && (
        <VouchernoSetting
          iScrId={props.ScrId}
          Close={() => setisVouchNoSett(!isVouchNoSett)}
        />
      )}
    </div>
  );
}

export default DebitCreditBody;
