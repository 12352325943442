import React from "react";
import { useEffect, useState } from "react";

import ReactTable from "../General/ReactTable";
import $ from "jquery";
import api from "../Admin/Config";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
function PrintLineDesign() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [strLineTempName, setstrLineTempName] = useState("");
  const [iLineTempId, setiLineTempId] = useState(0);

  const [iLineNameId, setiLineNameId] = useState("");
  const [strLineName, setstrLineName] = useState("");
  const [strLineColor, setstrLineColor] = useState("black");

  const [X1Position, setX1Position] = useState(0);
  const [Y1Position, setY1Position] = useState(0);
  const [Y2Position, setY2Position] = useState(0);
  const [X2Position, setX2Position] = useState(0);
  const [selected, setselected] = useState(0);

  const [LineTemplate, setLineTemplate] = useState([]);
  const [arrDesign, setarrDesign] = useState([]);
  const [isPrint, setisPrint] = useState(false);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_LinetTemplate("");
  }, []);
  const Load_LinetTemplate = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objsearch = {
        strSearch: strtxt,
        strColName: "",
        strCompanyDB: localStorage.db,
      };
      await api.post(
        `PrintMaster/Load_LinetTemplate/`,
        objsearch
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          setLineTemplate(res.data.EntLineTemplate);
        } else AlertMessage(res.data.strMessage, "info");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Save_Linetemplate = () => {
    const objLineModel = {
      iLineTempId: iLineTempId,
      strLineTempName: strLineTempName,
      strCompanyDB: localStorage.db,
    };
    api.post( "PrintMaster/InsUpdLinetemplate/", objLineModel).then(
      (res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          Clear_lineDesign();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      }
    );
  };
  const Delete_Linetemplate = () => {
    api.post(
      
        "PrintMaster/Delete_Linetemplate/" +
        iLineTempId +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        AlertMessage(res.data.strMessage, "success");
        Clear_lineDesign();
      } else {
        AlertMessage(res.data.strMessage, "error");
      }
    });
  };
  const Save_LineDesign = () => {
    if (strLineTempName === "") {
      AlertMessage("Please Enter Line Template Name", "info");
    } else {
      const LineInsUpd = {
        iPrintLineTempId: 0,
        strPrintLineTempName: "null",
        iLineTempId: Number(iLineTempId),
        strLineTempName: strLineTempName,
        iLineNameId: Number(iLineNameId),
        strLineName: strLineName,
        strLineColor: strLineColor,
        dX1: X1Position,
        dY1: Y1Position,
        dX2: X2Position,
        dY2: Y2Position,
        strCompanyDB: localStorage.db,
      };
      api.post( "PrintMaster/InsUpd_Linedesign/", LineInsUpd).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            View_LineTemplate(Number(iLineTempId));
            AlertMessage(res.data.strMessage, "success");
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    }
  };
  const Delete_LineDesign = (Lineid) => {
    try {
      dispatch(setload_Screen(true));
      api.post(
        
          "PrintMaster/Delete_LineDesign/" +
          Number(Lineid) +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          View_LineTemplate(Number(iLineTempId));
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "info");
    }
  };
  const View_LineTemplate = (iLineTempId) => {
    Clear_Design();
    setiLineTempId(iLineTempId);
    api.get(
      
        "PrintMaster/View_LineDesign/" +
        iLineTempId +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        setarrDesign(res.data.EntLineDesign);
      } else AlertMessage(res.data.strMessage, "info");
    });
  };
  const Modify_LineDesign = (LineInfo) => {
    setstrLineName(LineInfo.lineName);
    setX1Position(LineInfo.x1Pos);
    setX2Position(LineInfo.x2Pos);
    setY1Position(LineInfo.y1Pos);
    setY2Position(LineInfo.y2Pos);
    setiLineTempId(LineInfo.lineTempId);
    setiLineNameId(LineInfo.lineId);
    setstrLineColor(LineInfo.color);
  };
  const Clear_Design = () => {
    setX1Position(0);
    setY1Position(0);
    setY2Position(0);
    setX2Position(0);
    setiLineNameId(0);
    setstrLineName("");
    setstrLineColor("black");
    setarrDesign([]);
  };
  const Clear_lineDesign = () => {
    setX1Position(0);
    setY1Position(0);
    setY2Position(0);
    setX2Position(0);
    setiLineNameId(0);
    setstrLineName("");
    setstrLineColor("black");
    setstrLineTempName("");
    setiLineTempId(0);
    setarrDesign([]);
    Load_LinetTemplate("");
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const LineColums = [
    {
      Header: "Name",
      accessor: "lineName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "x1Pos",
      accessor: "x1Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "y1Pos",
      accessor: "y1Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "x2Pos",
      accessor: "x2Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "Y2Pos",
      accessor: "y2Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "Delete",
      id: "sNo",
      minWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={(e) => {
            dispatch(
              Show_MsgBox({
                Button: Delete_LineDesign,
                Question: "Do You Want to  Delete Line Design?",
                isdialog: true,
                Id: rowinfo.lineId,
              })
            );
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];

  return (
    <>
      <SideMenu ScrId={152} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="m-header">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Line Template"
                value={strSearch}
                onChange={(e) => Load_LinetTemplate(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Load_LinetTemplate(strSearch)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {LineTemplate.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = LineTemplate.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    setstrLineTempName(dt.strLineTempName);
                    View_LineTemplate(dt.iLineTempId);
                  }
                }}
              >
                <label
                  onClick={(e) => {
                    setstrLineTempName(dt.strLineTempName);
                    View_LineTemplate(dt.iLineTempId);
                  }}
                >
                  {dt.strLineTempName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label>Line Template Name</label>
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="Enter Line Template Name"
                  value={strLineTempName}
                  onChange={(e) => setstrLineTempName(e.target.value)}
                  disabled={iLineNameId > 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <br />
                <button
                  className="btn-fabgreen"
                  disabled={
                    iLineTempId > 0 ? (iLineNameId > 0 ? true : false) : true
                  }
                  onClick={(e) => {
                    if (strLineTempName === "") {
                      AlertMessage("Please Enter Template Name", "info");
                    } else {
                      dispatch(
                        Show_MsgBox({
                          Button: Save_Linetemplate,
                          Question: "Do You Want to Save Line Template?",
                          isdialog: true,
                        })
                      );
                    }
                  }}
                >
                  Save Line Template&nbsp; <i className="fa fa-save"></i>
                </button>
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <br />
                <button
                  className="btn-fabgreen"
                  disabled={
                    iLineTempId > 0 ? (iLineNameId > 0 ? true : false) : true
                  }
                  onClick={(e) => {
                    if (iLineTempId === 0) {
                      AlertMessage("Please Select Line template", "info");
                    } else {
                      dispatch(
                        Show_MsgBox({
                          Button: Delete_Linetemplate,
                          Question: "Do You Want to Delete Line Template?",
                          isdialog: true,
                        })
                      );
                    }
                  }}
                >
                  Delete Line Template&nbsp;
                  <i className="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-12 mt-1">
                <label>Line Name</label>
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="Enter Line Name"
                  value={strLineName}
                  onChange={(e) => setstrLineName(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>x1 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Line Start Point"
                  value={X1Position}
                  onChange={(e) => setX1Position(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>x2 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Line End Point"
                  value={X2Position}
                  onChange={(e) => setX2Position(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>y1 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Height Start Point"
                  value={Y1Position}
                  onChange={(e) => setY1Position(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>y2 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Height End Point"
                  value={Y2Position}
                  onChange={(e) => setY2Position(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-2 col-sm-12 mb-1">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow7"
                  showoption={_parem.isoptionShow7}
                  placeholder="Select Color"
                  select_value={(val) => setstrLineColor(val)}
                  defaultval={strLineColor}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <div className="p-2">
                <ReactTable
                  columns={LineColums}
                  data={arrDesign}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {
                    Modify_LineDesign(rowInfo);
                  }}
                  background={true}
                  className="full-table"
                />
              </div>
              {isPrint && (
                <div className="popup-container">
                  <div className="popup-content" style={{ width: "70%" }}>
                    <div className="popup-header">
                      <h5>{strLineTempName}</h5>
                      <div className="icon-popup-btn-grp">
                        <span
                          className="icon-btn"
                          onClick={(e) => setisPrint(!isPrint)}
                        >
                          <i className="fa-regular fa-rectangle-xmark"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="popup-body"
                      style={{
                        height: "80vh",
                        maxHeight: "80vh",
                      }}
                    >
                      <div className="LineDesign">
                        {arrDesign.map((design, Index) => (
                          <hr
                            key={Index}
                            style={{
                              position: "absolute",
                              top: design.y1Pos + "px",
                              left: design.x1Pos + "px",
                              height:
                                design.y2Pos === design.y1Pos
                                  ? "1px"
                                  : design.y2Pos - design.y1Pos + "px",
                              width:
                                design.y2Pos === design.y1Pos
                                  ? design.x2Pos - design.x1Pos + "px"
                                  : "1px",
                              background: design.color,
                              color: design.color,
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Messagedialogbox />
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (iLineTempId === 0) {
                  AlertMessage("Please Select Line Template", "info");
                } else if (strLineName === "") {
                  AlertMessage("Please Enter Line Name", "info");
                } else if (X1Position === 0) {
                  AlertMessage("Please Enter x1 Position", "info");
                } else if (X2Position === 0) {
                  AlertMessage("Please Enter x2 Position", "info");
                } else if (Y1Position === 0) {
                  AlertMessage("Please Enter y1 Position", "info");
                } else if (Y2Position === 0) {
                  AlertMessage("Please Enter y2 Position", "info");
                } else {
                  Save_LineDesign();
                }
              }}
            >
              Save Line Design&nbsp; <i className="fa fa-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setisPrint(!isPrint)}
              disabled={iLineTempId === 0 ? true : false}
            >
              Print Line Design&nbsp; <i className="fas fa-print"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Clear_lineDesign}
            >
              Clear Line Design&nbsp; <i className="fa-solid fa-broom"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href =
                  "/Others/PrintFormateDesigner")
              }
            >
              Close &nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintLineDesign;
