import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowHide_Popup,
  setItemRemark,
} from "../ReduxStore/Transactionstates";

function ItemRemarks() {
  const dispatch = useDispatch();
  const _Product = useSelector((store) => store.states.Product);

  const [strRemark1, setstrRemark1] = useState("");
  const [strRemark2, setstrRemark2] = useState("");
  const [strRemark3, setstrRemark3] = useState("");
  const [strRemark4, setstrRemark4] = useState("");

  useEffect(() => {
    setstrRemark1(_Product.Remarks.strRemark1);
    setstrRemark2(_Product.Remarks.strRemark2);
    setstrRemark3(_Product.Remarks.strRemark3);
    setstrRemark4(_Product.Remarks.strRemark4);
  }, []);
  const ClearClick = () => {
    setstrRemark1("");
    setstrRemark2("");
    setstrRemark3("");
    setstrRemark4("");
  };
  const SaveClick = () => {
    dispatch(
      setItemRemark({
        strRemark1: strRemark1,
        strRemark2: strRemark2,
        strRemark3: strRemark3,
        strRemark4: strRemark4,
      })
    );
    Popup_Close();
  };
  const Popup_Close = () => {
    dispatch(ShowHide_Popup("IsProRemark"));
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>ITEM REMARKS</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={Popup_Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2 ">
          <div className="row p-2">
            <div className="col-md-12 col-sm-12">
              <label>Remark 1</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="strRemark1"
                id="txtRemark1"
                autoFocus
                onClick={(e) => e.target.select()}
                value={strRemark1}
                onChange={(e) => setstrRemark1(e.target.value)}
                spellCheck={false}
              />
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Remark 2</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="strRemark2"
                id="txtRemark2"
                onClick={(e) => e.target.select()}
                value={strRemark2}
                onChange={(e) => setstrRemark2(e.target.value)}
                spellCheck={false}
              />
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Remark 3</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="strRemark3"
                id="txtRemark3"
                onClick={(e) => e.target.select()}
                value={strRemark3}
                spellCheck={false}
                onChange={(e) => setstrRemark3(e.target.value)}
              />
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Remark 4</label>
              <textarea
                style={{ height: "90px" }}
                className="form-control rounded"
                aria-label="With textarea"
                id="txtRemark4"
                onClick={(e) => e.target.select()}
                name="strRemark4"
                onChange={(e) => setstrRemark4(e.target.value)}
                spellCheck={false}
                value={strRemark4}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ItemRemarks;
