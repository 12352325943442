import React, { useEffect, useState } from "react";
import $ from "jquery";
import api from "../Admin/Config";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  Merge_ConvertedVouchers,
  Select_ProducttoConvert,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import { Table_Style } from "./Transaction";
function ConvertRowManipulation() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);

  const [iRowIndex, setiRowIndex] = useState(0);
  const [arrEntPenConDT, setarrEntPenConDT] = useState([]);
  const [iProId, setiProId] = useState(0);
  const [strProName, setstrProName] = useState("");
  const [dQty, setdQty] = useState(0);
  const [iUnitId, setiUnitId] = useState(0);
  const [UnitList, setUnitList] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_ConvertionProduct();
  }, []);
  const Load_ConvertionProduct = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post("Transaction/View_ConvertionProduct/", {
          _EntProduct: _DT.arrEntConDT,
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntPenConDT(Res.data.EntPenCon);
            setarrEntTableCol(Res.data.EntTablDesign);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Update_Convertion = async () => {
    try {
      if (Number(iProId) === 0) AlertMessage("Please Select Product", "info");
      else if (Number(dQty) === 0)
        AlertMessage("Qty Range should not be Zero(0)", "info");
      else {
        await dispatch(setload_Screen(true));
        api
          .post(`Transaction/Update_Convertion/`, {
            iRowIndex: iRowIndex,
            iScrId: _Common.iScrId,
            iProId: iProId,
            dQty: Number(dQty),
            iUnitId: iUnitId,
            objEntConRow: arrEntPenConDT,
            objEntHelp: _DT.arrEntDTHelp,
            iYearId: Number(localStorage.FYId),
            objEntPenConHelp: [],
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              setarrEntPenConDT(Res.data.EntPenCon);
              ClearInputFields();
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Merge_Convertion = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post(`Transaction/Merge_Convertion/`, {
          iScrId: _Common.iScrId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          objBaseTransDT: _DT.arrEntDT,
          objEntConRow: arrEntPenConDT,
          objConDCVouch: [],
          iYearId: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iUserId: Number(localStorage.UserId),
        })
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await dispatch(Merge_ConvertedVouchers(res.data));
            await dispatch(ShowHide_Popup("IsConvertion"));
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearInputFields = () => {
    setiProId(0);
    setstrProName("");
    setdQty(0);
    setiUnitId(0);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const View_Product = async (ProInfo, Index) => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get(
          `Transaction/View_ProductUnit/${Number(ProInfo.proId)}/Sales/${
            localStorage.db
          }`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setUnitList(Res.data.objUnit);
            setiRowIndex(Index);
            setiProId(ProInfo.proId);
            setstrProName(ProInfo.proName);
            setdQty(ProInfo.qty);
            setiUnitId(ProInfo.unitId);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>CONVERT Vouchers</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsPendinCon"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-sm-12 col-md-5 mb-2">
              <label>Product Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="strProName"
                id="txtProName"
                autoComplete="off"
                value={strProName}
                onChange={(e) => setstrProName(e.target.value)}
                readOnly
              />
            </div>
            <div className="col-sm-12 col-md-2 mb-2">
              <label>Qty</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="dQty"
                autoComplete="off"
                id="txtproQty"
                autoFocus
                onClick={(e) => e.target.select()}
                value={dQty}
                onChange={(e) => setdQty(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3 mb-2">
              <label>Unit</label>
              <CustomizedSelectoption
                optionparem="isoptionShow12"
                showoption={_selecrparem.isoptionShow12}
                placeholder="Select Unit"
                select_value={(val) => setiUnitId(val)}
                btnname="Unit"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={iUnitId}
                displayName="strUnitName"
                disvalue="iUnitId"
                arrydata={UnitList}
                disabled={false}
                EmptVal="Unit"
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <div className="btn-sub-section mt-2">
                <button className="btn-fabgreen" onClick={Update_Convertion}>
                  Update&nbsp;<i className="bx bx-layer-plus"></i>
                </button>
              </div>
            </div>
            <div className="table-card ">
              <table className="option-list">
                <thead>
                  <tr>
                    {arrEntTableCol.map(
                      (ht, Index) =>
                        ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {arrEntPenConDT.map((Item, Index) => (
                    <tr
                      key={Index}
                      tabIndex={Index}
                      onKeyDown={(e) => {
                        const arrlength = arrEntPenConDT.length - 1;
                        if (e.key === "ArrowDown") {
                          if (Index === arrlength)
                            $(`.option-list [tabindex=${0}]`).focus();
                          else
                            $(`.option-list [tabindex=${Index + 1}]`).focus();
                        }
                        if (e.key === "ArrowUp") {
                          if (Index === 0)
                            $(`.option-list [tabindex=${arrlength}]`).focus();
                          else
                            $(`.option-list [tabindex=${Index - 1}]`).focus();
                        }
                        if (e.key === "ArrowLeft") $("#to_date").focus();
                        if (e.key === "ArrowRight")
                          $("#Invoice_Search").focus();
                        if (e.key === "Enter") View_Product(Item, Index);
                      }}
                      onDoubleClick={(e) => View_Product(Item, Index)}
                    >
                      {arrEntTableCol.map(
                        (dt, Index) =>
                          dt.isShow && (
                            <td key={Index} style={Table_Style(dt, Item)}>
                              {dt.strDataType === "number"
                                ? Number(Item[dt.strColName]).toFixed(2)
                                : Item[dt.strColName]}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={Merge_Convertion}>
              Merge&nbsp;<i className="bx bx-merge"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={async (e) => {
                await dispatch(Select_ProducttoConvert([]));
                await dispatch(ShowHide_Popup("IsPendinCon"));
              }}
            >
              <i className="bx bx-arrow-back"></i>&nbsp;Back to Convertion
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConvertRowManipulation;
