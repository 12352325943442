import { useEffect, useRef, useState } from "react";
import $ from "jquery";
function ReactTable({
  columns,
  data,
  minRows,
  row_click,
  row_doubleclick,
  background,
  className,
  containerclass,
  tabIndex,
  KeyDown,
}) {
  const containerRef = useRef(null);
  const [selected, setselected] = useState(0);
  const [Emptyrow, setEmptyrow] = useState([]);

  const table_keydown = (e) => {
    let curre_Index = 0;
    switch (e.key) {
      case "ArrowUp":
        curre_Index = selected - 1;
        if (curre_Index === -1) {
          $(`.react-table .rt-tbody .rt-tr:eq(${data.length - 1})`).focus();
          setselected(data.length - 1);
          containerRef.current.scrollTop = (data.length - 1) * 25;
        } else {
          containerRef.current.scrollTop -= 25;
          setselected(curre_Index);
          $(`.react-table .rt-tbody .rt-tr:eq(${curre_Index})`).focus();
        }
        break;
      case "ArrowDown":
        curre_Index = selected + 1;
        if (curre_Index === data.length) {
          $(`.react-table .rt-tbody .rt-tr:eq(0)`).focus();
          setselected(0);
          containerRef.current.scrollTop = (data.length - 1) * -25;
        } else {
          containerRef.current.scrollTop += 25;
          setselected(curre_Index);
          $(`.react-table .rt-tbody .rt-tr:eq(${curre_Index})`).focus();
        }

        break;
      case "ArrowLeft":
        if (KeyDown) KeyDown(e);
        break;
      case "ArrowRight":
        if (KeyDown) KeyDown(e);
        break;
      case "Enter":
        row_doubleclick(data[selected]);
        break;
      default:
        return;
    }
  };
  const tdstyle = (td, color) => {
    let style = {};
    if (td) {
      style.width = td.minWidth
        ? td.minWidth.toString().includes("auto")
          ? "auto"
          : td.minWidth + "px"
        : "auto";
      style.minWidth = td.minWidth
        ? td.minWidth.toString().includes("auto")
          ? "auto"
          : td.minWidth + "px"
        : "0";
      style.maxWidth = td.maxWidth
        ? td.maxWidth.toString().includes("auto")
          ? "auto"
          : td.maxWidth + "px"
        : "100%";
      style.textAlign = td.style ? td.style.justifyContent : "left";
      style.color = color ? color : "black";
    }
    return style;
  };
  useEffect(() => {
    const rowncount = minRows - data.length;
    const arr = Array.from({ length: rowncount }, (_, index) => index + 1);
    setEmptyrow(arr);
  }, [data]);

  const show_column = (EntCol) => {
    EntCol = EntCol.filter(
      (col) => col.show === undefined || col.show === true
    );
    return EntCol;
  };
  return (
    <section
      className={
        containerclass
          ? containerclass + " react-table-container mt-2"
          : "react-table-container mt-2"
      }
      onKeyDown={(e) => table_keydown(e)}
      tabIndex={tabIndex ? tabIndex : 52}
      ref={containerRef}
    >
      <table
        id="react-table"
        className={className ? "react-table " + className : "react-table"}
      >
        <thead className="rt-thead">
          <tr className="rt-tr">
            {show_column(columns).map((hd, Index) => (
              <th key={Index} className="rt-th">
                {!hd.isHeaderCell && hd.Header}
                {hd.isHeaderCell && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <hd.Header />
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="rt-tbody">
          {data.map((row, rowInex) => (
            <tr
              key={rowInex}
              style={{
                background:
                  rowInex === selected
                    ? background
                      ? "#ddd"
                      : "white"
                    : "white",
              }}
              className="rt-tr"
              onClick={async (e) => {
                await setselected(rowInex);
                await row_click(row, rowInex);
              }}
              onDoubleClick={async (e) => {
                await setselected(rowInex);
                await row_doubleclick(row, rowInex);
              }}
            >
              {show_column(columns).map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={tdstyle(cell, "black")}
                  className="rt-td"
                >
                  {cell.cell && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <cell.cell rowinfo={row} />
                    </div>
                  )}
                  {!cell.cell &&
                    cell.Datatype !== "Decimal" &&
                    row[cell.accessor]}
                  {!cell.cell &&
                    cell.Datatype === "Decimal" &&
                    Number(row[cell.accessor]).toFixed(2)}
                  {cell.subaccessor !== undefined && (
                    <sub className="danger ml-1">{row[cell.subaccessor]}</sub>
                  )}
                </td>
              ))}
            </tr>
          ))}
          {Emptyrow.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{
                background: "white",
              }}
              className="rt-tr"
            >
              {show_column(columns).map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={tdstyle(cell, "black")}
                  className="rt-td"
                ></td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default ReactTable;
