import React from "react";
function ServiceRemark(props) {
  const handleClick = (event) => {
    event.target.select();
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>SERVICE REMARK</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => props.Close(props.strRemarkType)}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <label>Remarks</label>
          <textarea
            style={{ minHeight: "100px" }}
            className="form-control"
            id="strRemark"
            onClick={handleClick}
            name="strRemark"
            onChange={(e) => props.onChangeEvent(e.target.value)}
            autoComplete="off"
            value={props.strRemark}
            autoFocus
            spellCheck="off"
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default ServiceRemark;
