import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";
import Admintable from "./Admintable";

function AvailableDatabase() {
  const dispatch = useDispatch();
  const [arrMSDB, setarrMSDB] = useState([]);
  const [arrCMPDB, setarrCMPDB] = useState([]);
  useEffect(() => {
    Load_AvailableDB();
  }, []);

  const Load_AvailableDB = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_AvailableDB").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrMSDB(res.data.EntMSDB);
          setarrCMPDB(res.data.EntCMPDB);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const MSDBColumns = [
    {
      Name: "Master DataBase",
      ColName: "dbName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
  ];
  const CMPDBColumns = [
    {
      Name: "Company DataBase",
      ColName: "dbName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
  ];
  return (
    <div className="Dash-content">
      <div className="Dash-card1">
        <div className="Dash-wrapper">
          <div className="row">
            <div className="col-sm-12 col-md-6 pr-0">
              <Admintable
                Columns={MSDBColumns}
                Data={arrMSDB}
                View_Data={() => {}}
                IsBackground={false}
              />
            </div>
            <div className="col-sm-12 col-md-6 pl-0">
              <Admintable
                Columns={CMPDBColumns}
                Data={arrCMPDB}
                View_Data={() => {}}
                IsBackground={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailableDatabase;
