import React from "react";
import { useEffect, useState } from "react";
import api from "../Admin/Config";
import { Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import $ from "jquery";

function UserRole(props) {
  const dispatch = useDispatch();
  const [iRoleId, setiRoleId] = useState(0);
  const [strRole, setstrRole] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [ListUserRole, setListUserRole] = useState([]);
  useEffect(() => {
    Load_UserRole();
  }, []);
  const Load_UserRole = () => {
    try {
      api.get( "UserMaster/Load_UserRole/" + localStorage.Msdb).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setListUserRole(Res.data.EntUserRole);
          } else AlertMessage(Res.data.strMessage, "info");
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const View_UserRole = (info) => {
    setiRoleId(info.iRoleId);
    setstrDescription(info.strDescription);
    setstrRole(info.strName);
  };
  const SaveClick = () => {
    try {
      const objRole = {
        iRoleId: Number(iRoleId),
        strName: strRole,
        strDescription: strDescription,
        strMasterDB: localStorage.Msdb,
      };
      api.post( "UserMaster/InsUpd_UserRole/", objRole).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else AlertMessage(Res.data.strMessage, "error");
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const ClearClick = () => {
    setstrDescription("");
    setiRoleId(0);
    setstrRole("");
    Load_UserRole();
  };
  const DeleteClick = () => {
    try {
      api.post(
        
          "UserMaster/Delete_UserRole/" +
          Number(iRoleId) +
          "/" +
          localStorage.Msdb
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>USER ROLE</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="data-list">
                {ListUserRole.map((role, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onClick={(e) => View_UserRole(role)}
                    onKeyDown={(e) => {
                      const arrlength = ListUserRole.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_UserRole(role);
                      }
                    }}
                  >
                    <label>{role.strName}</label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="mt-1">
                <label>User Role</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strRole}
                  onChange={(e) => setstrRole(e.target.value)}
                  placeholder="Enter User Role"
                />
              </div>
              <div className="mt-1">
                <label>Description</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strDescription}
                  onChange={(e) => setstrDescription(e.target.value)}
                  placeholder="Enter Remark"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (strRole === "") {
                  AlertMessage("Please Enter Role Name", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save User Role?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              disabled={Number(iRoleId) === 1 ? true : false}
              onClick={(e) => {
                if (Number(iRoleId) === 1) {
                  AlertMessage("You Can't Delete Admin Role", "info");
                } else if (Number(iRoleId) === 0) {
                  AlertMessage("Please Select UserRole", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete User Role?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button type="reset" className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserRole;
