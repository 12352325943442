import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { ShowHide_PrintSett } from "../ReduxStore/Transactionstates";
function PrinterSetting() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);

  const [arrTemplate, setarrTemplate] = useState([]);
  const [arrPrinterType, setarrPrinterType] = useState([]);
  const [arrPaperType, setarrPaperType] = useState([]);
  const [iNoOfCopies, setiNoOfCopies] = useState(0);
  const [TempId1, setTempId1] = useState(0);
  const [TempId2, setTempId2] = useState(0);
  const [TempId3, setTempId3] = useState(0);
  const [TempId4, setTempId4] = useState(0);
  const [PrinterTypeId, setPrinterTypeId] = useState(0);
  const [PaperId, setPaperId] = useState(0);
  const [IsbPreview, setIsbPreview] = useState(false);
  const [IsbDefault, setIsbDefault] = useState(false);
  const [IsSetbDefault, setIsSetbDefault] = useState(false);
  const [IsDualSetDefault, setIsDualSetDefault] = useState(false);
  const [IsDualDefault, setIsDualDefault] = useState(false);
  const [MultiPrintStyle, setMultiPrintStyle] = useState(false);
  const [iScrId, setiScrId] = useState(0);

  useEffect(() => {
    if (CheckisPrintEligible(_Common.iScrId))
      Load_PrintSettings(_Common.iScrId);
  }, []);
  const Load_PrintSettings = async (ScrId) => {
    try {
      await dispatch(setload_Screen(true));
      setiScrId(ScrId);
      await api.get(
        `PrintMaster/Load_PrintSettings/${_Common.iScrId}/ ${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setMultiPrintStyle(Res.data.strSetting === "Yes" ? true : false);
          let EntSetting = Res.data.EntPageSetting[0];
          setiNoOfCopies(EntSetting.iNoofCopies);
          setIsbPreview(EntSetting.bPrintPreview);
          setPaperId(EntSetting.iPaperNameId);
          setarrPrinterType(Res.data.EntPrinterType);
          setarrTemplate(Res.data.EntTemplate);
          setarrPaperType(Res.data.EntPaperType);
          PrinterType_OnChange(EntSetting.iPrintTypeId, ScrId);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const CheckisPrintEligible = (ScrId) => {
    switch (ScrId) {
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 67:
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
      case 76:
      case 78:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 100:
      case 66:
        return true;
      default:
        return false;
    }
  };
  const Clear_PrinterSetting = () => {
    setTempId1(0);
    setTempId2(0);
    setTempId3(0);
    setTempId4(0);
    setIsbDefault(false);
    setIsbPreview(false);
    setIsDualSetDefault(false);
    setIsDualDefault(false);
    setIsSetbDefault(false);
    Load_PrintSettings(iScrId);
  };
  const PrinterType_OnChange = async (PrinterTypeid, ScrId) => {
    try {
      await dispatch(setload_Screen(true));
      setPrinterTypeId(PrinterTypeid);
      if (Number(PrinterTypeid) !== 0)
        await api.get(
          
            "PrintMaster/PrinterType_OnChange/" +
            ScrId +
            "/" +
            PrinterTypeid +
            "/" +
            localStorage.db
        ).then((Res) => {
          if (Res.data.strStatus === "Success") {
            if (Res.data.Template1.length > 0) {
              setTempId1(Res.data.Template1[0].iPrintTempId);
              setIsbDefault(Res.data.Template1[0].bSetDefault);
            } else setTempId1(0);

            if (Res.data.Template2.length > 0) {
              setTempId2(Res.data.Template2[0].iPrintTempId);
              setIsSetbDefault(Res.data.Template2[0].bIsDefaultTemplate);
            } else setTempId2(0);

            if (Res.data.Template3.length > 0) {
              setTempId3(Res.data.Template3[0].iPrintTempId);
              setIsDualDefault(Res.data.Template3[0].bSetDualDefault);
            } else setTempId3(0);

            if (Res.data.Template4.length > 0) {
              setTempId4(Res.data.Template4[0].iPrintTempId);
              setIsDualSetDefault(Res.data.Template4[0].bIsDualDefault);
            } else setTempId4(0);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Update_PrinterSetting = async () => {
    try {
      await dispatch(setload_Screen(true));
      const UpdatePrint = {
        strTag: "Print",
        iPaperNameId: PaperId,
        iScrId: iScrId,
        iPrinterTypeId: PrinterTypeId,
        iPrintTempId: TempId1,
        iPrintTemp1Id: TempId2,
        iPrintTemp2Id: TempId3,
        iPrintTemp3Id: TempId4,
        PaperId: PaperId,
        iNoofCopies: iNoOfCopies,
        bPreview: IsbPreview,
        bSetDefault: IsSetbDefault,
        bIsDefault: IsbDefault,
        bSetDualDefault: IsDualSetDefault,
        bIsDualDefault: IsDualDefault,
      };
      const objScrModel = {
        objCPM: UpdatePrint,
        objEntBTDT: [],
        objEntTSDT: [],
        strCompanyDB: localStorage.db,
      };
      await api.post(
         "PrintMaster/Update_PrintSettings/",
        objScrModel
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          Clear_PrinterSetting();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Template Setting</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_PrintSett(false))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <div className="row">
            <div className="col-md-6 col-sm-6 mb-1">
              <label>Printer Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow11"
                showoption={_selecrparem.isoptionShow11}
                placeholder="Select Printer Type"
                select_value={(val) => PrinterType_OnChange(val, iScrId)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={PrinterTypeId}
                displayName="strPrinterTypeName"
                disvalue="iPrinterTypeId"
                arrydata={arrPrinterType}
                EmptVal="Printer Type"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb-1">
              <label>Paper Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow10"
                showoption={_selecrparem.isoptionShow10}
                placeholder="Select Paper Type"
                select_value={(val) => setPaperId(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={PaperId}
                displayName="strPaperName"
                disvalue="iPaperNameId"
                arrydata={arrPaperType}
                EmptVal="Paper Type"
              />
            </div>
            <div className="col-md-8 col-sm-8 mb-1">
              <label>Local State</label>
              <CustomizedSelectoption
                optionparem="isoptionShow12"
                showoption={_selecrparem.isoptionShow12}
                placeholder="Select Template"
                select_value={(val) => setTempId1(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={TempId1}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={arrTemplate}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-md-4 col-sm-4 mb-1">
              <label>Default</label>
              <br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={IsSetbDefault}
                  onChange={(e) => setIsSetbDefault(!IsSetbDefault)}
                />
              </div>
            </div>
            <div className="col-md-8 col-sm-8 mb-1">
              <label>Inter State</label>
              <CustomizedSelectoption
                optionparem="isoptionShow13"
                showoption={_selecrparem.isoptionShow13}
                placeholder="Select Template"
                select_value={(val) => setTempId2(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={TempId2}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={arrTemplate}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-md-4 col-sm-4 mb-1">
              <label>Default</label>
              <br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={IsbDefault}
                  onChange={(e) => setIsbDefault(!IsbDefault)}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 mb-1">
              <label>No of Copies </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Remarks"
                id="txtRemarks"
                name="strRemarks"
                onClick={(e) => e.target.select()}
                value={iNoOfCopies}
                onChange={(e) => setiNoOfCopies(e.target.value)}
              />
            </div>
          </div>
          {MultiPrintStyle && (
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Local State</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow14"
                  showoption={_selecrparem.isoptionShow14}
                  placeholder="Select Template"
                  select_value={(val) => setTempId3(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={TempId3}
                  displayName="strPrintTempName"
                  disvalue="iPrintTempId"
                  arrydata={arrTemplate}
                  EmptVal="Template Name"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Default</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={IsDualSetDefault}
                    onChange={(e) => setIsDualSetDefault(!IsDualSetDefault)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Inter State</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow15"
                  showoption={_selecrparem.isoptionShow15}
                  placeholder="Select Template"
                  select_value={(val) => setTempId4(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={TempId4}
                  displayName="strPrintTempName"
                  disvalue="iPrintTempId"
                  arrydata={arrTemplate}
                  EmptVal="Template Name"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Default</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={IsDualDefault}
                    onChange={(e) => setIsDualDefault(!IsDualDefault)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="submit"
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(TempId1) === 0)
                  AlertMessage("Please Select Local State Template", "error");
                else if (Number(TempId2) === 0)
                  AlertMessage("Please Select Inter State Template", "error");
                else if (IsbDefault === false)
                  AlertMessage("Please Check Default Local State ", "error");
                else if (IsSetbDefault === false)
                  AlertMessage("Please Check Default Inter State ", "error");
                else {
                  dispatch(
                    Show_MsgBox({
                      Button: Update_PrinterSetting,
                      Question: "Do You Want to Update Setting?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Update&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              type="submit"
              className="btn-fabgreen"
              onClick={Clear_PrinterSetting}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrinterSetting;
