import { createSlice } from "@reduxjs/toolkit";
import {
  Validate_BtnPermission,
  Validate_Taborder,
  Validate_TransPermission,
} from "../Transaction/Transaction";

export const Transactionstates = createSlice({
  name: "Transactionstates",
  initialState: {
    Common: {
      Controller: "",
      iScrId: 0,
      strScrType: "",
      strScrName: "",

      VenTypeId: 0,
      PartyScrId: 0,
      ColName: "",
      PartyScrName: "",
      PartyPlaceHolder: "",
      JSScrId: 0,
      JSType: "",

      iTaxCalcOnId: 0,
      iALBillDiscId: 0,
      MinRow: 5,
      iPrintTempId: 0,

      strSetCurrentStatus: "",

      EnableReceipt: false,
      EnablePayment: false,
      IsPrint: false,
      IsTinder: false,
      IsHideBill: false,
      IsHideJob: true,
      IsHideInvDc: true,
      IsConvertDc: false,
      IsPartyDc: false,
      IsJobWorkDc: false,

      IsVenSearch: false,
      IsSerVendor: false,
      IsProSearch: false,
      IsSerSearch: false,
      IsVenCreation: false,
      IsShippAdd: false,
      IsVouchNo: false,
      IsProduct: false,
      IsProRemark: false,
      IsProCal: false,
      IsConvertion: false,
      IsReceipt: false,
      IsPayment: false,
      IsHideParty: false,
      IsPendinCon: false,
      IsTransView: false,
      IsEmail: false,
      IsWhatsApp: false,
      IsEInvoice: false,
      IsPrintSett: false,
      IsPrintShow: false,
      IsReturnPro: false,
      IsQtyReadonly: false,
      IsAttSearch: false,
      IsNewPro: false,
      IsJobSearch: false,

      BillType: [],
      TaxType: [],
      DelMode: [],
      arrVendore: [],
      arrService: [],
      ExpenseType: [],
      VendorType: [],
      arrValidation: [],
      arrtransper: [],
      arrfieldPer: [],
      arrTableCol: [],
      arrtaborder: {
        txtPartyName: "",
        txtBillAddress: "",
        txtShippingAdd: "",
        cbBillType: "",
        cbTaxType: "",
        txtTransNo: "",
        txtInvNo: "",
        dpInvDate: "",
        txtTransport: "",
        txtDespatch: "",
        dpDespatchDate: "",
        txtDestination: "",
        txtVehicle: "",
        cbDeliMode: "",
        txtRefName: "",
        txtAgentName: "",
        txtValidity: "",
        dpValidityUpto: "",
        txtPayTerm: "",
        dpPayTermUpto: "",
        txtDeliTerm: "",
        dpDeliTermUpto: "",

        txtAddLessDiscPer: "",
        txtAddLessDiscAmt: "",
        txtAddLessDiscValue: "",
        txtBillDiscPer: "",
        txtBillDiscAmt: "",
        txtBillDiscValue: "",

        btnNew: "",
        btnModify: "",
        btnSave: "",
        btnClear: "",
        btnView: "",
        btnExportImport: "",
        btnPrint: "",
        btnCancel: "",
        btnClose: "",
        btnPrintSettings: "",
        btnHelp: "",

        txtRemark1: "",
        txtRemark2: "",
        txtRemark3: "",
        txtRemark4: "",
        txtLRNumber: "",
        dpLRDate: "",

        TabParty: "",
        TabRefVouchers: "",
        TabVoucher: "",
        TabDelivery: "",
        TabTranport: "",
        TabValidity: "",
        TabRemarks: "",
        TabSummary: "",
        TabTax: "",
        TabDiscount: "",
        TabAddLess: "",
        TabName: "Party",

        txtRefNum1: "",
        txtRefNum1Date: "",
        txtRefNum2: "",
        txtRefNum2Date: "",
        txtRefNum3: "",
        txtRefNum3Date: "",
        txtRefNum4: "",
        txtRefNum4Date: "",
        btnRefresh: "",

        producttable: "",
        txtProductName: "",
        txtQty: "",
        cbUnit: "",
        txtRate: "",
        txtDiscount: "",
        btnAdd: "",
        discType: "",
      },
      Headertab: [
        { TabId: 0, Index: 0, Show: true, tabShow: true },
        { TabId: 1, Index: 0, Show: false, tabShow: false },
        { TabId: 2, Index: 0, Show: false, tabShow: false },
        { TabId: 3, Index: 0, Show: false, tabShow: false },
        { TabId: 4, Index: 0, Show: false, tabShow: false },
        { TabId: 5, Index: 0, Show: false, tabShow: false },
        { TabId: 6, Index: 0, Show: false, tabShow: false },
      ],
      Summarytab: [true, false, false],
      Commontab: [true, false, false, false],
      TransPer: {
        IsProCode: false,
        IsItemDisc: false,
        IsCashDisc: false,
        IsFQty: false,
      },
      btnSave: true,
      btnView: true,
      btnDelete: true,
      btnConvert: true,
      btnPrint: true,
    },
    Header: {
      iNumSeqNo: "",
      strPrefix: "",
      strCompCode: "",
      strFinanFrom: "",
      strTransNo: "",
      strVouchNo: "",
      isManualCode: false,

      iVouchId: 0,
      SelectedVouchId: 0,
      iBillTypeId: 0,
      iEITypeId: 1,
      iDMId: 0,
      BtnDisable: false,
      strVouchDate: new Date().toISOString().split("T")[0],
      strPartyDCVouch: "",
      strConvertDCVouch: "",
      strInWDCVouchers: "",
      iPartyId: 0,
      strPartyName: "",
      strPartyAdd: "",
      strShippingAdd: "",
      iTaxRelId: 0,
      arrAddress: [],

      strRefNum1: "",
      disablebtn: false,
      dpRefNum1Date: new Date().toISOString().split("T")[0],
      strRefNum2: "",
      dpRefNum2Date: new Date().toISOString().split("T")[0],
      strRefNum3: "",
      dpRefNum3Date: new Date().toISOString().split("T")[0],
      strRefNum4: "",
      dpRefNum4Date: new Date().toISOString().split("T")[0],

      strRemark1: "",
      strRemark2: "",
      strRemark3: "",
      strRemark4: "",

      strValidity: "0",
      dpValDateUpto: new Date().toISOString().split("T")[0],
      strPayTerm: "0",
      dpPayTermDateUpto: new Date().toISOString().split("T")[0],
      strDeliTerm: "0",
      dpDelTermDateUpto: new Date().toISOString().split("T")[0],

      strEComGstNo: "",
      strTransport: "",
      strVehicle: "",
      strDespatch: "",
      dpDespatchDate: new Date().toISOString().split("T")[0],
      strLRNNumber: "",
      dpLRNoDate: new Date().toISOString().split("T")[0],
      strAgent: "",
      strReference: "",
      strDestination: "",
    },
    Product: {
      iProId: 0,
      strProCode: "",
      strProName: "",
      iUnitId: 0,
      strUnitName: "",
      dQty: 0,
      FQty: 0,
      dRate: 0,
      dRatewithTax: 0,
      dItemDisc: 0,
      dCashDisc: 0,
      strReason: "",
      isItemDiscPer: true,
      isCashDiscPer: true,
      strProAttDTId: "",
      strStoAttDTId: "",
      strTraAttDTId: "",
      strProductDimAttGroupId: "",
      strStorageDimAttGroupId: "",
      strTrackingDimAttGroupId: "",
      strProductDimAttId: "",
      strStorageDimAttId: "",
      strTrackingDimAttId: "",
      strProductDimAttDTId: "",
      strStorageDimAttDTId: "",
      strTrackingDimAttDTId: "",
      iRowIndex: 0,
      Remarks: {
        strRemark1: "",
        strRemark2: "",
        strRemark3: "",
        strRemark4: "",
      },
      Calculation: {
        dValue1: 0,
        dValue2: 0,
        dValue3: 0,
        dConstantValue: 0,
        dFinalValue: 0,
        strOp1: "0",
        strOp2: "0",
        strOp3: "0",
      },
      Unit: [],
      AttCol: [],
      AttVal: [],
      Product: {},
      bUpdateStatus: false,
      isDiscChanged: false,
    },
    Data: {
      arrEntDT: [],
      arrEntDTCol: [],
      arrEntDTHelp: [],
      arrEntTax: [],
      arrEntAL: [],
      arrEntPenCon: [],
      arrEntConDT: [],
    },
    Summary: {
      NetAmt: 0,
      Qty: 0,
      GoodsValue: 0,
      Discount: 0,
      Tax: 0,
      AddLess: 0,
      TCSTax: 0,
      RoundOff: 0,
    },
    Discount: {
      dBillDisPer: 0,
      dBillDisAmt: 0,
      dBillDisValue: 0,
      dALBillDisPer: 0,
      dALBillDisAmt: 0,
      dALBillDisValue: 0,
      isDiscChanged: false,
    },
    AddLess: {
      iSerVenId: 0,
      VenCode: "",
      VenName: "",
      iSerId: 0,
      SerName: "",
      SerCode: "",
      dRate: (0).toFixed(2),
      dDisc: 0,
      IsDiscPer: true,
      iRowIndex: 0,
      bUpdateStatus: false,
      Remarks: {
        strRemark1: "",
        strRemark2: "",
        strRemark3: "",
        strRemark4: "",
      },
      Calculation: {
        dValue1: 0,
        dValue2: 0,
        dValue3: 0,
        dConstantValue: 0,
        dFinalValue: 0,
        strOp1: "0",
        strOp2: "0",
        strOp3: "0",
      },
    },
    JobWork: {
      JobId: "0",
      strJobName: "",
      strJobCode: "",
      arrJobWork: [],
      strRGRemark1: "",
      strRGRemark2: "",
      strRGRemark3: "",
      strRGRemark4: "",
      strRGHours: "",
      dRate: Number((0).toFixed(2)),
      dRatewithTax: Number((0).toFixed(2)),
      iRowIndex: 0,
      bUpdateStatus: false,
    },
  },
  reducers: {
    setdafaultparems: (state, { payload }) => {
      const { Data, tabBtn, TransPaprem } = payload;
      state.Common.Controller = TransPaprem.Controller;
      state.Common.iScrId = TransPaprem.iScrId;
      state.Common.strScrName = TransPaprem.strScrName;
      state.Common.strScrType = TransPaprem.strScrType;
      state.Common.PartyScrId = TransPaprem.iPartyScrId;
      state.Common.VenTypeId = TransPaprem.iVenType;
      state.Common.ColName = TransPaprem.strColName;
      state.Common.arrValidation = Data.Transtab;

      state.Common.arrtransper = Data.TransPer;
      state.Common.arrfieldPer = Data.FieldPer;
      state.Common.TransPer = Validate_TransPermission(Data.TransPer);
      const BtnPerm = Validate_BtnPermission(
        TransPaprem.strScrType,
        TransPaprem.iScrId,
        0,
        false
      );
      state.Common.btnSave = BtnPerm.btnSave;
      state.Common.btnView = BtnPerm.btnView;
      state.Common.btnDelete = BtnPerm.btnDelete;
      state.Common.btnConvert = BtnPerm.btnConvert;
      state.Common.btnPrint = BtnPerm.btnPrint;

      state.Common.arrtaborder = Validate_Taborder(
        Data.Transtab,
        JSON.parse(JSON.stringify(state.Common.arrtaborder))
      );

      state.Common.DelMode = Data.DelMode;
      state.Common.Headertab = tabBtn;

      state.Common.strSetCurrentStatus = Data.CurrStatus;
      state.Common.iTaxCalcOnId = Data.TaxCalcOnId;
      state.Common.iALBillDiscId = Data.ALBillDiscId;

      state.Header.iNumSeqNo = Data.NoFormat[0].iNumSeqNo;
      state.Header.strPrefix = Data.NoFormat[0].strPrefix;
      state.Header.strCompCode = Data.NoFormat[0].strCompCode;
      state.Header.strFinanFrom = Data.NoFormat[0].strFinanFrom;
      state.Header.strTransNo = Data.NoFormat[0].strTransNo;
      state.Header.strVouchNo = Data.NoFormat[0].strUserNo;
      state.Header.isManualCode = !Data.NoFormat[0].isManualNumber;

      switch (TransPaprem.iScrId) {
        case 71:
        case 74:
          state.Common.IsJobWorkDc = true;
          state.Common.IsHideBill = true;
          state.Common.IsConvertDc = true;
          state.Common.IsPartyDc = true;
          Assign_Defaultdata(state, Data);
          break;
        case 12:
        case 18:
          state.Common.IsHideBill = true;
          Assign_Defaultdata(state, Data);
          break;
        case 73:
          state.Common.IsHideInvDc = false;
          state.Common.EnableReceipt = Data.ReceiptShow;
          Assign_Defaultdata(state, Data);
          break;
        case 76:
          state.Common.IsHideInvDc = false;
          state.Common.EnablePayment = Data.PaymentShow;
          Assign_Defaultdata(state, Data);
          break;
        case 72:
        case 75:
          state.Common.IsHideBill = true;
          state.Common.IsHideJob = false;
          state.Common.IsQtyReadonly = true;
          state.Common.IsJobWorkDc = true;
          state.JobWork.arrJobWork = Data.JobWork;
          break;
        case 11:
          state.Common.EnablePayment = Data.PaymentShow;
          Assign_Defaultdata(state, Data);
          break;
        case 17:
          state.Common.EnableReceipt = Data.ReceiptShow;
          state.Common.IsPrint = Data.AutoPrint;
          if (Data.isDefaultCust) {
            // Load Default Customer for Sales Invoice
            state.Header.iPartyId = Data.PartyInfo;
            state.Header.strPartyName = Data.PartyInfo[0];
            state.Header.strPartyAdd = `${Data.PartyInfo[1]} ${Data.PartyInfo[2]} ${Data.PartyInfo[3]}`;
            state.Header.strShippingAdd = Data.strShippAdd;
            state.Header.iTaxRelId = Data.iTaxRelId;
            state.Header.arrAddress = Data.EntShipAdd;
          }
          Assign_Defaultdata(state, Data);
          break;
        default:
          state.Common.IsHideBill = false;
          state.Common.IsHideInvDc = true;
          state.Common.IsHideJob = true;
          state.Common.IsQtyReadonly = false;
          Assign_Defaultdata(state, Data);
          break;
      }
    },
    setExpenseIncomeDefault: (state, { payload }) => {
      const { Trans } = payload;
      state.Common.Controller = Trans.Controller;
      state.Common.iScrId = Trans.iScrId;
      state.Common.strScrType = Trans.strScrType;
      state.Common.strScrName = Trans.strScrName;
      state.Common.PartyPlaceHolder = "Enter Service Vendor Name";
      state.Common.PartyScrId = Trans.iPartyScrId;
      state.Common.VenTypeId = Trans.iVenType;
      state.Common.arrValidation = payload.Transtab;
      state.Common.arrtransper = payload.TransPer;
      const BtnPerm = Validate_BtnPermission(
        Trans.strScrType,
        Trans.iScrId,
        0,
        false
      );
      state.Common.btnSave = BtnPerm.btnSave;
      state.Common.btnView = BtnPerm.btnView;
      state.Common.btnDelete = BtnPerm.btnDelete;
      state.Common.btnConvert = BtnPerm.btnConvert;
      state.Common.btnPrint = BtnPerm.btnPrint;
      state.Common.Headertab = payload.tabBtn;
      state.Common.strSetCurrentStatus = payload.CurrStatus;
      state.Common.iTaxCalcOnId = payload.TaxCalcOnId;
      state.Common.iALBillDiscId = payload.ALBillDiscId;

      state.Common.arrtaborder = Validate_Taborder(
        payload.Transtab,
        JSON.parse(JSON.stringify(state.Common.arrtaborder))
      );
      state.Header.iNumSeqNo = payload.NoFormat[0].iNumSeqNo;
      state.Header.strPrefix = payload.NoFormat[0].strPrefix;
      state.Header.strCompCode = payload.NoFormat[0].strCompCode;
      state.Header.strFinanFrom = payload.NoFormat[0].strFinanFrom;
      state.Header.strTransNo = payload.NoFormat[0].strTransNo;
      state.Header.strVouchNo = payload.NoFormat[0].strUserNo;
      state.Header.isManualCode = !payload.NoFormat[0].isManualNumber;

      state.Common.BillType = payload.BillType;
      state.Common.TaxType = payload.TaxType;
      state.Common.ExpenseType = payload.EIType;
      state.Common.VendorType = payload.VenType;
      state.Common.arrService = payload.Service;
      state.Header.iBillTypeId = payload.BillTypeId;
      state.Common.EnablePayment = payload.PaymentShow;
      state.Common.EnableReceipt = payload.ReceiptShow;
    },
    Update_NoFormate: (state, { payload }) => {
      state.Header.iNumSeqNo = payload.iNumSeqNo;
      state.Header.strPrefix = payload.strPrefix;
      state.Header.strCompCode = payload.strCompCode;
      state.Header.strFinanFrom = payload.strFinanFrom;
      state.Header.strTransNo = payload.strTransNo;
      state.Header.strVouchNo = payload.strUserNo;
      state.Header.isManualCode = !payload.isManualNumber;
    },
    Vendore_TypeOnchange: (state, { payload }) => {
      state.Common.VenTypeId = payload;
    },
    setScrId: (state, { payload }) => {
      state.Common.iScrId = payload;
      state.Common.strScrName = "General";
    },
    setStockTrans: (state, { payload }) => {
      state.Common.iScrId = payload.iScrId;
      state.Common.strScrType = payload.strScrType;
      state.Common.strScrName = payload.strScrName;
      state.Common.IsJobWorkDc = true;
      state.Header.iPartyId = 0;
      state.Header.iTaxRelId = 1;
    },
    setBOMTrans: (state, { payload }) => {
      state.Common.iScrId = payload.iScrId;
      state.Common.strScrType = payload.strScrType;
      state.Common.strScrName = payload.strScrName;
      state.Header.iPartyId = 0;
      state.Header.iTaxRelId = 1;
    },
    setProductionTrans: (state, { payload }) => {
      state.Common.iScrId = payload.iScrId;
      state.Common.strScrType = payload.strScrType;
      state.Common.strScrName = payload.strScrName;
      state.Header.iPartyId = 0;
      state.Header.iTaxRelId = 1;
    },
    setPayRecepDefault: (state, { payload }) => {
      state.Common.strScrType = payload.ScrType;
      state.Common.PartyScrId = payload.PartyScrId;
      state.Common.VenTypeId = payload.VenType;
      state.Common.ColName = payload.ColName;
    },
    Update_BtnPermission: (state, { payload }) => {
      state.Common.btnSave = payload.btnSave;
      state.Common.btnView = payload.btnView;
      state.Common.btnDelete = payload.btnDelete;
      state.Common.btnConvert = payload.btnConvert;
      state.Common.btnPrint = payload.btnPrint;
    },
    settableCol: (state, { payload }) => {
      state.Data.arrEntDTCol = payload;
    },
    setParty: (state, action) => {
      const res = action.payload;
      state.Header.iPartyId = res.iPartyId;
      state.Header.strPartyName = res.strPartyName;
      state.Header.strPartyAdd = res.Address;
      state.Header.strShippingAdd = res.ShippAdd;
      state.Header.iTaxRelId = res.iTaxRelId;
      state.Header.arrAddress = res.arrShipAdd;

      state.Data.arrEntDT = [];
      state.Data.arrEntTax = [];
      state.Data.arrEntAL = [];
      state.Data.arrEntPenCon = [];

      state.Summary.NetAmt = (0).toFixed(2);
      state.Summary.Qty = (0).toFixed(2);
      state.Summary.Discount = (0).toFixed(2);
      state.Summary.GoodsValue = (0).toFixed(2);
      state.Summary.Tax = (0).toFixed(2);
      state.Summary.AddLess = (0).toFixed(2);
      state.Summary.TCSTax = (0).toFixed(2);
      state.Summary.RoundOff = (0).toFixed(2);
      state.Discount.dBillDisValue = (0).toFixed(2);
      state.Discount.dBillDisPer = (0).toFixed(2);
      state.Discount.dBillDisAmt = (0).toFixed(2);
      state.Discount.dALBillDisAmt = (0).toFixed(2);
      state.Discount.dALBillDisPer = (0).toFixed(2);
      state.Discount.dBillDisValue = (0).toFixed(2);
      state.Product = TransProduct;
    },
    Update_ShipAdd: (state, { payload }) => {
      state.Header.strShippingAdd = payload;
    },
    Input_Onchange: (state, { payload }) => {
      state.Header[payload.Name] = payload.Value;
    },
    ProductInput_Onchange: (state, { payload }) => {
      state.Product[payload.Name] = payload.Value;
    },
    ProductRate_Onchange: (state, { payload }) => {
      if (payload.Name === "dRate") {
        state.Product.dRate = Number(payload.Value);
        state.Product.dRatewithTax = Number((0).toFixed(2));
      } else {
        state.Product.dRate = Number((0).toFixed(2));
        state.Product.dRatewithTax = Number(payload.Value);
      }
    },
    ProductRate_LostFocus: (state, { payload }) => {
      if (payload.Name === "dRate") {
        state.Product.dRate =
          Number(payload.Value) === 0
            ? Number((0).toFixed(2))
            : Number(payload.Value);
      } else {
        state.Product.dRatewithTax =
          Number(payload.Value) === 0
            ? Number((0).toFixed(2))
            : Number(payload.Value);
      }
    },
    JobWorkInput_Onchange: (state, { payload }) => {
      state.JobWork[payload.Name] = payload.Value;
    },
    Merge_ConvertedVouchers: (state, { payload }) => {
      const { EntConvHT, Summary } = payload;
      state.Data.arrEntDT = payload.EntDT;
      state.Data.arrEntTax = payload.EntTaxDT;
      state.Data.arrEntAL = payload.EntALDT;
      state.Data.arrEntPenCon = payload.EntPenDT;
      state.Header.strConvertDCVouch = payload.ConvertedDC;
      state.Header.strPartyDCVouch = payload.PartyDc;
      state.Header.strInWDCVouchers = payload.InWDC;

      state.Summary.NetAmt = Summary.netAmt;
      state.Summary.Qty = Summary.qty;
      state.Summary.Discount = Summary.totalDiscount;
      state.Summary.GoodsValue = Summary.goodsValue;
      state.Summary.Tax = Summary.totalTax;
      state.Summary.AddLess = Summary.totalAddLess;
      state.Summary.TCSTax = Summary.totalTCSTax;
      state.Summary.RoundOff = Summary.roundOff;
      if (EntConvHT.length > 0) {
        state.Header.strRemark1 = EntConvHT[0].remark1;
        state.Header.strRemark2 = EntConvHT[0].remark2;
        state.Header.strRemark3 = EntConvHT[0].remark3;
        state.Header.strRemark4 = EntConvHT[0].remark4;
        state.Header.strShippingAdd = EntConvHT[0].shippingAdd;
      }
    },
    Select_Product: (state, { payload }) => {
      const { ProInfo, Data } = payload;
      state.Product.iProId = ProInfo.proId;
      state.Product.strProCode = ProInfo.proCode;
      state.Product.strProName = ProInfo.proName;
      state.Product.iUnitId = ProInfo.unitId;
      state.Product.strUnitName = ProInfo.unitName;
      state.Product.dQty = 1;
      state.Product.FQty = 0;
      state.Product.dRate =
        state.Common.strScrType === "Purchase"
          ? ProInfo.costRate
          : ProInfo.priceRate;
      state.Product.dRatewithTax = 0;
      state.Product.dItemDisc = ProInfo.discount;
      state.Product.dCashDisc = 0;
      state.Product.isItemDiscPer = true;
      state.Product.isCashDiscPer = true;
      state.Product.strProAttDTId = "";
      state.Product.strStoAttDTId = "";
      state.Product.strTraAttDTId = "";
      state.Product.Unit = Data.Unit;
      state.Product.bUpdateStatus = false;

      if (Data.IsAttPro) {
        state.Product.AttCol = Data.AttColumns;
        state.Product.AttVal = Data.AttValues;
      } else {
        state.Product.dRate = Data.RateWithoutTax;
      }
    },
    Select_ProductionProduct: (state, { payload }) => {
      state.Product.strProductDimAttGroupId = payload.strProductDimAttGroupId;
      state.Product.strStorageDimAttGroupId = payload.strStorageDimAttGroupId;
      state.Product.strTrackingDimAttGroupId = payload.strTrackingDimAttGroupId;
      state.Product.strProductDimAttId = payload.strProductDimAttId;
      state.Product.strStorageDimAttId = payload.strStorageDimAttId;
      state.Product.strTrackingDimAttId = payload.strTrackingDimAttId;
      state.Product.strProductDimAttDTId = payload.strProductDimAttDTId;
      state.Product.strStorageDimAttDTId = payload.strStorageDimAttDTId;
      state.Product.strTrackingDimAttDTId = payload.strTrackingDimAttDTId;
    },
    Select_JobWork: (state, { payload }) => {
      state.JobWork.JobId = Number(payload.iId);
      state.JobWork.strJobName = payload.strName;
      state.JobWork.strJobCode = payload.strCode;
    },
    Modify_JobWork: (state, { payload }) => {
      const { JobInfo, Index } = payload;
      state.JobWork.JobId = Number(JobInfo.jobId);
      state.JobWork.strJobName = JobInfo.jobName;
      state.JobWork.strJobCode = JobInfo.jobCode;
      state.JobWork.strRGRemark1 = JobInfo.remark1;
      state.JobWork.strRGRemark2 = JobInfo.remark2;
      state.JobWork.strRGRemark3 = JobInfo.remark3;
      state.JobWork.strRGRemark4 = JobInfo.remark4;
      state.JobWork.strRGHours = JobInfo.hours;
      state.JobWork.dRate = Number(JobInfo.amount);
      state.JobWork.iRowIndex = Index;
      state.JobWork.bUpdateStatus = true;
    },
    Add_JobWork: (state, { payload }) => {
      state.Data.arrEntDT = payload;
      state.JobWork = {
        JobId: "0",
        strJobName: "",
        strJobCode: "",
        arrJobWork: [],
        strRGRemark1: "",
        strRGRemark2: "",
        strRGRemark3: "",
        strRGRemark4: "",
        strRGHours: "",
        dRate: Number((0).toFixed(2)),
        dRatewithTax: Number((0).toFixed(2)),
        iRowIndex: 0,
        bUpdateStatus: false,
      };
    },
    Select_Unit: (state, { payload }) => {
      const { dPriceRate, UnitId } = payload;
      state.Product.iUnitId = UnitId;
      state.Product.dRate = dPriceRate;
      state.Product.dRatewithTax = Number(0).toFixed(2);
    },
    Modify_Product: (state, { payload }) => {
      const { ProInfo, Unit, Index } = payload;
      state.Product.iProId = ProInfo.proId;
      state.JobWork.JobId = ProInfo.jobId;
      state.Product.strProCode = ProInfo.proCode;
      state.Product.strProName = ProInfo.proName;
      state.Product.iUnitId = ProInfo.unitId;
      state.Product.strUnitName = ProInfo.unitName;
      state.Product.dQty = ProInfo.qty;
      state.Product.FQty = ProInfo.fQty;
      state.Product.dRate = ProInfo.rateWithoutTax;
      state.Product.dRatewithTax = ProInfo.rateWithTax;
      state.Product.dItemDisc = ProInfo.itemDiscPer + ProInfo.itemDiscAmt;
      state.Product.dCashDisc = ProInfo.cashDiscPer + ProInfo.cashDiscAmt;
      state.Product.isItemDiscPer =
        ProInfo.itemDiscPer >= ProInfo.itemDiscAmt ? true : false;
      state.Product.isCashDiscPer =
        ProInfo.cashDiscPer >= ProInfo.cashDiscAmt ? true : false;
      state.Product.strProAttDTId = "";
      state.Product.strStoAttDTId = "";
      state.Product.strTraAttDTId = "";
      state.Product.strReason = ProInfo.reason;
      state.Product.iRowIndex = Index;
      state.Product.bUpdateStatus = true;
      state.Product.Remarks = {
        strRemark1: ProInfo.remark1,
        strRemark2: ProInfo.remark2,
        strRemark3: ProInfo.remark3,
        strRemark4: ProInfo.remark4,
      };
      state.Product.Calculation = {
        dValue1: ProInfo.value1,
        dValue2: ProInfo.value2,
        dValue3: ProInfo.value3,
        dConstantValue: ProInfo.constantValue,
        dFinalValue: ProInfo.finalValue,
        strOp1: "",
        strOp2: "",
        strOp3: "",
      };
      state.Product.Unit = Unit;
      state.Product.AttCol = [];
      state.Product.AttVal = [];
      state.Product.Product = {};
    },
    Modify_Service: (state, { payload }) => {
      const { ServInfo, Index } = payload;
      state.AddLess.iSerId = ServInfo.serId;
      state.AddLess.SerName = ServInfo.serName;
      state.AddLess.dRate = ServInfo.rateWithoutTax;
      state.AddLess.dDisc = ServInfo.itemDiscPer + ServInfo.itemDiscAmt;
      state.AddLess.IsDiscPer =
        ServInfo.itemDiscPer >= ServInfo.itemDiscAmt ? true : false;
      state.AddLess.iRowIndex = Index;
      state.AddLess.bUpdateStatus = true;
      state.AddLess.Remarks = {
        strRemark1: ServInfo.remark1,
        strRemark2: ServInfo.remark2,
        strRemark3: ServInfo.remark3,
        strRemark4: ServInfo.remark4,
      };
      state.AddLess.Calculation = {
        dValue1: ServInfo.value1,
        dValue2: ServInfo.value2,
        dValue3: ServInfo.value3,
        dConstantValue: ServInfo.constantValue,
        dFinalValue: ServInfo.finalValue,
        strOp1: "",
        strOp2: "",
        strOp3: "",
      };
    },
    Modify_ProductionProduct: (state, { payload }) => {
      state.Product.iProId = payload.iProId;
      state.Product.strProCode = payload.strProCode;
      state.Product.strProName = payload.strProName;
      state.Product.iUnitId = payload.iUnitId;
      state.Product.Unit = payload.Unit;
      state.Product.strProCode = payload.strProductDimAttGroupId;
      state.Product.strProductDimAttGroupId = payload.strProductDimAttGroupId;
      state.Product.strStorageDimAttGroupId = payload.strStorageDimAttGroupId;
      state.Product.strTrackingDimAttGroupId = payload.strTrackingDimAttGroupId;
      state.Product.strProductDimAttId = payload.strProductDimAttId;
      state.Product.strStorageDimAttId = payload.strStorageDimAttId;
      state.Product.strTrackingDimAttId = payload.strTrackingDimAttId;
      state.Product.strProductDimAttDTId = payload.strProductDimAttDTId;
      state.Product.strStorageDimAttDTId = payload.strStorageDimAttDTId;
      state.Product.strTrackingDimAttDTId = payload.strTrackingDimAttDTId;
    },
    setAttValues: (state, { payload }) => {
      state.Product.strProAttDTId = payload.strProAttDTId;
      state.Product.strStoAttDTId = payload.strStoAttDTId;
      state.Product.strTraAttDTId = payload.strTraAttDTId;
      state.Product.strProName = state.Product.strProName + payload.strAttValue;
    },
    AddProduct: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;
      state.Data.arrEntPenCon = Data.EntPenDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.Discount.dBillDisValue = Data.dDiscValue;
      state.Product = TransProduct;
    },
    Add_Service: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
      state.Discount.dBillDisValue = Data.dDiscValue;
      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iSerId: 0,
        SerName: "",
        SerCode: "",
        dRate: (0).toFixed(2),
        dDisc: 0,
        IsDiscPer: true,
        iRowIndex: 0,
        bUpdateStatus: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
      };
    },
    AddStockProduct: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.RoundOff = Data.Summary.roundOff;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Product = TransProduct;
    },
    AddOpenStockProduct: (state, { payload }) => {
      state.Data.arrEntDT = payload;
      state.Product = TransProduct;
    },
    AddBOMProduct: (state, { payload }) => {
      state.Data.arrEntDT = payload;
      state.Product = TransProduct;
    },
    Remove_product: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;
      state.Data.arrEntPenCon = Data.EntPenDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.Discount.dBillDisValue = Data.dDiscValue;
      state.Product = TransProduct;
    },
    setRootCalculation: (state, action) => {
      state.Product.Calculation = action.payload;
    },
    setItemRemark: (state, action) => {
      state.Product.Remarks = action.payload;
    },
    Select_ReturnProduct: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
      state.Product = TransProduct;
    },
    Update_DiscountCalculation: (state, { payload }) => {
      const { Data, Disc } = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.Discount.isDiscChanged = false;
      if (Disc === "BillDicPer" || Disc === "BillDicAmt") {
        state.Discount.dBillDisPer = Data.dDiscPer;
        state.Discount.dBillDisAmt = Data.dDiscAmt;
        state.Discount.dBillDisValue = Data.dDiscValue;
      } else {
        state.Discount.dALBillDisPer = Data.dDiscPer;
        state.Discount.dALBillDisAmt = Data.dDiscAmt;
        state.Discount.dALBillDisValue = Data.dDiscValue;
      }
    },
    Discount_onchanged: (state, { payload }) => {
      if (payload.IsChanged) {
        switch (payload.Value.target.name) {
          case "dBillDisAmt":
            state.Discount.dBillDisAmt = payload.Value.target.value;
            state.Discount.dBillDisPer = 0;
            break;
          case "dALBillDisPer":
            state.Discount.dALBillDisPer = payload.Value.target.value;
            state.Discount.dALBillDisAmt = 0;
            break;
          case "dALBillDisAmt":
            state.Discount.dALBillDisAmt = payload.Value.target.value;
            state.Discount.dALBillDisPer = 0;
            break;
          default:
            state.Discount.dBillDisPer = payload.Value.target.value;
            state.Discount.dBillDisAmt = 0;
            break;
        }
        state.Discount.isDiscChanged = payload.IsChanged;
      }
    },
    AddLess_Onchange: (state, { payload }) => {
      state.AddLess[payload.Name] = payload.Value;
    },
    AddLess_LostFocus: (state, { payload }) => {
      state.Product.dServAmt =
        Number(payload.target.value) === 0
          ? Number((0).toFixed(2))
          : Number(payload.target.value);
    },
    Select_ServiceVendore: (state, { payload }) => {
      state.AddLess.iSerVenId = payload.partyId;
      state.AddLess.VenCode = payload.partyCode;
      state.AddLess.VenName = payload.partyName;
    },
    Update_ServiceVendor: (state, { payload }) => {
      state.Common.arrVendore = payload.EntVendore;
      state.AddLess.iSerVenId = payload.VenId;
    },
    Select_Service: (state, { payload }) => {
      state.AddLess.iSerId = payload.iId;
      state.AddLess.SerCode = payload.strCode;
      state.AddLess.SerName = payload.strName;
    },
    Update_Service: (state, { payload }) => {
      state.Common.arrService = payload.EntService;
      state.AddLess.iSerId = payload.ServId;
    },
    AddUpdate_AddLess: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iSerId: 0,
        SerName: "",
        SerCode: "",
        dRate: (0).toFixed(2),
        dDisc: 0,
        IsDiscPer: true,
        iRowIndex: 0,
        bUpdateStatus: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
      };
    },
    Modify_AddLess: (state, { payload }) => {
      const { Data, Index } = payload;
      state.AddLess.iRowIndex = Index;
      state.AddLess.iSerVenId = Data.venId;
      state.AddLess.VenName = Data.venName;
      state.AddLess.VenCode = Data.venCode;
      state.AddLess.iSerId = Data.serId;
      state.AddLess.SerName = Data.serName;
      state.AddLess.SerCode = Data.serCode;
      state.AddLess.dRate = Number(Data.rateWithoutTax).toFixed(2);
      state.AddLess.bUpdateStatus = true;
    },
    Remove_AddLess: (state, { payload }) => {
      const Data = payload;
      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTaxDT;
      state.Data.arrEntAL = Data.EntALDT;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;
    },
    Modify_Vouchers: (state, { payload }) => {
      const { Data, VouchId } = payload;
      const EntHT = Data.EntHT[0];

      state.Header.iVouchId = VouchId;
      state.Header.iNumSeqNo = EntHT.iNumSeqNo;
      state.Header.strPrefix = EntHT.strPrefix;
      state.Header.strCompCode = EntHT.strCompCode;
      state.Header.strFinanFrom = EntHT.strFinanFrom;
      state.Header.strTransNo = EntHT.strTransNo;
      state.Header.strVouchNo = EntHT.strVouchNo;
      state.Header.strVouchDate = EntHT.strVouchDate;

      state.Header.strRefNum1 = EntHT.strRefNum1;
      state.Header.strRefNum2 = EntHT.strRefNum2;
      state.Header.strRefNum3 = EntHT.strRefNum3;
      state.Header.strRefNum4 = EntHT.strRefNum4;
      state.Header.dpRefNum1Date = EntHT.dpRefNum1Date;
      state.Header.dpRefNum2Date = EntHT.dpRefNum2Date;
      state.Header.dpRefNum3Date = EntHT.dpRefNum3Date;
      state.Header.dpRefNum4Date = EntHT.dpRefNum4Date;
      state.Header.strRemark1 = EntHT.strRemark1;
      state.Header.strRemark2 = EntHT.strRemark2;
      state.Header.strRemark3 = EntHT.strRemark3;
      state.Header.strRemark4 = EntHT.strRemark4;
      state.Header.iDMId = EntHT.iDMId;

      switch (state.Common.iScrId) {
        case 71:
        case 72:
        case 74:
        case 75:
          state.Header.strConvertDCVouch = EntHT.strConvertDCVouch;
          state.Header.strPartyDCVouch = EntHT.strPartyDCVouch;
          break;
        case 73:
        case 76:
          state.Header.strInWDCVouchers = EntHT.inwDCConvertedVouchers;
          state.Header.strConvertDCVouch = EntHT.outWDCConvertedVouchers;
          state.Header.strPartyDCVouch = EntHT.partyConvertedVouchers;
          state.Header.iBillTypeId = EntHT.iBillTypeId;
          state.Header.iTaxRelId = EntHT.iTaxTypeId;
          state.Common.iALBillDiscId = EntHT.iAddLessBillDiscId;
          state.Common.iTaxCalcOnId = EntHT.iTaxCalcOnId;

          state.Header.strValidity = EntHT.strValidity;
          state.Header.dpValDateUpto = EntHT.dpValDateUpto;
          state.Header.strPayTerm = EntHT.strPayTerm;
          state.Header.dpPayTermDateUpto = EntHT.dpPayTermDateUpto;
          state.Header.strDeliTerm = EntHT.strDeliTerm;
          state.Header.dpDelTermDateUpto = EntHT.dpDelTermDateUpto;

          state.Discount.dBillDisPer = EntHT.dTBillDiscPer;
          state.Discount.dBillDisAmt = EntHT.dTBillDiscAmt;
          state.Discount.dBillDisValue = EntHT.dTBillDiscValue;
          state.Discount.dALBillDisPer = EntHT.dTAddLessDiscPer;
          state.Discount.dALBillDisAmt = EntHT.dTAddLessDiscAmt;
          state.Discount.dALBillDisValue = EntHT.dTAddLessDiscValue;
          break;
        default:
          state.Header.iBillTypeId = EntHT.iBillTypeId;
          state.Header.iTaxRelId = EntHT.iTaxTypeId;
          state.Header.strConvertDCVouch = EntHT.strConvertedVouchers;
          state.Header.strPartyDCVouch = EntHT.strPartyConvertedVouchers;
          state.Common.iALBillDiscId = EntHT.iAddLessBillDiscId;
          state.Common.iTaxCalcOnId = EntHT.iTaxCalcOnId;

          state.Header.strValidity = EntHT.strValidity;
          state.Header.dpValDateUpto = EntHT.dpValDateUpto;
          state.Header.strPayTerm = EntHT.strPayTerm;
          state.Header.dpPayTermDateUpto = EntHT.dpPayTermDateUpto;
          state.Header.strDeliTerm = EntHT.strDeliTerm;
          state.Header.dpDelTermDateUpto = EntHT.dpDelTermDateUpto;
          state.Header.strEComGstNo = EntHT.strEComGstNo;

          state.Discount.dBillDisPer = EntHT.dTBillDiscPer;
          state.Discount.dBillDisAmt = EntHT.dTBillDiscAmt;
          state.Discount.dBillDisValue = EntHT.dTBillDiscValue;
          state.Discount.dALBillDisPer = EntHT.dTAddLessDiscPer;
          state.Discount.dALBillDisAmt = EntHT.dTAddLessDiscAmt;
          state.Discount.dALBillDisValue = EntHT.dTAddLessDiscValue;
          break;
      }

      state.Header.strTransport = EntHT.strTransport;
      state.Header.strVehicle = EntHT.strVehicle;
      state.Header.strDespatch = EntHT.strDespatch;
      state.Header.dpDespatchDate = EntHT.dpDespatchDate;
      state.Header.strLRNNumber = EntHT.strLRNNumber;
      state.Header.dpLRNoDate = EntHT.dpLRNoDate;
      state.Header.strAgent = EntHT.strAgent;
      state.Header.strReference = EntHT.strReference;
      state.Header.strDestination = EntHT.strDestination;

      state.Header.strPartyName = Data.PartyInfo[0];
      state.Header.strPartyAdd = Data.PartyInfo[1];
      state.Header.iPartyId = EntHT.iPartyId;
      state.Header.strShippingAdd = EntHT.strShippingAdd;

      state.Data.arrEntDT = Data.EntDT;
      state.Data.arrEntTax = Data.EntTax;
      state.Data.arrEntPenCon = Data.EntPenCon;
      state.Data.arrEntAL = Data.EntAL;
      state.Data.arrEntDTHelp = Data.EntDTHelp;

      state.Summary.NetAmt = Data.Summary.netAmt;
      state.Summary.Qty = Data.Summary.qty;
      state.Summary.Discount = Data.Summary.totalDiscount;
      state.Summary.GoodsValue = Data.Summary.goodsValue;
      state.Summary.Tax = Data.Summary.totalTax;
      state.Summary.AddLess = Data.Summary.totalAddLess;
      state.Summary.TCSTax = Data.Summary.totalTCSTax;
      state.Summary.RoundOff = Data.Summary.roundOff;

      if (
        state.Common.strScrType === "Sales" ||
        state.Common.strScrType === "Purchase"
      )
        state.Header.BtnDisable = !Data.IsModify;
      else state.Header.BtnDisable = Data.IsModify;
    },
    Modify_ExpenseIncome: (state, { payload }) => {
      const EntHT = payload.EntHT[0];
      state.Header.iVouchId = EntHT.iEIId;
      state.Header.iNumSeqNo = EntHT.iNumSeqNo;
      state.Header.strPrefix = EntHT.strPrefix;
      state.Header.strCompCode = EntHT.strCompCode;
      state.Header.strFinanFrom = EntHT.strFinanFrom;
      state.Header.strTransNo = EntHT.strTransNo;
      state.Header.strVouchNo = EntHT.strVouchNo;
      state.Header.strVouchDate = EntHT.strVouchDate;

      state.Header.iBillTypeId = EntHT.iBillTypeId;
      state.Header.iTaxRelId = EntHT.iTaxTypeId;
      state.Common.iALBillDiscId = 0;
      state.Common.iTaxCalcOnId = EntHT.iTaxCalcOnId;

      state.Header.strPartyName = payload.PartyInfo[0];
      state.Header.strPartyAdd = payload.PartyInfo[1];
      state.Header.iPartyId = EntHT.iPartyId;
      state.Header.strShippingAdd = "";

      state.Header.strRemark1 = EntHT.strRemark1;
      state.Header.strRemark2 = EntHT.strRemark2;
      state.Header.strRemark3 = EntHT.strRemark3;
      state.Header.strRemark4 = EntHT.strRemark4;

      state.Summary.NetAmt = payload.Summary.netAmt;
      state.Summary.Qty = payload.Summary.qty;
      state.Summary.Discount = payload.Summary.totalDiscount;
      state.Summary.GoodsValue = payload.Summary.goodsValue;
      state.Summary.Tax = payload.Summary.totalTax;
      state.Summary.RoundOff = payload.Summary.roundOff;

      state.Discount.dBillDisPer = EntHT.dTBillDiscPer;
      state.Discount.dBillDisAmt = EntHT.dTBillDiscAmt;
      state.Discount.dBillDisValue = EntHT.dTBillDiscValue;

      state.Data.arrEntDT = payload.EntDT;
      state.Data.arrEntTax = payload.EntTaxDT;
    },
    Clear_Product: (state) => {
      state.Product = TransProduct;
    },
    Clear_Service: (state) => {
      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iSerId: 0,
        SerName: "",
        SerCode: "",
        dRate: (0).toFixed(2),
        dDisc: 0,
        IsDiscPer: true,
        iRowIndex: 0,
        bUpdateStatus: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
      };
    },
    Clear_Transaction: (state) => {
      state.Common = {
        Controller: state.Common.Controller,
        iScrId: state.Common.iScrId,
        strScrType: state.Common.strScrType,
        strScrName: state.Common.strScrName,

        VenTypeId: 0,
        PartyScrId: 0,
        ColName: "",
        PartyScrName: "",
        PartyPlaceHolder: "",
        JSScrId: 0,
        JSType: "",

        iTaxCalcOnId: 0,
        iALBillDiscId: 0,
        MinRow: 5,
        iPrintTempId: 0,

        strSetCurrentStatus: "",

        EnableReceipt: false,
        EnablePayment: false,
        IsPrint: false,
        IsTinder: false,
        IsHideBill: false,
        IsHideJob: true,
        IsHideInvDc: true,
        IsConvertDc: false,
        IsPartyDc: false,
        IsJobWorkDc: false,

        IsVenSearch: false,
        IsSerVendor: false,
        IsProSearch: false,
        IsVenCreation: false,
        IsShippAdd: false,
        IsVouchNo: false,
        IsProduct: false,
        IsProRemark: false,
        IsProCal: false,
        IsConvertion: false,
        IsReceipt: false,
        IsPayment: false,
        IsHideParty: false,
        IsPendinCon: false,
        IsTransView: false,
        IsEmail: false,
        IsWhatsApp: false,
        IsEInvoice: false,
        IsPrintSett: false,
        IsPrintShow: false,
        IsReturnPro: false,
        IsQtyReadonly: false,
        IsAttSearch: false,
        IsNewPro: false,
        IsJobSearch: false,

        BillType: [],
        TaxType: [],
        DelMode: [],
        arrVendore: [],
        arrService: [],
        ExpenseType: [],
        VendorType: [],
        arrValidation: [],
        arrtransper: [],
        arrfieldPer: [],
        arrTableCol: [],
        arrtaborder: {
          txtPartyName: "",
          txtBillAddress: "",
          txtShippingAdd: "",
          cbBillType: "",
          cbTaxType: "",
          txtTransNo: "",
          txtInvNo: "",
          dpInvDate: "",
          txtTransport: "",
          txtDespatch: "",
          dpDespatchDate: "",
          txtDestination: "",
          txtVehicle: "",
          cbDeliMode: "",
          txtRefName: "",
          txtAgentName: "",
          txtValidity: "",
          dpValidityUpto: "",
          txtPayTerm: "",
          dpPayTermUpto: "",
          txtDeliTerm: "",
          dpDeliTermUpto: "",

          txtAddLessDiscPer: "",
          txtAddLessDiscAmt: "",
          txtAddLessDiscValue: "",
          txtBillDiscPer: "",
          txtBillDiscAmt: "",
          txtBillDiscValue: "",

          btnNew: "",
          btnModify: "",
          btnSave: "",
          btnClear: "",
          btnView: "",
          btnExportImport: "",
          btnPrint: "",
          btnCancel: "",
          btnClose: "",
          btnPrintSettings: "",
          btnHelp: "",

          txtRemark1: "",
          txtRemark2: "",
          txtRemark3: "",
          txtRemark4: "",
          txtLRNumber: "",
          dpLRDate: "",

          TabParty: "",
          TabRefVouchers: "",
          TabVoucher: "",
          TabDelivery: "",
          TabTranport: "",
          TabValidity: "",
          TabRemarks: "",
          TabSummary: "",
          TabTax: "",
          TabDiscount: "",
          TabAddLess: "",
          TabName: "Party",

          txtRefNum1: "",
          txtRefNum1Date: "",
          txtRefNum2: "",
          txtRefNum2Date: "",
          txtRefNum3: "",
          txtRefNum3Date: "",
          txtRefNum4: "",
          txtRefNum4Date: "",
          btnRefresh: "",

          producttable: "",
          txtProductName: "",
          txtQty: "",
          cbUnit: "",
          txtRate: "",
          txtDiscount: "",
          btnAdd: "",
          discType: "",
        },
        Headertab: [
          { TabId: 0, Index: 0, Show: true, tabShow: true },
          { TabId: 1, Index: 0, Show: false, tabShow: false },
          { TabId: 2, Index: 0, Show: false, tabShow: false },
          { TabId: 3, Index: 0, Show: false, tabShow: false },
          { TabId: 4, Index: 0, Show: false, tabShow: false },
          { TabId: 5, Index: 0, Show: false, tabShow: false },
          { TabId: 6, Index: 0, Show: false, tabShow: false },
        ],
        Summarytab: [true, false, false],
        Commontab: [true, false, false, false],
        TransPer: {
          IsProCode: false,
          IsItemDisc: false,
          IsCashDisc: false,
          IsFQty: false,
        },
        btnSave: true,
        btnView: true,
        btnDelete: true,
        btnConvert: true,
        btnPrint: true,
      };
      state.Header = {
        iNumSeqNo: "",
        strPrefix: "",
        strCompCode: "",
        strFinanFrom: "",
        strTransNo: "",
        strVouchNo: "",
        isManualCode: false,

        iVouchId: 0,
        SelectedVouchId: 0,
        iBillTypeId: 0,
        iEITypeId: 1,
        iDMId: 0,
        BtnDisable: false,
        strVouchDate: new Date().toISOString().split("T")[0],
        strPartyDCVouch: "",
        strConvertDCVouch: "",
        strInWDCVouchers: "",
        iPartyId: 0,
        strPartyName: "",
        strPartyAdd: "",
        strShippingAdd: "",
        iTaxRelId: 0,
        arrAddress: [],

        strRefNum1: "",
        disablebtn: false,
        dpRefNum1Date: new Date().toISOString().split("T")[0],
        strRefNum2: "",
        dpRefNum2Date: new Date().toISOString().split("T")[0],
        strRefNum3: "",
        dpRefNum3Date: new Date().toISOString().split("T")[0],
        strRefNum4: "",
        dpRefNum4Date: new Date().toISOString().split("T")[0],

        strRemark1: "",
        strRemark2: "",
        strRemark3: "",
        strRemark4: "",

        strValidity: "0",
        dpValDateUpto: new Date().toISOString().split("T")[0],
        strPayTerm: "0",
        dpPayTermDateUpto: new Date().toISOString().split("T")[0],
        strDeliTerm: "0",
        dpDelTermDateUpto: new Date().toISOString().split("T")[0],

        strEComGstNo: "",
        strTransport: "",
        strVehicle: "",
        strDespatch: "",
        dpDespatchDate: new Date().toISOString().split("T")[0],
        strLRNNumber: "",
        dpLRNoDate: new Date().toISOString().split("T")[0],
        strAgent: "",
        strReference: "",
        strDestination: "",
      };
      state.Product = {
        iProId: 0,
        strProCode: "",
        strProName: "",
        iUnitId: 0,
        strUnitName: "",
        dQty: 0,
        FQty: 0,
        dRate: 0,
        dRatewithTax: 0,
        dItemDisc: 0,
        dCashDisc: 0,
        strReason: "",
        isItemDiscPer: true,
        isCashDiscPer: true,
        strProAttDTId: "",
        strStoAttDTId: "",
        strTraAttDTId: "",
        strProductDimAttGroupId: "",
        strStorageDimAttGroupId: "",
        strTrackingDimAttGroupId: "",
        strProductDimAttId: "",
        strStorageDimAttId: "",
        strTrackingDimAttId: "",
        strProductDimAttDTId: "",
        strStorageDimAttDTId: "",
        strTrackingDimAttDTId: "",
        iRowIndex: 0,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
        Unit: [],
        AttCol: [],
        AttVal: [],
        Product: {},
        bUpdateStatus: false,
        isDiscChanged: false,
      };
      state.Data = {
        arrEntDT: [],
        arrEntDTCol: [],
        arrEntDTHelp: [],
        arrEntTax: [],
        arrEntAL: [],
        arrEntPenCon: [],
        arrEntConDT: [],
      };
      state.Summary = {
        NetAmt: 0,
        Qty: 0,
        GoodsValue: 0,
        Discount: 0,
        Tax: 0,
        AddLess: 0,
        TCSTax: 0,
        RoundOff: 0,
      };
      state.Discount = {
        dBillDisPer: 0,
        dBillDisAmt: 0,
        dBillDisValue: 0,
        dALBillDisPer: 0,
        dALBillDisAmt: 0,
        dALBillDisValue: 0,
        isDiscChanged: false,
      };
      state.AddLess = {
        iSerVenId: 0,
        VenCode: "",
        VenName: "",
        iSerId: 0,
        SerName: "",
        SerCode: "",
        dRate: (0).toFixed(2),
        dDisc: 0,
        IsDiscPer: true,
        iRowIndex: 0,
        bUpdateStatus: false,
        Remarks: {
          strRemark1: "",
          strRemark2: "",
          strRemark3: "",
          strRemark4: "",
        },
        Calculation: {
          dValue1: 0,
          dValue2: 0,
          dValue3: 0,
          dConstantValue: 0,
          dFinalValue: 0,
          strOp1: "0",
          strOp2: "0",
          strOp3: "0",
        },
      };
      state.JobWork = {
        JobId: "0",
        strJobName: "",
        strJobCode: "",
        arrJobWork: [],
        strRGRemark1: "",
        strRGRemark2: "",
        strRGRemark3: "",
        strRGRemark4: "",
        strRGHours: "",
        dRate: Number((0).toFixed(2)),
        dRatewithTax: Number((0).toFixed(2)),
        iRowIndex: 0,
        bUpdateStatus: false,
      };
      sessionStorage.removeItem("VoucherId");
      sessionStorage.removeItem("VoucherType");
    },
    Show_tabs: (state, { payload }) => {
      const { arrtabbtn, tabtype } = payload;
      switch (tabtype) {
        case "transht":
          state.Common.Headertab = arrtabbtn;
          break;
        case "transsum":
          state.Common.Summarytab = arrtabbtn;
          break;
        default:
          state.Common.Commontab = arrtabbtn;
          break;
      }
    },
    Show_hidetranstabs: (state, { payload }) => {
      const { arrtabbtn, minrow, tabtype } = payload;
      switch (tabtype) {
        case "transht":
          state.Common.Headertab = arrtabbtn;
          state.Common.MinRow = minrow;
          break;
        case "transsum":
          state.Common.Summarytab = arrtabbtn;
          state.Common.MinRow = minrow;
          break;
        default:
          state.Common.Commontab = arrtabbtn;
          break;
      }
    },
    ShowHide_Popup: (state, { payload }) => {
      const EntPopup = [
        "IsVenSearch",
        "IsProSearch",
        "IsSerSearch",
        "IsVenCreatio",
        "IsShippAdd",
        "IsVouchNo",
        "IsProduct",
        "IsProRemark ",
        "IsProCal",
        "IsConvertion",
        "IsReceipt",
        "IsPayment",
        "IsHideParty",
        "IsPendinCon",
        "IsTransView",
        "IsEmail",
        "IsWhatsApp",
        "IsEInvoice",
        "IsPrintSett",
        "IsPrintShow",
        "IsReturnPro",
        "IsAttSearch",
        "IsNewPro",
        "IsJobSearch",
      ];
      EntPopup.forEach((Isshow) => {
        if (Isshow !== payload && Isshow !== payload.isshow)
          state.Common[Isshow] = false;
      });
      if (payload.Scrtype) {
        switch (payload.Scrtype) {
          case "Purchase":
            state.Common.JSScrId = 3;
            state.Common.JSType = "Purchase";
            state.Common.PartyScrName = "Supplier Master";
            state.Common.PartyPlaceHolder = "Enter Supplier Name";
            state.Common.IsHideParty = false;
            break;
          case "SerVen":
            state.Common.JSScrId = 3;
            state.Common.JSType = "SerVen";
            state.Common.PartyScrName = "Service Vendor";
            state.Common.PartyPlaceHolder = "Enter Vendore Name";
            state.Common.IsHideParty = false;
            break;
          case "Jobwork":
            state.Common.JSScrId = 50;
            state.Common.JSType = "Jobwork";
            state.Common.PartyScrName = "Vendor Master";
            state.Common.PartyPlaceHolder = "Enter Vendor Name";
            state.Common.IsHideParty = false;
            break;
          case "Job":
            state.Common.JSScrId = 51;
            state.Common.JSType = "Job";
            state.Common.PartyScrName = "JobWork Master";
            state.Common.PartyPlaceHolder = "Enter Job Name";
            state.Common.IsHideParty = true;
            break;
          case "Service":
            state.Common.JSScrId = 59;
            state.Common.JSType = "Service";
            state.Common.PartyScrName = "Service Master";
            state.Common.PartyPlaceHolder = "Enter Service Name";
            state.Common.IsHideParty = true;
            break;
          default:
            state.Common.JSScrId = 2;
            state.Common.JSType = "Sales";
            state.Common.PartyScrName = "Customer Master";
            state.Common.PartyPlaceHolder = "Enter Customer Name";
            state.Common.IsHideParty = false;
            break;
        }
        state.Common[payload.isshow] = !state.Common[payload.isshow];
      } else if (payload.default === true || payload.default === false) {
        state.Common[payload.isshow] = payload.default;
      } else if (payload.IsSerVendor === true) {
        state.Common.IsSerVendor = true;
        state.Common[payload.isshow] = !state.Common[payload];
      } else {
        state.Common[payload] = !state.Common[payload];
      }
    },
    ShowHide_PrintSett: (state, { payload }) => {
      state.Common.IsPrintSett = payload;
    },
    Selecte_Voucher: (state, { payload }) => {
      state.Header.SelectedVouchId = payload;
    },
    Select_ProducttoConvert: (state, { payload }) => {
      state.Data.arrEntConDT = payload;
    },
  },
});
export const {
  setdafaultparems,
  Update_NoFormate,
  Vendore_TypeOnchange,
  setScrId,
  Update_BtnPermission,
  setParty,
  settableCol,
  Input_Onchange,
  Clear_Transaction,
  ProductInput_Onchange,
  ProductRate_Onchange,
  ProductRate_LostFocus,
  JobWorkInput_Onchange,
  AddProduct,
  Modify_Product,

  Merge_ConvertedVouchers,
  Select_Product,
  Select_ProductionProduct,
  Select_Unit,
  Clear_Product,
  setAttValues,
  setRootCalculation,
  setItemRemark,
  Remove_product,
  Select_ReturnProduct,

  Update_ServiceVendor,
  Update_Service,
  Select_ServiceVendore,
  AddLess_LostFocus,
  AddUpdate_AddLess,
  AddLess_Onchange,
  Modify_AddLess,
  Remove_AddLess,

  Update_DiscountCalculation,
  Discount_onchanged,

  Modify_Vouchers,
  Update_ShipAdd,

  Show_tabs,
  Show_hidetranstabs,
  ShowHide_Popup,
  ShowHide_PrintSett,
  Selecte_Voucher,
  Select_ProducttoConvert,
  setPayRecepDefault,
  setStockTrans,
  AddStockProduct,
  AddOpenStockProduct,
  setBOMTrans,
  AddBOMProduct,
  Select_JobWork,
  Modify_JobWork,
  setProductionTrans,
  Add_JobWork,
  Modify_ProductionProduct,
  setExpenseIncomeDefault,
  Modify_ExpenseIncome,
  Add_Service,
  Modify_Service,
  Select_Service,
  Clear_Service,
} = Transactionstates.actions;

export default Transactionstates.reducer;

const Assign_Defaultdata = (state, Data) => {
  state.Common.BillType = Data.BillType;
  state.Common.TaxType = Data.TaxType;
  state.Header.iBillTypeId = Data.BillTypeId;
  state.Common.arrVendore = Data.Vendore;
  state.Common.arrService = Data.Service;
};
const TransProduct = {
  iProId: 0,
  strProCode: "",
  strProName: "",
  iUnitId: 0,
  strUnitName: "",
  dQty: 0,
  FQty: 0,
  dRate: 0,
  dRatewithTax: 0,
  dItemDisc: 0,
  dCashDisc: 0,
  strReason: "",
  isItemDiscPer: true,
  isCashDiscPer: true,
  strProAttDTId: "",
  strStoAttDTId: "",
  strTraAttDTId: "",
  strProductDimAttGroupId: "",
  strStorageDimAttGroupId: "",
  strTrackingDimAttGroupId: "",
  strProductDimAttId: "",
  strStorageDimAttId: "",
  strTrackingDimAttId: "",
  strProductDimAttDTId: "",
  strStorageDimAttDTId: "",
  strTrackingDimAttDTId: "",
  iRowIndex: 0,
  Remarks: {
    strRemark1: "",
    strRemark2: "",
    strRemark3: "",
    strRemark4: "",
  },
  Calculation: {
    dValue1: 0,
    dValue2: 0,
    dValue3: 0,
    dConstantValue: 0,
    dFinalValue: 0,
    strOp1: "0",
    strOp2: "0",
    strOp3: "0",
  },
  Unit: [],
  AttCol: [],
  AttVal: [],
  Product: {},
  bUpdateStatus: false,
  isDiscChanged: false,
};
