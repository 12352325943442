import { useEffect, useState } from "react";
import api from "../Admin/Config";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  Select_ReturnProduct,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import $ from "jquery";
import { Table_Style } from "./Transaction";
function ReturnSearch() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const [arrEntReturn, setarrEntReturn] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_ReturnProduct();
  }, []);
  const Load_ReturnProduct = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get(
          `Transaction/Load_ReturnProduct/${_Common.iScrId}/${_HT.iPartyId}/${_HT.iTaxRelId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntTableCol(Res.data.EntTablDesign);
            let EntRetPro = Res.data.EntReturnPro;
            if (_DT.arrEntDT.length > 0) {
              for (let i = 0; i < _DT.arrEntDT.length; i++) {
                let dtProId = _DT.arrEntDT[i].proId;
                EntRetPro = EntRetPro.filter((dt) => dt.proId !== dtProId);
              }
            }
            setarrEntReturn(EntRetPro);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_ReturnProduct = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        iScrId: _Common.iScrId,
        strSearch: strtxt,
        iPartyId: _HT.iPartyId,
        iTaxRelId: _HT.iTaxRelId,
        strCompanyDB: localStorage.db,
      };
      await api
        .post(`Transaction/Search_ReturnProduct`, objSearch)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            let EntRetPro = Res.data.EntReturnPro;
            if (_DT.arrEntDT.length > 0) {
              for (let i = 0; i < _DT.arrEntDT.length; i++) {
                let dtProId = _DT.arrEntDT[i].proId;
                EntRetPro = EntRetPro.filter((dt) => dt.proId !== dtProId);
              }
            }
            setarrEntReturn(EntRetPro);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const ConvertClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      let EntRetPro = arrEntReturn.filter((DT) => DT.isChecked === true);
      await api
        .post(`Transaction/View_ReturnProducts/`, {
          bUpdateStatus: false,
          iRowIndex: 0,
          iScrId: _Common.iScrId,
          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          objBaseTransDT: _DT.arrEntDT,
          objReturnModel: EntRetPro,
          strCompanyDB: localStorage.db,
        })
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(Select_ReturnProduct(Res.data));
            await dispatch(ShowHide_Popup("IsReturnPro"));
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = async (Message, Icon) => {
    await dispatch(
      Show_MsgBox({
        Message: Message,
        Type: Icon,
        isMsg: true,
      })
    );
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{_Common.strScrName}</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsReturnPro"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="search-card full">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                spellCheck={false}
                placeholder="Search Product"
                id="Item_Search"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown")
                    $(".option-list [tabindex=0]").focus();
                  else if (e.key === "Escape")
                    dispatch(ShowHide_Popup("IsReturnPro"));
                }}
                value={strSearch}
                onChange={(e) => Search_ReturnProduct(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_ReturnProduct(strSearch)}
              ></i>
            </div>
          </div>
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  {arrEntTableCol.map(
                    (ht, Index) =>
                      ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {arrEntReturn.map((Item, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEntReturn.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "ArrowLeft") $("#to_date").focus();
                      if (e.key === "ArrowRight") $("#Invoice_Search").focus();
                    }}
                  >
                    {arrEntTableCol.map((dt, Index) =>
                      dt.isReadOnly
                        ? dt.isShow && (
                            <td key={Index} style={Table_Style(dt, Item)}>
                              {dt.strDataType === "number"
                                ? Number(Item[dt.strColName]).toFixed(2)
                                : Item[dt.strColName]}
                            </td>
                          )
                        : dt.isShow && (
                            <td key={Index} style={Table_Style(dt, Item)}>
                              {dt.strDataType === "checkbox" && (
                                <input
                                  type={dt.strDataType}
                                  defaultChecked={Item[dt.strColName]}
                                  onChange={(e) =>
                                    (Item[dt.strColName] = !Item[dt.strColName])
                                  }
                                />
                              )}
                            </td>
                          )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={ConvertClick}>
              Convert&nbsp;<i className="bx bx-transfer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReturnSearch;
