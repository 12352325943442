import React, { useEffect, useState } from "react";
import AccountsButton from "./AccountsButton";
import SideMenu from "../General/SideMenu";
import MasterList from "../Master/MasterListItem";
import PayRecBody from "./PayRecBody";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function Payment(CallerScrId) {
  const dispatch = useDispatch();
  const iScrId = 41;
  const [iPayId, setiPayId] = useState(0);
  const [isManualNo, setisManualNo] = useState(false);
  const [strTransNo, setstrTransNo] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [strVouchDate, setstrVouchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iVenTypeId, setiVenTypeId] = useState(1);
  const [iVenId, setiVenId] = useState(0);
  const [strVenName, setstrVenName] = useState("");
  const [iPayModeId, setiPayModeId] = useState(1);
  const [iBankAccId, setiBankAccId] = useState(0);
  const [strChequeNo, setstrChequeNo] = useState("");
  const [bCancelStatus, setbCancelStatus] = useState(false);
  const [strChequeDate, setstrChequeDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strAccHolderName, setstrAccHolderName] = useState("");
  const [strBankName, setstrBankName] = useState("");
  const [iBankId, setiBankId] = useState(0);
  const [strAccNo, setstrAccNo] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [dAmount, setdAmount] = useState((0).toFixed(2));

  const [IsCheque, setIsCheque] = useState(true);
  const [IsBank, setIsBank] = useState(true);

  const [listNoFormat, setlistNoFormat] = useState([0]);
  const [listPayment, setlistPayment] = useState([]);
  const [listPayMode, setlistPayMode] = useState([]);
  const [listBankAcc, setlistBankAcc] = useState([]);
  const [listBankAccDetails, setlistBankAccDetails] = useState([]);
  const [listVenType, setlistVenType] = useState([]);
  const [listEntComBox, setlistEntComBox] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  useEffect(() => {
    Load_Payments();
  }, []);
  const Load_Payments = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await api.post(`Payment/Load_Payment`, parems).then((res) => {
        if (res.data.strStatus === "Success") {
          setlistEntComBox(res.data.objEntComBox);
          setlistPayMode(res.data.objEntPayMode);
          setlistVenType(res.data.objEntVenType);
          setlistBankAcc(res.data.objEntBank);
          setlistPayment(res.data.objEntPayment);
          setlistNoFormat(res.data.objEntNoFormat);
          setisManualNo(res.data.objEntNoFormat[0].isManualNumber);
          setstrTransNo(res.data.objEntNoFormat[0].strTransNo);
          setstrVouchNo(res.data.objEntNoFormat[0].strUserNo);
          setListValidateProperties(res.data.Transtab);
          if (res.data.objEntBank.length > 0)
            setiBankAccId(res.data.objEntBank[0].bankAccId);
          TabOrderAssign(res.data.Transtab);
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Payments = (strtxt) => {
    const objsearch = {
      strSearch: strtxt,
      strColName: "All",
      strCompanyDB: localStorage.db,
    };
    api.post(`Payment/Search_Payments/`, objsearch).then((Res) => {
      if (Res.data.strStatus === "Success")
        setlistPayment(Res.data.objEntPayment);
      else {
        AlertMessage(Res.data.strMessage, "error");
      }
    });
  };
  const View_Party = (Party) => {
    setiVenId(Party.partyId);
    setstrVenName(Party.partyName);
  };
  const GetBankDetails = (BNId, BankName) => {
    setiBankId(BNId);
    setstrBankName(BankName);
  };
  const ClearClick = () => {
    TextClear();
    setiVenId(0);
    setiVenTypeId(1);
    setiPayModeId(1);
    setiBankId(0);
    setstrVenName("");
    setstrRemark("");
    setstrPrintName("");
    setbCancelStatus(false);
    setIsCheque(true);
    setIsBank(true);
    setiPayId(0);
    setdAmount((0).toFixed(2));
    Load_Payments();
  };
  const TextClear = () => {
    setstrChequeNo("");
    setstrAccNo("");
    setstrAccHolderName("");
    setstrBankName("");
    setiBankId(0);
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
         "Payment/Cancel/" + iPayId + "/" + localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objInsUpdPay = {
        strPrefix: listNoFormat[0].strPrefix,
        iNumSeqNo: listNoFormat[0].iNumSeqNo,
        strCompCode: listNoFormat[0].strCompCode,
        strFinanFrom: listNoFormat[0].strFinanFrom,
        strPayNo: strVouchNo,
        strTransNo: strTransNo,
        dpPayDate: strVouchDate,
        iVenType: iVenTypeId,
        iVenId: iVenId,
        iPayModeId: iPayModeId,
        dAmount: dAmount,
        iBankAccId: iBankAccId,
        strChequeNo: strChequeNo === "" ? "0" : strChequeNo,
        dpChequeDate: strChequeDate,
        strAccNo: strAccNo,
        strAccHolderName: strAccHolderName,
        strPrintName: strPrintName,
        strRemark: strRemark,
        iBNId: iBankId,
        iVouchId: 0,
        iPayId: iPayId,
        iCallerScrId: CallerScrId.CallerScrId,
        strBankName: strBankName,
        strFilter: "",
        strFilterColumn: "All",
        strSearch: "",
        strVenName: strVenName,
        strCompanyDB: localStorage.db,
      };
      await api.post( "Payment/InsertUpdate/", objInsUpdPay).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const GetPaymentDetails = async (PayId) => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
         "Payment/GetPaymentDetails/" + PayId + "/" + localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setiPayId(PayId);
          setstrVouchNo(Res.data.objPayDetails.strPayNo);
          setstrVouchDate(Res.data.objPayDetails.dpPayDate);
          setstrTransNo(Res.data.objPayDetails.strTransNo);
          setiVenTypeId(Res.data.objPayDetails.iVenType);
          setiVenId(Res.data.objPayDetails.iVenId);
          setstrVenName(Res.data.objPayDetails.strVenName);
          setiPayModeId(Res.data.objPayDetails.iPayModeId);
          setiBankAccId(Res.data.objPayDetails.iBankAccId);
          setstrChequeNo(Res.data.objPayDetails.strChequeNo);
          setstrChequeDate(Res.data.objPayDetails.dpChequeDate);
          setiBankId(Res.data.objPayDetails.iBNId);
          setstrBankName(Res.data.objPayDetails.strBankName);
          setstrAccHolderName(Res.data.objPayDetails.strAccHolderName);
          setstrPrintName(Res.data.objPayDetails.strPrintName);
          setstrRemark(Res.data.objPayDetails.strRemark);
          setstrAccNo(Res.data.objPayDetails.strAccNo);
          setdAmount(Number(Res.data.objPayDetails.dAmount).toFixed(2));
          setbCancelStatus(Res.data.objPayDetails.bCancelStatus);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const OnChange = (e) => {
    switch (e.target.name) {
      case "strVouchNo":
        setstrVouchNo(e.target.value);
        break;
      case "strVouchDate":
        setstrVouchDate(e.target.value);
        break;
      case "strChequeNo":
        setstrChequeNo(e.target.value);
        break;
      case "strChequeDate":
        setstrChequeDate(e.target.value);
        break;
      case "strAccHolderName":
        setstrAccHolderName(e.target.value);
        break;
      case "strAccNo":
        setstrAccNo(e.target.value);
        break;
      case "strPrintName":
        setstrPrintName(e.target.value);
        break;
      case "strRemark":
        setstrRemark(e.target.value);
        break;
      case "dAmount":
        setdAmount(Number(e.target.value));
        break;
      default:
        break;
    }
  };
  const OnSelectionChange = (e) => {
    switch (e.target.name) {
      case "iVenTypeId":
        setiVenTypeId(Number(e.target.value));
        setstrVenName("");
        setiVenId(0);
        TextClear();
        break;
      case "iBankAccId":
        setiBankAccId(Number(e.target.value));
        break;
      default:
        break;
    }
  };
  const PayMode_OnSelectionChange = (value) => {
    setiPayModeId(Number(value));
    setIsCheque(true);
    setIsBank(true);
    switch (value) {
      case 1: // Cash
        TextClear();
        break;
      case 2: //Cheque / DD
        setIsCheque(false);
        break;
      default:
        setIsBank(false);
        break;
    }
    if (value !== 1) {
      api.all([
        api.get( "Payment/LoadBankAccount/" + localStorage.db),
        api.get(
          
            "Payment/Get_PartyBankDetails/" +
            iVenId +
            "/" +
            iVenTypeId +
            "/" +
            localStorage.db
        ),
      ]).then((Res) => {
        if (
          Res[0].data.strStatus === "Success" &&
          Res[1].data.strStatus === "Success"
        ) {
          let ListBankDT = Res[0].data.objEntBank;
          let EntBank = Res[1].data.objEntBankDetails;
          if (EntBank.length > 0) {
            setstrChequeNo("");
            setstrAccHolderName(EntBank[0].strAccHolderName);
            setiBankId(EntBank[0].iBankNameId);
            setstrBankName(EntBank[0].strBankName);
            setstrAccNo(EntBank[0].strAccNo);
            setlistBankAccDetails(EntBank);
          } else TextClear();
          if (ListBankDT.length > 0) {
            setiBankAccId(ListBankDT[0].bankAccId);
          }
        } else {
          AlertMessage(
            Res[0].data.strMessage + Res[1].data.strMessage,
            "error"
          );
        }
      });
    }
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      VoucherTab: "",
      TransNoTab: "",
      TranDate: "",
      VouchDateTap: "",
      VenNameTab: "",
      VenTypeTab: "",
      PayModeTab: "",
      CheckNoTab: "",
      CheckDateTab: "",
      BankAccountTab: "",
      AccHolTab: "",
      AccNoTab: "",
      BankNameTab: "",
      AmountTab: "",
      PrintNameTab: "",
      Remarktab: "",
      PaymentListTab: "",
      PaymentFIlterTab: "",
      PaymentSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnCancel: "",
      BtnAdjust: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtEntNo":
          TabOrder[0].TransNoTab = EntProperties[i].iTabIndex;
          break;
        case "dpEntDate":
          TabOrder[0].TranDate = EntProperties[i].iTabIndex;
          break;
        case "txtVouchNo":
          TabOrder[0].VoucherTab = EntProperties[i].iTabIndex;
          break;
        case "dpVouchDate":
          TabOrder[0].VouchDateTap = EntProperties[i].iTabIndex;
          break;
        case "cbVenType":
          TabOrder[0].VenTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtVenName":
          TabOrder[0].VenNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemark":
          TabOrder[0].Remarktab = EntProperties[i].iTabIndex;
          break;
        case "cbPayMode":
          TabOrder[0].PayModeTab = EntProperties[i].iTabIndex;
          break;
        case "cbBankAcc":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "txtChequeNo":
          TabOrder[0].CheckNoTab = EntProperties[i].iTabIndex;
          break;
        case "dpChequeDate":
          TabOrder[0].CheckDateTab = EntProperties[i].iTabIndex;
          break;
        case "txtBankName":
          TabOrder[0].BankAccountTab = EntProperties[i].iTabIndex;
          break;
        case "txtAccHolderName":
          TabOrder[0].AccHolTab = EntProperties[i].iTabIndex;
          break;
        case "txtAmt":
          TabOrder[0].AmountTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnCancel":
          TabOrder[0].BtnCancel = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnAdjust":
          TabOrder[0].BtnAdjust = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].PaymentFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].PaymentSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbVouchers":
          TabOrder[0].PaymentListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    );
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].PaymentListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtVouchNo":
          ErrorMsg = strVouchNo === "" ? false : true;
          break;
        case "cbVenType":
          ErrorMsg = iVenTypeId === "" ? false : true;
          break;
        case "txtVenName":
          ErrorMsg = strVenName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtRemark":
          ErrorMsg = strRemark === "" ? false : true;
          break;
        case "txtChequeNo":
          if (Number(iPayModeId) === 2)
            ErrorMsg = strChequeNo === "" ? false : true;
          break;
        case "dpChequeDate":
          if (Number(iPayModeId) === 2)
            ErrorMsg = strChequeDate === "" ? false : true;
          break;
        case "txtBankName ":
          if (Number(iPayModeId) !== 1)
            ErrorMsg = strBankName === "" ? false : true;
          break;
        case "txtAccHolderName":
          if (Number(iPayModeId) !== 1)
            ErrorMsg = strAccHolderName === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntComBox}
          EntList={listPayment}
          GetDetails={GetPaymentDetails}
          Search={Search_Payments}
          Filter={Search_Payments}
          ScreenID={iScrId}
          accessor="strPayNo"
          accessorid="iPayId"
          ListTab={ListTabOrder[0].PaymentListTab}
          FilterTab={ListTabOrder[0].PaymentFIlterTab}
          SearchTab={ListTabOrder[0].PaymentSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <PayRecBody
              ScrId={iScrId}
              iVouchId={iPayId}
              strVouchNo={strVouchNo}
              strTransNo={strTransNo}
              strVouchDate={strVouchDate}
              iVenTypeId={iVenTypeId}
              strVenTypeName={""}
              strVenName={strVenName}
              iPayModeId={iPayModeId}
              strPayMode={""}
              iBankAccId={iBankAccId}
              strChequeNo={strChequeNo}
              strChequeDate={strChequeDate}
              strAccHolderName={strAccHolderName}
              strBankName={strBankName}
              iBankId={iBankId}
              strAccNo={strAccNo}
              strPrintName={strPrintName}
              strRemark={strRemark}
              dAmount={dAmount}
              listPayMode={listPayMode}
              listBankAcc={listBankAcc}
              listVenType={listVenType}
              View_Party={View_Party}
              GetBankDetails={GetBankDetails}
              PayMode_OnSelectionChange={PayMode_OnSelectionChange}
              OnSelectionChange={OnSelectionChange}
              OnChangeEvent={OnChange}
              IsCheque={IsCheque}
              IsBank={IsBank}
              ListTabOrder={ListTabOrder}
              TabIndex={TabIndex}
              isManualNo={isManualNo}
            />
            <Messagedialogbox />
          </div>
          <AccountsButton
            ScrId={iScrId}
            Save={(e) => {
              if (PropertiesValidation()) {
                if (Number(iPayModeId) === 2 && strChequeNo === "") {
                  AlertMessage("Cheque/DD Number is Invalid", "info");
                } else if (Number(dAmount) === 0) {
                  AlertMessage("Please Enter Amount", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Payment?",
                      isdialog: true,
                    })
                  );
                }
              }
            }}
            Clear={ClearClick}
            Cancel={(e) => {
              if (iPayId === 0) {
                AlertMessage("Please Select Payment", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Payment?",
                    isdialog: true,
                  })
                );
              }
            }}
            CancelStatus={bCancelStatus}
            SaveIndex={ListTabOrder[0].BtnSavtab}
            CloseIndex={ListTabOrder[0].BtnClosetab}
            ClearIndex={ListTabOrder[0].BtnCleartab}
            CancelIndex={ListTabOrder[0].BtnCancel}
            SettleIndex={ListTabOrder[0].BtnAdjust}
            TabIndex={TabIndex}
            Iid={iPayId}
            ScrName="Payment"
          />
        </div>
      </div>
    </>
  );
}
export default Payment;
