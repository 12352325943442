import React, { useState, useEffect } from "react";
import api from "../Admin/Config";
import UOMMaster from "../Master/UOMMaster";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { ShowHide_Popup } from "../ReduxStore/Transactionstates";
function ProductCreation() {
  const dispatch = useDispatch();
  const _ScrId = 1;
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [IsManualCode, setIsManualCode] = useState(true);
  const [IsUnitOpen, setIsUnitOpen] = useState(false);
  const [iProId, setiProId] = useState(0);
  const [strProCode, setstrProCode] = useState("");
  const [strProName, setstrProName] = useState("");
  const [strHSNCode, setstrHSNCode] = useState("");
  const [dCostRate, setdCostRate] = useState(0);
  const [dPriceRate, setdPriceRate] = useState(0);
  const [strUnitName, setstrUnitName] = useState("");
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [iSGSTTaxId, setiSGSTTaxId] = useState(8);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(16);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(21);
  const [listUnit, setlistUnit] = useState([]);
  const [listCodeFormat, setlistCodeFormat] = useState([]);

  useEffect(() => {
    Load_Product();
  }, []);
  const Load_Product = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .get(`ProductMaster/Load_PopupProduct/${localStorage.db}`)
        .then((Res) => {
          const Data = Res.data;
          setlistCodeFormat(Data.EntCodeFormat);
          setstrProCode(Data.EntCodeFormat[0].strCode);
          setIsManualCode(!Data.EntCodeFormat[0].isManualCode);
          setlistSGST(Data.EntSGSTTax);
          setlistCGST(Data.EntCGSTTax);
          setlistIGST(Data.EntIGSTTax);
          setdCostRate((0).toFixed(2));
          setdPriceRate((0).toFixed(2));
          if (Data.EntUnit !== undefined) {
            setlistUnit(Data.EntUnit);
            setstrUnitName(Data.strUnitName);
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Unit = (DefaultUnit, UnitName) => {
    try {
      setIsUnitOpen(!IsUnitOpen);
      setlistUnit(DefaultUnit);
      setstrUnitName(UnitName);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      if (strProName === "") {
        AlertMessage("Please Enter Product Name", "info");
      } else {
        await dispatch(setload_Screen(true));
        const listProdcutMaster = {
          iTitleTag: _ScrId,
          iProId: iProId,
          iNumSeqNo: listCodeFormat[0].iNumSeqNo,
          strPrefix: listCodeFormat[0].strPrefix,
          strProCode: strProCode,
          strProName: strProName,
          strRemark: "",
          strPrintName: "",
          strCommodityCode: strHSNCode,
          iCat1Id: 0,
          iCat2Id: 0,
          iCat3Id: 0,
          iStatusId: 1,
          iProTypeId: 1,
          iProGenreId: 1,
          iProDimId: 0,
          iStoDimId: 0,
          iTraDimId: 0,
          dPriceRate: Number(dPriceRate),
          dMinMargin: Number(0),
          dCostRate: Number(dCostRate),
          dDiscount: Number(0),
          iTaxTypeId: 1,
          iSGSTTaxId: Number(iSGSTTaxId),
          iCGSTTaxId: Number(iCGSTTaxId),
          iIGSTTaxId: Number(iIGSTTaxId),
          bQtyDecimal: true,
          bStockMaintain: true,
          strConcatAttNames: "",
          strAll: "",
          strAttNames: "",
          strCat1Name: "",
          strCat2Name: "",
          strCat3Name: "",
          strFilter: "",
          strFilterColumn: "",
          strMasterProCodeTag: "",
          strProCodeTag: "",
          strProDescription1: "",
          strProDescription2: "",
          strProGenreName: "",
          strProTypeName: "",
          strSearch: "",
          strStatusName: "",
          strUnitName: "",
        };
        const ProductDescription = [
          {
            iProDescId: 0,
            iProId: 0,
            strProDescription1: "",
            strProDescription2: "",
          },
        ];
        const InserProduct = {
          objEntProMaster: listProdcutMaster,
          objEntUOM: listUnit,
          objEntProDesc: ProductDescription,
          strCompanyDB: localStorage.db,
        };
        await api
          .post("ProductMaster/InsUpd_Product/", InserProduct)
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    try {
      setiProId(0);
      setIsManualCode(true);
      setIsUnitOpen(false);
      setstrProName("");
      setstrHSNCode("");
      setstrUnitName("");
      setdCostRate((0).toFixed(2));
      setdPriceRate((0).toFixed(2));
      setiSGSTTaxId(8);
      setiCGSTTaxId(16);
      setiIGSTTaxId(21);
      setlistSGST([]);
      setlistCGST([]);
      setlistIGST([]);
      setlistUnit([]);
      Load_Product();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Product Master</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsProSearch"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2">
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-1">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Product Name"
                value={strProName}
                onChange={(e) => setstrProName(e.target.value)}
                autoFocus
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>
                Code <span className="danger">*</span>
              </label>
              <br />
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={strProCode}
                readOnly={IsManualCode}
                onClick={(e) => e.target.select()}
                onChange={(e) => setstrProCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>Commodity/HSN</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Commodity Details"
                onClick={(e) => e.target.select()}
                value={strHSNCode}
                onChange={(e) => setstrHSNCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>Unit Name </label>
              <div className="custom-select">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please Select Unit"
                  value={strUnitName}
                  onInput={(e) => setIsUnitOpen(!IsUnitOpen)}
                  onClick={(e) => setIsUnitOpen(!IsUnitOpen)}
                />
                <i
                  className="fa-solid fa-caret-down"
                  style={{
                    position: "absolute",
                    right: "10px",
                    color: "darkgreen",
                    transform: IsUnitOpen ? "rotate(-180deg)" : "rotate(0deg)",
                    fontSize: "15px",
                  }}
                ></i>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>GST %</label>
              <CustomizedSelectoption
                optionparem="isoptionShow19"
                showoption={_parem.isoptionShow19}
                placeholder="Select IGST Tax"
                select_value={(val) => TaxOnChange(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={iIGSTTaxId}
                displayName="strTaxDesc"
                disvalue="iTaxId"
                arrydata={listIGST}
                EmptVal="IGST "
                replace={["IGST", "GST"]}
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>Purchase Rate</label>
              <input
                type="text"
                className="form-control"
                onClick={(e) => e.target.select()}
                placeholder="Enter Purchase Rate"
                value={dCostRate}
                onChange={(e) => setdCostRate(e.target.value)}
                onBlur={(e) => {
                  if (Number(e.target.value) === 0) {
                    setdCostRate((0).toFixed(2));
                  } else {
                    setdCostRate(Number(e.target.value).toFixed(2));
                  }
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-1">
              <label>Sales Rate</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Sales Rate"
                value={dPriceRate}
                onChange={(e) => setdPriceRate(e.target.value)}
                onClick={(e) => e.target.select()}
                onBlur={(e) => {
                  if (Number(e.target.value) === 0) {
                    setdPriceRate((0).toFixed(2));
                  } else {
                    setdPriceRate(Number(e.target.value).toFixed(2));
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
        {IsUnitOpen && (
          <UOMMaster
            Close={(e) => setIsUnitOpen(!IsUnitOpen)}
            GetDefautUnit={View_Unit}
            ListUnit={listUnit}
            UnitName={strUnitName}
            ProId={IsUnitOpen}
          />
        )}
      </div>
    </div>
  );
}

export default ProductCreation;
