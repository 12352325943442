import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox } from "../ReduxStore/Message";

import api from "../Admin/Config";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
import $ from "jquery";
function ReportSetting(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [arrEntDT, setarrEntDT] = useState([]);
  const [arrColSetting, setarrColSetting] = useState([]);
  const [arrEntReptCol, setarrEntReptCol] = useState([]);
  const [arrTabShow, setarrTabShow] = useState([true, false, false, false]);
  const [RptColId, setRptColId] = useState(0);
  const [strOriginalName, setstrOriginalName] = useState("");
  const [strColumnName, setstrColumnName] = useState("");
  const [strDataType, setstrDataType] = useState("String");
  const [strConditionColumn, setstrConditionColumn] = useState("");
  const [isSumColumn, setisSumColumn] = useState(false);
  const [isShow, setisShow] = useState(false);
  const [isFilter, setisFilter] = useState(false);
  const [isOrderBy, setisOrderBy] = useState(false);
  const arrDatatype = ["String", "DateTime", "decimal"];
  useEffect(() => {
    Load_ReportSetting("Design");
  }, []);
  const Load_ReportSetting = (strtabName) => {
    api.get(
      
        "Reports/View_ReportSetting/" +
        strtabName +
        "/" +
        Number(props.iRptId) +
        "/" +
        localStorage.db
    ).then((Res) => {
      if (strtabName === "UpdateColumns") {
        setarrEntReptCol(Res.data.EntReptColumns);
      } else {
        setarrEntDT(Res.data.EntColSetting);
        setarrColSetting(Res.data.EntRptSetting);
      }
    });
  };
  const Save_ReportDesign = () => {
    try {
      const Update = {
        strTag: "Design",
        EntSumColumns: [],
        objEntRptSet: arrColSetting[0],
        RptColumnDesign: arrEntDT,
        strCompanyDB: localStorage.db,
      };
      api.post( "Reports/Update_ReportSettings", Update).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (error) {
      AlertMessage(error.message, "error");
    }
  };
  const Save_SummaryDesign = () => {
    try {
      const Update = {
        RptColumnDesign: [],
        EntSumColumns: arrEntDT,
        objEntRptSet: arrColSetting[0],
        strTag: "Summary",
        strCompanyDB: localStorage.db,
      };
      api.post( "Reports/Update_ReportSettings", Update).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (error) {
      AlertMessage(error.message, "error");
    }
  };
  const Clear_Columns = () => {
    setstrOriginalName("");
    setstrColumnName("");
    setstrDataType("String");
    setstrConditionColumn("");
    setisSumColumn(false);
    setisShow(false);
    setisFilter(false);
    setisOrderBy(false);
  };
  const Save_ReportColumn = () => {
    try {
      const objRptCol = [
        {
          RptColId: RptColId,
          RptId: Number(props.iRptId),
          RptColName: strOriginalName,
          RptColRoot: strColumnName,
          DataType: strDataType,
          ViewQuery: "",
          ConditionQuery: "",
          RptSumColRoot: "",
          IsSumCol: Number(isSumColumn),
          IsChecked: Number(isShow),
          IsDefaultOrderBy: Number(isOrderBy),
          IsReportFilter: Number(isFilter),
          ConditionColumn: strConditionColumn,
        },
      ];
      const Update = {
        iRptId: Number(props.iRptId),
        iRptColId: Number(RptColId),
        RptColumn: objRptCol,
        strCompanyDB: localStorage.db,
      };
      api.post( "Reports/InsUpd_ReportColumn", Update).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          Clear_Columns();
          tab_click(0, "Design");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (error) {
      AlertMessage(error.message, "error");
    }
  };
  const View_ReportColumn = (objCol) => {
    setRptColId(objCol.rptColId);
    setstrOriginalName(objCol.rptColName);
    setstrColumnName(objCol.rptColRoot);
    setstrDataType(objCol.dataType);
    setstrConditionColumn(objCol.conditionColumn);
    setisSumColumn(Boolean(objCol.isSumCol));
    setisShow(Boolean(objCol.isChecked));
    setisFilter(Boolean(objCol.isReportFilter));
    setisOrderBy(Boolean(objCol.isDefaultOrderBy));
  };
  const Column = [
    {
      Header: "Field Name",
      style: { justifyContent: "left" },
      accessor: "originalName",
      show: arrTabShow[0],
    },
    {
      Header: "Field Name",
      accessor: "aliasName",
      style: { justifyContent: "left" },
      show: !arrTabShow[0],
    },
    {
      Header: "Name",
      id: "Alias",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.aliasName}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.aliasName = e.target.value)}
        ></input>
      ),
      show: arrTabShow[0],
    },
    {
      Header: "In Order",
      maxWidth: 100,
      minWidth: 100,
      id: "InOrderTo",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.inOrderTo}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.inOrderTo = e.target.value)}
        ></input>
      ),
      show: arrTabShow[0],
    },
    {
      Header: "Width",
      maxWidth: 100,
      minWidth: 100,
      id: "Width",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.reportWidth}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.reportWidth = e.target.value)}
        ></input>
      ),
      show: arrTabShow[0],
    },
    {
      Header: "Text Align",
      maxWidth: 100,
      minWidth: 100,
      id: "fontAlign",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.fontAlign}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.fontAlign = e.target.value)}
        ></input>
      ),
      show: arrTabShow[0],
    },
    {
      Header: "Show",
      id: "showInReport",
      maxWidth: 100,
      minWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          className="form-check"
          defaultChecked={rowinfo.showInReport}
          onChange={(e) => (rowinfo.showInReport = !rowinfo.showInReport)}
        ></input>
      ),
      show: arrTabShow[0],
    },
    {
      Header: "Show",
      id: "isChecked",
      maxWidth: 100,
      minWidth: 100,
      show: !arrTabShow[0] ? true : false,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          className="form-check"
          onClick={(e) => e.target.select()}
          defaultChecked={rowinfo.isChecked}
          onChange={(e) => (rowinfo.isChecked = !rowinfo.isChecked)}
        ></input>
      ),
    },
  ];
  const ReportColumn = [
    {
      Header: "Report Column",
      style: { justifyContent: "left" },
      accessor: "rptColName",
    },
    {
      Header: "Root Column",
      accessor: "rptColRoot",
      style: { justifyContent: "left" },
    },
    {
      Header: "Data Type",
      accessor: "dataType",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Condition Column",
      accessor: "conditionColumn",
      minWidth: 120,
      style: { justifyContent: "left" },
    },
    {
      Header: "Order By",
      style: { justifyContent: "center" },
      accessor: "isDefaultOrderBy",
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: "Filter",
      style: { justifyContent: "center" },
      accessor: "isReportFilter",
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Sum",
      style: { justifyContent: "center" },
      accessor: "isSumCol",
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Show",
      style: { justifyContent: "center" },
      accessor: "isChecked",
      maxWidth: 100,
      minWidth: 100,
    },
  ];
  const tab_click = (tabindex, tabName) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    let EntTab = arrTabShow.map((Dt, Index) => {
      if (Index === tabindex) Dt = true;
      else Dt = false;
      return Dt;
    });
    setarrTabShow(EntTab);
    if (tabindex === 0 || tabindex === 1 || tabindex === 2)
      Load_ReportSetting(tabName);
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Report Setting</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="trans-tab">
              <div className="trans-buttons">
                <label
                  onClick={(e) => tab_click(0, "Design")}
                  className="active"
                >
                  Design
                </label>
                <label onClick={(e) => tab_click(1, "Summary")}>Summary</label>
                <label onClick={(e) => tab_click(2, "UpdateColumns")}>
                  Update Column
                </label>
                <label onClick={(e) => tab_click(3, "Columns")}>
                  New Columns
                </label>
                <div id="tab-line"></div>
              </div>
            </div>
            {!arrTabShow[2] && !arrTabShow[3] && (
              <ReactTable
                columns={Column}
                data={arrEntDT}
                minRows={5}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {}}
                background={true}
                className="full-table"
              />
            )}
            {arrTabShow[2] && (
              <div className="row p-2">
                <div className="col-sm-12 col-md-4">
                  <label>Column Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={strOriginalName}
                    onChange={(e) => setstrOriginalName(e.target.value)}
                    placeholder="Enter Column Name"
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <label>Column Root</label>
                  <input
                    type="text"
                    className="form-control"
                    value={strColumnName}
                    onChange={(e) => setstrColumnName(e.target.value)}
                    placeholder="Enter Column Root Name"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Data Type</label>
                  <CustomeIndexSelectOption
                    optionparem="isoptionShow10"
                    showoption={_parem.isoptionShow10}
                    placeholder="Select Data Type"
                    select_value={(val) => setstrDataType(val)}
                    defaultval={strDataType}
                    arrydata={arrDatatype}
                    EmptVal="Data Type"
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label>Condition Column</label>
                  <input
                    type="text"
                    className="form-control"
                    value={strConditionColumn}
                    onChange={(e) => setstrConditionColumn(e.target.value)}
                    placeholder="Enter Condition Column Name"
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Order By</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isOrderBy}
                    onChange={(e) => setisOrderBy(!isOrderBy)}
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Report Filter</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isFilter}
                    onChange={(e) => setisFilter(!isFilter)}
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Sum Column</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isSumColumn}
                    onChange={(e) => setisSumColumn(!isSumColumn)}
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Show Sum Column</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isShow}
                    onChange={(e) => setisShow(!isShow)}
                  />
                </div>
                <ReactTable
                  columns={ReportColumn}
                  data={arrEntReptCol}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {
                    View_ReportColumn(rowInfo);
                  }}
                  background={true}
                  className="full-table"
                />
              </div>
            )}
            {arrTabShow[3] && (
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <label>Column Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={strOriginalName}
                    onChange={(e) => setstrOriginalName(e.target.value)}
                    placeholder="Enter Column Name"
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <label>Column Root</label>
                  <input
                    type="text"
                    className="form-control"
                    value={strColumnName}
                    onChange={(e) => setstrColumnName(e.target.value)}
                    placeholder="Enter Column Root Name"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Data Type</label>
                  <CustomeIndexSelectOption
                    optionparem="isoptionShow10"
                    showoption={_parem.isoptionShow10}
                    placeholder="Select Data Type"
                    select_value={(val) => setstrDataType(val)}
                    defaultval={strDataType}
                    arrydata={arrDatatype}
                    EmptVal="Data Type"
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <label>Condition Column</label>
                  <input
                    type="text"
                    className="form-control"
                    value={strConditionColumn}
                    onChange={(e) => setstrConditionColumn(e.target.value)}
                    placeholder="Enter Condition Column Name"
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Report Filter</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isFilter}
                    onChange={(e) => setisFilter(!isFilter)}
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Sum Column</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isSumColumn}
                    onChange={(e) => setisSumColumn(!isSumColumn)}
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Show In Report</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    checked={isShow}
                    onChange={(e) => setisShow(!isShow)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {arrTabShow[0] && (
              <button
                onClick={(e) => Save_ReportDesign()}
                className="btn-fabgreen"
              >
                Update&nbsp;<i className="fas fa-save"></i>
              </button>
            )}
            {arrTabShow[0] && (
              <button
                onClick={(e) => Load_ReportSetting("Design")}
                className="btn-fabgreen"
              >
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            {arrTabShow[1] && (
              <button
                onClick={(e) => Save_SummaryDesign()}
                className="btn-fabgreen"
              >
                Update&nbsp;<i className="fas fa-save"></i>
              </button>
            )}
            {arrTabShow[1] && (
              <button
                onClick={(e) => Load_ReportSetting("Summary")}
                className="btn-fabgreen"
              >
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            {arrTabShow[2] && (
              <button
                className="btn-fabgreen"
                onClick={() => {
                  if (strOriginalName.length === 0)
                    AlertMessage("Please Enter Column Name", "info");
                  if (strColumnName.length === 0)
                    AlertMessage("Please Enter Alias Name", "info");
                  if (strDataType.length === 0)
                    AlertMessage("Please Enter Alias Name", "info");
                  if (strConditionColumn.length === 0)
                    AlertMessage("Please Enter Alias Name", "info");
                  else
                    dispatch(
                      Show_MsgBox({
                        Button: Save_ReportColumn,
                        Question: "Do You Want to Add New Report Column?",
                        isdialog: true,
                      })
                    );
                }}
              >
                Save&nbsp;<i className="fas fa-save"></i>
              </button>
            )}
            {arrTabShow[2] && (
              <button className="btn-fabgreen" onClick={() => Clear_Columns()}>
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            {arrTabShow[3] && (
              <button
                className="btn-fabgreen"
                onClick={() => {
                  if (strOriginalName.length === 0)
                    AlertMessage("Please Enter Column Name", "info");
                  if (strColumnName.length === 0)
                    AlertMessage("Please Enter Alias Name", "info");
                  if (strDataType.length === 0)
                    AlertMessage("Please Enter Alias Name", "info");
                  if (strConditionColumn.length === 0)
                    AlertMessage("Please Enter Alias Name", "info");
                  else
                    dispatch(
                      Show_MsgBox({
                        Button: Save_ReportColumn,
                        Question: "Do You Want to Add New Report Column?",
                        isdialog: true,
                      })
                    );
                }}
              >
                Save&nbsp;<i className="fas fa-save"></i>
              </button>
            )}
            {arrTabShow[3] && (
              <button className="btn-fabgreen" onClick={() => Clear_Columns()}>
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSetting;
