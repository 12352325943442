import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import SettlementInput from "./SettlementInput";
import api from "../Admin/Config";
import $ from "jquery";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function PaymentSettlement() {
  const dispatch = useDispatch();
  const [iDTselected, setiDTselected] = useState(0);

  const [objVenTypeList, setobjVenTypeList] = useState([]);
  const [objVouchTypeList, setobjVouchTypeList] = useState([]);
  const [objHeaderList, setobjHeaderList] = useState([]);
  const [objDTList, setobjDTList] = useState([]);

  const [iPayId, setiPayId] = useState(0);
  const [strVouchType, setstrVouchType] = useState("");

  const [iScrId, setiScrId] = useState(11);
  const [iVenTypeId, setiVenTypeId] = useState(1);
  const [iVenId, setiVenId] = useState(0);
  const [strVenName, setstrVenName] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strVouchNo, setstrVouchNo] = useState("");
  const [dAmount, setdAmount] = useState(0);
  const [dBalAmount, setdBalAmount] = useState(0);
  const [dUnSettleAmount, setdUnSettleAmount] = useState(0);
  const [iPartyScrId, setiPartyScrId] = useState(3);
  const [strPartyScrColName, setstrPartyScrColName] = useState("SuppName");
  const [issettled, setissettled] = useState(false);
  useEffect(() => {
    Load_PaymentSettlement();
  }, []);

  const Load_PaymentSettlement = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await api
        .post(`PaymentSettlement/Load_PaymentSettlement`, parems)
        .then((Res) => {
          setobjVenTypeList(Res.data.objVenType);
          setobjVouchTypeList(Res.data.objVouchType);
          setstrFromDate(Res.data.strFromDate);
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const SetPartyScreens = (VenTypeId) => {
    switch (VenTypeId) {
      case 4:
        setiPartyScrId(2);
        setstrPartyScrColName("CustName");
        setiScrId(17);
        break;
      case 5:
        setiPartyScrId(50);
        setstrPartyScrColName("VenName");
        setiScrId(73);
        break;
      default:
        setiPartyScrId(3);
        setstrPartyScrColName("SuppName");
        setiScrId(11);
        break;
    }
  };
  const View_Party = async (Party) => {
    setiVenId(Party.partyId);
    setstrVenName(Party.partyName);
    setiPayId(0);
    setstrSearch("");
    setstrToDate(new Date().toISOString().split("T")[0]);
    setobjHeaderList([]);
    setobjDTList([]);
    await ClearInputField();
    if (!issettled) await LoadPayVouchers(Party.partyId, iScrId);
    else await LoadAdjustedPayVouchers(Party.partyId, iScrId);
  };
  const LoadPayVouchers = async (VenId, VouchTypeId, FromDate, Todate) => {
    try {
      if (VenId === 0) AlertMessage("Please Select Vendor", "info");
      else {
        var FDate = FromDate === undefined ? strFromDate : FromDate;
        var TDate = Todate === undefined ? strToDate : Todate;
        await dispatch(setload_Screen(true));
        await api
          .post("PaymentSettlement/LoadUnAdjustPayAndVouchers/", {
            iScrId: VouchTypeId,
            iVenType: iVenTypeId,
            iVenId: VenId,
            dpFrom: FDate,
            dpTo: TDate,
            strSearch: strSearch,
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              if (Res.data.objPayHeader.length > 0) {
                setobjHeaderList(Res.data.objPayHeader);
                setobjDTList(Res.data.objVouchers);
              } else AlertMessage("No Payments are found", "info");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const LoadAdjustedPayVouchers = async (
    VenId,
    VouchTypeId,
    FromDate,
    Todate
  ) => {
    try {
      if (VenId === 0) AlertMessage("Please Select Vendor", "info");
      else {
        await dispatch(setload_Screen(true));
        var FDate = FromDate === undefined ? strFromDate : FromDate;
        var TDate = Todate === undefined ? strToDate : Todate;
        await api
          .post("PaymentSettlement/LoadAdjustedPayAndVouchers/", {
            iScrId: VouchTypeId,
            iVenType: iVenTypeId,
            iVenId: VenId,
            dpFrom: FDate,
            dpTo: TDate,
            strSearch: strSearch,
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              setobjHeaderList(Res.data.objPayHeader);
              setobjDTList(Res.data.objVouchers);
              if (Res.data.objPayHeader.length === 0)
                AlertMessage("No Settlement are found", "info");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const LoadVouchers = async (PayId, ScrId) => {
    try {
      let TabItem = issettled ? "Settled" : "UnSettled";
      await dispatch(setload_Screen(true));
      await api
        .get(
          `PaymentSettlement/LoadAdjustedAndVouchers/${PayId}/${ScrId}/${TabItem}/${iVenId}/${iVenTypeId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setobjDTList(Res.data.objVouchers);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const ViewClick = (Data, Datafor) => {
    switch (Datafor) {
      case "From":
        setstrFromDate(Data);
        if (!issettled) LoadPayVouchers(iVenId, iScrId, Data, strToDate);
        else LoadAdjustedPayVouchers(iVenId, iScrId, Data, strToDate);
        break;
      default:
        setstrToDate(Data);
        if (!issettled) LoadPayVouchers(iVenId, iScrId, strFromDate, Data);
        else LoadAdjustedPayVouchers(iVenId, iScrId, strFromDate, Data);
        break;
    }
  };
  const AddClick = async () => {
    try {
      if (iPayId === 0) {
        AlertMessage("Validation", "Please Select Atleast One Payment", "info");
      } else {
        await dispatch(setload_Screen(true));
        await api
          .post("PaymentSettlement/AddSettledAmt/", {
            dAmount: dAmount,
            dUnAdjAmt: Number(dUnSettleAmount),
            strVouchType: strVouchType,
            objPayDetails: objDTList,
            iRowIndex: iDTselected,
            dBalAmt: Number(dBalAmount),
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Error") {
              AlertMessage(Res.data.strMessage, "error");
            } else {
              setobjDTList(Res.data.objVouchers);
              setdBalAmount(Res.data.dBalAmt);
              ClearInputField();
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post("PaymentSettlement/Save/", {
          iPayId: iPayId,
          objPayDetails: objDTList,
          strVouchType: "",
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            tab_click(!issettled ? 0 : 1);
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const ClearInputField = () => {
    setstrVouchNo("");
    setdAmount(0);
    setdUnSettleAmount(0);
    setiDTselected(0);
  };
  const DeleteClick = async (PayDTId) => {
    try {
      await dispatch(setload_Screen(true));
      await api
        .post("PaymentSettlement/Delete/" + PayDTId + "/" + localStorage.db)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            tab_click(!issettled ? 0 : 1);
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const OnVenTypeChangeEvent = (VenTypeId) => {
    SetPartyScreens(VenTypeId);
    setiVenTypeId(VenTypeId);
    ClearClick();
  };
  const OnVouchTypeChangeEvent = (VouchTypeId) => {
    setiScrId(VouchTypeId);
    if (!issettled) LoadPayVouchers(iVenId, VouchTypeId);
    else LoadAdjustedPayVouchers(iVenId, VouchTypeId);
  };
  const ClearClick = () => {
    setiVenId(0);
    setiPayId(0);
    setstrVenName("");
    setstrSearch("");
    setstrToDate(new Date().toISOString().split("T")[0]);
    setobjHeaderList([]);
    setobjDTList([]);
    ClearInputField();
    Load_PaymentSettlement();
  };
  const OnChangeEvent = (e) => {
    setstrSearch(e.target.value);
  };
  const HeaderColumn = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Payment Mode",
      accessor: "paymentMode",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Payment Date",
      accessor: "payDate",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Payment No",
      accessor: "payNo",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Payment Amount",
      accessor: "payAmt",
      DataType: "Decimal",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      DataType: "Decimal",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      DataType: "Decimal",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
    },
  ];
  const DTColumn = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Type",
      accessor: "vouchType",
      minWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Bill Type",
      accessor: "billType",
      minWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Date",
      accessor: "vouchDate",
      style: { justifyContent: "center" },
      minWidth: 130,
      maxWidth: 130,
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Vouch Amount",
      accessor: "vouchAmt",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
      DataType: "Decimal",
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
      DataType: "Decimal",
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
      DataType: "Decimal",
    },
    {
      Header: "Amount",
      accessor: "amount",
      DataType: "Decimal",
      style: { justifyContent: "right" },
      minWidth: 130,
      maxWidth: 130,
    },
  ];
  const DTColumn1 = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Type",
      accessor: "vouchType",
      minWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Bill Type",
      accessor: "billType",
      minWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch Date",
      accessor: "vouchDate",
      minWidth: 150,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Vouch Amount",
      accessor: "vouchAmt",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "right" },
      DataType: "Decimal",
    },
    {
      Header: "Settled Amount",
      accessor: "adjustAmt",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "right" },
      DataType: "Decimal",
    },
    {
      Header: "Balance Amount",
      accessor: "unAdjustAmt",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "right" },
      DataType: "Decimal",
    },
    {
      Header: "Options",
      id: "payDTId",
      minWidth: 70,
      maxWidth: 70,
      cell: ({ rowinfo }) => (
        <div>
          <i
            onClick={(e) => {
              dispatch(
                Show_MsgBox({
                  Button: DeleteClick,
                  Question: "Do You Want to Delete Settlement?",
                  isdialog: true,
                  Id: rowinfo.payDTId,
                })
              );
            }}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const tab_click = async (tabindex) => {
    setissettled(tabindex === 0 ? false : true);
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");

    setiPayId(0);
    setstrSearch("");
    setstrToDate(new Date().toISOString().split("T")[0]);
    setobjHeaderList([]);
    setobjDTList([]);
    await ClearInputField();
    if (tabindex === 0) await LoadPayVouchers(iVenId, iScrId);
    else await LoadAdjustedPayVouchers(iVenId, iScrId);
  };
  return (
    <>
      <SideMenu ScrId={131} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="fg-card shadow-base">
              <div className="trans-tab">
                <div className="trans-buttons">
                  <label onClick={(e) => tab_click(0)} className="active">
                    UnSettled Payments
                  </label>
                  <label onClick={(e) => tab_click(1)}>Settled Payments</label>
                  <div id="tab-line"></div>
                </div>
              </div>
              <SettlementInput
                VenTypeId={iVenTypeId}
                VenName={strVenName}
                VouchTypeList={objVouchTypeList}
                VenTypeList={objVenTypeList}
                PartyScrId={iPartyScrId}
                ScrId={iScrId}
                PartyScrColName={strPartyScrColName}
                View_Party={View_Party}
                View={ViewClick}
                FromDate={strFromDate}
                ToDate={strToDate}
                OnChange={OnChangeEvent}
                OnVenTypeChange={OnVenTypeChangeEvent}
                OnVouchTypeChange={OnVouchTypeChangeEvent}
              />
              <ReactTable
                columns={HeaderColumn}
                data={objHeaderList}
                minRows={5}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  setiPayId(rowInfo.payId);
                  setdBalAmount(rowInfo.unAdjustAmt.toFixed(2));
                  LoadVouchers(rowInfo.payId, iScrId);
                }}
                background={true}
                className="full-table"
              />
            </div>
            <div className="fg-card shadow-base">
              {!issettled && (
                <div className="row p-2">
                  <div className="col-sm-12 col-md-3 mt-1">
                    <label>Voucher Number </label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      name="VenName"
                      id="VenName"
                      value={strVouchNo}
                      onClick={(e) => e.target.select()}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 mt-1">
                    <label>UN Settled Amount </label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      name="dUnSettleAmount"
                      id="dUnSettleAmount"
                      value={dUnSettleAmount}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 mt-1">
                    <label>Amount </label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      name="dAmount"
                      id="dAmount"
                      value={dAmount}
                      onChange={(e) => setdAmount(Number(e.target.value))}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 mt-4">
                    <div className="btn-sub-section">
                      <button className="btn-fabgreen" onClick={AddClick}>
                        Adjust
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 mt-1">
                    <div className="net-amount">
                      <h1>Available Amount</h1>
                      <h2>
                        {new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "INR",
                        }).format(Number(dBalAmount).toFixed(2))}
                      </h2>
                    </div>
                  </div>
                </div>
              )}
              <ReactTable
                columns={issettled ? DTColumn1 : DTColumn}
                data={objDTList}
                minRows={5}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  setiDTselected(rowInfo.index);
                  setstrVouchNo(rowInfo.vouchNo);
                  setdUnSettleAmount(rowInfo.unAdjustAmt.toFixed(2));
                  setstrVouchType(rowInfo.vouchType);
                  setdAmount(rowInfo.amount);
                }}
                background={true}
                className="full-table"
              />
            </div>
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (iVenId === 0) {
                  AlertMessage("Vendor Name is Required", "info");
                } else if (objHeaderList.length === 0) {
                  AlertMessage("You don't have payments to Save", "info");
                } else if (iPayId === 0) {
                  AlertMessage(
                    "You have to select one payment to Save",
                    "info"
                  );
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Settlement?",
                      isdialog: true,
                    })
                  );
                }
              }}
              disabled={issettled}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = `/Accounts/Payment`)}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSettlement;
