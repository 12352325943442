import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";

function CountryMaster(props) {
  const dispatch = useDispatch();
  const [Country, setCountry] = useState({
    iCountryId: 0,
    strCountryName: "",
    strDescription: "",
    arrEntCountry: [],
    strSearch: "",
  });
  const updateCountryField = (field, value) => {
    setCountry((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  useEffect(() => {
    Load_CountryMaster();
  }, []);
  const Load_CountryMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = { strSearch: "", strCompanyDB: localStorage.db };
      await api.post(`CountryState/Load_Country`, parems).then(
        async (res) => {
          if (res.data.strStatus === "Success") {
            await updateCountryField("arrEntCountry", res.data.EntCountry);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Country = async (strtxt) => {
    try {
      const parems = { strSearch: strtxt, strCompanyDB: localStorage.db };
      await api.post(`CountryState/Load_Country`, parems).then(
        async (res) => {
          if (res.data.strStatus === "Success") {
            await updateCountryField("arrEntCountry", res.data.EntState);
            await updateCountryField("strSearch", strtxt);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Save_Country = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iCountryId: Country.iCountryId,
        strCountryName: Country.strCountryName,
        strDescription: Country.strDescription,
        strCountrTag: "",
        strSearch: "",
        strDBName: localStorage.db,
      };
      await api.post(`CountryState/InsUpd_Country`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            Clear_Country();
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Country = async (objcountry) => {
    await updateCountryField("iCountryId", objcountry.iCountryId);
    await updateCountryField("strCountryName", objcountry.strCountryName);
    await updateCountryField("strDescription", objcountry.strDescription);
  };
  const Delete_Country = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        `CountryState/Delete_Country/${Country.iCountryId}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          Clear_Country();
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Country = async () => {
    await setCountry({
      iCountryId: 0,
      strCountryName: "",
      strDescription: "",
      arrEntCountry: [],
      strSearch: "",
    });
    await Load_CountryMaster();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Country Master</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  className="form-control"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      $(".dt-content [tabindex=0]").focus();
                    }
                  }}
                  value={Country.strSearch}
                  tabIndex={59}
                  onChange={(e) => Search_Country(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Country(Country.strSearch)}
                ></i>
              </div>
              <div className="data-list">
                {Country.arrEntCountry.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onClick={(e) => View_Country(dt)}
                    onKeyDown={(e) => {
                      const arrlength = Country.arrEntCountry.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Country(dt);
                      }
                    }}
                  >
                    <label>{dt.strCountryName}</label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>
                    Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    placeholder="Enter Country Name"
                    className="form-control"
                    value={Country.strCountryName}
                    onChange={(e) =>
                      updateCountryField("strCountryName", e.target.value)
                    }
                    autoFocus
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>Description</label>
                  <textarea
                    type="text"
                    aria-label="First name"
                    tabIndex="53"
                    className="form-control"
                    value={Country.strDescription}
                    onChange={(e) =>
                      updateCountryField("strDescription", e.target.value)
                    }
                    placeholder="Description"
                    style={{ minHeight: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              tabIndex="54"
              onClick={(e) => {
                if (Country.strCountryName === "") {
                  AlertMessage("Country Name is Rquired", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Save_Country,
                      Question: "Do You Want to Save Country?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex="55"
              onClick={(e) => {
                if (Number(Country.iCountryId) === 0) {
                  AlertMessage("Validation", "Please Select Category", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Delete_Country,
                      Question: "Do You Want to Delete Country ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              tabIndex="56"
              onClick={Clear_Country}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CountryMaster;
