import React, { useEffect, useState } from "react";
import AccountsButton from "../Accounts/AccountsButton";
import SideMenu from "../General/SideMenu";
import MasterList from "../Master/MasterListItem";
import DebitCreditBody from "./DebitCreditBody";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function DebitNote(CallerScrId) {
  const dispatch = useDispatch();
  const iScrId = 43;
  const [iDebId, setiDebId] = useState(0);
  const [strTransNo, setstrTransNo] = useState("");
  const [isManualNo, setisManualNo] = useState(false);
  const [strVouchNo, setstrVouchNo] = useState("");
  const [strVouchDate, setstrVouchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iVenTypeId, setiVenTypeId] = useState(4);
  const [iVenId, setiVenId] = useState(0);
  const [strVenName, setstrVenName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [dAmount, setdAmount] = useState(0);
  const [iDebCount, setiDebCount] = useState(0);
  const [bCancelStatus, setbCancelStatus] = useState(false);

  const [listNoFormat, setlistNoFormat] = useState([0]);
  const [listDebitNote, setlistDebitNote] = useState([]);
  const [listVenType, setlistVenType] = useState([]);
  const [listEntComBox, setlistEntComBox] = useState([]);

  const [ListValidateProperties, setListValidateProperties] = useState([]);

  useEffect(() => {
    Load_DebitNote();
  }, []);
  const Load_DebitNote = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await api.post(`DebitNote/Load_DebitNote`, parems).then((res) => {
        if (res.data.strStatus === "Success") {
          setlistEntComBox(res.data.objEntComBox);
          setlistVenType(res.data.objEntVenType);
          setlistDebitNote(res.data.objEntDebitNote);
          setiDebCount(listDebitNote.length);
          setlistNoFormat(res.data.objEntNoFormat);
          setisManualNo(res.data.objEntNoFormat[0].isManualNumber);
          setstrTransNo(res.data.objEntNoFormat[0].strTransNo);
          setstrVouchNo(res.data.objEntNoFormat[0].strUserNo);
          setListValidateProperties(res.data.Transtab);
          TabOrderAssign(res.data.Transtab);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_DebitNote = async (strtxt) => {
    const objsearch = {
      strSearch: strtxt,
      strColName: "All",
      strCompanyDB: localStorage.db,
    };
    await api.post(`DebitNote/Search_DebitNote/`, objsearch).then((Res) => {
      if (Res.data.strStatus === "Success")
        setlistDebitNote(Res.data.objEntDebitNote);
      else {
        AlertMessage(Res.data.strMessage, "error");
      }
    });
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const View_Party = (Party) => {
    setiVenId(Party.partyId);
    setstrVenName(Party.partyName);
  };
  const ClearClick = () => {
    setdAmount(0);
    setiVenId(0);
    setiVenTypeId(4);
    setiDebId(0);
    setstrVenName("");
    setstrRemark("");
    setstrVenName("");
    setstrRemark("");
    setstrTransNo("");
    setstrVouchNo("");
    setstrVouchDate(new Date().toISOString().split("T")[0]);
    setbCancelStatus(false);
    setlistNoFormat([]);
    setlistDebitNote([]);
    Load_DebitNote();
  };
  const DeleteClick = () => {
    dispatch(setload_Screen(true));
    api
      .post("DebitNote/Cancel/" + iDebId + "/" + localStorage.db)
      .then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
  };
  const DeleteIconClick = (DebId) => {
    dispatch(setload_Screen(true));
    api
      .post("DebitNote/Cancel/" + DebId + "/" + localStorage.db)
      .then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
  };
  const SaveClick = () => {
    dispatch(setload_Screen(true));
    const objInsUpdDeb = {
      strPrefix: listNoFormat[0].strPrefix,
      iNumSeqNo: listNoFormat[0].iNumSeqNo,
      strCompCode: listNoFormat[0].strCompCode,
      strFinanFrom: listNoFormat[0].strFinanFrom,
      strDebNo: strVouchNo,
      strTransNo: strTransNo,
      dpDebDate: strVouchDate,
      iVenType: iVenTypeId,
      iVenId: iVenId,
      iDebId: iDebId,
      dAmount: dAmount,
      strRemark: strRemark,
      iVouchId: CallerScrId.iVouchId,
      iCallerScrId: CallerScrId.CallerScrId,
      strFilter: "",
      strFilterColumn: "",
      strSearch: "",
      strVenName: "",
      strCompanyDB: localStorage.db,
    };
    api.post("DebitNote/InsertUpdate/", objInsUpdDeb).then((Res) => {
      if (Res.data.strStatus === "Success") {
        ClearClick();
        AlertMessage(Res.data.strMessage, "success");
      } else {
        AlertMessage(Res.data.strMessage, "error");
      }
      dispatch(setload_Screen(false));
    });
  };
  const GetDebitNoteDetails = (DebId) => {
    api
      .post("DebitNote/GetDebitNoteDetails/" + DebId + "/" + localStorage.db)
      .then((Res) => {
        if (Res.data.strStatus === "Success") {
          setiDebId(DebId);
          setstrVouchNo(Res.data.objDebDetails.strDebNo);
          var CEDate = Res.data.objDebDetails.dpDebDate;
          CEDate = CEDate.split(" ");
          CEDate = CEDate[0];
          CEDate = CEDate.split("-").reverse().join("-");
          setstrVouchDate(CEDate);
          setstrTransNo(Res.data.objDebDetails.strTransNo);
          setiVenTypeId(Res.data.objDebDetails.iVenType);
          setiVenId(Res.data.objDebDetails.iVenId);
          setstrVenName(Res.data.objDebDetails.strVenName);
          setstrRemark(Res.data.objDebDetails.strRemark);
          setdAmount(Res.data.objDebDetails.dAmount);
          setbCancelStatus(Res.data.objDebDetails.bCancelStatus);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
  };
  const OnChange = (e) => {
    switch (e.target.name) {
      case "strVouchNo":
        setstrVouchNo(e.target.value);
        break;
      case "strVouchDate":
        setstrVouchDate(e.target.value);
        break;
      case "strRemark":
        setstrRemark(e.target.value);
        break;
      case "dAmount":
        setdAmount(Number(e.target.value));
        break;
      default:
        break;
    }
  };
  const OnSelectionChange = (e) => {
    setiVenTypeId(Number(e.target.value));
    setstrVenName("");
    setiVenId(0);
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      VoucherTab: "",
      TransNoTab: "",
      TranDate: "",
      VouchDateTap: "",
      VenNameTab: "",
      VenTypeTab: "",
      AmountTab: "",
      Remarktab: "",
      DebitNoteListTab: "",
      DebitNoteFIlterTab: "",
      DebitNoteSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnCancel: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtEntNo":
          TabOrder[0].TransNoTab = EntProperties[i].iTabIndex;
          break;
        case "dpEntDate":
          TabOrder[0].TranDate = EntProperties[i].iTabIndex;
          break;
        case "txtVouchNo":
          TabOrder[0].VoucherTab = EntProperties[i].iTabIndex;
          break;
        case "dpVouchDate":
          TabOrder[0].VouchDateTap = EntProperties[i].iTabIndex;
          break;
        case "cbVenType":
          TabOrder[0].VenTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtVenName":
          TabOrder[0].VenNameTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemark":
          TabOrder[0].Remarktab = EntProperties[i].iTabIndex;
          break;
        case "txtAmt":
          TabOrder[0].AmountTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnCancel":
          TabOrder[0].BtnCancel = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].DebitNoteFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].DebitNoteSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbVouchers":
          TabOrder[0].DebitNoteListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].DebitNoteListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtVouchNo":
          ErrorMsg = strVouchNo === "" ? false : true;
          break;
        case "dpVouchDate":
          ErrorMsg = strVouchDate === "" ? false : true;
          break;
        case "cbVenType":
          ErrorMsg = iVenTypeId.toString() === "0" ? false : true;
          break;
        case "txtVenName":
          ErrorMsg = strVenName === "" ? false : true;
          break;
        case "txtRemark":
          ErrorMsg = strRemark === "" ? false : true;
          break;
        case "txtAmt":
          ErrorMsg = dAmount.toString() === "0" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntComBox}
          EntList={listDebitNote}
          GetDetails={GetDebitNoteDetails}
          Delete={DeleteIconClick}
          Count={iDebCount}
          Search={Search_DebitNote}
          ScreenID={iScrId}
          accessor="strDebNo"
          accessorid="iDebId"
          ListTab={ListTabOrder[0].DebitNoteListTab}
          FilterTab={ListTabOrder[0].DebitNoteFIlterTab}
          SearchTab={ListTabOrder[0].DebitNoteSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <DebitCreditBody
              ScrId={iScrId}
              strVouchNo={strVouchNo}
              strTransNo={strTransNo}
              strVouchDate={strVouchDate}
              iVenTypeId={iVenTypeId}
              strVenTypeName={""}
              strVenName={strVenName}
              strRemark={strRemark}
              dAmount={dAmount}
              listVenType={listVenType}
              View_Party={View_Party}
              OnSelectionChange={OnSelectionChange}
              OnChangeEvent={OnChange}
              ListTabOrder={ListTabOrder}
              TabIndex={TabIndex}
              isManualNo={isManualNo}
            />
            <Messagedialogbox />
          </div>
          <AccountsButton
            ScrId={iScrId}
            Save={(e) => {
              if (PropertiesValidation()) {
                dispatch(
                  Show_MsgBox({
                    Button: SaveClick,
                    Question: "Do You Want to  Save Debit Note?",
                    isdialog: true,
                  })
                );
              }
            }}
            Clear={ClearClick}
            Cancel={(e) => {
              if (iDebId === 0) {
                AlertMessage("Please Select DebitNote", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Debit Note?",
                    isdialog: true,
                  })
                );
              }
            }}
            CancelStatus={bCancelStatus}
            SaveIndex={ListTabOrder[0].BtnSavtab}
            CloseIndex={ListTabOrder[0].BtnClosetab}
            ClearIndex={ListTabOrder[0].BtnCleartab}
            CancelIndex={ListTabOrder[0].BtnCancel}
            TabIndex={TabIndex}
            ScrName="DebitNote"
            Iid={iDebId}
          />
        </div>
      </div>
    </>
  );
}
export default DebitNote;
