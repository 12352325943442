import React from "react";
import SideMenu from "../General/SideMenu";

function Tinder(dBillAmt, dPaidAmt, dBalAmt, onChangeEvent,Close) {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();

      event.preventDefault();
    }
  };

  const handleClick = (event) => {
    event.target.select();
  };

  const CloseClick = (event) => {
    event.preventDefault();
   Close();
  };

  return (
    <div className="col-md-12">
      <div className="remark-popup-box">
        <div className="remark-box">
          <SideMenu ScreenName={"TINDER"} />
          <br />
          <form>
            <div
              className="row"
              style={{
                backgroundColor: "#f5f5f5",
                width: "100%",
                paddingBottom: "2px",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <label>Bill Amount</label>
                <input
                  type="number"
                  aria-label="First name"
                  style={{ height: "35px" }}
                  className="form-control"
                  name="dBillAmt"
                  onSelect={handleClick}
                  value={dBillAmt}
                  readOnly
                  autoComplete="off"
                />
              </div>
              <div style={{ marginRight: "5px" }}>
                <label>Paid Amount</label>
                <input
                  type="number"
                  aria-label="First name"
                  style={{ height: "35px" }}
                  className="form-control"
                  name="dPaidAmt"
                  autoFocus
                  onSelect={handleClick}
                  value={dPaidAmt}
                  onChange={(e) => onChangeEvent(e)}
                  autoComplete="off"
                  onKeyDown={handleEnter}
                />
              </div>
              <div style={{ marginRight: "5px" }}>
                <label
                  style={{
                    height: "100px",
                    backgroundColor: "#1B5E20",
                    fontWeight: "800",
                    color: "white",
                  }}
                >
                  {dBalAmt}
                </label>
              </div>
            </div>
          </form>
          <div className="row mt-1">
            <div className="btn-section">
              <button className="btn-fabgreen" onClick={CloseClick}>
                Close&nbsp;<i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tinder;
