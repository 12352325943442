
import React, { useEffect } from "react";
import api from "../Admin/Config";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import Transaction, {
  Load_Transaction,
  TransConfig,
} from "../Transaction/Transaction";
import { Clear_Transaction } from "../ReduxStore/Transactionstates";

function SalesOrder() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _Sum = useSelector((store) => store.states.Summary);
  const _Disc = useSelector((store) => store.states.Discount);

  const _iScrId = 15;

  useEffect(() => {
    Load_Transaction(_Common, dispatch, TransConfig(_iScrId));
  }, []);

  const SaveClick = async () => {
    try {
      if (_DT.arrEntDT.length === 0) {
        AlertMessage("Atleast One Item is Required ", "error");
      } else {
        dispatch(setload_Screen(true));
        const objParems = {
          iSalOrdId: _HT.iVouchId,
          iTitleTag: _Common.iScrId,
          iPartyId: _HT.iPartyId,
          strShippingAdd: _HT.strShippingAdd,
          iNumSeqNo: _HT.iNumSeqNo,
          strPrefix: _HT.strPrefix,
          strCompCode: _HT.strCompCode,
          strFinanFrom: _HT.strFinanFrom,
          strTransNo: _HT.strTransNo,
          strVouchNo: _HT.strVouchNo,
          strVouchDate: _HT.strVouchDate,
          iAddLessBillDiscId: _Common.iALBillDiscId,
          iBillTypeId: _HT.iBillTypeId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iTaxTypeId: _HT.iTaxRelId,
          iDMId: _HT.iDMId,
          strConvertDCVouch: _HT.strConvertDCVouch,
          strPartyDCVouch: _HT.strPartyDCVouch,
          strRefNum1: _HT.strRefNum1,
          dpRefNum1Date: _HT.dpRefNum1Date,
          strRefNum2: _HT.strRefNum2,
          dpRefNum2Date: _HT.dpRefNum2Date,
          strRefNum3: _HT.strRefNum3,
          dpRefNum3Date: _HT.dpRefNum3Date,
          strRefNum4: _HT.strRefNum4,
          dpRefNum4Date: _HT.dpRefNum4Date,
          strRemark1: _HT.strRemark1,
          strRemark2: _HT.strRemark2,
          strRemark3: _HT.strRemark3,
          strRemark4: _HT.strRemark4,
          strValidity: _HT.strValidity,
          dpValDateUpto: _HT.dpValDateUpto,
          strPayTerm: _HT.strPayTerm,
          dpPayTermDateUpto: _HT.dpPayTermDateUpto,
          strDeliTerm: _HT.strDeliTerm,
          dpDelTermDateUpto: _HT.dpDelTermDateUpto,
          strEComGstNo: _HT.strEComGstNo,
          strTransport: _HT.strTransport,
          strVehicle: _HT.strVehicle,
          strDespatch: _HT.strDespatch,
          dpDespatchDate: _HT.dpDespatchDate,
          strLRNNumber: _HT.strLRNNumber,
          dpLRNoDate: _HT.dpLRNoDate,
          strAgent: _HT.strAgent,
          strReference: _HT.strReference,
          strDestination: _HT.strDestination,

          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dTBillDiscValue: Number(_Disc.dBillDisValue),
          dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
          dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
          dTAddLessDiscValue: Number(_Disc.dALBillDisValue),

          dNetAmt: Number(_Sum.NetAmt),
          dTotQty: Number(_Sum.Qty),
          dTotGoodval: Number(_Sum.GoodsValue),
          dTotDiscount: Number(_Sum.Discount),
          dTotTax: Number(_Sum.Tax),
          dAddLess: Number(_Sum.AddLess),
          dTotTCSTax: Number(_Sum.TCSTax),
          dRoundOff: Number(_Sum.RoundOff),
        };
        await api.post(`${_Common.Controller}/InsUpd_Voucher`, {
          objEntSI: objParems,
          objBaseTransDT: _DT.arrEntDT,
          objPenConDT: _DT.arrEntPenCon,
          objDTHelp: _DT.arrEntDTHelp,
          objEntALDT: _DT.arrEntAL,
          objEntTax: _DT.arrEntTax,
          iUserId: Number(localStorage.UserId),
          FYid: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
        }).then((Res) => {
          if (Res.data.strStatus === "Success") {
            Clear_Click();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Click = async () => {
    try {
      await dispatch(Clear_Transaction());
      await Load_Transaction(_Common, dispatch);
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const AlertMessage = async (Message, Icon) => {
    await dispatch(
      Show_MsgBox({
        Message: Message,
        Type: Icon,
        isMsg: true,
      })
    );
  };
  return <Transaction Save={SaveClick} ScrId={_iScrId} />;
}
export default SalesOrder;
