import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import $ from "jquery";
import SideMenu from "../General/SideMenu";

import ReactTable from "../General/ReactTable";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import MasterCodeSetting from "../Master/MasterCodeSetting";
function Attribute() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 60;
  const Curr_Date = new Date().toISOString().split("T")[0];

  const [iAttId, setiAttId] = useState(0);
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [strAttName, setstrAttName] = useState("");
  const [strAttCode, setstrAttCode] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [iDimensionId, setiDimensionId] = useState(1);
  const [iValueType, setiValueType] = useState(1);

  const [ListValueType, setListValueType] = useState([]);
  const [ListDimension, setListDimension] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  const [ListAttList, setListAttList] = useState([]);
  const [IsMasterCode, setIsMasterCode] = useState(false);

  const [strAttValue, setstrAttValue] = useState("");
  const [strAlias, setstrAlias] = useState("");
  const [bIsDefault, setbIsDefault] = useState(false);
  const [ManuFacturingDate, setManuFacturingDate] = useState(Curr_Date);
  const [ExpiryDate, setExpiryDate] = useState(Curr_Date);
  const [AttDTId, setAttDTId] = useState(0);
  const [iRowIndex, setiRowIndex] = useState(0);
  const [bUpdateStatus, setbUpdateStatus] = useState(false);
  const [IsText, setIsText] = useState(true);
  const [arrEntAttDT, setarrEntAttDT] = useState([]);

  useEffect(() => {
    Load_Attribute();
  }, []);
  const Load_Attribute = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await api.post(`Attribute/Load_Attribute`, parems).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setListValueType(Res.data.EntAttType);
            setListDimension(Res.data.EntAttDim);
            setListFilter(Res.data.EntFilter);
            setListAttList(Res.data.EntAttList);
            setstrPrefix(Res.data.EntCodeFormate[0].strPrefix);
            setstrAttCode(Res.data.EntCodeFormate[0].strCode);
            setiNumSeqNo(Res.data.EntCodeFormate[0].iNumSeqNo);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objAtt = {
        iAttId: iAttId,
        strAttName: strAttName,
        strAttCode: strAttCode,
        strDescription: strDescription,
        strRemarks: strRemarks,
        iDimensionId: Number(iDimensionId),
        iValueType: Number(iValueType),
        strAttValue: strAttValue,
        iTitleTag: iScrId,
        iNumSeqNo: iNumSeqNo,
        strFilter: "",
        strSearch: "",
        strFilterColumn: "All",
        strAttCodeTag: "",
        iTextBoxLength: arrEntAttDT.filter((e) => e.AttValue !== "").length,
        iDateLength: arrEntAttDT.filter((e) => e.AttValue !== "").length,
        strPrefix: strPrefix,
        iAttValueTypeId: Number(iValueType),
      };
      const InsUpd = {
        EntTextDT: arrEntAttDT,
        EntDateDT: arrEntAttDT,
        objAttribute: objAtt,
        strCompanyDB: localStorage.db,
      };
      await api.post(`Attribute/InsUpd_Attribute/`, InsUpd).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Attribute = async (Att) => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(
        `Attribute/View_Attribute/${iScrId}/${Att.iAttId}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setiAttId(Att.iAttId);
          setstrAttName(Att.strAttName);
          setstrAttCode(Att.strAttCode);
          setiDimensionId(Att.iDimensionId);
          setiValueType(Att.iValueType);
          setstrDescription(Att.strDescription);
          setstrRemarks(Att.strRemarks);
          setstrPrefix(Att.strPrefix);
          setiNumSeqNo(Att.iNumSeqNo);
          setarrEntAttDT(Res.data.EntAttribute);
          if (Att.iValueType === 2) setIsText(false);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        `Attribute/Delete_Attribute/${iScrId}/${iAttId}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiAttId(0);
    setstrAttName("");
    setAttDTId(0);
    setAttDTId(0);
    setstrAttValue("");
    setstrAttCode("");
    setiDimensionId(1);
    setiValueType(iValueType);
    setstrDescription("");
    setstrRemarks("");
    setstrAlias("");
    setstrPrefix("");
    setbIsDefault(false);
    setIsText(false);
    setManuFacturingDate(Curr_Date);
    setExpiryDate(Curr_Date);
    setAttDTId(0);
    setListAttList([]);
    setarrEntAttDT([]);
    Load_Attribute();
  };
  const ValueType_Onchange = async (val) => {
    await setIsText(Number(val) === 1 ? true : false);
    await setiValueType(val);
    await Clear_AttLvl();
    await setarrEntAttDT([]);
  };
  const Add_AttributeValues = async () => {
    try {
      if (strAttValue.length === 0)
        AlertMessage("Please Enter AttributeValue", "info");
      else {
        await dispatch(setload_Screen(true));
        const objParems = {
          EntTextDT: arrEntAttDT,
          EntDateDT: arrEntAttDT,
          strCompanyDB: localStorage.db,
          iRowIndex: iRowIndex,
          bUpdateStatus: bUpdateStatus,
          iValueType: Number(iValueType),
          AttDTId: AttDTId,
          AttValue: strAttValue,
          Default: bIsDefault,
          Alias: strAlias,
          ManufactureDate: ManuFacturingDate,
          ExpiryDate: ExpiryDate,
        };
        await api.post(
          `Transaction/Add_AttributeValues/`,
          objParems
        ).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await setarrEntAttDT(Res.data.EntAttDT);
            await Clear_AttLvl();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Modify_AttributeValues = (RowInfo, Index) => {
    setbUpdateStatus(true);
    setiRowIndex(Index);
    setstrAlias(RowInfo.alias);
    setstrAttValue(RowInfo.attValue);
    setbIsDefault(RowInfo.default);
    setAttDTId(RowInfo.attDTId);
    if (iValueType === 2) {
      setManuFacturingDate(RowInfo.manufactureDate);
      setExpiryDate(RowInfo.expiryDate);
    }
  };
  const Remove_AttributeValues = async (rowinfo) => {
    try {
      if (strAttValue.length === 0)
        AlertMessage("Please Enter AttributeValue", "info");
      else {
        await dispatch(setload_Screen(true));
        const objParems = {
          EntTextDT: arrEntAttDT,
          EntDateDT: arrEntAttDT,
          strCompanyDB: localStorage.db,
          iRowIndex: rowinfo.sNo,
          bUpdateStatus: bUpdateStatus,
          iValueType: Number(iValueType),
          AttDTId: AttDTId,
          AttValue: strAttValue,
          Default: bIsDefault,
          Alias: strAlias,
          ManufactureDate: ManuFacturingDate,
          ExpiryDate: ExpiryDate,
        };
        await api.post(
          `Transaction/Remove_AttributeValues/`,
          objParems
        ).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await setarrEntAttDT(Res.data.EntAttDT);
            await Clear_AttLvl();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_AttLvl = () => {
    setstrAttValue("");
    setstrAlias("");
    setbIsDefault(false);
    setManuFacturingDate(Curr_Date);
    setExpiryDate(Curr_Date);
    setAttDTId(0);
    setiRowIndex(0);
    setbUpdateStatus(false);
  };
  const Search_Attribute = async (strtxt) => {
    try {
      const objSearch = {
        strColName: "All",
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(`Attribute/Search_Attribute/`, objSearch).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setListAttList(Res.data.EntAttribute);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const TableColumns = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Default",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input type="checkbox" readOnly checked={rowinfo.default} />
      ),
    },
    {
      Header: "AttributeValue",
      accessor: "attValue",
      style: { justifyContent: "left" },
    },
    {
      Header: "Alias",
      accessor: "alias",
      style: { justifyContent: "left" },
    },
    {
      Header: "ManufactureDate",
      accessor: "manufactureDate",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
      show: !IsText,
    },
    {
      Header: "ExpiryDate",
      accessor: "expiryDate",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
      show: !IsText,
    },
    {
      Header: "Delete",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={() => Remove_AttributeValues(rowinfo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const TabIndex = (e) => {
    var maxTab = 17;
    var ActiveIndex = document.activeElement.tabIndex;
    let AttList = arrEntAttDT;
    var ProductListTab = iValueType === 1 ? 10 : 12;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ProductListTab && AttList.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ProductListTab && AttList.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ProductListTab && AttList.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ProductListTab && AttList.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ProductListTab && AttList.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ProductListTab && AttList.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={ListFilter}
          EntList={ListAttList}
          GetDetails={View_Attribute}
          Search={Search_Attribute}
          ScreenID={iScrId}
          accessor="strAttName"
          accessorid="iAttId"
          ListTab={17}
          FilterTab={15}
          SearchTab={16}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row ">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Attribute Name"
                  onClick={(e) => e.target.select()}
                  value={strAttName}
                  onChange={(e) => setstrAttName(e.target.value)}
                  autoFocus
                  tabIndex={1}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>
                  Code<span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={strAttCode}
                    onChange={(e) => setstrAttCode(e.target.value)}
                    tabIndex={0}
                    onKeyDown={(e) => TabIndex(e)}
                    readOnly
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Remarks"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                  tabIndex={2}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Description</label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  aria-label="First name"
                  className="form-control"
                  value={strDescription}
                  placeholder="Enter Description"
                  onChange={(e) => setstrDescription(e.target.value)}
                  tabIndex={3}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Value Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select  Value Type"
                  select_value={(val) => ValueType_Onchange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iValueType}
                  displayName="strAttValueTypeName"
                  disvalue="iAttValueTypeId"
                  arrydata={ListValueType}
                  EmptVal="Value Type"
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>
                  Dimension<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select Dimension"
                  select_value={(val) => setiDimensionId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iDimensionId}
                  displayName="strAttDimName"
                  disvalue="iAttDimId"
                  arrydata={ListDimension}
                  EmptVal="Dimension"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12 mt-1">
                <label>{IsText ? "Attribute Value" : "Batch No"}</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder={`Enter  ${
                    IsText ? "Attribute Value" : "Batch No"
                  }`}
                  onClick={(e) => e.target.select()}
                  value={strAttValue}
                  tabIndex={6}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrAttValue(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Alias Name</label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder="Enter Attribute Alias Name"
                  className="form-control"
                  tabIndex={7}
                  onClick={(e) => e.target.select()}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strAlias}
                  onChange={(e) => setstrAlias(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Manufacturing Date</label>
                <input
                  type="date"
                  aria-label="First name"
                  className="form-control"
                  value={ManuFacturingDate}
                  onChange={(e) => setManuFacturingDate(e.target.value)}
                  tabIndex={8}
                  onKeyDown={(e) => TabIndex(e)}
                  disabled={IsText}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Expiry Date</label>
                <input
                  type="date"
                  aria-label="First name"
                  className="form-control"
                  value={ExpiryDate}
                  onChange={(e) => setExpiryDate(e.target.value)}
                  tabIndex={9}
                  onKeyDown={(e) => TabIndex(e)}
                  disabled={IsText}
                />
              </div>
              <div className="col-md-2 col-sm-12 mt-1">
                <label>Defalut Attribute</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input mlt-2"
                    type="checkbox"
                    tabIndex={8}
                    onKeyDown={(e) => TabIndex(e)}
                    checked={bIsDefault}
                    onChange={(e) => setbIsDefault(!bIsDefault)}
                  />
                </div>
              </div>
              <div className="col-md-1 col-sm-12 mt-1">
                <div className="btn-sub-section mt-3">
                  <button
                    onClick={Add_AttributeValues}
                    className="btn-fabgreen"
                    tabIndex={9}
                    onKeyDown={(e) => TabIndex(e)}
                  >
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                </div>
              </div>
              <div className="p-2">
                <ReactTable
                  columns={TableColumns}
                  data={arrEntAttDT}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {
                    Modify_AttributeValues(rowInfo, Index);
                  }}
                  background={true}
                  className="full-table"
                />
              </div>
            </div>
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={iScrId}
              />
            )}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (strAttCode === "") {
                AlertMessage("Please Enter Attribute Code", "info");
              } else if (strAttName === "") {
                AlertMessage("Please Enter Attribute Name", "info");
              } else {
                if (iAttId > 0) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Update Attribute?",
                      isdialog: true,
                    })
                  );
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Attribute?",
                      isdialog: true,
                    })
                  );
                }
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iAttId === 0) {
                AlertMessage("Please Select Attribute", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Attribute ?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={iScrId}
            SaveIndex={11}
            DeleteIndex={12}
            CloseIndex={14}
            ClearIndex={13}
            ExportIndex={""}
            onKeyDown={(e) => TabIndex(e)}
          />
        </div>
      </div>
    </>
  );
}
export default Attribute;
