import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import MasterList from "../Master/MasterListItem";
import ProductSearch from "../Transaction/ProductSearch";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import ProductCreation from "../Transaction/ProductCreation";
import ProductAttributeView from "../Master/ProductAttributeView";
import {
  Clear_Product,
  Modify_ProductionProduct,
  Select_Unit,
  ShowHide_Popup,
  setProductionTrans,
} from "../ReduxStore/Transactionstates";
import MasterCodeSetting from "../Master/MasterCodeSetting";
function ProductionOrder() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _Product = useSelector((store) => store.states.Product);
  const _HT = useSelector((store) => store.states.Header);
  const iScrId = 70;
  const Curr_Date = new Date().toISOString().split("T")[0];
  const Curr_Time = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const [arrEntBOM, setarrEntBOM] = useState([]);
  const [arrEntRouteGroup, setarrEntRouteGroup] = useState([]);
  const [arrEntPODT, setarrEntPODT] = useState([]);
  const [arrNOFormat, setarrNOFormat] = useState([0]);
  const [arrStatus, setarrStatus] = useState([0]);
  const [arrFilterCol, setarrFilterCol] = useState([]);
  const [arrEntPO, setarrEntPO] = useState([]);
  const [iPOId, setiPOId] = useState(0);
  const [strPOCode, setstrPOCode] = useState("");
  const [strDate, setstrDate] = useState(Curr_Date);
  const [ManualCode, setManualCode] = useState(false);

  const [iBOMId, setiBOMId] = useState(0);
  const [iRGId, setiRGId] = useState(0);
  const [iStatusId, setiStatusId] = useState(1);
  const [dPlanedQty, setdPlanedQty] = useState(0);
  const [dProductionQty, setdProductionQty] = useState(0);
  const [dSuspendedQty, setdSuspendedQty] = useState(0);
  const [dRejectedQty, setdRejectedQty] = useState(0);
  const [dConvertedQty, setdConvertedQty] = useState(0);
  const [dpStartDate, setdpStartDate] = useState(Curr_Date);
  const [tStartTime, settStartTime] = useState(Curr_Time());
  const [dpEndDate, setdpEndDate] = useState(Curr_Date);
  const [tEndTime, settEndTime] = useState(Curr_Time());
  const [strRemark, setstrRemark] = useState("");

  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [btnSave, setbtnSave] = useState(false);
  const [btnDelete, setbtnDelete] = useState(false);

  useEffect(() => {
    Load_ProductionOrder();
  }, []);

  const Load_ProductionOrder = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };

      await dispatch(setload_Screen(true));
      await api.post(
        `ProductionOrder/Load_ProductionOrder`,
        parems
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          setarrFilterCol(Res.data.objEntComBoxVa);
          setarrNOFormat(Res.data.objEntNoFormat);
          setarrEntPO(Res.data.objEntProdOrder);
          setarrStatus(Res.data.objEntStatus);
          setarrEntBOM(Res.data.objEntBOM);
          setarrEntRouteGroup(Res.data.objEntRG);
          setstrPOCode(Res.data.objEntNoFormat[0].strCode);
          setManualCode(!Res.data.objEntNoFormat[0].isManualCode);
          TabOrderAssign(Res.data.Transtab);
          await dispatch(
            setProductionTrans({
              iScrId: iScrId,
              strScrType: "JobWork",
              strScrName: "Production Order",
            })
          );
          await Validate_BtnPermission(0, 1);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Select_BOM = async (BomId) => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(
        
          "ProductionOrder/Check_BOMProductExists/" +
          Number(_Product.iProId) +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setiBOMId(BomId);
        } else {
          setiBOMId(0);
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Select_RouteGroup = async (RGId) => {
    try {
      await dispatch(setload_Screen(true));
      setiRGId(RGId);
      await api.get(
        
          "ProductionOrder/View_RouteGrpJobs/" +
          Number(RGId) +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntPODT(Res.data.EntRouteGroup);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objPOHTList = {
        iScrId: iScrId,
        iPOId: iPOId,
        dpPODate: strDate,
        strPOCode: strPOCode,
        iNumSeqNo: arrNOFormat[0].iNumSeqNo,
        strPrefix: arrNOFormat[0].strPrefix,

        strProCode: _Product.strProCode,
        iProId: Number(_Product.iProId),
        strProName: _Product.strProName,
        iUnitId: Number(_Product.iUnitId),
        strProductDimAttGroupId: _Product.strProductDimAttGroupId,
        strStorageDimAttGroupId: _Product.strStorageDimAttGroupId,
        strTrackingDimAttGroupId: _Product.strTrackingDimAttGroupId,
        strProductDimAttId: _Product.strProductDimAttId,
        strStorageDimAttId: _Product.strStorageDimAttId,
        strTrackingDimAttId: _Product.strTrackingDimAttId,
        strProductDimAttDTId: _Product.strProductDimAttDTId,
        strStorageDimAttDTId: _Product.strStorageDimAttDTId,
        strTrackingDimAttDTId: _Product.strTrackingDimAttDTId,
        PlanedQty: Number(dPlanedQty),
        RejectedQty: Number(dRejectedQty),
        SuspendedQty: Number(dSuspendedQty),
        ProductionQty: Number(dProductionQty),
        ConvertedQty: Number(dConvertedQty),
        dpStartDate: dpStartDate,
        dpEndDate: dpEndDate,
        strStartTime: tStartTime,
        strEndTime: tEndTime,
        strRemark: strRemark,
        iRGId: Number(iRGId),
        iBOMId: Number(iBOMId),
        iStatusId: Number(iStatusId),
        strBOMName: "",
        strRGName: "",
        strStsName: "",
        strUnitName: "",
      };
      await api.post(`ProductionOrder/InsUpd_ProductionOrder/`, {
        iPOId: iPOId,
        objPOModel: objPOHTList,
        objPODTModel: arrEntPODT,
        FYId: Number(localStorage.FYId),
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
      }).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = async () => {
    setarrEntPODT([]);
    setstrDate(Curr_Date);
    setiPOId(0);
    setiBOMId(0);
    setiRGId(0);
    setdPlanedQty(0);
    setiStatusId(1);
    setdProductionQty(0);
    setdSuspendedQty(0);
    setdRejectedQty(0);
    setdConvertedQty(0);
    setdpStartDate(Curr_Date);
    settStartTime(Curr_Time());
    setdpEndDate(Curr_Date);
    settEndTime(Curr_Time());
    setstrRemark("");
    await dispatch(Clear_Product());
    Load_ProductionOrder();
  };
  const Delete_ProductionOrder = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.delete(
        `ProductionOrder/Delete_ProductionOrder/${Number(
          iPOId
        )}/${Number(iScrId)}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_ProductionOrder = async (PO) => {
    try {
      await dispatch(setload_Screen(true));
      await api.post( "ProductionOrder/View_ProductionOrder/", {
        objModel: PO,
        strCompanyDB: localStorage.db,
      }).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          const EntHT = Res.data.POHT;
          setiPOId(EntHT.iPOId);
          setstrPOCode(EntHT.strPOCode);
          setstrDate(EntHT.dpPODate);
          const NoFormat = [
            {
              iNumSeqNo: EntHT.iNumSeqNo,
              strPrefix: EntHT.strPrefix,
            },
          ];
          setarrNOFormat(NoFormat);
          setiRGId(EntHT.iRGId);
          setiBOMId(EntHT.iBOMId);
          setiStatusId(EntHT.iStatusId);
          setdConvertedQty(EntHT.convertedQty);
          setdProductionQty(EntHT.productionQty);
          setdPlanedQty(EntHT.planedQty);
          setdRejectedQty(EntHT.rejectedQty);
          setdSuspendedQty(EntHT.suspendedQty);
          setdpStartDate(EntHT.dpStartDate);
          settStartTime(EntHT.strStartTime);
          setdpEndDate(EntHT.dpEndDate);
          settEndTime(EntHT.strEndTime);
          setstrRemark(EntHT.strRemark);
          setarrEntPODT(Res.data.PODT);
          await Validate_BtnPermission(EntHT.iPOId, EntHT.isConverted);
          EntHT.Unit = Res.data.Unit;
          await dispatch(Modify_ProductionProduct(EntHT));
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_ProductionOrder = async (strtxt) => {
    try {
      const objsearch = {
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(
         "ProductionOrder/Search_ProductionOrder/",
        objsearch
      ).then((Res) => {
        if (Res.data.strStatus === "Success")
          setarrEntPO(Res.data.objEntProdOrder);
        else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const [arrTabOrder, setarrTabOrder] = useState({
    btnClose: "",
    btnSave: "",
    btnNew: "",
    btnModify: "",
    btnClear: "",
    btnDelete: "",
    btnSettings: "",
    btnHelp: "",
    txtProductName: "",
    txtProductCode: "",
    txtBOM: "",
    txtRouteGroup: "",
    cbUOM: "",
    txtPlanedQty: "",
    txtRejectedQty: "",
    txtSuspendedQty: "",
    txtProductionQty: "",
    dpProductionStartDate: "",
    dpProductionEndDate: "",
    txtProductionStartTime: "",
    txtProductionEndTime: "",
    cbProductionStatus: "",
    txtProductionCode: "",
    txtRemarks: "",
    ProductListTab: "",
    ProductFIlterTab: "",
    ProductSearchTab: "",
  });
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = arrTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "btnClose":
          TabOrder.btnClose = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder.btnSave = EntProperties[i].iTabIndex;
          break;
        case "btnNew":
          TabOrder.btnNew = EntProperties[i].iTabIndex;
          break;
        case "btnModify":
          TabOrder.btnModify = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder.btnClear = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder.btnDelete = EntProperties[i].iTabIndex;
          break;
        case "btnSettings":
          TabOrder.btnSettings = EntProperties[i].iTabIndex;
          break;
        case "btnHelp":
          TabOrder.btnHelp = EntProperties[i].iTabIndex;
          break;
        case "txtProductName":
          TabOrder.txtProductName = EntProperties[i].iTabIndex;
          break;
        case "txtProductCode":
          TabOrder.txtProductCode = EntProperties[i].iTabIndex;
          break;
        case "txtBOM":
          TabOrder.txtBOM = EntProperties[i].iTabIndex;
          break;
        case "txtRouteGroup":
          TabOrder.txtRouteGroup = EntProperties[i].iTabIndex;
          break;
        case "cbUOM":
          TabOrder.cbUOM = EntProperties[i].iTabIndex;
          break;
        case "txtPlanedQty":
          TabOrder.txtPlanedQty = EntProperties[i].iTabIndex;
          break;
        case "txtRejectedQty":
          TabOrder.txtRejectedQty = EntProperties[i].iTabIndex;
          break;
        case "txtSuspendedQty":
          TabOrder.txtSuspendedQty = EntProperties[i].iTabIndex;
          break;
        case "txtProductionQty":
          TabOrder.txtProductionQty = EntProperties[i].iTabIndex;
          break;
        case "dpProductionStartDate":
          TabOrder.dpProductionStartDate = EntProperties[i].iTabIndex;
          break;
        case "dpProductionEndDate":
          TabOrder.dpProductionEndDate = EntProperties[i].iTabIndex;
          break;
        case "txtProductionStartTime":
          TabOrder.txtProductionStartTime = EntProperties[i].iTabIndex;
          break;
        case "txtProductionEndTime":
          TabOrder.txtProductionEndTime = EntProperties[i].iTabIndex;
          break;
        case "cbProductionStatus":
          TabOrder.cbProductionStatus = EntProperties[i].iTabIndex;
          break;
        case "txtProductionCode":
          TabOrder.txtProductionCode = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder.txtRemarks = EntProperties[i].iTabIndex;
          break;
        case "lbProduct":
          TabOrder.ProductListTab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder.ProductFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder.ProductSearchTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setarrTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrTabOrder.ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Process",
      accessor: "jobName",
      style: { justifyContent: "left" },
    },
    {
      Header: "ProcessingQty",
      accessor: "processingQty",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "End Time",
      accessor: "endTime",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "FinishedQty",
      accessor: "finishedQty",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Unit_Onchange = (UnitId) => {
    api.get(
      
        "Transaction/Unit_Onchange/" +
        Number(UnitId) +
        "/" +
        _Product.iProId +
        "/" +
        _HT.iPartyId +
        "/" +
        _HT.iTaxRelId +
        "/" +
        _Common.strScrType +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        dispatch(
          Select_Unit({ dPriceRate: res.data.dPriceRate, UnitId: UnitId })
        );
      } else {
        AlertMessage(res.data.message, "error");
      }
    });
  };
  const Validate_BtnPermission = (POId, StatusId) => {
    let Menu = JSON.parse(localStorage.Menu);
    Menu = Menu.objEntJPM.filter((Data) => Data.iScrId === iScrId)[0];
    const BtnPerm = {
      btnSave: !Menu.isNew,
      btnView: !Menu.isView,
      btnDelete: !Menu.isCancel,
    };
    if (Number(POId) > 0) {
      BtnPerm.btnSave =
        !Menu.isModify === false
          ? StatusId === 1
            ? true
            : false
          : !Menu.isModify;
      BtnPerm.btnDelete =
        BtnPerm.btnDelete === false
          ? StatusId === 1
            ? true
            : false
          : BtnPerm.btnDelete;
    }
    setbtnSave(BtnPerm.btnSave);
    setbtnDelete(BtnPerm.btnDelete);
  };
  const Update_RouteGroup = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParem = {
        ProcessingQty: Number(dPlanedQty),
        FinishedQty: Number(dProductionQty),
        StartDate: dpStartDate,
        StartTime: tStartTime,
        EndDate: dpEndDate,
        EndTime: tEndTime,
        EntRG: arrEntPODT,
        strCompanyDB: localStorage.db,
      };
      await api.post(
        `ProductionOrder/Update_RouteGrpJobs/`,
        objParem
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntPODT(Res.data.EntRouteGroup);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={arrFilterCol}
          EntList={arrEntPO}
          GetDetails={View_ProductionOrder}
          Count={0}
          Search={Search_ProductionOrder}
          ScreenID={iScrId}
          accessor="strPOCode"
          accessorid="iPOId"
          ListTab={arrTabOrder.ProductListTab}
          FilterTab={arrTabOrder.ProductFIlterTab}
          SearchTab={arrTabOrder.ProductSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Product Name <span className="danger">*</span>
                </label>
                <div className="custom-select">
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    id="strProName"
                    name="strProName"
                    autoComplete="off"
                    value={_Product.strProName}
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        dispatch(ShowHide_Popup("IsProSearch"));
                    }}
                    onClick={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
                    placeholder="Press Enter or Click to Product"
                    tabIndex={arrTabOrder.txtProductName}
                    autoFocus
                    disabled={Number(iPOId) > 0 ? true : false}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: _Common.IsProSearch
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Unit</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Select Unit"
                  select_value={(val) => Unit_Onchange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={_Product.iUnitId}
                  displayName="strUnitName"
                  disvalue="iUnitId"
                  arrydata={_Product.Unit}
                  EmptVal="Unit"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>
                  PO Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    id="strPOCode"
                    name="strPOCode"
                    autoComplete="off"
                    value={strPOCode}
                    onChange={(e) => setstrPOCode(e.target.value)}
                    readOnly={ManualCode}
                  />{" "}
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Date </label>
                <input
                  type="date"
                  name="strDate"
                  className="form-control"
                  value={strDate}
                  onChange={(e) => setstrDate(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Remark </label>
                <input
                  type="text"
                  name="strRemark"
                  className="form-control"
                  autoComplete="off"
                  value={strRemark}
                  onChange={(e) => setstrRemark(e.target.value)}
                  placeholder="Enter Remarks"
                  tabIndex={arrTabOrder.txtRemarks}
                  onKeyDown={(e) => TabIndex(e)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>BOM</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select BOM"
                  select_value={(val) => Select_BOM(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iBOMId}
                  displayName="strBOMName"
                  disvalue="iBOMId"
                  arrydata={arrEntBOM}
                  EmptVal="BOM"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Route Group</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select Route Group"
                  select_value={(val) => Select_RouteGroup(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iRGId}
                  displayName="strRGName"
                  disvalue="iRGId"
                  arrydata={arrEntRouteGroup}
                  EmptVal=" Route Group"
                  disabled={Number(iBOMId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Status"
                  select_value={(val) => setiStatusId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatusId}
                  displayName="psName"
                  disvalue="psId"
                  arrydata={arrStatus}
                  EmptVal="Status"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Planed Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dPlanedQty"
                  autoComplete="off"
                  id="dPlanedQty"
                  value={dPlanedQty}
                  tabIndex={arrTabOrder.txtPlanedQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdPlanedQty(e.target.value)}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    setdPlanedQty(Number(e.target.value));
                    Update_RouteGroup();
                  }}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Suspended Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dSuspendedQty"
                  autoComplete="off"
                  id="dSuspendedQty"
                  value={dSuspendedQty}
                  tabIndex={arrTabOrder.txtSuspendedQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdSuspendedQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setdSuspendedQty((0).toFixed(2));
                    }
                  }}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Rejected Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dRejectedQty"
                  autoComplete="off"
                  id="dRejectedQty"
                  value={dRejectedQty}
                  tabIndex={arrTabOrder.txtRejectedQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdRejectedQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setdRejectedQty((0).toFixed(2));
                    }
                  }}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Production Qty</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dProductionQty"
                  id="dProductionQty"
                  autoComplete="off"
                  value={dProductionQty}
                  tabIndex={arrTabOrder.txtProductionQty}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setdProductionQty(e.target.value)}
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => {
                    setdProductionQty(Number(e.target.value));
                    Update_RouteGroup();
                  }}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Start Date </label>
                <input
                  type="date"
                  name="dpStartDate"
                  className="form-control"
                  value={dpStartDate}
                  onChange={(e) => setdpStartDate(e.target.value)}
                  tabIndex={arrTabOrder.dpProductionStartDate}
                  onKeyDown={(e) => TabIndex(e)}
                  onBlur={(e) => Update_RouteGroup()}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Start Time </label>
                <input
                  type="time"
                  className="form-control"
                  value={tStartTime}
                  onChange={(e) => settStartTime(e.target.value)}
                  onBlur={(e) => Update_RouteGroup()}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>End Date </label>
                <input
                  type="date"
                  name="dpEndDate"
                  className="form-control"
                  value={dpEndDate}
                  onChange={(e) => setdpEndDate(e.target.value)}
                  tabIndex={arrTabOrder.dpProductionEndDate}
                  onKeyDown={(e) => TabIndex(e)}
                  onBlur={(e) => Update_RouteGroup()}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>End Time </label>
                <input
                  type="time"
                  className="form-control"
                  value={tEndTime}
                  onChange={(e) => settEndTime(e.target.value)}
                  onBlur={(e) => Update_RouteGroup()}
                  disabled={Number(iRGId) === 0 ? true : false}
                />
              </div>
              <ReactTable
                columns={Column}
                data={arrEntPODT}
                minRows={4}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {}}
                background={true}
                className="full-table"
              />
            </div>
            <Messagedialogbox />
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={iScrId}
              />
            )}
            {_Common.IsProSearch && <ProductSearch />}
            {_Common.IsNewPro && <ProductCreation />}
            {_Common.IsAttSearch && <ProductAttributeView />}
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(_Product.iProId) === 0) {
                  AlertMessage("Please Select Product", "info");
                } else if (Number(iBOMId) === 0) {
                  AlertMessage("BOM is Required", "info");
                } else if (Number(iRGId) === 0) {
                  AlertMessage("Route Group is Required", "info");
                } else if (Number(dPlanedQty) === 0) {
                  AlertMessage("Planed Qty is Required", "info");
                } else if (
                  Number(iStatusId) === 2 &&
                  Number(dProductionQty) === 0
                ) {
                  AlertMessage("Production Qty is Required", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save  Production?",
                      isdialog: true,
                    })
                  );
                }
              }}
              disabled={btnSave}
              tabIndex={arrTabOrder.btnSave}
              onKeyDown={(e) => TabIndex(e)}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={ClearClick}
              tabIndex={arrTabOrder.btnClear}
              onKeyDown={(e) => TabIndex(e)}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex={arrTabOrder.btnDelete}
              onKeyDown={(e) => TabIndex(e)}
              disabled={btnDelete}
              onClick={(e) => {
                if (iPOId === 0) {
                  AlertMessage("Please Select Production", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Delete_ProductionOrder,
                      Question: "Do You Want to Delete Production?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() =>
                (window.location.href =
                  "/Dashboard")
              }
              tabIndex={arrTabOrder.btnClose}
              onKeyDown={(e) => TabIndex(e)}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductionOrder;
