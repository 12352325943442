import React, { useState } from "react";
import PartySearch from "../Transaction/PartySearch";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useSelector } from "react-redux";

function SettlementInput(props) {
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [IsPartyOpen, setIsPartyOpen] = useState(false);

  const View_Party = (Party) => {
    setIsPartyOpen(!IsPartyOpen);
    props.View_Party(Party);
  };

  return (
    <div className="row p-2">
      <div className="col-md-3 col-sm-12">
        <label>
          Vendor Name <span className="danger">*</span>
        </label>
        <div className="custom-select">
          <input
            type="text"
            aria-label="First name"
            className="form-control"
            name="VenName"
            id="partyVenName"
            value={props.VenName}
            onChange={(e) => props.OnChange(e.target.value)}
            onClick={(e) => setIsPartyOpen(!IsPartyOpen)}
            placeholder="Press Enter to Select Vendor"
            autoFocus
          />
          <i className="fa-solid fa-caret-down"></i>
        </div>
      </div>
      <div className="col-md-3 col-sm-6">
        <label>Vendor Type</label>
        <CustomizedSelectoption
          optionparem="isoptionShow"
          showoption={_selecrparem.isoptionShow}
          placeholder="Select Vendor Type"
          select_value={(val) => props.OnVenTypeChange(val)}
          btnname=""
          btnshow={false}
          show_popup={""}
          valueonly={true}
          defaultval={props.VenTypeId}
          displayName="strVenTypeName"
          disvalue="iVenTypeId"
          arrydata={props.VenTypeList}
          EmptVal="Vendor Type"
          disabled={false}
        />
      </div>
      <div className="col-md-2 col-sm-6">
        <label>Voucher Type</label>
        <CustomizedSelectoption
          optionparem="isoptionShow1"
          showoption={_selecrparem.isoptionShow1}
          placeholder="Select Voucher Type"
          select_value={(val) => props.OnVouchTypeChange(val)}
          btnname=""
          btnshow={false}
          show_popup={""}
          valueonly={true}
          defaultval={props.ScrId}
          displayName="strScrName"
          disvalue="iScrId"
          arrydata={props.VouchTypeList}
          EmptVal="Voucher Type"
          disabled={false}
        />
      </div>
      <div className="col-md-2 col-sm-6">
        <label>From</label>
        <input
          type="date"
          name="FromDate"
          className="form-control"
          value={props.FromDate}
          onChange={(e) => props.View(e.target.value, "From")}
        />
      </div>
      <div className="col-md-2 col-sm-6">
        <label>To</label>
        <input
          type="date"
          name="ToDate"
          className="form-control"
          value={props.ToDate}
          onChange={(e) => props.View(e.target.value, "To")}
        />
      </div>
      {IsPartyOpen && (
        <PartySearch
          Close={() => setIsPartyOpen(!IsPartyOpen)}
          View_Party={View_Party}
          ScrId={props.PartyScrId}
          ColumnName={props.PartyScrColName}
          VenTypeId={props.VenTypeId}
          Partyname={props.VenName}
          isNew={false}
        />
      )}
    </div>
  );
}
export default SettlementInput;
