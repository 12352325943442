import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";
import Admintable from "./Admintable";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import ReactTable from "../General/ReactTable";
function Usermaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [iClientId, setiClientId] = useState(0);
  const [strMasterDB, setstrMasterDB] = useState("");
  const [arrEntUser, setarrEntUser] = useState([]);
  const [arrEntClients, setarrEntClients] = useState([]);

  const [IsUser, setIsUser] = useState(false);

  const [bIsNew, setbIsNew] = useState(false);
  const [bIsView, setbIsView] = useState(false);
  const [bIsModify, setbIsModify] = useState(false);
  const [bIsExport, setbIsExport] = useState(false);
  const [bIsDelete, setbIsDelete] = useState(false);
  const [bIsPrint, setbIsPrint] = useState(false);
  const [bIsNoAccess, setbIsNoAccess] = useState(false);
  const [bIsSelect, setbIsSelect] = useState(false);
  const [bIsShow, setbIsShow] = useState(false);

  const [isUserRole, setisUserRole] = useState(false);
  const [IsMaster, setIsMaster] = useState(true);
  const [IsTrans, setIsTrans] = useState(false);
  const [IsCompany, setIsCompany] = useState(false);
  const [IsReport, setIsReport] = useState(false);
  const [IsCompanyList, setIsCompanyList] = useState(false);
  const [iUserId, setiUserId] = useState(0);
  const [strName, setstrName] = useState("");
  const [dpDOB, setdpDOB] = useState(new Date().toISOString().split("T")[0]);
  const [dpDOJ, setdpDOJ] = useState(new Date().toISOString().split("T")[0]);
  const [iGender, setiGender] = useState(1);
  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strConfirmPassword, setstrConfirmPassword] = useState("");
  const [iRoleId, setiRoleId] = useState(1);
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [strPincode, setstrPincode] = useState("");
  const [iCountry, setiCountry] = useState(1);
  const [iState, setiState] = useState(32);
  const [strPhone, setstrPhone] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [iStatus, setiStatus] = useState(1);
  const [strRemarks, setstrRemarks] = useState("");
  const [iScrCatId, setiScrCatId] = useState(1);
  const [arrEntUserRole, setarrEntUserRole] = useState([]);
  const [arrEntScr, setarrEntScr] = useState([]);
  const [arrEntState, setarrEntState] = useState([]);
  const [arrEntCountry, setarrEntCountry] = useState([]);
  const [arrEntStatus, setarrEntStatus] = useState([]);
  const [arrEntGender, setarrEntGender] = useState([]);
  const [arrEntPermission, setarrEntPermission] = useState([]);
  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Clients").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntClients(res.data.EntClients);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_ClientUser = async (client) => {
    try {
      await ClearClick();
      setiClientId(client.clientId);
      setstrMasterDB(client.strMasterDB);
      await dispatch(setload_Screen(true));
      await api
        .get(`Admin/View_ClientUser/${client.strMasterDB}`)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            setarrEntUser(res.data.EntUser);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_User = async (user) => {
    try {
      setIsUser(!IsUser);
      await dispatch(setload_Screen(true));
      await api
        .get(`Admin/View_User/${user.iUserId}/${strMasterDB}`)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            setarrEntUserRole(res.data.EntUserRole);
            setarrEntScr(res.data.EntScrType);
            setarrEntState(res.data.EntState);
            setarrEntCountry(res.data.EntCountry);
            setarrEntStatus(res.data.EntStatus);
            setarrEntGender(res.data.EntGender);
            setarrEntPermission(res.data.EntPermiss);
            const User = res.data.EntUser[0];
            setiUserId(Number(User.iUserId));
            setiRoleId(Number(User.iUserRole));
            setiGender(Number(User.iGender));
            setstrName(User.strName);
            setdpDOB(User.dpDOB.split("T")[0]);
            setdpDOJ(User.dpDOJ.split("T")[0]);
            setstrUserName(User.strUserName);
            setstrPassword(User.strPassword);
            setstrConfirmPassword(User.strPassword);
            setstrAdd1(User.strAdd1);
            setstrAdd2(User.strAdd2);
            setstrAdd3(User.strAdd3);
            setstrAdd4(User.strAdd4);
            setstrPincode(User.strPincode);
            setiCountry(User.iCountry);
            setiState(User.istate);
            setstrPhone(User.strPhone1);
            setstrMobile(User.strMobile1);
            setstrEmail(User.strEmail);
            setiStatus(Number(User.iStatus));
            setstrRemarks(User.strRemarks);
            await View_PermColumn(Number(1));
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Load_UserPermssion = async (CatId, UId) => {
    try {
      setiScrCatId(Number(CatId));
      await dispatch(setload_Screen(true));
      await api
        .get(`Admin/Load_UserPermission/${UId}/${CatId}/${strMasterDB}`)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await View_PermColumn(Number(CatId));
            setarrEntPermission(res.data.EntPermiss);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Save_User = async () => {
    try {
      if (strUserName === "") {
        AlertMessage("Pleaser Enter Valid User Name", "info");
      } else if (strPassword === "") {
        AlertMessage("Pleaser Enter Valid Password", "info");
      } else if (strPassword === !strConfirmPassword) {
        AlertMessage("Password doesn't Match", "info");
      } else {
        const UserModel = {
          iUserId: iUserId,
          strName: strName,
          iGender: Number(iGender),
          dpDOB: dpDOB,
          strAdd1: strAdd1,
          strAdd2: strAdd2,
          strAdd3: strAdd3,
          strAdd4: strAdd4,
          strPincode: strPincode,
          iCountry: Number(iCountry),
          istate: Number(iState),
          strPhone1: strPhone,
          strPhone2: "",
          strMobile1: strMobile,
          strMobile2: "",
          strEmail: strEmail,
          iStatus: Number(iStatus),
          strRemarks: strRemarks,
          strUserName: strUserName,
          strPassword: strPassword,
          dpDOJ: dpDOJ,
          iUserRole: iRoleId,
          iClientId: Number(iClientId),
          strMasterDB: strMasterDB,
        };
        const objUser = {
          iUserId: iUserId,
          iRoleId: iRoleId,
          iCurrUserId: 0,
          iScrCategId: iScrCatId,
          strMasterDB: strMasterDB,
          objUser: UserModel,
          objEntMPM: arrEntPermission,
          objEntSPM: arrEntPermission,
          objEntTPM: arrEntPermission,
          objEntRPM: arrEntPermission,
          objEntCMPM: arrEntPermission,
          objEntCLPM: arrEntPermission,
        };
        await api.post("UserMaster/InsUpd_UserMaster", objUser).then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const ClearClick = () => {
    setIsUser(false);
    setiUserId(0);
    setiScrCatId(1);
    setiRoleId(1);
    setiGender(1);
    setstrName("");
    setdpDOB(new Date().toISOString().split("T")[0]);
    setdpDOJ(new Date().toISOString().split("T")[0]);
    setstrUserName("");
    setstrPassword("");
    setstrConfirmPassword("");
    setstrAdd1("");
    setstrAdd2("");
    setstrAdd3("");
    setstrAdd4("");
    setstrPincode("");
    setiCountry(1);
    setiState(32);
    setstrPhone("");
    setstrMobile("");
    setstrEmail("");
    setiStatus(1);
    setstrRemarks("");
    setarrEntUserRole([]);
    setarrEntUser([]);
    setarrEntScr([]);
    setarrEntState([]);
    setarrEntCountry([]);
    setarrEntStatus([]);
    setarrEntGender([]);
    setarrEntPermission([]);
    setiClientId(0);
    setstrMasterDB("");
  };
  const View_State = (CountryId) => {
    try {
      setiCountry(CountryId);
      api
        .get("UserMaster/Load_State/" + CountryId + "/" + strMasterDB)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            setarrEntState(res.data.EntState);
          } else {
            AlertMessage(res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const DeleteUser = async () => {
    try {
      await api
        .post(
          `UserMaster/Delete_User/${Number(iClientId)}/${Number(
            iUserId
          )}/${strMasterDB}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const PermissionAll_OnChange = (Value, ValueFor) => {
    let UpdatePermission = arrEntPermission;
    switch (ValueFor) {
      case "NEW":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isNew = Value;
          return Data;
        });
        break;
      case "VIEW":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isView = Value;
          return Data;
        });
        break;
      case "MODIFY":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isModify = Value;
          return Data;
        });
        break;
      case "DELETE":
        UpdatePermission = UpdatePermission.map((Data) => {
          if (Data.isDelete === undefined) {
            Data.isCancel = Value;
          } else if (Data.isCancel === undefined) {
            Data.isDelete = Value;
          }
          return Data;
        });
        break;
      case "PRINT":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isPrint = Value;
          return Data;
        });
        break;
      case "ACCESS":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isNOAccess = Value;
          return Data;
        });
        break;
      case "SELECT":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.bIsVisible = Value;
          return Data;
        });
        break;
      case "EXPORT":
        UpdatePermission = UpdatePermission.map((Data) => {
          Data.isExport = Value;
          return Data;
        });
        break;
      case "SHOW":
        UpdatePermission = UpdatePermission.map((Data) => {
          if (Data.bShowReport === undefined) Data.bShowCompany = Value;
          else if (Data.bShowCompany === undefined) Data.bShowReport = Value;
          return Data;
        });
        break;
      default:
        break;
    }
    setarrEntPermission(UpdatePermission);
  };
  const PermissionColumns = [
    {
      Header: "S.No",
      accessor: "sNo",
      style: { justifyContent: "center" },
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: "SCREEN NAME",
      accessor: "strScrName",
      style: { justifyContent: "left" },
      show: IsMaster || IsTrans,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsNew}
            onChange={(e) => {
              setbIsNew(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "NEW");
            }}
          />
          <span>New</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isNew}
          onChange={(e) => {
            rowinfo.isNew = !rowinfo.isNew;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsView}
            onChange={(e) => {
              setbIsView(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "VIEW");
            }}
          />
          <span>View</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isView}
          onChange={(e) => {
            rowinfo.isView = !rowinfo.isView;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsModify}
            onChange={(e) => {
              setbIsModify(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "MODIFY");
            }}
          />
          <span>Modify</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          checked={rowinfo.isModify}
          onChange={(e) => (rowinfo.isModify = !rowinfo.isModify)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsExport}
            onChange={(e) => {
              setbIsExport(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "EXPORT");
            }}
          />
          <span>Export</span>
        </div>
      ),
      isHeaderCell: true,
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
      show: IsMaster,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isExport}
          onChange={(e) => (rowinfo.isExport = !rowinfo.isExport)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsDelete}
            onChange={(e) => {
              setbIsDelete(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "DELETE");
            }}
          />
          <span>DELETE</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={IsTrans ? rowinfo.isCancel : rowinfo.isDelete}
          onChange={(e) => {
            if (IsTrans) rowinfo.isCancel = !rowinfo.isCancel;
            else rowinfo.isDelete = !rowinfo.isDelete;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsPrint}
            onChange={(e) => {
              setbIsPrint(!bIsPrint);
              PermissionAll_OnChange(!bIsPrint, "PRINT");
            }}
          />
          <span>Print</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      show: IsTrans,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isPrint}
          onChange={(e) => {
            rowinfo.isPrint = !rowinfo.isPrint;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsNoAccess}
            onChange={(e) => {
              setbIsNoAccess(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "ACCESS");
            }}
          />
          <span>No Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsMaster || IsTrans,
      cell: ({ rowinfo }) => (
        <div>
          <input
            type="checkbox"
            className="form-check-input"
            defaultChecked={rowinfo.isNOAccess}
            onChange={(e) => (rowinfo.isNOAccess = !rowinfo.isNOAccess)}
          />
        </div>
      ),
    },
    {
      Header: "Access",
      accessor: "strAccessName",
      show: IsCompany,
      style: { justifyContent: "left" },
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsSelect}
            onChange={(e) => {
              setbIsSelect(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "SELECT");
            }}
          />
          <span>Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsCompany,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bIsVisible}
          onChange={(e) => {
            rowinfo.bIsVisible = !rowinfo.bIsVisible;
          }}
        />
      ),
    },
    {
      Header: "REPORT NAME",
      accessor: "strRptName",
      style: { justifyContent: "left" },
      show: IsReport,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsShow}
            onChange={(e) => {
              setbIsShow(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "SHOW");
            }}
          />
          <span>Show</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsReport,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bShowReport}
          onChange={(e) => {
            rowinfo.bShowReport = !rowinfo.bShowReport;
          }}
        />
      ),
    },
    {
      Header: "COMPANY NAME",
      accessor: "strCompName",
      style: { justifyContent: "left" },
      show: IsCompanyList,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsShow}
            onChange={(e) => {
              setbIsShow(e.target.checked);
              PermissionAll_OnChange(e.target.checked, "SHOW");
            }}
          />
          <span>Show</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: IsCompanyList,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bShowCompany}
          onChange={(e) => {
            rowinfo.bShowCompany = !rowinfo.bShowCompany;
          }}
        />
      ),
    },
  ];
  const View_PermColumn = (ScrId) => {
    setIsReport(false);
    setIsCompanyList(false);
    setIsCompany(false);
    setIsTrans(false);
    setIsMaster(false);
    switch (ScrId) {
      case 2:
      case 3:
      case 5:
      case 6:
        setIsTrans(true);
        break;
      case 7:
      case 8:
        setIsCompany(true);
        break;
      case 10:
        setIsReport(true);
        break;
      case 11:
        setIsCompanyList(true);
        break;
      default:
        setIsMaster(true);
        break;
    }
  };
  const ClientColumns = [
    {
      Name: "Client Name",
      ColName: "clientName",
      Color: "Black",
      Width: "auto",
      textAlign: "left",
    },
  ];
  const UserColumns = [
    {
      Name: "Name",
      ColName: "strName",
      Color: "Black",
      Width: "auto",
      Suffix: "",
      textAlign: "left",
    },
    {
      Name: "   User Name",
      ColName: "strUserName",
      Color: "black",
      Width: "auto",
      Suffix: "",
      textAlign: "left",
    },
    {
      Name: "Password",
      ColName: "strPassword",
      Color: "black",
      Width: "auto",
      Suffix: "",
      textAlign: "left",
    },
    {
      Name: "Logout Status",
      ColName: "isLogOut",
      Color: "black",
      Width: "100",
      Suffix: "",
      textAlign: "center",
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="Dash-content">
      <div className="Dash-card">
        <Admintable
          Columns={ClientColumns}
          Data={arrEntClients}
          View_Data={View_ClientUser}
          IsBackground={true}
        />
      </div>
      <div className="Dash-card">
        <div className="Dash-wrapper">
          {!IsUser && (
            <Admintable
              Columns={UserColumns}
              Data={arrEntUser}
              View_Data={View_User}
              IsBackground={true}
            />
          )}
          {IsUser && (
            <div className="row pl-2">
              <div className="col-sm-12 col-md-3  mt-1">
                <label>User Role</label> <span className="danger">*</span>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select User Role"
                  select_value={(val) => setiRoleId(val)}
                  btnname="User Role"
                  btnshow={true}
                  show_popup={(e) => setisUserRole(!isUserRole)}
                  valueonly={true}
                  defaultval={iRoleId}
                  displayName="strName"
                  disvalue="iRoleId"
                  arrydata={arrEntUserRole}
                  EmptVal="User Role"
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Name</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Name"
                  tabIndex={1}
                  value={strName}
                  onChange={(e) => setstrName(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>User Name</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Name"
                  tabIndex={2}
                  value={strUserName}
                  onChange={(e) => setstrUserName(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Password</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Password"
                  tabIndex={3}
                  value={strPassword}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPassword(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Confirm Password</label>{" "}
                <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Password"
                  tabIndex={4}
                  value={strConfirmPassword}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrConfirmPassword(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value !== strPassword) {
                      AlertMessage("Password Doesn't Match", "info");
                    }
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  tabIndex={8}
                  value={dpDOB}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdpDOB(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Date of Joining</label>
                <input
                  type="date"
                  className="form-control"
                  tabIndex={8}
                  value={dpDOJ}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdpDOJ(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Gender</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select Gender"
                  select_value={(val) => setiGender(val)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iGender}
                  displayName="strGenderName"
                  disvalue="iGenderId"
                  arrydata={arrEntGender}
                  disabled={false}
                  EmptVal="Gender"
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Door/Office No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Door/Office No"
                  tabIndex={5}
                  value={strAdd1}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd1(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Street/Area</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Street/Area"
                  tabIndex={6}
                  value={strAdd2}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd2(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Village/Town</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Village/Town"
                  tabIndex={7}
                  value={strAdd3}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd3(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City"
                  tabIndex={8}
                  value={strAdd4}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAdd4(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Pincode</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Pincode"
                  tabIndex={8}
                  value={strPincode}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPincode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Select Country"
                  select_value={(val) => View_State(val)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iCountry}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={arrEntCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select State"
                  select_value={(val) => setiState(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iState}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={arrEntState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile No"
                  tabIndex={8}
                  value={strMobile}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrMobile(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone No"
                  tabIndex={8}
                  value={strPhone}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPhone(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={8}
                  value={strEmail}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrEmail(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Remark"
                  tabIndex={8}
                  value={strRemarks}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_selecrparem.isoptionShow4}
                  placeholder="Select Status"
                  select_value={(val) => setiStatus(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatus}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={arrEntStatus}
                  disabled={false}
                  EmptVal="Status "
                />
              </div>
              <div className="col-md-6 col-sm-6 mt-1">
                <label>Screen</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow5"
                  showoption={_selecrparem.isoptionShow5}
                  placeholder="Select Screen"
                  select_value={(val) => Load_UserPermssion(val, iUserId)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iScrCatId}
                  displayName="strScrCatName"
                  disvalue="iScrCatId"
                  arrydata={arrEntScr}
                  EmptVal="Screen"
                />
              </div>
              <div className="p-2">
                <ReactTable
                  columns={PermissionColumns}
                  data={arrEntPermission}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {}}
                  background={true}
                  className="full-table "
                />
              </div>
            </div>
          )}
        </div>
        <div className="Dash-section">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (Number(iRoleId) === 0) {
                AlertMessage("Please Enter UserRole", "info");
              } else if (strUserName === "") {
                AlertMessage("Please Enter UserName", "info");
              } else if (strPassword === "") {
                AlertMessage("Please Enter Password", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: Save_User,
                    Question: "Do You Want to Update User?",
                    isdialog: true,
                  })
                );
              }
            }}
          >
            {Number(iUserId) === 0 ? "Save" : "Update"}&nbsp;
            <i className="bx bxs-save"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (Number(iClientId) === 0) {
                AlertMessage("Please Select User", "info");
              } else if (Number(iUserId) === 1) {
                AlertMessage("Admin User Can't Delete ", "error");
              } else if (Number(iUserId) === 0) {
                AlertMessage("Please Select User", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteUser,
                    Question: "Do You Want to Delete User?",
                    isdialog: true,
                  })
                );
              }
            }}
          >
            Delete&nbsp;<i className="bx bx-trash-alt"></i>
          </button>
          <button className="btn-fabgreen" onClick={ClearClick}>
            Clear&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Usermaster;
