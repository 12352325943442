import React, { useEffect, useState } from "react";

import api from "../Admin/Config";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  Select_ServiceVendore,
  ShowHide_Popup,
  setParty,
} from "../ReduxStore/Transactionstates";
import PopupSearch from "./PopupSearch";
function PartySearch(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);

  const [strColName, setstrColName] = useState("All");
  const [strSearch, setstrSearch] = useState("");
  const [arrParty, setarrParty] = useState([]);
  const [arrFilter, setarrFilter] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Party();
  }, []);
  const Load_Party = async () => {
    try {
      setstrSearch("");
      await dispatch(setload_Screen(true));
      let VenType = props.VenTypeId ? props.VenTypeId : _Common.VenTypeId;
      let ScrId = props.ScrId ? props.ScrId : _Common.PartyScrId;
      let ColName = props.ColumnName ? props.ColumnName : strColName;
      if (_Common.IsSerVendor && _Common.iScrId !== 66) {
        ScrId = 3;
        VenType = 2;
        ColName = "SuppName";
      }
      const objSearch = {
        iScrId: ScrId,
        iVenType: VenType,
        strColName: ColName,
        strSearch: "",
        strCompanyDB: localStorage.db,
        iUserId: Number(localStorage.UserId),
      };
      setstrColName(objSearch.strColName);
      await api.post(`Transaction/Load_Party`, objSearch).then((res) => {
        if (res.data.strStatus === "success") {
          setarrFilter(res.data.objCmbValue);
          setarrParty(res.data.EntParty);
          setarrEntTableCol(res.data.EntTablDesign);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Party = async (strtxt, ColName) => {
    try {
      setstrColName(ColName);
      let VenType = props.VenTypeId ? props.VenTypeId : _Common.VenTypeId;
      let ScrId = props.ScrId ? props.ScrId : _Common.PartyScrId;
      if (_Common.IsSerVendor && _Common.iScrId !== 66) {
        ScrId = 3;
        VenType = 2;
      }
      const objSearch = {
        iScrId: ScrId,
        iVenType: VenType,
        strColName: ColName,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
        iUserId: Number(localStorage.UserId),
      };
      await api.post(`Transaction/Search_Party`, objSearch).then((res) => {
        if (res.data.strStatus === "success") {
          setarrParty(res.data.EntParty);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const View_Party = async (party) => {
    try {
      if (props.View_Party) {
        props.View_Party(party);
      } else {
        const objTrans = {
          Controller: "",
          strScrType: _Common.strScrType,
          iScrId: _Common.iScrId,
          iPartyScrId: _Common.PartyScrId,
          iPartyId: party.partyId,
          iVenType: _Common.VenTypeId,
          strSearch: "",
          strColName: "",
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iClientId: Number(localStorage.ClientId),
          iUserId: Number(localStorage.UserId),
        };
        await dispatch(setload_Screen(true));
        if (_Common.IsSerVendor && _Common.iScrId !== 66) {
          await dispatch(Select_ServiceVendore(party));
          await dispatch(ShowHide_Popup("IsVenSearch"));
        } else {
          await api
            .post(`Transaction/View_Party/`, objTrans)
            .then(async (Res) => {
              if (Res.data.strStatus === "Success") {
                await dispatch(
                  setParty({
                    iPartyId: party.partyId,
                    Address:
                      Res.data.PartyInfo[1] +
                      Res.data.PartyInfo[2] +
                      Res.data.PartyInfo[3],
                    strPartyName: Res.data.PartyInfo[0],
                    ShippAdd: Res.data.strShippAdd,
                    iTaxRelId: Res.data.iTaxRelId,
                    arrShipAdd: Res.data.EntShipAdd,
                  })
                );

                if (!_Common.IsSerVendor) {
                  if (_Common.iScrId === 12 || _Common.iScrId === 18) {
                    await dispatch(ShowHide_Popup("IsReturnPro"));
                  } else if (
                    _Common.iScrId === 72 ||
                    _Common.iScrId === 73 ||
                    _Common.iScrId === 75 ||
                    _Common.iScrId === 76
                  ) {
                    await dispatch(ShowHide_Popup("IsPendinCon"));
                  } else {
                    await dispatch(ShowHide_Popup("IsVenSearch"));
                  }
                } else await dispatch(ShowHide_Popup("IsVenSearch"));
              } else {
                AlertMessage(Res.data.strMessage, "error");
              }
            });
        }
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <PopupSearch
      ScrName="Party Master"
      FilterCol={arrFilter}
      FilterVal={strColName}
      FilterShow={true}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrParty}
      UpdateFilterCol={(col) => {
        setstrColName(col);
        Search_Party(strSearch, col);
      }}
      Search_Data={(txt) => {
        setstrSearch(txt);
        Search_Party(txt, strColName);
      }}
      View_Data={(e) => View_Party(e)}
      Close={() => {
        if (props.Close) props.Close();
        else dispatch(ShowHide_Popup("IsVenSearch"));
      }}
      IsParty={true}
    />
  );
}
export default PartySearch;
