import React, { useEffect, useState } from "react";
import $ from "jquery";
import api, { Logout_User, Backup_Data } from "../Admin/Config";
import ActivationKeyExpired from "../Admin/ActivationKeyExpired";
import { useDispatch } from "react-redux";
import { Show_MsgBox } from "../ReduxStore/Message";
import Configuration from "../Setting/Configuration";
import MasterCode from "../Setting/MasterCode";
import VoucherNo from "../Setting/VoucherNo";
import CustomizeScreen from "../Setting/CustomizeScreen";
import { setScrId } from "../ReduxStore/Transactionstates";
function SideMenu(props) {
  const dispatch = useDispatch();
  const [strCompanyName, setstrCompanyName] = useState("");
  const [Tittle, setTittle] = useState("");
  const [iScrId, setiScrId] = useState(0);
  const [strSubscription, setstrSubscription] = useState("");

  const [isValidKey, setisValidKey] = useState(false);
  const [isBackup, setisBackup] = useState(false);
  const [isSales, setisSales] = useState(false);
  const [isPurchase, setisPurchase] = useState(false);
  const [isProduct, setisProduct] = useState(false);
  const [isCompany, setisCompany] = useState(false);
  const [isConfig, setisConfig] = useState(false);
  const [isVouchNo, setisVouchNo] = useState(false);
  const [isMasterCode, setisMasterCode] = useState(false);
  const [isUserAccount, setisUserAccount] = useState(false);
  const [isCustomize, setisCustomize] = useState(false);
  const [isShowConfig, setisShowConfig] = useState(false);
  const [isShowMasterCode, setisShowMasterCode] = useState(false);
  const [isShowCustomize, setisShowCustomize] = useState(false);
  const [isShowVouchNo, setisShowVouchNo] = useState(false);

  const [Master, setMaster] = useState([]);
  const [Sales, setSales] = useState([]);
  const [Purchase, setPurchase] = useState([]);
  const [Group, setGroup] = useState([]);
  const [Jobwork, setJobwork] = useState([]);
  const [Accounts, setAccounts] = useState([]);
  const [Reports, setReports] = useState([]);
  const [News, setNews] = useState([]);
  const [Others, setOthers] = useState([]);
  const [strImage, setstrImage] = useState("");

  useEffect(() => {
    Load_Company();
  }, []);
  useEffect(() => {
    try {
      const x = new Date();
      var ExpirDate = new Date(localStorage.Licenseexpiry);
      calculate_keyexpiry();
      if (x > ExpirDate) {
        setisValidKey(!isValidKey);
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  }, [localStorage.Licenseexpiry]);
  useEffect(() => {
    if (JSON.parse(localStorage.isLogout) === true) {
      Logout_User();
    }
  }, [localStorage.isLogout]);

  const calculate_keyexpiry = async () => {
    const start = new Date();
    const end = new Date(localStorage.Licenseexpiry);

    // Calculate the difference in milliseconds
    const difference = Math.abs(end - start);

    // Convert milliseconds to days
    const days = Math.ceil(difference / (1000 * 60 * 60 * 24));

    setstrSubscription(days);
  };
  const Load_Company = async () => {
    try {
      let Menu = JSON.parse(localStorage.Menu);
      setstrCompanyName(localStorage.OrgName);
      setiScrId(Number(props.ScrId));
      await dispatch(setScrId(props.ScrId));
      setTittle(await ScreenName(props.ScrId));

      Menu.objEntMPM = Menu.objEntMPM.sort((a, b) => a.sNo - b.sNo);
      Menu.objEntCLPM.forEach((DT) => {
        if (DT.strAccessName === "Backup") setisBackup(DT.bIsVisible);
        if (DT.strAccessName === "View") setisCompany(DT.bIsVisible);
      });
      Menu.objEntJPM.push({
        iPerId: 1002,
        isCancel: true,
        iScrId: 110,
        iScrTabId: 5,
        isModify: true,
        isNew: true,
        isNOAccess: false,
        isPrint: true,
        isView: true,
        sNo: 9,
        strNavMenupath: "/Forecast",
        strScrName: "Production Forecast",
      });
      Menu.objEntAPM.push({
        iPerId: 0,
        isCancel: true,
        iScrId: 103,
        iScrTabId: 6,
        isModify: true,
        isNew: true,
        isNOAccess: false,
        isPrint: true,
        isView: true,
        sNo: 6,
        strNavMenupath: "/JournalEntry",
        strScrName: "Journal Entry",
      });
      const reports = [
        {
          strNavMenupath: "/GeneralReports",
          strScrName: "General Reports",
          iScrId: 101,
        },
        {
          strNavMenupath: "/AccountsReport",
          strScrName: "Accounts Reports",
          iScrId: 102,
        },
      ];
      let ToolsMenu = [
        {
          strNavMenupath: "/Configuration",
          strScrName: "Configuration Setting",
          iScrId: 104,
          iNoAccess: !Menu.objEntTPM[0].isConfig,
        },
        {
          strNavMenupath: "/MasterCode",
          strScrName: "Master Code",
          iScrId: 105,
          iNoAccess: !Menu.objEntTPM[0].isMasterCodeSetttings,
        },
        {
          strNavMenupath: "/ReNameScreen",
          strScrName: "Rename Screen",
          iScrId: 106,
          iNoAccess: !Menu.objEntTPM[0].isRenameSettings,
        },
        {
          strNavMenupath: "/UserAccount",
          strScrName: "User Account",
          iScrId: 107,
          iNoAccess: !Menu.objEntTPM[0].isUser,
        },
        {
          strNavMenupath: "/VoucherNo",
          strScrName: "Voucher No",
          iScrId: 108,
          iNoAccess: !Menu.objEntTPM[0].isVoucherNoSettings,
        },
      ];
      setMaster(Menu.objEntMPM);
      setSales(Menu.objEntSPM);
      setPurchase(Menu.objEntPPM);
      setGroup(Menu.objEntGPM);
      setJobwork(Menu.objEntJPM);
      setAccounts(Menu.objEntAPM);
      setReports(reports);
      setOthers(Menu.objEntOPM);
      setisProduct(filterpermission(Menu.objEntMPM, 1));
      setisSales(filterpermission(Menu.objEntSPM, 17));
      setisPurchase(filterpermission(Menu.objEntPPM, 11));
      setisConfig(filterpermission(ToolsMenu, 104));
      setisMasterCode(filterpermission(ToolsMenu, 105));
      setisVouchNo(filterpermission(ToolsMenu, 108));
      setisCustomize(filterpermission(ToolsMenu, 104));
      setisUserAccount(filterpermission(ToolsMenu, 107));
      if (localStorage.Logo) {
        setstrImage(localStorage.Logo);
      }
      await MenuClick(0);
      await Load_ClientDefault();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Load_ClientDefault = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await api.post(`Home/Load_ClientDefault`, parems).then((res) => {
        if (res.data.strStatus === "Success") {
          setNews(res.data.News);
          localStorage.isLogout = res.data.AutoLogOut;
          localStorage.Licenseexpiry = res.data.KeyExpiry;
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const filterpermission = (permss, ScrId) => {
    let EntPer = permss.filter((dt) => dt.iScrId === ScrId);
    return EntPer.length > 0 ? (EntPer[0].iNoAccess ? false : true) : false;
  };
  const MenuClick = (Index) => {
    $(`.nav-item`).removeClass("active rotate show");
    $(`.nav-item:eq(${Index})`).toggleClass("active");
    $(`.nav-item:eq(${Index}) .fa-caret-down`).toggleClass("rotate");
    $(`.nav-item:eq(${Index}) ul`).toggleClass("show");
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ScreenName = (ScrId) => {
    switch (ScrId) {
      case 1:
        return "Product Master ";
      case 2:
        return "Customer Master ";
      case 3:
        return "Supplier Master ";
      case 4:
        return "Category Master ";
      case 5:
        return "OtherHead Master ";
      case 7:
        return "Purchase Enquiry ";
      case 8:
        return "Purchase Quotation ";
      case 9:
        return "Purchase Order ";
      case 10:
        return "Purchase Challan ";
      case 11:
        return "Purchase Invoice ";
      case 12:
        return "Purchase Return ";
      case 13:
        return "Sales Enquiry ";
      case 14:
        return "Sales Quotation ";
      case 15:
        return "Sales Order ";
      case 16:
        return "Sales Challan ";
      case 17:
        return "Sales Invoice ";
      case 18:
        return "Sales Return ";
      case 29:
        return "BankAccount ";
      case 37:
        return "OpenStock ";
      case 38:
        return "Stock Adjustment ";
      case 41:
        return "Payment ";
      case 42:
        return "Receipt ";
      case 43:
        return "Debit Note ";
      case 44:
        return "Credit Note ";
      case 50:
        return "Vendor Master ";
      case 51:
        return "JobWork Master ";
      case 59:
        return "Service Master ";
      case 60:
        return "Attribute ";
      case 61:
        return "Attribute Group ";
      case 62:
        return "Price Master ";
      case 64:
        return "Unit Master ";
      case 66:
        return "Expense Income ";
      case 67:
        return "Sales Estimate ";
      case 68:
        return "Bill Of Material ";
      case 69:
        return "Route Group ";
      case 70:
        return "Production Order ";
      case 71:
        return "Inward Challan ";
      case 72:
        return "Outward Challan ";
      case 73:
        return "Outward Invoice ";
      case 74:
        return "Outsource Challan ";
      case 75:
        return "Insource Challan ";
      case 76:
        return "Insource Invoice ";
      case 77:
        return "Transaction Adjustment ";
      case 78:
        return "Purchase Estimate ";
      case 79:
        return "Journal Entry ";
      case 80:
        return "PriceGroup Master ";
      case 83:
        return "Production Forcast ";
      case 85:
        return "E-Mail Settings";
      case 110:
        return "Tax Master";
      case 102:
        return "Accounts Reports ";
      case 101:
        return "GENERAL REPORTS";
      case 32:
        return "Print Design";
      case 104:
        return "Default  Setting ";
      case 108:
        return "Voucher NumberSetting ";
      case 105:
        return "Master CodeSetting ";
      case 106:
        return "ReName Screen ";
      case 107:
        return "User Account ";
      case 120:
        return "Barcode Generator";
      case 121:
        return "Barcode Adjustment";
      case 130:
        return "RECEIPT SETTLEMENT";
      case 131:
        return "PAYMENT SETTLEMENT";
      case 150:
        return "Company List";
      case 151:
        return "E-Way Billing";
      case 152:
        return "Print Line Design";
      default:
        sessionStorage.removeItem("maxTab");
        return "Dashboard";
    }
  };

  return (
    <>
      <section className="header" id="header">
        <div className="ScreenName">
          <h5>{Tittle.toUpperCase()}</h5>
        </div>
        <div className="Header-Menu">
          <div
            onClick={(e) => $(".news-container").toggleClass("show")}
            className="news-icon"
          >
            <div className="menutooltip">
              <a href="##">
                <i
                  className={
                    News.length > 0 ? "bx bxs-bell-ring " : "bx bx-bell"
                  }
                ></i>
                <sub>{News.length > 0 ? News.length : ""}</sub>
              </a>
              <span className="mtooltiptext">News</span>
            </div>
          </div>
          <ul
            className="news-container"
            onMouseLeave={(e) => $(".news-container").removeClass("show")}
          >
            {News.map((dt, Index) => (
              <li key={Index}>
                <label>{dt.strNews}</label>
              </li>
            ))}
          </ul>
          {isProduct && (
            <div className="menutooltip">
              <a href="/Master/ProductMaster">
                <i className="bx bx-cube-alt"></i>
              </a>
              <span className="mtooltiptext">Product</span>
            </div>
          )}
          {isPurchase && (
            <div className="menutooltip">
              <a href="/Purchase/PurchaseInvoice">
                <i className="bx bxs-shopping-bags"></i>
              </a>
              <div className="mtooltiptext">Purchase</div>
            </div>
          )}
          {isSales && (
            <div className="menutooltip">
              <a href="/Sales/SalesInvoice">
                <i className="bx bx-cart"></i>
              </a>
              <div className="mtooltiptext">Sales</div>
            </div>
          )}
          {isCompany && (
            <div className="Profile-Img">
              <img
                src={strImage}
                alt=""
                onClick={(e) => $(".right_menu").toggleClass("active")}
              ></img>
            </div>
          )}
          <ul
            className="right_menu"
            onMouseLeave={(e) => $(".right_menu").removeClass("active")}
          >
            <li className="profile-desc">
              <div className="img-wrapper">
                <img src={strImage} alt=""></img>
              </div>
              <div className="org-cont">
                <h5
                  onClick={(e) => {
                    if (!Boolean(Number(localStorage.IsDirect)))
                      window.location.href =
                        "/CompanyMaster";
                  }}
                >
                  <span>
                    <i className="bx bx-buildings"></i>&nbsp;Organization
                  </span>
                  : {strCompanyName}
                </h5>
                <div className="yearlice">
                  <h5>
                    <span>Year</span> : {localStorage.CurrFY}
                  </h5>
                  <h5>
                    <span>License</span> : {strSubscription} Days
                  </h5>
                </div>
                <div className="baclog">
                  {isBackup && !Boolean(Number(localStorage.IsDirect)) && (
                    <label
                      onClick={(e) => {
                        if (isBackup) {
                          dispatch(
                            Show_MsgBox({
                              Button: Backup_Data,
                              Question: "Do You Want Backup Data?",
                              isdialog: true,
                              Id: {
                                dispatch: dispatch,
                                Msdb: localStorage.Msdb,
                              },
                            })
                          );
                        }
                      }}
                    >
                      <i className="bx bx-cloud-download"></i>Backup
                    </label>
                  )}
                  <label onClick={(e) => Logout_User()}>
                    <i className="bx bx-log-out"></i>Sign Out
                  </label>
                </div>
              </div>
            </li>
            {isConfig && (
              <li onClick={() => setisShowConfig(!isShowConfig)}>
                <i className="bx bx-cog"></i>&nbsp;Configuration
              </li>
            )}
            {isMasterCode && (
              <li onClick={() => setisShowMasterCode(!isShowMasterCode)}>
                <i className="bx bx-receipt"></i>&nbsp;Master Code
              </li>
            )}
            {isVouchNo && (
              <li onClick={() => setisShowVouchNo(!isShowVouchNo)}>
                <i className="bx bx-receipt"></i>&nbsp;Voucher No
              </li>
            )}
            {isCustomize && iScrId > 0 && (
              <li onClick={() => setisShowCustomize(!isShowCustomize)}>
                <i className="bx bxs-customize"></i>&nbsp;Customize Screen
              </li>
            )}
            {isUserAccount && (
              <li
                onClick={() => {
                  window.location.href =
                    "/Tools/UserAccount";
                }}
              >
                <i className="fas fa-user-cog"></i>&nbsp;User Management
              </li>
            )}
          </ul>
        </div>
        {!Boolean(Number(localStorage.IsDirect)) && (
          <div
            className="btn-bars"
            onClick={(e) => {
              $(".btn-bars").toggleClass("click");
              $(".sidebar").toggleClass("show");
            }}
          >
            <span className=" fas fa-bars third"></span>
          </div>
        )}
      </section>
      {!Boolean(Number(localStorage.IsDirect)) && (
        <section className="sidebar">
          <div className="logo">
            <div className="logo-wrapper">
              <img src={strImage} alt=""></img>
            </div>
            {strCompanyName}
          </div>
          <ul>
            <li className="nav-item" onClick={() => MenuClick(0)}>
              <a href="/Dashboard">
                <i className="fas fa-home"></i>
                Dashboard
              </a>
            </li>
            <li className="nav-item" onClick={() => MenuClick(1)}>
              <a href="##">
                <i>
                  <div className="master-grid">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </i>
                Master
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Master.map((Master, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Master${Master.strNavMenupath}`}
                    >
                      {Master.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(2)}>
              <a href="##">
                <i className="fa-solid fa-cart-shopping"></i>
                Sales
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Sales.map((Sales, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Sales${Sales.strNavMenupath}`}
                    >
                      {Sales.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(3)}>
              <a href="##">
                <i className="fa-solid fa-bag-shopping"></i>
                Purchase
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Purchase.map((Purchase, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Purchase${Purchase.strNavMenupath}`}
                    >
                      {Purchase.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(4)}>
              <a href="##">
                <i className="fa-solid fa-layer-group"></i>
                Groups
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Group.map((Group, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Group${Group.strNavMenupath}`}
                    >
                      {Group.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(5)}>
              <a href="##">
                <i className="fas fa-tools"></i>
                Job work
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Jobwork.map((Jobwork, Index) => (
                  <li key={Index}>
                    <a
                      href={`/JobWork${Jobwork.strNavMenupath}`}
                    >
                      {Jobwork.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(6)}>
              <a href="##">
                <i className="fas fa-wallet"></i>
                Accounts
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Accounts.map((Accounts, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Accounts${Accounts.strNavMenupath}`}
                    >
                      {Accounts.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(7)}>
              <a href="##">
                <i className="fa-solid fa-chart-pie"></i>
                Reports
                <i className="fas fa-caret-down"></i>
              </a>
              <ul>
                {Reports.map((Reports, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Reports${Reports.strNavMenupath}`}
                    >
                      {Reports.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item" onClick={() => MenuClick(8)}>
              <a href="##">
                <i className="fas fa-ellipsis-h"></i>
                Others
                <i className="fas fa-caret-down nine"></i>
              </a>
              <ul>
                {Others.map((Others, Index) => (
                  <li key={Index}>
                    <a
                      href={`/Others${Others.strNavMenupath}`}
                    >
                      {Others.strScrName}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </section>
      )}
      {isShowConfig && (
        <Configuration Close={(e) => setisShowConfig(!isShowConfig)} />
      )}
      {isShowMasterCode && (
        <MasterCode Close={(e) => setisShowMasterCode(!isShowMasterCode)} />
      )}
      {isShowVouchNo && (
        <VoucherNo Close={(e) => setisShowVouchNo(!isShowVouchNo)} />
      )}
      {isShowCustomize && (
        <CustomizeScreen
          Close={(e) => setisShowCustomize(!isShowCustomize)}
          ScrId={iScrId}
        />
      )}
      {isValidKey && <ActivationKeyExpired />}
    </>
  );
}
export default SideMenu;

