import { useState, useEffect } from "react";

import SearchLedger from "./SearchLedger";
import JournalView from "./JournalView";
import SideMenu from "../General/SideMenu";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import VouchernoSetting from "../Setting/VouchernoSetting";
import ReactTable from "../General/ReactTable";
function JournalEntry() {
  const dispatch = useDispatch();
  const iScrId = 79;
  const [IsLedgerOpen, setIsLedgerOpen] = useState(false);
  const [IsJournalOpen, setIsJournalOpen] = useState(false);
  const [isManualNo, setisManualNo] = useState(false);
  const [isVouchNoSett, setisVouchNoSett] = useState(false);

  const [iJEId, setiJEId] = useState(0);
  const [bStatus, setbStatus] = useState(true);
  const [strEditMode, setstrEditMode] = useState("New");
  const [strVoucherNo, setstrVoucherNo] = useState("");
  const [strTransNo, setstrTransNo] = useState("");
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [strCompCode, setstrCompCode] = useState("");
  const [strFinanFromTo, setstrFinanFromTo] = useState("");
  const [strDate, setstrDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iLedId, setiLedId] = useState(0);
  const [strLedName, setstrLedName] = useState("");
  const [iDebit, setiDebit] = useState(0);
  const [iCredit, setiCredit] = useState(0);
  const [strRemarks, setstrRemarks] = useState("");
  const [iSumDiffAmt, setiSumDiffAmt] = useState(0);
  const [iSumDebit, setiSumDebit] = useState(0);
  const [iSumCredit, setiSumCredit] = useState(0);
  const [strSumRemarks, setstrSumRemarks] = useState("");
  const [ListJournals, setListJournals] = useState([]);
  const [ListLedger, setListLedger] = useState([]);

  useEffect(() => {
    Load_JournalEntry();
  }, []);
  const Load_JournalEntry = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await api.post(`JournalEntry/Load_JournalEntry`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setisManualNo(res.data.objNoFormate[0].isManualNumber);
            setstrVoucherNo(res.data.objNoFormate[0].strUserNo);
            setstrTransNo(res.data.objNoFormate[0].strTransNo);
            setstrPrefix(res.data.objNoFormate[0].strPrefix);
            setiNumSeqNo(res.data.objNoFormate[0].iNumSeqNo);
            setstrCompCode(res.data.objNoFormate[0].strCompCode);
            setstrFinanFromTo(res.data.objNoFormate[0].strFinanFrom);
            setiSumCredit(res.data.dCredit);
            setiSumDebit(res.data.dDebit);
            setiSumDiffAmt(res.data.dNetAmt);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      const objEntJE = {
        iScrId: iScrId,
        iJEId: Number(iJEId),
        strPrefix: strPrefix,
        strCompCode: strCompCode,
        strFinanFrom: strFinanFromTo,
        iNumSeqNo: Number(iNumSeqNo),
        strTransNo: strTransNo,
        strVouchNo: strVoucherNo,
        strVouchDate: strDate,
        iUserId: Number(localStorage.UserId),
        status: bStatus,
        dDiffAmt: Number(iSumDiffAmt),
      };
      await dispatch(setload_Screen(true));
      /* Read more about isConfirmed, isDenied below */
      await api.post( "JournalEntry/InsUpdJournelEntry", {
        objEntJE: objEntJE,
        objEntJEDT: ListJournals,
        strCompanyDB: localStorage.db,
      }).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }

        dispatch(setload_Screen(false));
      });
    } catch (e) {
      dispatch(setload_Screen(false));
      AlertMessage(e.message, "error");
    }
  };
  const View_JournalEntry = async (iJEId) => {
    try {
      setIsJournalOpen(!IsJournalOpen);
      await dispatch(setload_Screen(true));
      await api.get(
        
          "JournalEntry/View_JournalEntry/" +
          Number(iJEId) +
          "/" +
          localStorage.db
      ).then((res) => {
        let EntHT = res.data.objEntHT[0];
        setstrDate(EntHT.strVouchDate);
        setiJEId(EntHT.iJEId);
        setstrPrefix(EntHT.strPrefix);
        setstrCompCode(EntHT.strCompCode);
        setstrFinanFromTo(EntHT.strFinanFrom);
        setiNumSeqNo(EntHT.iNumSeqNo);
        setstrTransNo(EntHT.strTransNo);
        setstrVoucherNo(EntHT.strVouchNo);
        setiSumDiffAmt(EntHT.dDiffAmt);
        setbStatus(EntHT.status);
        setListJournals(res.data.objEntDT);
        Calculation(res.data.objEntDT);
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = () => {
    try {
      dispatch(setload_Screen(true));
      api.post(
         "JournalEntry/Delete/" + Number(iJEId) + "/" + localStorage.db
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }

        dispatch(setload_Screen(false));
      });
    } catch (e) {
      dispatch(setload_Screen(false));
      AlertMessage(e.message, "error");
    }
  };
  const ClearClick = () => {
    setiJEId(0);
    setstrVoucherNo("");
    setstrDate(new Date().toISOString().split("T")[0]);
    setListJournals([]);
    setListLedger([]);
    setiLedId(0);
    setstrLedName("");
    setstrSumRemarks("");
    Calculation([]);
    Load_JournalEntry();
  };
  const SelectLedger = (Ledger) => {
    setIsLedgerOpen(!IsLedgerOpen);
    setiLedId(Ledger.ledId);
    setListLedger(Ledger);
    setstrLedName(Ledger.ledName);
  };
  const AddTable = () => {
    if (Number(iDebit) > 0 && Number(iCredit) > 0) {
      AlertMessage("Please Enter Credit or Debit Amount", "info");
    } else if (Number(iDebit) === 0 && Number(iCredit) === 0) {
      AlertMessage("Please Enter Credit or Debit Amount", "info");
    } else {
      switch (strEditMode) {
        case "New":
          if (Number(iLedId) === 0) {
            AlertMessage("Please Select Ledger", "info");
          } else {
            const AddLedger = {
              sNo: ListJournals.length + 1,
              ledName: ListLedger.ledName,
              ledCode: ListLedger.ledCode,
              ledId: ListLedger.ledId,
              dr: Number(iDebit),
              cr: Number(iCredit),
              remarks: strRemarks,
              vouId: 0,
            };
            if (ListJournals.length === 0) {
              setListJournals([AddLedger]);
              Calculation([AddLedger]);
            } else {
              setListJournals([...ListJournals, AddLedger]);
              Calculation([...ListJournals, AddLedger]);
            }
          }
          break;
        case "Modify":
          let LedgerFilter = ListJournals.filter(
            (Data) => Data.ledId === iLedId
          );
          if (LedgerFilter.length > 0) {
            ListJournals.map((Data) => {
              if (Data.ledId === iLedId) {
                Data.dr = Number(iDebit);
                Data.cr = Number(iCredit);
                Data.remarks = strRemarks;
              }
              return Data;
            });
            Calculation(ListJournals);
          }
          break;
        default:
          break;
      }
    }
  };
  const Calculation = (listJournal) => {
    try {
      let SumDebit = 0;
      let SumCredit = 0;
      let SumDiffAmt = 0;
      listJournal.map((Data) => {
        SumDebit += Data.dr;
        SumCredit += Data.cr;
        return Data;
      });
      SumDiffAmt = SumDebit - SumCredit;
      setiSumCredit(SumCredit.toFixed(2));
      setiSumDebit(SumDebit.toFixed(2));
      setiSumDiffAmt(SumDiffAmt.toFixed(2));
      setiLedId(0);
      setstrLedName("");
      setiDebit(0);
      setiCredit(0);
      setstrRemarks("");
      setstrEditMode("New");
      setListLedger([]);
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const RemoveTableRow = (RowInfo) => {
    try {
      const newList = ListJournals.filter(
        (Data) => Data.ledId !== RowInfo.ledId
      );
      newList.map((Data) => {
        if (Data.sNo > RowInfo.sNo && Data.sNo > 1) {
          Data.sNo = Data.sNo - 1;
        }
        return Data;
      });
      setListJournals(newList);
      Calculation(newList);
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ModifyJurnal = (RowInfo) => {
    try {
      setstrLedName(RowInfo.ledName);
      setiCredit(RowInfo.cr);
      setiDebit(RowInfo.dr);
      setstrRemarks(RowInfo.remarks);
      setiLedId(Number(RowInfo.ledId));
      setstrEditMode("Modify");
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const JournelTable = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "LedegerName",
      accessor: "ledName",
    },
    {
      Header: "Debit",
      accessor: "dr",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Credit",
      accessor: "cr",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      minWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Action",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={() => RemoveTableRow(rowinfo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div className="fg-card shadow-base p-2">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-2">
                      <label>Voucher No</label>
                      <div>
                        <input
                          type="text"
                          placeholder="Voucher Number"
                          className="form-control"
                          value={strVoucherNo}
                          onChange={(e) => setstrVoucherNo(e.target.value)}
                          disabled={!isManualNo}
                        />
                        <span
                          className="inv-no"
                          onClick={(e) => setisVouchNoSett(!isVouchNoSett)}
                        >
                          <i className="bx bx-cog"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-2">
                      <label>Date</label>
                      <input
                        type="date"
                        aria-label="First name"
                        className="form-control"
                        value={strDate}
                        onChange={(e) => setstrDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12 mb-2">
                      <label>Ledger</label>
                      <div className="custom-select">
                        <input
                          type="text"
                          aria-label="First name"
                          className="form-control"
                          value={strLedName}
                          placeholder="Press Enter to Select Ledger"
                          onInput={(e) => setIsLedgerOpen(!IsLedgerOpen)}
                          onClick={(e) => setIsLedgerOpen(!IsLedgerOpen)}
                          autoFocus
                        />
                        <i
                          className="fa-solid fa-caret-down"
                          style={{
                            position: "absolute",
                            right: "10px",
                            color: "darkgreen",
                            transform: IsLedgerOpen
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 mb-2">
                      <label>Debit</label>
                      <input
                        type="number"
                        aria-label="First name"
                        className="form-control"
                        placeholder="Enter Debit Amount"
                        onClick={(e) => e.target.select()}
                        value={iDebit}
                        onChange={(e) => setiDebit(e.target.value)}
                        onBlur={(e) => {
                          if (e.target.value === "")
                            setiDebit(parseInt(0).toFixed(2));
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-6 mb-2">
                      <label>Credit</label>
                      <input
                        type="number"
                        aria-label="First name"
                        onClick={(e) => e.target.select()}
                        className="form-control"
                        placeholder="Enter Credit Amount"
                        value={iCredit}
                        onChange={(e) => setiCredit(e.target.value)}
                        onBlur={(e) => {
                          if (e.target.value === "")
                            setiCredit(parseInt(0).toFixed(2));
                        }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-2">
                      <label>Remarks</label>
                      <input
                        type="text"
                        aria-label="First name"
                        onClick={(e) => e.target.select()}
                        className="form-control"
                        placeholder="Enter Remarks"
                        value={strRemarks}
                        onChange={(e) => setstrRemarks(e.target.value)}
                      />
                    </div>
                    <div className="col-md-2 col-sm-12 mt-2">
                      <div className="btn-sub-section">
                        <button className="btn-fabgreen" onClick={AddTable}>
                          Add&nbsp;<i className="bx bx-layer-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <ReactTable
                    columns={JournelTable}
                    data={ListJournals}
                    minRows={10}
                    row_click={(rowInfo) => {}}
                    row_doubleclick={(rowInfo) => {
                      ModifyJurnal(rowInfo);
                    }}
                    background={true}
                    className="full-table"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="fg-card shadow-base p-2">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mt-1">
                      <label>Debit</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={iSumDebit}
                        readOnly
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-1">
                      <label>Credit</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={iSumCredit}
                        readOnly
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-1">
                      <div className="net-amount">
                        <h1>DIFFERENCE AMOUNT</h1>
                        <h2>
                          {new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "INR",
                          }).format(Number(iSumDiffAmt).toFixed(2))}
                        </h2>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mt-1">
                      <label>Remark</label>
                      <input
                        type="text"
                        aria-label="First name"
                        onClick={(e) => e.target.select()}
                        className="form-control"
                        placeholder="Enter Remark"
                        value={strSumRemarks}
                        onChange={(e) => setstrSumRemarks(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {IsLedgerOpen && (
              <SearchLedger
                Close={(e) => setIsLedgerOpen(!IsLedgerOpen)}
                SelectLedger={SelectLedger}
                ColName="JE"
              />
            )}
            {IsJournalOpen && (
              <JournalView
                Close={(e) => setIsJournalOpen(!IsJournalOpen)}
                JournalView={View_JournalEntry}
              />
            )}
            {isVouchNoSett && (
              <VouchernoSetting
                iScrId={iScrId}
                Close={() => setisVouchNoSett(!isVouchNoSett)}
              />
            )}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iSumDiffAmt) !== 0) {
                  AlertMessage("Credit and Debit Not Tally", "info");
                } else if (ListJournals.length < 2) {
                  AlertMessage("Atleast Two Ledger Required", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Journal Entry?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() => setIsJournalOpen(!IsJournalOpen)}
            >
              View&nbsp;<i className="fas fa-eye"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iJEId) === 0) {
                  AlertMessage("Please Select Journal Entry", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Journal Entry?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() =>
                (window.location.href =
                  "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default JournalEntry;
