import React, { useEffect, useState } from "react";
import Admintable from "./Admintable";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";

function DatabaseMaster() {
  const dispatch = useDispatch();
  const [arrDataBase, setarrDataBase] = useState([]);

  useEffect(() => {
    Load_Clients();
  }, []);

  const Load_Clients = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_DBRecords").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrDataBase(res.data.EntDatabase);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const DBColumns = [
    {
      Name: "Client Name",
      ColName: "strClientName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Master DataBase",
      ColName: "strMasterDB",
      Color: "black",
      Width: "200",
      textAlign: "left",
    },
    {
      Name: "Company Name",
      ColName: "strCompanyName",
      Color: "black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Financial Year",
      ColName: "strYear",
      Color: "black",
      Width: "150",
      textAlign: "center",
    },
    {
      Name: "Company Database",
      ColName: "strCompanyDB",
      Color: "black",
      Width: "200",
      textAlign: "left",
    },
  ];
  return (
    <div className="Dash-content">
      <div className="Dash-card1">
        <div className="Dash-wrapper pr-0">
          <Admintable
            Columns={DBColumns}
            Data={arrDataBase}
            View_Data={() => {}}
            IsBackground={false}
          />
        </div>
      </div>
    </div>
  );
}

export default DatabaseMaster;
