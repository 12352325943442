import React, { useState, useEffect } from "react";
import api from "../Admin/Config";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import $ from "jquery";
import {
  Merge_ConvertedVouchers,
  Select_ProducttoConvert,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import { Table_Style } from "./Transaction";
function PendingConvertion() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);

  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [IsJobWorkInv, setIsJobWorkInv] = useState(false);

  const [arrEntVoucher, setarrEntVoucher] = useState([]);
  const [arrtabbtn, setarrtabbtn] = useState([true, true, true]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);
  useEffect(() => {
    Load_PendingScreens();
  }, []);
  const Load_PendingScreens = () => {
    if (_Common.strScrType !== "JobWork") {
      let EntTab = arrtabbtn;
      switch (_Common.iScrId) {
        case 14:
        case 8:
          EntTab = EntTab.map((dt) => {
            dt = false;
            return dt;
          });
          break;
        case 15:
        case 9:
          EntTab = EntTab.map((dt, Index) => {
            if (Index > 0) {
              dt = false;
            }
            return dt;
          });
          break;
        case 16:
        case 10:
          EntTab = EntTab.map((dt, Index) => {
            if (Index > 1) {
              dt = false;
            }
            return dt;
          });
          break;
        default:
          break;
      }
      setarrtabbtn(EntTab);
    }
    if (_Common.iScrId === 73 || _Common.iScrId === 76)
      Load_JobworkChallanPending();
    else Load_PendingVouchers("Enq");
  };
  const Load_JobworkChallanPending = async () => {
    try {
      await dispatch(setload_Screen(true));
      let iScrId = 0;
      switch (_Common.iScrId) {
        case 73:
          iScrId = 72; // Load Outward Challan
          break;
        case 76:
          iScrId = 75; // Load Insource Challan
          break;
        default:
          break;
      }
      setIsJobWorkInv(true);
      const objSearch = {
        strColName: "Convertion",
        iScrId: iScrId,
        iPartyId: _HT.iPartyId,
        strCompanyDB: localStorage.db,
        strScrName: _Common.strScrName,
        iUserId: Number(localStorage.UserId),
      };
      await api.post(`Transaction/Load_Vouchers`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setstrFromDate(Res.data.strFromDate);
          setstrToDate(Res.data.strToDate);
          setarrEntVoucher(Res.data.objVouchers);
          setarrEntTableCol(Res.data.EntTablDesign);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Load_PendingVouchers = async (VouchType) => {
    try {
      let ScrId = 0;
      switch (_Common.strScrType) {
        case "Sales":
          switch (VouchType) {
            case "Enq":
              ScrId = 14;
              break;
            case "Quo":
              ScrId = 15;
              break;
            case "Ord":
              ScrId = 16;
              break;
            case "DC":
              ScrId = 17;
              break;
            default:
              break;
          }
          break;
        case "Purchase":
          switch (VouchType) {
            case "Enq":
              ScrId = 8;
              break;
            case "Quo":
              ScrId = 9;
              break;
            case "Ord":
              ScrId = 10;
              break;
            case "DC":
              ScrId = 11;
              break;
            default:
              break;
          }
          break;
        default:
          ScrId = _Common.iScrId;
          break;
      }
      await dispatch(setload_Screen(true));
      await api
        .get(
          `Transaction/View_Convertion/${ScrId}/${_Common.strScrType}/${_HT.iPartyId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntVoucher(Res.data.EntPenConDT);
            setarrEntTableCol(Res.data.EntTablDesign);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Vouchers = async (Value, SearchFor) => {
    try {
      const objSearch = {
        iScrId: _Common.iScrId,
        dpFrom: strFromDate,
        dpTo: strToDate,
        strSearch: strSearch,
        iPartyId: _HT.iPartyId,
        strCompanyDB: localStorage.db,
        iUserId: Number(localStorage.UserId),
      };
      switch (SearchFor) {
        case "From":
          setstrFromDate(Value);
          objSearch.dpFrom = Value;
          break;
        case "To":
          setstrToDate(Value);
          objSearch.dpTo = Value;
          break;
        case "Search":
          setstrSearch(Value);
          objSearch.strSearch = Value;
          break;
        default:
          break;
      }
      await api.post("Transaction/Search_Vouchers/", objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntVoucher(Res.data.EntVouchers);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const ConvertClick = async () => {
    try {
      const EntPenConDT = arrEntVoucher.filter((DT) => DT.isChecked === true);
      if (EntPenConDT.length > 0) {
        if (_Common.iScrId === 73 || _Common.iScrId === 76) {
          const ConDCVouchers = EntPenConDT.map(function (a) {
            return a.vouchId;
          });
          await dispatch(setload_Screen(true));
          await api
            .post(`Transaction/Merge_Convertion/`, {
              iScrId: _Common.iScrId,
              iTaxCalcOnId: _Common.iTaxCalcOnId,
              iTaxRelId: _HT.iTaxRelId,
              iPartyId: _HT.iPartyId,
              objBaseTransDT: _DT.arrEntDT,
              objEntConRow: [],
              objConDCVouch: ConDCVouchers,
              iYearId: Number(localStorage.FYId),
              strCompanyDB: localStorage.db,
              strMasterDB: localStorage.Msdb,
              iUserId: Number(localStorage.UserId),
            })
            .then(async (res) => {
              if (res.data.strStatus === "Success") {
                await dispatch(Merge_ConvertedVouchers(res.data));
                await dispatch(ShowHide_Popup("IsPendinCon"));
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        } else {
          await dispatch(Select_ProducttoConvert(EntPenConDT));
          await dispatch(ShowHide_Popup("IsConvertion"));
        }
      } else AlertMessage("Atleast Voucher Required", "info");
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const tab_click = (TabIndex, VouchType) => {
    let line = document.getElementById("popup-tab-line");
    let lable = $(`.popup-tab label`);
    let fromleft = lable[TabIndex].offsetLeft;
    let width = lable[TabIndex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.popup-tab label:eq(${TabIndex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    Load_PendingVouchers(VouchType);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const sortedData = React.useMemo(() => {
    let sortableData = [...arrEntVoucher];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [arrEntVoucher, sortConfig]);
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>PENDING CONVERTION</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsPendinCon"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-0">
          <div className="row">
            {IsJobWorkInv && (
              <div className="col-md-6 col-sm-12 mb-1 pl-1 mt-1">
                <div className="m-hsearch ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    autoFocus
                    autoComplete="off"
                    id="Invoice_Search"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") $("#from_date").focus();
                      else if (e.key === "ArrowDown")
                        $(".option-list [tabindex=0]").focus();
                      else if (e.key === "Escape")
                        dispatch(ShowHide_Popup("IsPendinCon"));
                    }}
                    tabIndex={52}
                    name="strSearch"
                    value={strSearch}
                    onChange={(e) => Search_Vouchers(e.target.value, "Search")}
                  />
                  <i
                    className="fas fa-search"
                    onClick={(e) => Search_Vouchers(strSearch, "Search")}
                  ></i>
                </div>
              </div>
            )}
            {IsJobWorkInv && (
              <div className="col-md-3 col-sm-6 mb-1 mt-1">
                <input
                  type="date"
                  className="form-control"
                  value={strFromDate}
                  name="strFromDate"
                  id="from_date"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowLeft") $("#Invoice_Search").focus();
                    else if (e.key === "ArrowRight") $("#to_date").focus();
                    else if (e.key === "Escape")
                      dispatch(ShowHide_Popup("IsTransView"));
                  }}
                  onChange={(e) => Search_Vouchers(e.target.value, "From")}
                />
              </div>
            )}
            {IsJobWorkInv && (
              <div className="col-md-3 col-sm-6 mb-1 mt-1">
                <input
                  type="date"
                  id="to_date"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowLeft") $("#from_date").focus();
                    else if (e.key === "ArrowRight")
                      $(".option-list [tabindex=0]").focus();
                    else if (e.key === "Escape")
                      dispatch(ShowHide_Popup("IsTransView"));
                  }}
                  className="form-control"
                  value={strToDate}
                  name="strToDate"
                  onChange={(e) => Search_Vouchers(e.target.value, "To")}
                />
              </div>
            )}
            {_Common.strScrType !== "JobWork" && (
              <div className="popup-tab">
                <label onClick={(e) => tab_click(0, "Enq")} className="active">
                  Enquiry
                </label>
                {arrtabbtn[0] && (
                  <label onClick={(e) => tab_click(1, "Quo")}>Quotation</label>
                )}
                {arrtabbtn[1] && (
                  <label onClick={(e) => tab_click(2, "Ord")}>Order</label>
                )}
                {arrtabbtn[2] && (
                  <label onClick={(e) => tab_click(3, "DC")}>Challan</label>
                )}
                <div id="popup-tab-line"></div>
              </div>
            )}
            <div className="table-card">
              <table className="option-list">
                <thead>
                  <tr>
                    {arrEntTableCol.map(
                      (ht, Index) =>
                        ht.isShow && (
                          <th
                            key={Index}
                            onClick={(e) => requestSort(ht.strColName)}
                          >
                            {ht.strAliasName}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((Item, Index) => (
                    <tr
                      key={Index}
                      tabIndex={Index}
                      onKeyDown={(e) => {
                        const arrlength = arrEntVoucher.length - 1;
                        if (e.key === "ArrowDown") {
                          if (Index === arrlength)
                            $(`.option-list [tabindex=${0}]`).focus();
                          else
                            $(`.option-list [tabindex=${Index + 1}]`).focus();
                        }
                        if (e.key === "ArrowUp") {
                          if (Index === 0)
                            $(`.option-list [tabindex=${arrlength}]`).focus();
                          else
                            $(`.option-list [tabindex=${Index - 1}]`).focus();
                        }
                        if (e.key === "ArrowLeft") $("#to_date").focus();
                        if (e.key === "ArrowRight")
                          $("#Invoice_Search").focus();
                      }}
                    >
                      {arrEntTableCol.map((dt, Index) =>
                        dt.isReadOnly
                          ? dt.isShow && (
                              <td key={Index} style={Table_Style(dt, Item)}>
                                {dt.strDataType === "number"
                                  ? Number(Item[dt.strColName]).toFixed(2)
                                  : Item[dt.strColName]}
                              </td>
                            )
                          : dt.isShow && (
                              <td key={Index} style={Table_Style(dt, Item)}>
                                {dt.strDataType === "checkbox" && (
                                  <input
                                    type={dt.strDataType}
                                    defaultChecked={Item[dt.strColName]}
                                    onChange={(e) =>
                                      (Item[dt.strColName] =
                                        !Item[dt.strColName])
                                    }
                                  />
                                )}
                              </td>
                            )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={ConvertClick}>
              Convert&nbsp;<i className="bx bx-transfer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PendingConvertion;
