import axios from "axios";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import moment from "moment";
const api = axios;

const Local = `localhost:5000`;
const Production = `Server.fabgreentechnologies.in`;
const Testing = `TestingApp.fabgreentechnologies.in`;
const NewHosting = `Server.justbill.co.in`;
const VpsHosting = `server.justbillsoftware.com`;
const API_URL = `https://${VpsHosting}/api/`;

api.interceptors.request.use(
  function (config) {
    config.baseURL = API_URL;
    config.headers["Authorization"] = `Bearer ${localStorage.AuthKey}`;
    return config;
  },
  function (e) {
    return Authorize_User(e);
  }
);
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (e) {
    return Authorize_User(e);
  }
);
const Authorize_User = async (e) => {
  if (e.response && e.response.status === 401) {
    await window.location.reload();
    await localStorage.clear();
    await sessionStorage.clear();
    window.location.href = "https://justbillsoftware.com";
  }
  console.error(e);
  return Promise.reject(e);
};
export const Logout_User = async () => {
  const parems = {
    iScrId: 0,
    strCompanyDB: localStorage.Msdb,
    strMasterDB: localStorage.db,
    iClientId: Number(localStorage.ClientId),
    iUserId: Number(localStorage.UserId),
    iRoleId: Number(localStorage.RoleId),
  };
  await api
    .post(`Admin/Update_UserLogOut`, parems)
    .then((dt) => {})
    .catch((e) => {
      console.log(e);
    })
    .finally(async (e) => {
      await localStorage.clear();
      await sessionStorage.clear();
      await window.location.reload();
      window.location.href = "/";
    });
};
export const Backup_Data = async ({ Name, dispatch, Msdb }) => {
  let Status = false;
  try {
    await dispatch(setload_Screen(true));
    await fetch(`${API_URL}Admin/Backup_Database/${Msdb}`)
      .then((res) => res.blob())
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = url;
        const now = moment();
        const curr_date = now.format("D-MMMM-YYYY");
        const ClientName = Name ? Name : "JustBill";
        const FileName = `${ClientName}_${curr_date}.zip`;
        link.setAttribute("download", FileName);
        link.click();
        Status = true;
      })
      .catch(async (err) => {
        await dispatch(
          Show_MsgBox({ Message: err.stack, Type: "error", isMsg: true })
        );
        Status = false;
      });
  } catch (err) {
    await dispatch(
      Show_MsgBox({ Message: err.message, Type: "error", isMsg: true })
    );
    Status = false;
  } finally {
    await dispatch(setload_Screen(false));
    return Status;
  }
};
export const Backup_Server = async (dispatch) => {
  let Status = false;
  try {
    await dispatch(setload_Screen(true));
    await fetch(`${API_URL}Admin/Backup_Server`)
      .then((res) => res.blob())
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = url;
        const now = moment();
        const curr_date = now.format("D-MMMM-YYYY");
        const FileName = `JustBill_${curr_date}.zip`;
        link.setAttribute("download", FileName);
        link.click();
        Status = true;
      })
      .catch(async (err) => {
        await dispatch(
          Show_MsgBox({ Message: err.stack, Type: "error", isMsg: true })
        );
        Status = false;
      });
  } catch (err) {
    await dispatch(
      Show_MsgBox({ Message: err.message, Type: "error", isMsg: true })
    );
    Status = false;
  } finally {
    await dispatch(setload_Screen(false));
    return Status;
  }
};
export default api;
