import React, { useEffect, useState } from "react";

import api from "../Admin/Config";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  ShowHide_Popup,
  Update_ShipAdd,
} from "../ReduxStore/Transactionstates";
function ShippingAddressPopup(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const [iShippAddId, setiShippAddId] = useState(0);
  const [iPartyId, setiPartyId] = useState(0);
  const [iScrId, setiScrId] = useState(0);
  const [strPartyName, setstrPartyName] = useState("");
  const [strDoorNo, setstrDoorNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [iState, setiState] = useState(32);
  const [iCountry, setiCountry] = useState(1);
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGST, setstrGST] = useState("");
  const [bIsAddDefault, setbIsAddDefault] = useState(false);
  const [bIsShippDefault, setbIsShippDefault] = useState(false);
  const [isNewModify, setisNewModify] = useState(false);
  const [arrAddAdress, setarrAddAdress] = useState([]);
  const [arrState, setarrState] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);

  useEffect(() => {
    let PartyId = 0;
    let iScrId = 0;
    if (props.PartyId) {
      PartyId = props.PartyId;
      iScrId = props.iScrId;
    } else {
      PartyId = _HT.iPartyId;
      iScrId = _Common.PartyScrId;
    }
    LoadPartyAddress(PartyId, iScrId);
  }, []);

  const LoadPartyAddress = async (PartyId, PartyScrId) => {
    try {
      setiPartyId(PartyId);
      setiScrId(PartyScrId);
      await dispatch(setload_Screen(true));
      await api
        .get(
          `ShippingAddress/Load_ShippingAddress/${PartyId}/${PartyScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "success") {
            setarrAddAdress(Res.data.EntShipAdd);
            setarrCountry(Res.data.EntCountry);
            setarrState(Res.data.EntState);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
          dispatch(setload_Screen(false));
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const InsUpd_Address = () => {
    try {
      const InsUpdAddressParem = {
        ShippAddId: iShippAddId,
        strPartyName: strPartyName,
        iPartyId: Number(iPartyId),
        iScrId: iScrId,
        strAdd1: strDoorNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPinCode: strPinCode,
        strMobile: strMobile,
        strEmail: strEmail,
        strGst: strGST,
        iState: iState,
        iCountry: iCountry,
        bIsAddDefault: Number(bIsAddDefault),
        bIsShippDefault: Number(bIsShippDefault),
        strCompanyDB: localStorage.db,
      };
      dispatch(setload_Screen(true));
      api
        .post(`ShippingAddress/InsUpdShippingAddress`, InsUpdAddressParem)
        .then((Res) => {
          if (Res.data.strStatus === "success") {
            AlertMessage(Res.data.strMessage, "success");
            dispatch(Update_ShipAdd(Res.data.EntShipAdd[0].shippAddress));
            Popup_Close();
            clear_click();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
          dispatch(setload_Screen(false));
        });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const Update_ShippingDeafult = (add) => {
    try {
      dispatch(setload_Screen(true));
      api
        .post(
          `ShippingAddress/Update_ShippingDeafult/${
            add.shippAddId
          }/${1}/${Number(iPartyId)}/${iScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "success") {
            dispatch(Update_ShipAdd(add.shippAddress));
            Popup_Close();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
          dispatch(setload_Screen(false));
        });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const delete_address = () => {
    try {
      dispatch(setload_Screen(true));
      api
        .post(
          `ShippingAddress/deletepartyaddress/${Number(iShippAddId)}/${
            localStorage.db
          }`
        )
        .then((Res) => {
          if (Res.data.strStatus === "success") {
            AlertMessage(Res.data.strMessage, "success");
            clear_click();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const clear_click = () => {
    setiShippAddId(0);
    setstrPartyName("");
    setstrDoorNo("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrPinCode("");
    setstrMobile("");
    setstrEmail("");
    setstrGST("");
    setiState(32);
    setiCountry(1);
    setbIsAddDefault(false);
    setbIsShippDefault(false);
    LoadPartyAddress(iPartyId, iScrId);
  };
  const Select_Address = (address) => {
    setisNewModify(true);
    setiShippAddId(address.shippAddId);
    setstrPartyName(address.strPartyName);
    setstrDoorNo(address.strAdd1);
    setstrStreet(address.strAdd2);
    setstrArea(address.strAdd3);
    setstrCity(address.strAdd4);
    setstrPinCode(address.strPinCode);
    setiState(address.iState);
    setiCountry(address.iCountry);
    setstrMobile(address.strMobile);
    setstrEmail(address.strEmail);
    setstrGST(address.strGst);
    setbIsAddDefault(address.bIsAddDefault === 1 ? true : false);
    setbIsShippDefault(address.bIsShippDefault === 1 ? true : false);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Country_Onchange = async (CountryId) => {
    try {
      setiCountry(CountryId);
      const parems = {
        iCountryId: Number(CountryId),
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await api.post(`CountryState/Load_State`, parems).then((res) => {
        if (res.data.strStatus === "Success") {
          setarrState(res.data.EntState);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Add_NewAddress = () => {
    setisNewModify(true);
    setstrPartyName(strPartyName);
    setiShippAddId(0);
    setstrDoorNo("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrPinCode("");
    setstrMobile("");
    setstrEmail("");
    setstrGST("");
    setiState(32);
    setiCountry(1);
  };
  const Popup_Close = () => {
    if (props.Close) props.Close();
    else dispatch(ShowHide_Popup("IsShippAdd"));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Additional Address</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={Popup_Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2 ">
          {!isNewModify && (
            <div className="add-container">
              {arrAddAdress.map((add, Index) => (
                <div className="add-cart" key={Index}>
                  <h5>{add.strPartyName}</h5>
                  <h6>
                    <i className="fa-solid fa-location-dot mr-2"></i>
                    {add.strAdd1}
                    {add.strAdd2 !== "" ? "," + add.strAdd2 : ""}
                    {add.strAdd3 !== "" ? "," + add.strAdd3 : ""}
                  </h6>
                  <h6>
                    {add.strAdd4}
                    {add.sName !== "" ? "," + add.sName : ""}
                  </h6>
                  <h6>
                    {add.cName}
                    {add.strPinCode.length > 1 ? "-" + add.strPinCode : ""}
                  </h6>
                  <h6>
                    <i className="fa-solid fa-phone mr-1"></i>
                    {add.strMobile}
                  </h6>
                  <h6>
                    <i className="fa-regular fa-envelope mr-1"></i>
                    {add.strEmail}
                  </h6>
                  <h6>GSTIN : {add.strGst}</h6>
                  <div className="btn-add">
                    <span className="lbltooltip">
                      <span
                        className="icon-btn"
                        onClick={(e) => Select_Address(add)}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </span>
                      <span className="tooltiptext">Edit</span>
                    </span>
                    <span className="lbltooltip">
                      <span className="icon-btn">
                        {add.bIsShippDefault === 1 && (
                          <i
                            className="fa-solid fa-star"
                            onClick={(e) => Update_ShippingDeafult(add)}
                          ></i>
                        )}
                        {add.bIsShippDefault === 0 && (
                          <i
                            className="fa-regular fa-star"
                            onClick={(e) => Update_ShippingDeafult(add)}
                          ></i>
                        )}
                      </span>
                      <span className="tooltiptext">Select Address</span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
          {isNewModify && (
            <div className="row p-2">
              <div className="col-md-6 col-sm-12">
                <label>Party Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={strPartyName}
                  onChange={(e) => setstrPartyName(e.target.value)}
                  placeholder="Enter Party Name"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Door No</label>
                <input
                  type="text"
                  className="form-control"
                  value={strDoorNo}
                  onChange={(e) => setstrDoorNo(e.target.value)}
                  placeholder="Enter Door No"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Street</label>
                <input
                  type="text"
                  className="form-control"
                  value={strStreet}
                  onChange={(e) => setstrStreet(e.target.value)}
                  placeholder="Enter Street"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Area</label>
                <input
                  type="text"
                  className="form-control"
                  value={strArea}
                  onChange={(e) => setstrArea(e.target.value)}
                  placeholder="Enter Area"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={strCity}
                  onChange={(e) => setstrCity(e.target.value)}
                  placeholder="Enter City"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Pin Code</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Enter Pincode"
                  value={strPinCode}
                  onChange={(e) => setstrPinCode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Country</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow15"
                  showoption={_selecrparem.isoptionShow15}
                  placeholder="Select Country"
                  select_value={(val) => Country_Onchange(val)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iCountry}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={arrCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>State</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow16"
                  showoption={_selecrparem.isoptionShow16}
                  placeholder="Select State"
                  select_value={(val) => setiState(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iState}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={arrState}
                  EmptVal="State"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  value={strMobile}
                  onChange={(e) => setstrMobile(e.target.value)}
                  placeholder="Enter Mobile Number"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>E-Mail</label>
                <input
                  type="text"
                  className="form-control"
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                  placeholder="Enter Email"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>GST Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={strGST}
                  onChange={(e) => setstrGST(e.target.value)}
                  placeholder="Enter GST Number"
                  maxLength={15}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Default Billing Address</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={bIsAddDefault}
                    id="flexCheckChecked"
                    onChange={(e) => setbIsAddDefault(!bIsAddDefault)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Default Shipping Address</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={bIsShippDefault}
                    id="flexCheckChecked"
                    onChange={(e) => setbIsShippDefault(!bIsShippDefault)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="popup-footer">
          <div className="popup-btn-group">
            {isNewModify && (
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  dispatch(
                    Show_MsgBox({
                      Button: InsUpd_Address,
                      Question: "Do You Want to Save Address?",
                      isdialog: true,
                    })
                  );
                }}
              >
                Save&nbsp;<i className="bx bxs-save"></i>
              </button>
            )}
            {isNewModify && (
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  if (Number(iShippAddId) === 0)
                    AlertMessage("Please Select Address", "info");
                  else
                    dispatch(
                      Show_MsgBox({
                        Button: delete_address,
                        Question: "Do You Want to Delete Address?",
                        isdialog: true,
                      })
                    );
                }}
              >
                Delete&nbsp;<i className="bx bx-trash-alt"></i>
              </button>
            )}
            {isNewModify && (
              <button className="btn-fabgreen" onClick={clear_click}>
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            <button className="btn-fabgreen" onClick={(e) => Add_NewAddress()}>
              New&nbsp;<i className="fa-solid fa-location-dot"></i>
            </button>
            {isNewModify && (
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  setisNewModify(false);
                  clear_click();
                }}
              >
                <i className="bx bx-arrow-back"></i>&nbsp;Back to Address
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShippingAddressPopup;
