
import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import ReactTable from "../General/ReactTable";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function PriceGroupMaster(props) {
  const dispatch = useDispatch();
  const iScrId = 80;
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const strPGCodeTag = "Enter PriceGroup Code";
  const [bIsDefault, setbIsDefault] = useState(false);
  const [strPGCode, setstrPGCode] = useState("");
  const [iPGrpId, setiPGrpId] = useState(0);
  const [strPGrpName, setstrPGrpName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [dDiscount, setdDiscount] = useState((0).toFixed(2));
  const [strSearch, setstrSearch] = useState("");
  const [ListPriceGRP, setListPriceGRP] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);
  useEffect(() => {
    GetDefaultPriceGroup();
  }, []);
  const GetDefaultPriceGroup = () => {
    try {
      api.get(
        
          "PriceGroupMaster/GetDefaultData/" +
          iScrId +
          "/" +
          localStorage.db
      ).then((Res) => {
        setListPriceGRP(Res.data.objEntPRG);
        setstrPGCode(Res.data.objCodFormate[0].strCode);
        setstrPrefix(Res.data.objCodFormate[0].strPrefix);
        setiNumSeqNo(Res.data.objCodFormate[0].iNumSeqNo);
        dispatch(setload_Screen(false));
        setManualCode(!Res.data.objCodFormate[0].isManualCode);
        ButtonPermission(JSON.parse(localStorage.Menu), iPGrpId);
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = () => {
    try {
      dispatch(setload_Screen(true));
      const InsUpdPG = {
        strPGCode: strPGCode,
        iPGrpId: iPGrpId,
        strPGrpName: strPGrpName,
        dDiscount: Number(dDiscount),
        bIsDefault: bIsDefault,
        iTitleTag: iScrId,
        iNumSeqNo: iNumSeqNo,
        strPrefix: strPrefix,
        strDescription: strDescription,
        dMinMargin: 0,
        strPGCodeTag: strPGCodeTag,
        strSearch: strSearch,
        strFilter: "",
        strFilterColumn: "All",
        strCompanyDB: localStorage.db,
      };
      api.post( "PriceGroupMaster/InsUpdPG/", InsUpdPG).then(
        (json) => {
          if (json.data.strStatus === "Success") {
            AlertMessage(json.data.strMessage, "success");
            ClearClick();
          } else {
            dispatch(setload_Screen(false));
            AlertMessage(json.data.strMessage, "error");
          }
        }
      );
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const ClearClick = () => {
    try {
      dispatch(setload_Screen(false));
      setstrPGCode("");
      setiPGrpId(0);
      setstrPGrpName("");
      setbIsDefault(false);
      setstrDescription("");
      setdDiscount((0.0).toFixed());
      setBtnDeleteDisable(false);
      setBtnSaveDisable(false);
      GetDefaultPriceGroup();
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const DeleteClick = () => {
    try {
      dispatch(setload_Screen(true));
      api.post(
        
          "PriceGroupMaster/Delete/" +
          iScrId +
          "/" +
          iPGrpId +
          "/" +
          bIsDefault +
          "/" +
          localStorage.db
      ).then((json) => {
        if (json.data.strStatus === "Success") {
          AlertMessage(json.data.strMessage, "success");
          ClearClick();
        } else {
          dispatch(setload_Screen(false));
          AlertMessage(json.data.strMessage, "error");
        }
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const View_Pricegroup = (iPGrpId) => {
    try {
      dispatch(setload_Screen(true));
      api.get(
        
          "PriceGroupMaster/GetPriceGroup/" +
          iPGrpId +
          "/" +
          iScrId +
          "/" +
          localStorage.db
      ).then((Res) => {
        setstrPGCode(Res.data[0].strPGCode);
        setiPGrpId(Res.data[0].iPGrpId);
        setstrPGrpName(Res.data[0].strPGrpName);
        setbIsDefault(Res.data[0].bIsDefault);
        setdDiscount(Res.data[0].dDiscount);
        setstrDescription(Res.data[0].strDescription);
        dispatch(setload_Screen(false));
        ButtonPermission(JSON.parse(localStorage.Menu), Res.data[0].iPGrpId);
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const Search_PriceGroup = (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objsearch = {
        strSearch: strtxt,
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
      };
      api.post(
         "PriceGroupMaster/Search_PriceGroup/",
        objsearch
      ).then((Res) => {
        setListPriceGRP(Res.data);
      });
    } catch (err) {
      AlertMessage(err.strMessage, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const columns = [
    {
      Header: "Price Group",
      accessor: "strPGrpName",
      style: { justifyContent: "left" },
    },
  ];
  const ButtonPermission = (ListMenu, PgId) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === 62);
    if (ListMenu.length > 0) {
      if (Number(PgId) > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (Number(PgId) === 0) {
        if (ListMenu[0].isNew === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
      }
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>PRICE GROUP</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2">
          <div className="row p-2">
            <div className="col-sm-12 col-md-6 p-0">
              <div className="row">
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>
                    Code<span className="danger">*</span>
                  </label>
                  <div className="d-inline-flex " style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      value={strPGCode}
                      readOnly={ManualCode}
                      onChange={(e) => setstrPGCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>
                    Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strPGrpName}
                    onChange={(e) => setstrPGrpName(e.target.value)}
                    autoFocus
                    placeholder="PriceGroup Name"
                  />
                </div>
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>Discount</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    value={dDiscount}
                    onClick={(e) => e.target.select()}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        setdDiscount((0).toFixed(2));
                      } else setdDiscount(Number(dDiscount).toFixed(2));
                    }}
                    onChange={(e) => setdDiscount(e.target.value)}
                  />
                </div>
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>Description</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strDescription}
                    onChange={(e) => setstrDescription(e.target.value)}
                    placeholder="Description"
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Is Default</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={bIsDefault}
                      onChange={(e) => setbIsDefault(!bIsDefault)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 p-0">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  value={strSearch}
                  onChange={(e) => Search_PriceGroup(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_PriceGroup(strSearch)}
                ></i>
              </div>
              <ReactTable
                columns={columns}
                data={ListPriceGRP}
                minRows={15}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  View_Pricegroup(rowInfo.iPGrpId);
                }}
                background={true}
                className="full-table"
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              disabled={BtnSaveDisable}
              onClick={(e) => {
                if (strPGCode === "") {
                  AlertMessage("Please Enter Code", "error");
                } else if (strPGrpName === "") {
                  AlertMessage("Please Enter Name", "error");
                } else if (dDiscount === 0) {
                  AlertMessage("Please Enter Discount", "error");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Price Group?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              disabled={BtnDeleteDisable}
              onClick={(e) => {
                if (iPGrpId === 0) {
                  AlertMessage("Please Select PriceGroup", "error");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Price Group?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PriceGroupMaster;
