import { useEffect, useState } from "react";
import api from "../Admin/Config";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { Select_JobWork, ShowHide_Popup } from "../ReduxStore/Transactionstates";
import PopupSearch from "../Transaction/PopupSearch";
function JobSearch() {
  const dispatch = useDispatch();
  const [arrEntJobWork, setarrEntJobWork] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_Jobwork();
  }, []);
  const Load_Jobwork = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(
        `Transaction/Load_Jobwork/${localStorage.db}/${
          localStorage.Msdb
        }/${Number(localStorage.UserId)}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntJobWork(Res.data.EntJobWork);
          setarrEntTableCol(Res.data.EntTablDesign);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_JobWork = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objsearch = {
        strSearch: strtxt,
        iRoleId: Number(localStorage.RoleId),
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
      };
      await api.post(`Transaction/Search_JobWork`, objsearch).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntJobWork(Res.data.EntJobWork);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <PopupSearch
      ScrName="Jobwork SEARCH "
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrEntJobWork}
      UpdateFilterCol={""}
      Search_Data={Search_JobWork}
      View_Data={async (e) => {
        await dispatch(ShowHide_Popup("IsJobSearch"));
        await dispatch(Select_JobWork(e));
      }}
      Close={() => dispatch(ShowHide_Popup("IsJobSearch"))}
      IsJobWork={true}
    />
  );
}
export default JobSearch;
