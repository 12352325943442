
import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
function PrintTemplateCopy(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iScreenFrom, setiScreenFrom] = useState(0);
  const [iTempFor, setiTempFor] = useState(0);
  const [iScreenTo, setiScreenTo] = useState(0);
  const [iSourceTemId, setiSourceTemId] = useState(0);
  const [strTempName, setstrTempName] = useState("");
  const [ListScreenFrom, setListScreenFrom] = useState([]);
  const [ListTempFor, setListTempFor] = useState([]);
  const [ListSourceTemp, setListSourceTemp] = useState([]);
  const [ListScreenTo, setListScreenTo] = useState([]);

  useEffect(() => {
    Load_PrintTemplates();
  }, []);
  const Load_PrintTemplates = () => {
    try {
      api.get(
        
          "PrintMaster/Load_OriginalTemplate/" +
          localStorage.db +
          "/" +
          localStorage.Msdb
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListScreenFrom(Res.data.EntScreen);
          setListScreenTo(Res.data.EntScreen);
          setListTempFor(Res.data.EntPrinterType);
        } else AlertMessage(Res.data.strMessage, "error");
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "info");
    }
  };
  const View_SourceTemplate = (val, valfor) => {
    try {
      let Printer = iTempFor;
      let ScrId = iScreenFrom;
      switch (valfor) {
        case "Screen":
          setiScreenFrom(val);
          ScrId = val;
          break;
        case "PrinterType":
          setiTempFor(val);
          Printer = val;
          break;
        default:
          break;
      }
      api.get(
        
          "PrintMaster/View_SourceTemplate/" +
          ScrId +
          "/" +
          Printer +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListSourceTemp(Res.data.EntTemplate);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const Copy_PrintTemplate = () => {
    try {
      const objCopyTem = {
        iFromScrId: Number(iScreenFrom),
        iToScrId: Number(iScreenTo),
        iPrintTypeId: Number(iTempFor),
        iSourceTempId: Number(iSourceTemId),
        strNewTempName: strTempName,
        strCompanyDB: localStorage.db,
      };
      api.post( "PrintMaster/Copy_PrintTemplate/", objCopyTem).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            Clear_Template();
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const CheckTempExist = (strtempName) => {
    try {
      const objSearch = {
        strSearch: strtempName,
        strCompanyDB: localStorage.db,
      };
      api.post( "PrintMaster/CheckTemplateExist", objSearch).then(
        (Res) => {
          if (Res.data.strStatus === "error")
            AlertMessage(Res.data.strMessage, "info");
        }
      );
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const Clear_Template = () => {
    dispatch(setload_Screen(true));
    setiScreenFrom(0);
    setiScreenTo(0);
    setstrTempName("");
    setiTempFor(0);
    setiSourceTemId(0);
    Load_PrintTemplates();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div className="popup-container">
      <div className="popup-content" style={{ maxWidth: "300px" }}>
        <div className="popup-header">
          <h5>Print Template Copy</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row p-2">
            <div className="col-md-6 col-sm-12 mb-1">
              <label className="mb-1">Screen From</label>
              <CustomizedSelectoption
                optionparem="isoptionShow16"
                showoption={_parem.isoptionShow16}
                placeholder="Select Screen"
                select_value={(val) =>
                  View_SourceTemplate(Number(val), "Screen")
                }
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iScreenFrom}
                displayName="strScrName"
                disvalue="iScrId"
                arrydata={ListScreenFrom}
                EmptVal="Screen Name"
              />
            </div>
            <div className="col-md-6  col-sm-12 mb-1">
              <label className="mb-1">Screen To</label>
              <CustomizedSelectoption
                optionparem="isoptionShow18"
                showoption={_parem.isoptionShow18}
                placeholder="Select Screen"
                select_value={(val) => setiScreenTo(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iScreenTo}
                displayName="strScrName"
                disvalue="iScrId"
                arrydata={ListScreenTo}
                EmptVal="Screen Name"
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-1">
              <label className="mb-1">Printer Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow17"
                showoption={_parem.isoptionShow17}
                placeholder="Select Printer Type"
                select_value={(val) =>
                  View_SourceTemplate(Number(val), "PrinterType")
                }
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iTempFor}
                displayName="strPrinterTypeName"
                disvalue="iPrinterTypeId"
                arrydata={ListTempFor}
                EmptVal="Printer Type"
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-1">
              <label className="mb-1">Source Template</label>
              <CustomizedSelectoption
                optionparem="isoptionShow19"
                showoption={_parem.isoptionShow19}
                placeholder="Select Template"
                select_value={(val) => setiSourceTemId(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iSourceTemId}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={ListSourceTemp}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-md-12 col-sm-12 mb-1">
              <label>Template Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Template Name"
                value={strTempName}
                onChange={(e) => setstrTempName(e.target.value)}
                onBlur={(e) => CheckTempExist(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iScreenFrom) === 0) {
                  AlertMessage("Please Select Screen From", "info");
                } else if (Number(iTempFor) === 0) {
                  AlertMessage("Please Select Printer Type", "info");
                } else if (Number(iScreenTo) === 0) {
                  AlertMessage("Please Select Screen To", "info");
                } else if (Number(iSourceTemId) === 0) {
                  AlertMessage("Please Select Source Template", "info");
                } else if (strTempName.length === 0) {
                  AlertMessage("Please Enter Template Name", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Copy_PrintTemplate,
                      Question: "Do You Want to Copy Template?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintTemplateCopy;
