import React, { useState } from "react";
import PartySearch from "../Transaction/PartySearch";
import BankSearch from "../Master/BankSearch";
import { useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import VouchernoSetting from "../Setting/VouchernoSetting";
import BankMaster from "../Master/BankMaster";

function PayRecBody(props) {
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [IsPartyOpen, setIsPartyOpen] = useState(false);
  const [IsBankOpen, setIsBankOpen] = useState(false);
  const [IsNewBank, setIsNewBank] = useState(false);
  const [isVouchNoSett, setisVouchNoSett] = useState(false);
  const [iScrId, setiScrId] = useState(0);
  const [strFieldVenName, setstrFieldVenName] = useState("");

  const PartySearchPopup = () => {
    if (props.iVenTypeId === 4) {
      setiScrId(2);
      setstrFieldVenName("CustName");
    } else if (props.iVenTypeId === 5) {
      setiScrId(50);
      setstrFieldVenName("VenName");
    } else {
      setiScrId(3);
      setstrFieldVenName("SuppName");
    }
    setIsPartyOpen(!IsPartyOpen);
  };
  const View_Party = (Party) => {
    setIsPartyOpen(!IsPartyOpen);
    props.View_Party(Party);
  };
  const View_Data = (bank) => {
    setIsBankOpen(!IsBankOpen);
    props.GetBankDetails(bank.bnId, bank.bankNames);
  };

  return (
    <div className="row p-2">
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Voucher no <span className="danger">*</span>
        </label>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Voucher Number"
            aria-label="Recipient's username"
            value={props.strVouchNo}
            aria-describedby="button-addon2"
            tabIndex={props.ListTabOrder[0].VoucherTab}
            onKeyDown={(e) => props.TabIndex(e)}
            id="txtVoucherNo"
            name="strVouchNo"
            disabled={!props.isManualNo}
            onChange={(e) => props.OnChangeEvent(e)}
          />
          <span
            className="inv-no"
            onClick={(e) => setisVouchNoSett(!isVouchNoSett)}
          >
            <i className="bx bx-cog"></i>
          </span>
        </div>
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Trans No <span className="danger">*</span>
        </label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          id="txtTransNo"
          name="strTransNo"
          tabIndex={props.ListTabOrder[0].TransNoTab}
          onKeyDown={(e) => props.TabIndex(e)}
          disabled
          value={props.strTransNo}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Date <span className="danger">*</span>
        </label>
        <input
          type="date"
          name="strVouchDate"
          className="form-control"
          tabIndex={props.ListTabOrder[0].VouchDateTap}
          onKeyDown={(e) => props.TabIndex(e)}
          value={props.strVouchDate}
          onChange={(e) => props.OnChangeEvent(e)}
          disabled={props.iVouchId > 0 ? true : false}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Vendor Type <span className="danger">*</span>
        </label>
        <CustomizedSelectoption
          optionparem="isoptionShow"
          showoption={_selecrparem.isoptionShow}
          placeholder="Select  Vendor Type "
          taborder={props.ListTabOrder[0].VenTypeTab}
          keydown={(e) => props.TabIndex(e)}
          select_value={(val) =>
            props.OnSelectionChange({
              target: { value: val, name: "iVenTypeId" },
            })
          }
          btnname=""
          btnshow={false}
          show_popup={""}
          valueonly={true}
          defaultval={props.iVenTypeId}
          displayName="strVenTypeName"
          disvalue="iVenTypeId"
          arrydata={props.listVenType}
          EmptVal="Vendor Type "
        />
      </div>
      <div className="col-md-6 col-sm-12 mt-1">
        <label>
          Vendor Name <span className="danger">*</span>
        </label>
        <div className="custom-select">
          <input
            autoFocus
            type="text"
            aria-label="First name"
            className="form-control"
            tabIndex={props.ListTabOrder[0].VenNameTab}
            placeholder="Press Enter to Select Vendor"
            value={props.strVenName}
            onChange={(e) => props.OnChangeEvent(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") PartySearchPopup();
              else props.TabIndex(e);
            }}
            onClick={PartySearchPopup}
          />
          <i
            className="fa-solid fa-caret-down"
            style={{
              position: "absolute",
              right: "10px",
              color: "darkgreen",
              transform: IsPartyOpen ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          ></i>
        </div>
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Payment Mode </label>
        <CustomizedSelectoption
          optionparem="isoptionShow1"
          showoption={_selecrparem.isoptionShow1}
          placeholder="Select Payment Mode "
          taborder={props.ListTabOrder[0].PayModeTab}
          keydown={(e) => props.TabIndex(e)}
          select_value={(val) => props.PayMode_OnSelectionChange(val)}
          btnname=""
          btnshow={false}
          show_popup={""}
          valueonly={true}
          defaultval={props.iPayModeId}
          displayName="strPaymentMode"
          disvalue="iPM_Id"
          arrydata={props.listPayMode}
          EmptVal="Payment Mode "
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Bank Account</label>
        <CustomizedSelectoption
          optionparem="isoptionShow2"
          showoption={_selecrparem.isoptionShow2}
          placeholder="Select Bank Account"
          taborder={props.ListTabOrder[0].BankAccountTab}
          keydown={(e) => props.TabIndex(e)}
          select_value={(val) => {
            if (!props.IsBank)
              props.OnSelectionChange({
                target: { value: val, name: "iBankAccId" },
              });
          }}
          btnname=""
          btnshow={false}
          show_popup={""}
          valueonly={true}
          defaultval={props.iBankAccId}
          displayName="bankNames"
          disvalue="bankAccId"
          arrydata={props.listBankAcc}
          EmptVal="Bank Account"
          disabled={props.listBankAcc.length > 1 ? false : true}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Cheque Number </label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          name="strChequeNo"
          tabIndex={props.ListTabOrder[0].CheckNoTab}
          placeholder="Enter Cheque Number"
          disabled={props.IsCheque}
          value={props.strChequeNo}
          onKeyDown={(e) => props.TabIndex(e)}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Cheque Date</label>
        <input
          type="date"
          className="form-control"
          name="strChequeDate"
          value={props.strChequeDate}
          disabled={props.IsCheque}
          tabIndex={props.ListTabOrder[0].CheckDateTab}
          onKeyDown={(e) => props.TabIndex(e)}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Account Holder Name</label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          name="strAccHolderName"
          placeholder="Enter Account Holder Name"
          tabIndex={props.ListTabOrder[0].AccHolTab}
          onKeyDown={(e) => props.TabIndex(e)}
          value={props.strAccHolderName}
          onChange={(e) => props.OnChangeEvent(e)}
          disabled={props.IsBank}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Account Number</label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          tabIndex={props.ListTabOrder[0].AccNoTab}
          onKeyDown={(e) => props.TabIndex(e)}
          name="strAccNo"
          placeholder="Enter Account Number"
          value={props.strAccNo}
          onChange={(e) => props.OnChangeEvent(e)}
          disabled={props.IsBank}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Bank Name </label>
        <div className="custom-select">
          <input
            type="text"
            aria-label="First name"
            className="form-control"
            name="strBankName"
            placeholder="Enter to Select Bank"
            onChange={(e) => props.OnChangeEvent(e)}
            value={props.strBankName}
            disabled={props.IsBank}
            tabIndex={props.ListTabOrder[0].BankNameTab}
            onClick={() => setIsBankOpen(!IsBankOpen)}
            onInput={() => setIsBankOpen(!IsBankOpen)}
          />
          <i
            className="fa-solid fa-caret-down"
            style={{
              position: "absolute",
              right: "10px",
              color: "darkgreen",
              transform: IsBankOpen ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          ></i>
        </div>
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Amount</label>
        <input
          type="number"
          aria-label="First name"
          className="form-control"
          onKeyDown={(e) => props.TabIndex(e)}
          onClick={(e) => e.target.select()}
          tabIndex={props.ListTabOrder[0].AmountTab}
          name="dAmount"
          style={{ textAlign: "left" }}
          value={props.dAmount}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Print Name</label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          onKeyDown={(e) => props.TabIndex(e)}
          name="strPrintName"
          placeholder="Enter Print Name"
          tabIndex={props.ListTabOrder[0].PrintNameTab}
          value={props.strPrintName}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      <div className="col-md-3 col-sm-12 mt-1">
        <label>Remarks </label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          onKeyDown={(e) => props.TabIndex(e)}
          name="strRemark"
          tabIndex={props.ListTabOrder[0].Remarktab}
          placeholder="Enter Remarks"
          value={props.strRemark}
          onChange={(e) => props.OnChangeEvent(e)}
        />
      </div>
      {IsPartyOpen && (
        <PartySearch
          Close={() => setIsPartyOpen(!IsPartyOpen)}
          View_Party={View_Party}
          ScrId={iScrId}
          ColumnName={strFieldVenName}
          VenTypeId={props.iVenTypeId}
          Partyname={props.strVenName}
          isNew={false}
        />
      )}
      {IsBankOpen && (
        <BankSearch
          Close={() => setIsBankOpen(!IsBankOpen)}
          View_Data={View_Data}
          NewBank={() => {
            setIsBankOpen(!IsBankOpen);
            setIsNewBank(!IsNewBank);
          }}
        />
      )}
      {IsNewBank && (
        <BankMaster
          Close={() => setIsNewBank(!IsNewBank)}
          Bank_Search={() => {
            setIsNewBank(!IsNewBank);
            setIsBankOpen(!IsBankOpen);
          }}
        />
      )}
      {isVouchNoSett && (
        <VouchernoSetting
          iScrId={props.ScrId}
          Close={() => setisVouchNoSett(!isVouchNoSett)}
        />
      )}
    </div>
  );
}

export default PayRecBody;
