import React, { useEffect } from "react";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import ProductSearch from "../Transaction/ProductSearch";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  AddOpenStockProduct,
  Modify_Product,
  ProductInput_Onchange,
  Select_Unit,
  ShowHide_Popup,
  setStockTrans,
} from "../ReduxStore/Transactionstates";
import ProductAttributeView from "../Master/ProductAttributeView";
function OpenStock() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _Product = useSelector((store) => store.states.Product);

  const iScrId = 37;
  useEffect(() => {
    Load_OpenStock();
  }, []);

  const Load_OpenStock = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get(
         "OpenStock/Load_OpenStock/" + localStorage.db
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await dispatch(AddOpenStockProduct(Res.data.EntDT));
          await dispatch(
            setStockTrans({
              iScrId: iScrId,
              strScrType: "Purchase",
              strScrName: "Open Stock",
            })
          );
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Update_Stock = async () => {
    try {
      await dispatch(setload_Screen(true));
      api.post( "OpenStock/Update_OpenStock/", {
        EntProduct: _DT.arrEntDT,
        strCompanyDB: localStorage.db,
        iUserid: Number(localStorage.UserId),
      }).then((Res) => {
        if (Res.data.strStatus === "Success") {
          Clear_OpenStock();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_OpenStock = async () => {
    await dispatch(AddOpenStockProduct([]));
    await Load_OpenStock();
  };
  const Add_Product = async () => {
    try {
      if (_Product.iProId === 0) {
        AlertMessage("Please select Product", "info");
      } else if (Number(_Product.dQty) === 0) {
        AlertMessage("Minimum Qty is Required", "info");
      } else {
        await dispatch(setload_Screen(true));
        await api.post(`Transaction/Add_OpenStock/`, {
          bUpdateStatus: _Product.bUpdateStatus,
          iRowIndex: _Product.iRowIndex,
          iProId: _Product.iProId,
          dQty: Number(_Product.dQty),
          iUnitId: Number(_Product.iUnitId),
          strProAttDTId: _Product.strProAttDTId,
          strStoAttDTId: _Product.strStoAttDTId,
          strTraAttDTId: _Product.strTraAttDTId,
          EntProduct: _DT.arrEntDT,
          strCompanyDB: localStorage.db,
        }).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(AddOpenStockProduct(Res.data.EntDT));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Remove_TableProduct = async (ProInfo) => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(`Transaction/Remove_OpenStock/`, {
        bUpdateStatus: _Product.bUpdateStatus,
        iRowIndex: ProInfo.sNo,
        EntProduct: _DT.arrEntDT,
        strProAttDTId: _Product.strProAttDTId,
        strStoAttDTId: _Product.strStoAttDTId,
        strTraAttDTId: _Product.strTraAttDTId,
        strCompanyDB: localStorage.db,
      }).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await dispatch(AddOpenStockProduct(Res.data.EntDT));
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Column = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Product Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Unit",
      accessor: "unitName",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Qty",
      accessor: "qty",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Delete",
      style: { justifyContent: "center" },
      minWidth: 50,
      maxWidth: 50,
      cell: ({ rowinfo }) => (
        <i
          onClick={() => Remove_TableProduct(rowinfo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Unit_Onchange = (UnitId) => {
    api.get(
      
        "Transaction/Unit_Onchange/" +
        Number(UnitId) +
        "/" +
        _Product.iProId +
        "/" +
        _HT.iPartyId +
        "/" +
        _HT.iTaxRelId +
        "/" +
        _Common.strScrType +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        dispatch(
          Select_Unit({ dPriceRate: res.data.dPriceRate, UnitId: UnitId })
        );
      } else {
        AlertMessage(res.data.message, "error");
      }
    });
  };
  const View_ProductUnit = async (ProInfo, Index) => {
    try {
      dispatch(setload_Screen(true));
      api.get(
        `Transaction/View_ProductUnit/${ProInfo.proId}/${_Common.strScrType}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success")
          dispatch(
            Modify_Product({
              ProInfo: ProInfo,
              Unit: res.data.objUnit,
              Index: Index,
            })
          );
        else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-2">
                <label>
                  Product Name <span className="danger">*</span>
                </label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    id="strProName"
                    name="strProName"
                    placeholder="Click to Select Product"
                    defaultValue={_Product.strProName}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        dispatch(ShowHide_Popup("IsProSearch"));
                    }}
                    onClick={() => dispatch(ShowHide_Popup("IsProSearch"))}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: _Common.IsProSearch
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Qty <span className="danger">*</span>
                </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dQty"
                  autoComplete="off"
                  value={_Product.dQty}
                  onChange={(e) => Input_Onchange(e)}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Unit</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Unit"
                  select_value={(val) => Unit_Onchange(val)}
                  btnname="Unit"
                  btnshow={false}
                  show_popup=""
                  valueonly={true}
                  defaultval={_Product.iUnitId}
                  displayName="strUnitName"
                  disvalue="iUnitId"
                  arrydata={_Product.Unit}
                  disabled={_Product.iProId > 0 ? false : true}
                  EmptVal="Unit"
                />
              </div>
              <div className="col-md-2 col-sm-12 mt-4">
                <div className="btn-sub-section">
                  <button className="btn-fabgreen" onClick={Add_Product}>
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <ReactTable
              columns={Column}
              data={_DT.arrEntDT}
              minRows={20}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {
                View_ProductUnit(rowInfo, Index);
              }}
              background={true}
              className="full-table"
            />
            {_Common.IsProSearch && <ProductSearch />}
            {_Common.IsAttSearch && <ProductAttributeView />}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={Update_Stock}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Clear_OpenStock}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href =
                  "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OpenStock;
