import { createSlice } from "@reduxjs/toolkit";

export const CustomeselectSlice = createSlice({
  name: "EntTrans",
  initialState: {
    parems: {
      displayName: "",
      Value: "",
      isoptionShow: false,
      isoptionShow1: false,
      isoptionShow2: false,
      isoptionShow3: false,
      isoptionShow4: false,
      isoptionShow5: false,
      isoptionShow6: false,
      isoptionShow7: false,
      isoptionShow8: false,
      isoptionShow9: false,
      isoptionShow10: false,
      isoptionShow11: false,
      isoptionShow12: false,
      isoptionShow13: false,
      isoptionShow14: false,
      isoptionShow15: false,
      isoptionShow16: false,
      isoptionShow17: false,
      isoptionShow18: false,
      isoptionShow19: false,
      isoptionShow20: false,
      isoptionShow21: false,
      isoptionShow22: false,
      isoptionShow23: false,
      isoptionShow24: false,
      isoptionShow25: false,
      isoptionShow26: false,

      EntOption: [],
    },
  },
  reducers: {
    Show_Option: (state, action) => {
      state.parems = action.payload;
    },
    hide_Options: (state, action) => {
      state.parems.isoptionShow = action.payload.isoptionShow;
      state.parems.isoptionShow1 = action.payload.isoptionShow1;
      state.parems.isoptionShow2 = action.payload.isoptionShow2;
      state.parems.isoptionShow3 = action.payload.isoptionShow3;
      state.parems.isoptionShow4 = action.payload.isoptionShow4;
      state.parems.isoptionShow5 = action.payload.isoptionShow5;
      state.parems.isoptionShow6 = action.payload.isoptionShow6;
      state.parems.isoptionShow7 = action.payload.isoptionShow7;
      state.parems.isoptionShow8 = action.payload.isoptionShow8;
      state.parems.isoptionShow9 = action.payload.isoptionShow9;
      state.parems.isoptionShow10 = action.payload.isoptionShow10;
      state.parems.isoptionShow11 = action.payload.isoptionShow11;
      state.parems.isoptionShow12 = action.payload.isoptionShow12;
      state.parems.isoptionShow13 = action.payload.isoptionShow13;
      state.parems.isoptionShow14 = action.payload.isoptionShow14;
      state.parems.isoptionShow15 = action.payload.isoptionShow15;
      state.parems.isoptionShow16 = action.payload.isoptionShow16;
      state.parems.isoptionShow17 = action.payload.isoptionShow17;
      state.parems.isoptionShow18 = action.payload.isoptionShow18;
      state.parems.isoptionShow19 = action.payload.isoptionShow19;
      state.parems.isoptionShow20 = action.payload.isoptionShow20;
      state.parems.isoptionShow21 = action.payload.isoptionShow21;
      state.parems.isoptionShow22 = action.payload.isoptionShow22;
      state.parems.isoptionShow23 = action.payload.isoptionShow23;
      state.parems.isoptionShow24 = action.payload.isoptionShow24;
      state.parems.isoptionShow25 = action.payload.isoptionShow25;
      state.parems.isoptionShow26 = action.payload.isoptionShow26;
    },
  },
});
export const { Show_Option, hide_Options } = CustomeselectSlice.actions;
export default CustomeselectSlice.reducer;
