import { useState } from "react";

import $ from "jquery";
function MasterList(props) {
  const [strSearch, setstrSearch] = useState("");
  const Search_Item = (strtxt) => {
    setstrSearch(strtxt);
    props.Search(strtxt);
  };
  const NameClick = (objItem, index) => {
    if (props.Setting !== undefined) props.GetDetails(objItem);
    else
      switch (props.ScreenID) {
        case 1:
          props.GetDetails(objItem.iProId);
          break;
        case 2:
          props.GetDetails(objItem.iCustId);
          break;
        case 3:
          props.GetDetails(objItem.iSuppId);
          break;
        case 4:
          props.GetDetails(objItem.iCatId);
          break;
        case 5:
          props.GetDetails(objItem.iLedId);
          break;
        case 6:
          props.GetDetails(objItem.iTaxId);
          break;
        case 41:
          props.GetDetails(objItem.iPayId);
          break;
        case 42:
          props.GetDetails(objItem.iRecId);
          break;
        case 43:
          props.GetDetails(objItem.iDebId);
          break;
        case 44:
          props.GetDetails(objItem.iCreId);
          break;
        case 50:
          props.GetDetails(objItem.iVenId);
          break;
        case 51:
          props.GetDetails(objItem.strJobId);
          break;
        case 59:
          props.GetDetails(objItem.iSerId);
          break;
        case 60:
          props.GetDetails(objItem);
          break;
        case 61:
          props.GetDetails(objItem);
          break;
        case 68:
        case 70:
          props.GetDetails(objItem);
          break;
        case 69:
          props.GetDetails(objItem);
          break;
        case 80:
          props.GetDetails(objItem.iPGrpId);
          break;
        case 64:
          props.GetDetails(objItem.iUnitId);
          break;
        case 29:
          props.GetDetails(objItem.iBankAccId);
          break;
        default:
          break;
      }
  };
  return (
    <div className="Master-card">
      <div className="m-header">
        <div className="m-hsearch">
          <input
            type="text"
            autoCorrect="off"
            autoComplete="off"
            placeholder="Search"
            onKeyDown={(e) => {
              if (e.key === "ArrowDown" || e.key === "ArrowUp")
                $(".data-list [tabindex=0]").focus();
            }}
            value={strSearch}
            tabIndex={props.SearchTab}
            onChange={(e) => Search_Item(e.target.value)}
            autoFocus
          />
          <i
            className="fas fa-search"
            onClick={(e) => Search_Item(strSearch)}
          ></i>
        </div>
      </div>
      <div className="data-list">
        {props.EntList.map((dt, Index) => (
          <button
            key={Index}
            tabIndex={Index}
            onClick={(e) => NameClick(dt, Index)}
            onKeyDown={(e) => {
              const arrlength = props.EntList.length - 1;
              if (e.key === "ArrowDown") {
                if (Index === arrlength)
                  $(`.data-list [tabindex=${Index + 1}]`).focus();
                else $(`.data-list [tabindex=${0}]`).focus();
              }
              if (e.key === "ArrowUp") {
                if (Index === 0)
                  $(`.data-list [tabindex=${arrlength}]`).focus();
                else $(`.data-list [tabindex=${Index - 1}]`).focus();
              }
              if (e.key === "Enter") {
                NameClick(dt, Index);
              }
            }}
          >
            <label>{dt[props.accessor]}</label>
          </button>
        ))}
      </div>
    </div>
  );
}
export default MasterList;
