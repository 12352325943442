import React, { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import api from "../Admin/Config";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import CountryMaster from "./CountryMaster";
import StateMaster from "./StateMaster";
import BankSearch from "./BankSearch";
import BankMaster from "./BankMaster";
function BankAccount() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 29;

  const [iAccHolderType, setiAccHolderType] = useState(1);
  const [HolderName, setHolderName] = useState("");
  const [iVentId, setiVentId] = useState(0);

  const [iBankAccId, setiBankAccId] = useState(0);

  const [strBankName, setstrBankName] = useState("");
  const [iBankNameId, setiBankNameId] = useState("");
  const [strBranch, setstrBranch] = useState("");
  const [strIFSC, setstrIFSC] = useState("");
  const [strMICR, setstrMICR] = useState("");
  const [strAccHolderName, setstrAccHolderName] = useState("");
  const [strAccNo, setstrAccNo] = useState(0);
  const [iAccType, setiAccType] = useState(1);
  const [iCountry, setiCountry] = useState(1);
  const [iState, setiState] = useState(32);

  const [bPrintDefault, setbPrintDefault] = useState(false);
  const [bPayDefault, setbPayDefault] = useState(false);
  const [bRecDefault, setbRecDefault] = useState(false);
  const [IsVendor, setIsVendor] = useState(false);
  const [IsCountry, setIsCountry] = useState(false);
  const [IsState, setIsState] = useState(false);
  const [IsBank, setIsBank] = useState(false);
  const [IsNewBank, setIsNewBank] = useState(false);

  const [arrAccHolder, setarrAccHolder] = useState([]);
  const [arrState, setarrState] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrAccType, setarrAccType] = useState([]);

  const [arrEntBank, setarrEntBank] = useState([]);
  const [arrParty, setarrParty] = useState([]);

  useEffect(() => {
    Load_BankAccount(iAccHolderType);
  }, []);
  const Load_BankAccount = async (iAccHType) => {
    try {
      setiAccHolderType(iAccHType);
      const parems = {
        iAccHolderType: iAccHType,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      setIsVendor(false);
      await dispatch(setload_Screen(true));
      await api.post(
        `BankAccountMaster/Load_BankAccount`,
        parems
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          setarrAccHolder(res.data.EntAccHolder);
          setarrState(res.data.EntState);
          setarrCountry(res.data.EntCountry);
          setarrAccType(res.data.EntAccType);
          setarrEntBank(res.data.EntAccount);
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    try {
      Clear_Inputs();
      Load_BankAccount(1);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Clear_Inputs = () => {
    setiVentId(0);
    setiBankAccId(0);
    setstrBankName("");
    setiBankNameId(0);
    setstrBranch("");
    setstrIFSC("");
    setstrMICR("");
    setstrAccHolderName("");
    setstrAccNo("");
    setiAccType(1);
    setiCountry(1);
    setiState(32);
    setbPrintDefault(false);
    setbPayDefault(false);
    setbRecDefault(false);
    setIsCountry(false);
    setIsState(false);
    setIsBank(false);
    setIsNewBank(false);
    setarrEntBank([]);
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const InsUpdBank = {
        iBankAccId: iBankAccId,
        strBankName: strBankName,
        iBankNameId: iBankNameId,
        strBranch: strBranch,
        strIFSC: strIFSC,
        strMICR: strMICR,
        strAccHolderName: strAccHolderName,
        strAccNo: strAccNo,
        iAccType: Number(iAccType),
        iCountry: Number(iCountry),
        iState: Number(iState),
        bPrintDefault: bPrintDefault,
        bPayDefault: bPayDefault,
        bRecDefault: bRecDefault,
        iAccHolderType: iAccHolderType,
        iTitleTag: iScrId,
        iVentId: iVentId,
        IsInsert: iBankAccId === 0 ? true : false,
        strCompanyDB: localStorage.db,
      };
      await api.post(
         "BankAccountMaster/InsUpd_Account/",
        InsUpdBank
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          Clear_Inputs();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        `BankAccountMaster/Delete_Account/${iBankAccId}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          Clear_Inputs();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AccHolder_OnChange = async (acchtype) => {
    try {
      await Clear_Inputs();
      await dispatch(setload_Screen(true));
      setIsVendor(true);
      setiAccHolderType(Number(acchtype));
      let IsViewParty = true;
      switch (Number(acchtype)) {
        case 2:
          setHolderName("Supplier Name");
          break;
        case 3:
          setHolderName("Customer Name");
          break;
        case 4:
          setHolderName("Service Vendor");
          break;
        case 5:
          setHolderName("Othe service");
          break;
        case 6:
          setHolderName("JobWork Vendor");
          break;
        default:
          IsViewParty = false;
          break;
      }
      if (IsViewParty) {
        await api.get(
          `BankAccountMaster/AccHolder_OnChange/${Number(acchtype)}/${
            localStorage.db
          }`
        ).then((res) => {
          if (res.data.strStatus === "Success") {
            setarrParty(res.data.EntParty);
            setarrEntBank([]);
          } else AlertMessage(res.data.strMessage, "error");
        });
      } else await Load_BankAccount(Number(acchtype));
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_PartyAccount = async (party) => {
    try {
      await dispatch(setload_Screen(true));
      setiVentId(party.partyId);
      const parems = {
        iPartyId: Number(party.partyId),
        iAccHolderType: Number(iAccHolderType),
        strCompanyDB: localStorage.db,
      };
      await api.post(
        `BankAccountMaster/View_PartyAccount`,
        parems
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntBank(res.data.EntBank);
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_BankAccount = async (AccId) => {
    try {
      const parems = {
        iPartyId: Number(iVentId),
        iAccHolderType: Number(iAccHolderType),
        iBankAccId: Number(AccId),
        strCompanyDB: localStorage.db,
      };
      await dispatch(setload_Screen(true));
      await api.post(`BankAccountMaster/View_Account`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            let Bank = res.data.EntBank[0];
            setstrBankName(Bank.strBankName);
            setiBankNameId(Bank.iBankNameId);
            setstrBranch(Bank.strBranch);
            setstrIFSC(Bank.strIFSC);
            setstrMICR(Bank.strMICR);
            setstrAccHolderName(Bank.strAccHolderName);
            setstrAccNo(Bank.strAccNo);
            setiAccType(Bank.iAccType);
            setiCountry(Bank.iCountry);
            setiState(Bank.iState);
            setbPrintDefault(Bank.bPrintDefault);
            setbPayDefault(Bank.bPayDefault);
            setbRecDefault(Bank.bRecDefault);
            setiBankAccId(Bank.iBankAccId);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Bank = (bank) => {
    setIsBank(!IsBank);
    setiBankNameId(bank.bnId);
    setstrBankName(bank.bankNames);
  };
  const Country_Onchange = async (CountryId) => {
    try {
      setiCountry(CountryId);
      const parems = {
        iCountryId: Number(CountryId),
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await api.post(`CountryState/Load_State`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setarrState(res.data.EntState);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const TabIndex = (e) => {
    var maxTab = 18;
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 18) {
          $(".Ban .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 18) {
          $(".Ban .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 18) {
          $(".Ban .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $(".Ban .react-table .rt-tbody .rt-tr:eq(0)").focus();
      }
    }
  };

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="data-list mt-2">
            {arrEntBank.map((bank, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = arrEntBank.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_BankAccount(bank.iBankAccId);
                  }
                }}
              >
                <label onClick={(e) => View_BankAccount(bank.iBankAccId)}>
                  {bank.strBankName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-2">
                <label>A/C Holder Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select A/C Holder Type"
                  taborder={0}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => AccHolder_OnChange(val)}
                  btnname="A/C Holder Type"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iAccHolderType}
                  displayName="accHolderName"
                  disvalue="accHolderId"
                  arrydata={arrAccHolder}
                  EmptVal="A/C Holder Type"
                />
              </div>
              {IsVendor && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>
                    {HolderName}
                    <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Select Vendore"
                    taborder={1}
                    select_value={(val) => View_PartyAccount(val)}
                    btnname="Vendor"
                    btnshow={false}
                    show_popup=""
                    valueonly={false}
                    defaultval={iVentId}
                    displayName="partyName"
                    disvalue="partyId"
                    arrydata={arrParty}
                    EmptVal="Vendor"
                    disabled={iBankAccId > 0 ? true : false}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  A/C Holder Name<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  autoFocus
                  placeholder=" Enter Acc Hoder Name"
                  onClick={(e) => e.target.select()}
                  tabIndex="2"
                  onKeyDown={(e) => TabIndex(e)}
                  value={strAccHolderName}
                  onChange={(e) => setstrAccHolderName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Bank Name<span className="danger">*</span>
                </label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    name="strBankName"
                    autoComplete="off"
                    placeholder="Select Bank"
                    defaultValue={strBankName}
                    onClick={() => setIsBank(!IsBank)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") setIsBank(!IsBank);
                    }}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: IsBank ? "rotate(-180deg)" : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Branch Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder=" Enter Branch Name"
                  className="form-control"
                  tabIndex="4"
                  onKeyDown={(e) => TabIndex(e)}
                  onClick={(e) => e.target.select()}
                  value={strBranch}
                  onChange={(e) => setstrBranch(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  IFSC Code<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder=" Enter IFSC Code"
                  className="form-control"
                  tabIndex="5"
                  onKeyDown={(e) => TabIndex(e)}
                  onClick={(e) => e.target.select()}
                  value={strIFSC}
                  onChange={(e) => setstrIFSC(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>MICR CODE</label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder=" Enter MICR Code"
                  className="form-control"
                  tabIndex="6"
                  onKeyDown={(e) => TabIndex(e)}
                  onClick={(e) => e.target.select()}
                  value={strMICR}
                  onChange={(e) => setstrMICR(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  A/C No<span className="danger">*</span>
                </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  tabIndex="7"
                  onKeyDown={(e) => TabIndex(e)}
                  value={strAccNo}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrAccNo(e.target.value)}
                />
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12  mb-2">
                  <label>A/C Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_selecrparem.isoptionShow3}
                    placeholder="Select A/C Type"
                    taborder={8}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiAccType(val)}
                    btnname="A/C Type"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iAccType}
                    displayName="accTypeName"
                    disvalue="accTypeId"
                    arrydata={arrAccType}
                    EmptVal="A/C Type"
                  />
                </div>{" "}
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Country</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Select Country"
                    select_value={(val) => Country_Onchange(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={9}
                    btnname="Country"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iCountry}
                    displayName="strCountryName"
                    disvalue="iCountryId"
                    arrydata={arrCountry}
                    disabled={false}
                    EmptVal="Country "
                  />
                </div>
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>State</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_selecrparem.isoptionShow5}
                    placeholder="Select State"
                    select_value={(val) => setiState(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={10}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iState}
                    displayName="strSName"
                    disvalue="iStateId"
                    arrydata={arrState}
                    disabled={false}
                    EmptVal="State "
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 text-nowrap mb-2">
                  <label>Print Default</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      tabIndex="11"
                      onKeyDown={(e) => TabIndex(e)}
                      type="checkbox"
                      checked={bPrintDefault}
                      onChange={(e) => setbPrintDefault(!bPrintDefault)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 text-nowrap mb-2">
                  <label>Payment Default</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={bPayDefault}
                      tabIndex="12"
                      onKeyDown={(e) => TabIndex(e)}
                      onChange={(e) => setbPayDefault(!bPayDefault)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 text-nowrap mb-2">
                  <label>Recipte Default</label>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={bRecDefault}
                      tabIndex="13"
                      onKeyDown={(e) => TabIndex(e)}
                      onChange={(e) => setbRecDefault(!bRecDefault)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {IsCountry && (
              <CountryMaster Close={() => setIsCountry(!IsCountry)} />
            )}
            {IsState && <StateMaster Close={() => setIsState(!IsState)} />}
            {IsBank && (
              <BankSearch
                Close={() => setIsBank(!IsBank)}
                View_Data={View_Bank}
                NewBank={() => {
                  setIsBank(!IsBank);
                  setIsNewBank(!IsNewBank);
                }}
              />
            )}
            {IsNewBank && (
              <BankMaster
                Close={() => setIsNewBank(!IsNewBank)}
                Bank_Search={() => {
                  setIsNewBank(!IsNewBank);
                  setIsBank(!IsBank);
                }}
              />
            )}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (strBankName === "") {
                AlertMessage("Please Select Bank", "info");
              } else if (strIFSC === "") {
                AlertMessage("Please Enter IFSC Code", "info");
              } else if (strAccHolderName === "") {
                AlertMessage("Please Enter Name", "info");
              } else if (strAccNo === "") {
                AlertMessage("Please Enter Account Number", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: SaveClick,
                    Question: "Do You Want to Save Bank Account?",
                    isdialog: true,
                  })
                );
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iBankAccId === 0) {
                AlertMessage("Please Select Account", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Bank Account?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={iScrId}
            Iid={iBankAccId}
            SaveIndex={14}
            DeleteIndex={15}
            CloseIndex={17}
            ClearIndex={16}
            ExportIndex={""}
            onKeyDown={(e) => TabIndex(e)}
          />
        </div>
      </div>
    </>
  );
}
export default BankAccount;
