/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import * as XLSX from "xlsx";

import ProductExcel from "../ExcelFormate/ProductMaster.xlsx";
import CustomerExcel from "../ExcelFormate/CustomerMaster.xlsx";
import SupplierExcel from "../ExcelFormate/SupplierMaster.xlsx";
import VendoreExcel from "../ExcelFormate/VendorMaster.xlsx";
import Excel from "../ExcelFormate/excel.png";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import $ from "jquery";

function ExportImport(props) {
  const dispatch = useDispatch();
  const [strScrenName, setstrScrenName] = useState("PRODUCT MASTER");
  const [strExcelName, setstrExcelName] = useState("");
  const [strExcelFile, setstrExcelFile] = useState("");
  const [strExcelFileName, setstrExcelFileName] = useState("");
  const [arrExportColumns, setarrExportColumns] = useState([]);
  const [arrImportColumns, setarrImportColumns] = useState([]);
  const [arrExportData, setarrExportData] = useState([]);
  const [arrImportData, setarrImportData] = useState([]);
  const [isExport, setisExport] = useState(true);

  useEffect(() => {
    Load_defaultData();
  }, []);
  const Load_defaultData = async () => {
    try {
      dispatch(setload_Screen(true));
      await api.get(
        `ExportImport/Export_Master/${Number(props.iScrId)}/${
          localStorage.db
        }`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrExportData(Res.data.EntData);
          setarrExportColumns(Object.keys(Res.data.EntData[0]));
          setstrScrenName(Res.data.strScrenName);
          setstrExcelName(Res.data.strExcelName);
        }
      });
      switch (props.iScrId) {
        case 1:
          setstrExcelFile(ProductExcel);
          break;
        case 2:
          setstrExcelFile(CustomerExcel);
          break;
        case 3:
          setstrExcelFile(SupplierExcel);
          break;
        case 50:
          setstrExcelFile(VendoreExcel);
          break;
        default:
          break;
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const tab_click = (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    setisExport(tabindex === 0 ? true : false);
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    if (tabindex === 1) {
      setarrImportData([]);
    } else Load_defaultData();
  };
  const Clear_Excel = () => {
    setarrExportData([]);
    setarrExportColumns([]);
    setstrExcelFile("");
    setstrExcelName("");
    setstrExcelFileName("");
    Load_defaultData();
  };
  const Clear_File = () => {
    setarrImportData([]);
    setarrImportColumns([]);
    setstrExcelFile("");
    setstrExcelFileName("");
  };
  const Import_Excel = () => {
    try {
      if (strExcelFileName === "") {
        AlertMessage("Please Select File", "error");
      } else {
        let arrEntExcelData = [];
        let ExpImpUrl = "";
        let RejectMessage = "";
        switch (props.iScrId) {
          case 1:
            for (let i = 0; i < arrImportData.length; i++) {
              let RowData = arrImportData[i];
              arrEntExcelData.push({
                SNo: i + 1,
                ProCode: RowData[0],
                CommodityCode: RowData[1],
                ProName: RowData[2],
                PrintName: RowData[3],
                UnitName1: RowData[4],
                UnitName2: RowData[5],
                UnitName3: RowData[6],
                UnitName4: RowData[7],
                UnitName5: RowData[8],
                Cat1Name: RowData[9],
                Cat2Name: RowData[10],
                Cat3Name: RowData[11],
                Remark: RowData[12],
                QtyDecimal: RowData[13],
                StockMaintain: RowData[14],
                Discount: Number(RowData[15]),
                MinMargin: Number(RowData[16]),
                CostRate: Number(RowData[17]),
                PriceRate: Number(RowData[18]),
                SGST: Number(RowData[19]),
                CGST: Number(RowData[20]),
                IGST: Number(RowData[21]),
                PreFix: "",
                Reason: "",
              });
            }
            ExpImpUrl = "ExportImport/ProductImport/";
            RejectMessage = "Product Rejected Please Refer Reson";
            break;
          case 2:
            for (let i = 0; i < arrImportData.length; i++) {
              let RowData = arrImportData[i];
              arrEntExcelData.push({
                SNo: i + 1,
                CustCode: RowData[0],
                CustName: RowData[1],
                AliasName: RowData[2],
                Add1: RowData[3],
                Add2: RowData[4],
                Add3: RowData[5],
                Add4: RowData[6],
                Pin: RowData[7],
                State: RowData[8],
                Country: RowData[9],
                Phone1: RowData[10],
                Phone2: RowData[11],
                Mobile1: RowData[12],
                Mobile2: RowData[13],
                Email: RowData[14],
                PriceGrpId: RowData[15],
                GstNo: RowData[16],
                Remarks: RowData[17],
                OpBal: Number(RowData[18]),
                CreditTerm: RowData[19],
                CreditLimit: Number(RowData[20]),
                BalType: "",
                PreFix: "",
                Reason: "",
                Status: "",
              });
            }
            ExpImpUrl = "ExportImport/CustImport/";
            RejectMessage = "Customer  Rejected Please Refer Reson";
            break;
          case 3:
            for (let i = 0; i < arrImportData.length; i++) {
              let RowData = arrImportData[i];
              arrEntExcelData.push({
                SNo: i + 1,
                SuppCode: RowData[0],
                SuppName: RowData[1],
                AliasName: RowData[2],
                Add1: RowData[3],
                Add2: RowData[4],
                Add3: RowData[5],
                Add4: RowData[6],
                Pin: RowData[7],
                State: RowData[8],
                Country: RowData[9],
                Phone1: RowData[10],
                Phone2: RowData[11],
                Mobile1: RowData[12],
                Mobile2: RowData[13],
                Email: RowData[14],
                Remarks: RowData[15],
                GstNo: RowData[16],
                TinNo: RowData[17],
                OpBal: Number(RowData[18]),
                CreditTerm: RowData[19],
                BalType: "",
                PreFix: "",
                Reason: "",
                Status: "",
                VenTypeId: "",
              });
            }
            ExpImpUrl = "ExportImport/SupplierImport/";
            RejectMessage = "Supplier Rejected Please Refer Reson";
            break;
          case 50:
            for (let i = 0; i < arrImportData.length; i++) {
              let RowData = arrImportData[i];
              arrEntExcelData.push({
                SNo: i + 1,
                VenCode: RowData[0],
                VenName: RowData[1],
                AliasName: RowData[2],
                Add1: RowData[3],
                Add2: RowData[4],
                Add3: RowData[5],
                Add4: RowData[6],
                Pin: RowData[7],
                State: RowData[8],
                Country: RowData[9],
                Phone1: RowData[10],
                Phone2: RowData[11],
                Mobile1: RowData[12],
                Mobile2: RowData[13],
                Email: RowData[14],
                Remarks: RowData[15],
                GstNo: RowData[16],
                TinNo: RowData[17],
                OpBal: Number(RowData[18]),
                CreditTerm: RowData[19],
                BalType: "",
                PreFix: "",
                Reason: "",
                Status: "",
                VenCastId: "",
              });
            }
            ExpImpUrl = "ExportImport/VendorImport/";
            RejectMessage = "Vendor Rejected Please Refer Reson";
            break;
          default:
            break;
        }
        api.post( ExpImpUrl, {
          ObjImportModel: arrEntExcelData,
          strCompanyDB: localStorage.db,
        }).then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
          } else if (Res.data.strStatus === "error") {
            AlertMessage(Res.data.strMessage, "error");
          } else if (Res.data.strStatus === undefined) {
            AlertMessage(RejectMessage, "error");
            setarrImportData(Res.data);
            setarrImportColumns(Object.keys(Res.data[0]));
          }
        });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  const CapitalizeString = (string) => {
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  };
  const Select_Excel = () => {
    const defaultBtn = document.getElementById("file-upload");
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ].includes(file.type)
      ) {
        AlertMessage("Only .xlsx or .xls file format are allowed", "info");
      } else {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function () {
          var data = new Uint8Array(reader.result);
          var work_book = XLSX.read(data, { type: "array" });
          var sheet_name = work_book.SheetNames;
          var Work_Sheet = work_book.Sheets[sheet_name[0]];
          var sheet_data = XLSX.utils.sheet_to_json(Work_Sheet, {
            header: 1,
            defval: "",
            blankrows: false,
          });
          if (sheet_data.length > 0 && strExcelName === sheet_name[0]) {
            let EntExcelCol = sheet_data[0];
            let EntExcelDate = sheet_data.filter((dt, Index) => Index !== 0);
            let EntData = [];
            EntExcelDate.forEach((Edt) => {
              EntData.push(
                EntExcelCol.reduce(
                  (obj, key, index) => ({ ...obj, [key]: Edt[index] }),
                  {}
                )
              );
            });
            setstrExcelFileName(sheet_name[0]);
            setarrImportColumns(EntExcelCol);
            setarrImportData(EntData);
          } else {
            Clear_Excel();
            AlertMessage("Please Select " + strScrenName + " File", "info");
          }
        };
      }
    });
  };
  const Download_ExcelFormat = () => {
    var link = document.createElement("a");
    link.setAttribute("href", strExcelFile);
    link.setAttribute("download", strExcelName);
    link.click();
  };
  const Download_Excel = () => {
    var ExportDiv = document.createElement("div");
    ExportDiv.setAttribute("class", "ExportExcel");
    var table = document.createElement("table");
    table.innerHTML = document.getElementById("Exptable").innerHTML;
    var ThElemt = table.getElementsByTagName("th");
    for (var H = 0; H < ThElemt.length; H++) {
      ThElemt[H].style.color = "rgb(0, 176, 80)";
      ThElemt[H].style.textAlign = "center";
      ThElemt[H].style.fontSize = "13px";
      ThElemt[H].style.fontWeight = "bold";
      ThElemt[H].style.border = "1px solid lightblue";
      ThElemt[H].style.fontFamily = "Times New Roman";
    }
    var TdElemt = table.getElementsByTagName("td");
    for (var B = 0; B < TdElemt.length; B++) {
      TdElemt[B].style.border = "1px solid lightblue";
      TdElemt[B].style.fontSize = "13px";
      TdElemt[B].style.background = "#fff";
      TdElemt[B].style.fontFamily = "Times New Roman";
    }
    ExportDiv.appendChild(table);

    let file = new Blob([$(ExportDiv).html()], {
      type: "application/vnd.ms-excel",
    });
    let url = URL.createObjectURL(file);
    var Link = document.createElement("a");
    Link.href = url;
    Link.download = strExcelName + ".xls";
    Link.click();
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{strScrenName} </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-0">
          <div className="trans-tab">
            <div className="trans-buttons">
              <label onClick={(e) => tab_click(0)} className="active">
                Export
              </label>
              <label onClick={(e) => tab_click(1)}>Import</label>
              <div id="tab-line"></div>
            </div>
          </div>
          {isExport && (
            <div className="table-card">
              <table id="Exptable" className="option-list">
                <thead>
                  <tr>
                    {arrExportColumns.map((colname, Index) => (
                      <th key={Index}>{CapitalizeString(colname)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {arrExportData.map((dt, trIndex) => (
                    <tr key={trIndex}>
                      {arrExportColumns.map((colname, tdindex) => (
                        <td key={tdindex}>{dt[colname]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!isExport && (
            <>
              {arrImportData.length === 0 && (
                <div id="FileUpload">
                  <div className="filewrapper">
                    <div className="uploadfile">
                      <img src={Excel} alt="Excel Formate" />
                      <span className="upload__button" onClick={Select_Excel}>
                        Browse File
                      </span>
                      <input
                        type="file"
                        id="file-upload"
                        accept=".xlsx,.xls"
                        hidden
                      />
                    </div>
                  </div>
                </div>
              )}
              {arrImportData.length > 0 && (
                <div className="table-card">
                  <table id="Exptable" className="option-list">
                    <thead>
                      <tr>
                        {arrImportColumns.map((colname, Index) => (
                          <th key={Index}>{CapitalizeString(colname)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {arrImportData.map((dt, trIndex) => (
                        <tr key={trIndex}>
                          {arrImportColumns.map((colname, tdindex) => (
                            <td key={tdindex}>{dt[colname]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {isExport && (
              <button
                className="btn-fabgreen"
                onClick={(e) => Download_Excel()}
              >
                Export Data&nbsp;<i className="bx bx-download"></i>
              </button>
            )}
            {isExport && (
              <button className="btn-fabgreen" onClick={(e) => Clear_Excel()}>
                Clear Data&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            {!isExport && (
              <button className="btn-fabgreen" onClick={(e) => Import_Excel()}>
                Import Data&nbsp;<i className="bx bx-import"></i>
              </button>
            )}
            {!isExport && (
              <button className="btn-fabgreen" onClick={(e) => Clear_File()}>
                Clear Data&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            <button
              className="btn-fabgreen"
              onClick={(e) => Download_ExcelFormat()}
            >
              Excel Format&nbsp;<i className="bx bx-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExportImport;
