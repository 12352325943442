import ReactTable from "../General/ReactTable";

function SearchOtherHeadLedger({ SelectLedger, Close, LoadLedger }) {
  const columns = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Code",
      accessor: "ledCode",
      maxWidth: 100,
    },
    {
      Header: "Name",
      accessor: "ledName",
      id: "ledId",
    },
    {
      Header: "GroupName",
      accessor: "tGrpName",
    },
  ];
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Ledger</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => Close()}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2 ">
          <div className="row p-0">
            <ReactTable
              columns={columns}
              data={LoadLedger}
              minRows={15}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {
                SelectLedger(rowInfo);
              }}
              background={true}
              className="full-table"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchOtherHeadLedger;
