import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import CustomizedSelectoption from "../General/CustomizedSelectoption";

function StateMaster(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [State, setState] = useState({
    iCountryId: 1,
    iStateId: 0,
    strSName: "",
    strDescription: "",
    strStateCode: "",
    strStateTag: "",
    strDBName: "",
    strSearch: "",
    arrEntState: [],
    arrEntCountry: [],
  });
  const updateStateField = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  useEffect(() => {
    Load_StateMaster();
  }, []);
  const Load_StateMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iCountryId: 0,
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await api.post(`CountryState/Load_StateMaster`, parems).then(
        async (res) => {
          if (res.data.strStatus === "Success") {
            await updateStateField("arrEntCountry", res.data.EntCountry);
            await updateStateField("arrEntState", res.data.EntState);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_State = async (strtxt) => {
    try {
      const parems = {
        iCountryId: 0,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(`CountryState/Load_State`, parems).then(
        async (res) => {
          if (res.data.strStatus === "Success") {
            await updateStateField("arrEntState", res.data.EntState);
            await updateStateField("strSearch", strtxt);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Save_State = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iStateId: State.iStateId,
        strSName: State.strSName,
        strDescription: State.strDescription,
        strStateCode: State.strStateCode,
        iCountryId: State.iCountryId,
        strStateTag: "",
        strDBName: localStorage.db,
      };
      await api.post(`CountryState/InsUpd_State`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            Clear_State();
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_State = async (objState) => {
    await updateStateField("iCountryId", objState.iCountryId);
    await updateStateField("iStateId", objState.iStateId);
    await updateStateField("strSName", objState.strSName);
    await updateStateField("strDescription", objState.strDescription);
    await updateStateField("strStateCode", objState.strStateCode);
  };
  const Delete_State = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        `CountryState/Delete_State/${State.iStateId}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          Clear_State();
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_State = async () => {
    await setState({
      iCountryId: 1,
      iStateId: 0,
      strSName: "",
      strDescription: "",
      strStateCode: "",
      strStateTag: "",
      strSearch: "",
      strDBName: "",
      arrEntState: [],
      arrEntCountry: [],
    });
    await Load_StateMaster();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>State Master</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  className="form-control"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      $(".dt-content [tabindex=0]").focus();
                    }
                  }}
                  value={State.strSearch}
                  tabIndex={59}
                  onChange={(e) => Search_State(e.target.value)}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_State(State.strSearch)}
                ></i>
              </div>
              <div className="data-list">
                {State.arrEntState.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onClick={(e) => View_State(dt)}
                    onKeyDown={(e) => {
                      const arrlength = State.arrEntState.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_State(dt);
                      }
                    }}
                  >
                    <label>{dt.strSName}</label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>
                    Country <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow20"
                    showoption={_selecrparem.isoptionShow20}
                    placeholder="Select Country"
                    select_value={(val) => updateStateField("iCountryId", val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={State.iCountryId}
                    displayName="strCountryName"
                    disvalue="iCountryId"
                    arrydata={State.arrEntCountry}
                    disabled={false}
                    EmptVal="Country "
                  />
                </div>
                <div className="col-md-8 col-sm-12 mb-1">
                  <label>
                    Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    placeholder="Enter State Name"
                    className="form-control"
                    value={State.strSName}
                    onChange={(e) =>
                      updateStateField("strSName", e.target.value)
                    }
                    autoFocus
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-1">
                  <label>
                    Code <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    placeholder="Enter State Code"
                    className="form-control"
                    value={State.strStateCode}
                    onChange={(e) =>
                      updateStateField("strStateCode", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>Description</label>
                  <textarea
                    type="text"
                    aria-label="First name"
                    tabIndex="53"
                    className="form-control"
                    value={State.strDescription}
                    onChange={(e) =>
                      updateStateField("strDescription", e.target.value)
                    }
                    placeholder="Description"
                    style={{ minHeight: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              tabIndex="54"
              onClick={(e) => {
                if (State.strSName === "") {
                  AlertMessage("State Name is Rquired", "info");
                } else if (State.strStateCode === "") {
                  AlertMessage("State Code is Rquired", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Save_State,
                      Question: "Do You Want to Save State?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex="55"
              onClick={(e) => {
                if (Number(State.iStateId) === 0) {
                  AlertMessage("Validation", "Please Select Category", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Delete_State,
                      Question: "Do You Want to Delete State ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              tabIndex="56"
              onClick={Clear_State}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StateMaster;
