import React, { useEffect, useState } from "react";
import api from "../Admin/Config";
import { ShowHide_Popup, setAttValues } from "../ReduxStore/Transactionstates";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import $ from "jquery";
function ProductAttributeView() {
  const dispatch = useDispatch();
  const _Product = useSelector((store) => store.states.Product);
  const _Common = useSelector((store) => store.states.Common);
  const [ListAttEnt, setListAttEnt] = useState([]);
  const [ListAttColumns, setListAttColumns] = useState([]);

  useEffect(() => {
    AssignColums(_Product.AttCol);
    setListAttEnt(_Product.AttVal);
  }, []);
  const AssignColums = async (AttColums) => {
    let columns = [];
    for (let i = 0; i < AttColums.length; i++) {
      const objAtt = {
        Header: AttColums[i].strColHeader,
        accessor: AttColums[i].strColName,
        show: AttColums[i].isShowCol,
        minWidth: 150,
      };
      columns.push(objAtt);
    }
    await setListAttColumns(columns);
    await $(`.option-list [tabindex=${0}]`).focus();
  };
  const Select_AttProduct = async (strDimGrpId) => {
    try {
      await dispatch(setload_Screen(true));
      const objAtt = {
        strDimGrpId: strDimGrpId,
        strCompanyDB: localStorage.db,
      };
      await api.post(
        `Transaction/View_ProductAttValues`,
        objAtt
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await dispatch(setAttValues(Res.data));
          await dispatch(ShowHide_Popup("IsAttSearch"));
          if (_Common.iScrId === 70) Select_ProductionProduct(Res.data);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Select_ProductionProduct = (ProIfno) => {
    try {
      api.post( "ProductionOrder/AddProduct/", {
        iProId: _Product.ProId,
        iScrId: _Common.iScrId,
        strProAttDTId: ProIfno.ProAttDTId.toString(),
        strStoAttDTId: ProIfno.StoAttDTId.toString(),
        strTraAttDTId: ProIfno.TraAttDTId.toString(),
        strCompanyDB: localStorage.db,
      }).then((res) => {
        if (res.data.strStatus === "Success") {
          dispatch(Select_ProductionProduct(res.data));
        } else {
          AlertMessage(res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Attribute View</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsProSearch"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  {ListAttColumns.map((ht, Index) => (
                    <th key={Index}>{ht.Header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {ListAttEnt.map((Item, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = ListAttEnt.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter")
                        Select_AttProduct(Item.allDimGroup);
                    }}
                    onDoubleClick={(e) => Select_AttProduct(Item.allDimGroup)}
                  >
                    {ListAttColumns.map((dt, Index) => (
                      <td
                        key={Index}
                        style={{
                          textAlign: dt.accessor === "tQty" ? "right" : "left",
                          width: dt.minWidth + "px",
                          color:
                            dt.accessor === "tQty"
                              ? Number(Item.tQty) < 0
                                ? "red"
                                : "black"
                              : "black",
                          whiteSpace: "wrap",
                        }}
                      >
                        {dt.accessor === "tQty"
                          ? Number(Item[dt.accessor]).toFixed(2)
                          : Item[dt.accessor]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductAttributeView;
