
import React, { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SearchOtherHeadLedger from "./SearchOtherHeadLedger";
import api from "../Admin/Config";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function TaxMaster() {
  const dispatch = useDispatch();
  const iScrId = 6;

  const [iTaxId, setiTaxId] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [dTaxPer, setdTaxPer] = useState(0);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [iStatusId, setiStatusId] = useState(1);
  const [iPurLedId, setiPurLedId] = useState(0);
  const [iSalLedId, setiSalLedId] = useState(0);
  const [iJobLedId, setiJobLedId] = useState(0);
  const [iSalTaxLedId, setiSalTaxLedId] = useState(0);
  const [iPurRetLedId, setiPurRetLedId] = useState(0);
  const [iSalRetLedId, setiSalRetLedId] = useState(0);
  const [iPurTaxLedId, setiPurTaxLedId] = useState(0);
  const [iJobTaxLedId, setiJobTaxLedId] = useState(0);
  const [iSerInputTaxLedId, setiSerInputTaxLedId] = useState(0);
  const [iSerOutputTaxLedId, setiSerOutputTaxLedId] = useState(0);

  const [strTaxDesc, setstrTaxDesc] = useState("");
  const [strPLName, setstrPLName] = useState("");
  const [strSLName, setstrSLName] = useState("");
  const [strPTLName, setstrPTLName] = useState("");
  const [strSTLName, setstrSTLName] = useState("");
  const [strPRLName, setstrPRLName] = useState("");
  const [strSRLName, setstrSRLName] = useState("");
  const [strJLName, setstrJLName] = useState("");
  const [strJTLName, setstrJTLName] = useState("");
  const [strINPLName, setstrINPLName] = useState("");
  const [strOutPLName, setstrOutPLName] = useState("");
  const [strAlias, setstrAlias] = useState("");
  const [Count, setCount] = useState(0);
  const [binputCreditReq, setbinputCreditReq] = useState(false);
  const [Ledgerfrom, setLedgerfrom] = useState("");

  const [ListEntList, setListEntList] = useState([]);
  const [ListStatus, setListStatus] = useState([]);
  const [ListTaxTyp, setListTaxTyp] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  const [ListLedger, setListLedger] = useState([]);

  useEffect(() => {
    return Load();
  }, []);

  const Load = () => {
    setstrTaxDesc("");
    setstrAlias("");
    setiTaxTypeId(1);
    setbinputCreditReq(false);
    setdTaxPer(0);
    setiTaxId(0);
    setiPurLedId(0);
    setiPurTaxLedId(0);
    setiSalLedId(0);
    setiSalTaxLedId(0);
    setstrPLName("");
    setstrPTLName("");
    setstrSLName("");
    setstrSTLName("");
    setstrPRLName("");
    setiPurRetLedId(0);
    setstrSRLName("");
    setiSalRetLedId(0);
    setstrJLName("");
    setiJobLedId(0);
    setstrJTLName("");
    setiJobTaxLedId(0);
    setstrINPLName("");
    setiSerInputTaxLedId(0);
    setstrOutPLName("");
    setiSerOutputTaxLedId(0);
    setiStatusId(1);
    dispatch(setload_Screen(true));
    api.get(
       "TaxCreationMaster/GetDefaultData/" + localStorage.db
    ).then((res) => {
      setListEntList(res.data.objEntList);
      setListFilter(res.data.objFilter);
      setListStatus(res.data.objLoadStatus);
      setListTaxTyp(res.data.objEntTaxType);
      setListLedger(res.data.objLedger);
      setCount(res.data.objEntList.length);
      dispatch(setload_Screen(false));
    });
  };

  const DeleteIconClick = (iTaxId) => {
    api.post(
      
        "TaxCreationMaster/Delete/" +
        iTaxId +
        "/" +
        localStorage.db
    ).then((json) => {
      if (json.data.strStatus === "Success") {
        AlertMessage(json.data.strMessage, "success");
        Load();
      } else {
        AlertMessage(json.data.strMessage, "error");
      }
    });
  };

  const DeleteClick = () => {
    api.post(
      
        "TaxCreationMaster/Delete/" +
        iTaxId +
        "/" +
        localStorage.db
    ).then((json) => {
      if (json.data.strStatus === "Success") {
        AlertMessage(json.data.strMessage, "success");
        Load();
      } else {
        AlertMessage(json.data.strMessage, "error");
      }
    });
  };

  const GetLedger = (res) => {
    api.get(
      
        "TaxCreationMaster/GetTaxCreation/" +
        Number(res) +
        "/" +
        localStorage.db
    ).then((res) => {
      setstrTaxDesc(res.data.strTaxDesc);
      setstrAlias(res.data.strAlias);
      setiTaxTypeId(res.data.iTaxTypeId);
      setbinputCreditReq(res.data.binputCreditReq);
      setdTaxPer(res.data.dTaxPer);
      setiTaxId(res.data.iTaxId);
      setiPurLedId(res.data.iPurLedId);
      setiPurTaxLedId(res.data.iPurTaxLedId);
      setiSalLedId(res.data.iSalLedId);
      setiSalTaxLedId(res.data.iSalTaxLedId);
      setstrPLName(res.data.strPLName);
      setstrPTLName(res.data.strPTLName);
      setstrSLName(res.data.strSLName);
      setstrSTLName(res.data.strSTLName);
      setstrPRLName(res.data.strPRLName);
      setiPurRetLedId(res.data.iPurRetLedId);
      setstrSRLName(res.data.strSRLName);
      setiSalRetLedId(res.data.iSalRetLedId);
      setstrJLName(res.data.strJLName);
      setiJobLedId(res.data.iJobLedId);
      setstrJTLName(res.data.strJTLName);
      setiJobTaxLedId(res.data.iJobTaxLedId);
      setstrINPLName(res.data.strINPLName);
      setiSerInputTaxLedId(res.data.iSerInputTaxLedId);
      setstrOutPLName(res.data.strOutPLName);
      setiSerOutputTaxLedId(res.data.iSerOutputTaxLedId);
      setiStatusId(res.data.iStatusId);
    });
  };

  const InsUpdateTax = () => {
    const TaxInsUpd = {
      iScrId: iScrId,
      iTaxId: iTaxId,
      strTaxDesc: strTaxDesc,
      strAlias: strAlias,
      binputCreditReq: binputCreditReq,
      dTaxPer: Number(dTaxPer),
      iTaxTypeId: Number(iTaxTypeId),
      iStatusId: Number(iStatusId),
      iPurLedId: Number(iPurLedId),
      iSalLedId: Number(iSalLedId),
      iJobLedId: Number(iJobLedId),
      iPurRetLedId: Number(iPurRetLedId),
      iSalRetLedId: Number(iSalRetLedId),
      iSalTaxLedId: Number(iSalTaxLedId),
      iPurTaxLedId: Number(iPurTaxLedId),
      iJobTaxLedId: Number(iJobTaxLedId),
      iSerInputTaxLedId: Number(iSerInputTaxLedId),
      iSerOutputTaxLedId: Number(iSerOutputTaxLedId),
      strCompanyDB: localStorage.db,
    };
    api.post( "TaxCreationMaster/InsUpd/", TaxInsUpd).then((res) => {
      if (res.data.strStatus === "Success") {
        AlertMessage(res.data.strMessage, "success");
        Load();
      } else {
        AlertMessage(res.data.strMessage, "error");
      }
    });
  };

  const ClearClick = () => {
    Load();
  };

  const GlobalSearch = (res) => {
    if (res.strSearch === "") {
      res.strSearch = "null";
    }
    api.get(
      
        "TaxCreationMaster/Search/" +
        res.strSearch +
        "/" +
        res.strFilterColumn +
        "/" +
        localStorage.db
    ).then((res) => {
      setListEntList(res.data);
    });
  };

  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  const LedgerOpen = (LedgerFrom, Filter, Ledger) => {
    if (Filter === "") {
      Filter = "null";
    }
    setLedgerfrom(Ledger);
    api.get(
      
        "TaxCreationMaster/LedgerOpen/" +
        LedgerFrom +
        "/" +
        Filter +
        "/" +
        localStorage.db
    ).then((res) => {
      setListLedger(res.data);
      setisOpen(!isOpen);
    });
  };

  const SelectLedger = (Ledger) => {
    switch (Ledgerfrom) {
      case "PL":
        setiPurLedId(Ledger.ledId);
        setstrPLName(Ledger.ledName);
        break;
      case "PTL":
        setiPurTaxLedId(Ledger.ledId);
        setstrPTLName(Ledger.ledName);
        break;
      case "PRL":
        setiPurRetLedId(Ledger.ledId);
        setstrPRLName(Ledger.ledName);
        break;
      case "SL":
        setiSalLedId(Ledger.ledId);
        setstrSLName(Ledger.ledName);
        break;
      case "STL":
        setiSalTaxLedId(Ledger.ledId);
        setstrSTLName(Ledger.ledName);
        break;
      case "SRL":
        setiSalRetLedId(Ledger.ledId);
        setstrSRLName(Ledger.ledName);
        break;
      case "SILN":
        setiSerInputTaxLedId(Ledger.ledId);
        setstrINPLName(Ledger.ledName);
        break;
      case "SOTL":
        setiSerOutputTaxLedId(Ledger.ledId);
        setstrOutPLName(Ledger.ledName);
        break;
      case "JL":
        setiJobLedId(Ledger.ledId);
        setstrJLName(Ledger.ledName);
        break;
      case "JTL":
        setiJobTaxLedId(Ledger.ledId);
        setstrJTLName(Ledger.ledName);
        break;
      default:
        break;
    }
    setisOpen(!isOpen);
  };

  return (
    <div>
      <div className="Master-view">
        <div className="container-fluid">
          <div className="row w-auto">
            <div className="col-md-9 col-sm-12 order-sm-1 mt-3 ">
              <div className="fg-card shadow-base mb-3">
                <div className="container-fluid pt-2">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Tax Description</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strTaxDesc}
                        onChange={(e) => setstrTaxDesc(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Alias</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strAlias}
                        onChange={(e) => setstrAlias(e.target.value)}
                      />
                    </div>
                    <div className="col-md-2 col-sm-12  mb-2">
                      <label>Tax Type</label>
                      <br />
                      <div className="btn-group">
                        <select
                          className="select "
                          value={iTaxTypeId}
                          onChange={(e) => setiTaxTypeId(e.target.value)}
                        >
                          {ListTaxTyp.map((TaxTyp, Index) => (
                            <option key={Index} value={TaxTyp.iTT_Id}>
                              {TaxTyp.strTaxType}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-2 col-md-2 mb-2">
                      <label>Status</label>
                      <br />
                      <div className="btn-group">
                        <select
                          className="select "
                          value={iStatusId}
                          onChange={(e) => setiStatusId(e.target.value)}
                        >
                          {ListStatus.map((Status, Index) => (
                            <option key={Index} value={Status.iStatusId}>
                              {Status.strStatusName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2 text-nowrap">
                      <label>Input Credit</label>
                      <br />
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={binputCreditReq}
                          onChange={(e) => setbinputCreditReq(!binputCreditReq)}
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Tax %</label>
                      <input
                        type="number"
                        aria-label="First name"
                        className="form-control"
                        value={dTaxPer}
                        onChange={(e) => setdTaxPer(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Purchase Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strPLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("25", "", "PL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>JobWork Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strJLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("35", "", "JL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Purchase Tax Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strPTLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("12", "", "PTL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>JobWork Tax Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strJTLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("12", "", "JTL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Sales Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strSLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("28", "", "SL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Service InputTax Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strINPLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("12", "", "SILN")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Sales Tax Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strSTLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("12", "", "STL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Service OutputTax Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strOutPLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("12", "", "SOTL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Purchase Return Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strPRLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("25", "", "PRL")}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Sales Return Ledger</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={strSRLName}
                        readOnly
                        onDoubleClick={(e) => LedgerOpen("28", "", "SRL")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Button
                Save={(e) => {
                  if (iPurLedId === 0) {
                    AlertMessage("Please Select Purchase Ledger", "info");
                  } else if (strTaxDesc === "") {
                    AlertMessage("Please Enter Description", "info");
                  } else if (dTaxPer === 0) {
                    AlertMessage("Please Enter Tax %", "info");
                  } else if (iJobLedId === 0) {
                    AlertMessage("Please Select JobWork Ledger", "info");
                  } else if (iPurTaxLedId === 0) {
                    AlertMessage("Please Select Purchase Tax Ledger", "info");
                  } else if (iJobTaxLedId === 0) {
                    AlertMessage("Please Select Jobwork Tax Ledger", "info");
                  } else if (iSalLedId === 0) {
                    AlertMessage("Please Select Sales Ledger", "info");
                  } else if (iSerInputTaxLedId === 0) {
                    AlertMessage("Please Select Service Input Ledger", "info");
                  } else if (iSalTaxLedId === 0) {
                    AlertMessage("Please Select Sales Tax Ledger", "info");
                  } else if (iSerOutputTaxLedId === 0) {
                    AlertMessage("Please Select Service Output Ledger", "info");
                  } else if (iPurRetLedId === 0) {
                    AlertMessage(
                      "Please Select Purchase Return Ledger",
                      "info"
                    );
                  } else if (iSalRetLedId === 0) {
                    AlertMessage("Please Select Sales Return Ledger", "info");
                  } else {
                    dispatch(
                      Show_MsgBox({
                        Button: InsUpdateTax,
                        Question: "Do You Want to Save Tax?",
                        isdialog: true,
                      })
                    );
                  }
                }}
                Clear={ClearClick}
                Delete={(e) => {
                  if (iTaxId === 0) {
                    AlertMessage("Please Select Tax", "error");
                  } else {
                    dispatch(
                      Show_MsgBox({
                        Button: DeleteClick,
                        Question: "Do You Want to Delete Tax ?",
                        isdialog: true,
                      })
                    );
                  }
                }}
                ScrId={iScrId}
              />
            </div>
            <MasterList
              ComBoxValue={ListFilter}
              EntList={ListEntList}
              GetDetails={GetLedger}
              Delete={DeleteIconClick}
              Count={Count}
              Search={GlobalSearch}
              ScreenID={iScrId}
              accessor="strTaxDesc"
              accessorid="iTaxId"
            />
          </div>
        </div>
        {isOpen && (
          <SearchOtherHeadLedger
           Close={(e) => setisOpen(!isOpen)}
            SelectLedger={SelectLedger}
            LoadLedger={ListLedger}
          />
        )}
        <Messagedialogbox />
      </div>
    </div>
  );
}
export default TaxMaster;
