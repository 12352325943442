import React, { useEffect, useState } from "react";
import Admintable from "./Admintable";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api from "./Config";
import CustomizedSelectoption from "../General/CustomizedSelectoption";

function EnquiryMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [arrEntEnquiry, setarrEntEnquiry] = useState([]);

  const [iEnquiryId, setiEnquiryId] = useState(0);
  const [strClientName, setstrClientName] = useState("");
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGST, setstrGST] = useState("");

  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strConfPassword, setstrConfPassword] = useState("");

  const [iStatus, setiStatus] = useState(1);
  const [ActiveDate, setActiveDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [EnquiryDate, setEnquiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isOrganization, setisOrganization] = useState(false);
  const [IsDisable, setIsDisable] = useState(false);
  const [arrEntStatus, setarrEntStatus] = useState([]);
  useEffect(() => {
    Load_Enquiry();
  }, []);

  const Load_Enquiry = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Enquirys").then((res) => {
        if (res.data.strStatus === "Success") {
          setarrEntEnquiry(res.data.EntEnquiry);
          setarrEntStatus(res.data.EntStatus);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Enquiry = (Enquiry) => {
    setiEnquiryId(Enquiry.enquiryId);
    setstrClientName(Enquiry.companyName);
    setstrAdd1(Enquiry.add1);
    setstrAdd2(Enquiry.add2);
    setstrAdd3(Enquiry.add3);
    setstrAdd4(Enquiry.add4);
    setstrPinCode(Enquiry.pincode);
    setstrMobile(Enquiry.mobile);
    setstrEmail(Enquiry.email);
    setstrGST(Enquiry.gst);
    setEnquiryDate(Enquiry.enquiryDate);
    setiStatus(Enquiry.status);
    setIsDisable(Enquiry.status === 3 ? true : false);
    setActiveDate(Enquiry.activeDate);
  };
  const InsUpd_ClientEnquiry = async () => {
    try {
      await dispatch(setload_Screen(true));
      const UpdateRequest = {
        EnquiryId: iEnquiryId,
        CompanyName: strClientName,
        Add1: strAdd1,
        Add2: strAdd2,
        Add3: strAdd3,
        Add4: strAdd4,
        Pincode: strPinCode,
        Mobile: strMobile,
        GST: strGST,
        Email: strEmail,
        EnquiryDate: EnquiryDate,
        Status: iStatus,
        ActiveDate: ActiveDate,
      };
      await api
        .post("Admin/InsUpd_ClientEnquiry", UpdateRequest)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            ClearClick();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_ClientEnquiry = async () => {
    try {
      if (Number(iEnquiryId) === 0) {
        AlertMessage("Please Select Enquiry", "info");
      } else {
        await api
          .post(`Admin/Delete_ClientEnquiry/${Number(iEnquiryId)}`)
          .then((res) => {
            if (res.data.strStatus === "Success") {
              ClearClick();
              AlertMessage(res.data.strMessage, "success");
            } else {
              AlertMessage(res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Create_NewClient = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        strUserName: strUserName,
      };
      await api.post(`Admin/CheckUserName/`, parems).then(async (result) => {
        if (result.data.strStatus === "Success") {
          var today = new Date();
          var ExpiredDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 7
          );

          let date1 = new Date();
          let date2 = new Date(ExpiredDate);

          //calculate time difference
          var time_difference = date2.getTime() - date1.getTime();

          //calculate days difference by dividing total milliseconds in a day
          var days_difference = Math.ceil(
            time_difference / (1000 * 60 * 60 * 24)
          );
          var ActivationDate = new Date().toISOString().split("T")[0];
          ExpiredDate = ExpiredDate.toISOString().split("T")[0];
          const UserModel = {
            ClientId: 0,
            ClientName: strClientName,
            Add1: strAdd1,
            Add2: strAdd2,
            Add3: strAdd3,
            Add4: strAdd4,
            PinCode: strPinCode,
            Mobile: strMobile,
            Email: strEmail,
            GST: strGST,
            ActivationDate: ActivationDate,
            ExpiryDate: ExpiredDate,
            Subscription: days_difference + " Days",
            MasterDB: 0,
            strMasterDB: "",
            strUserName: strUserName,
            strPassword: strPassword,
            iStatus: 1,
            iUserId: 1,
            iRoleId: 1,
          };
          await api
            .post("Admin/InsUpd_ClientMaster", UserModel)
            .then(async (res) => {
              if (res.data.strStatus === "Success") {
                await InsUpd_ClientEnquiry();
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        } else {
          AlertMessage(result.data.strMessage, "info");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiEnquiryId(0);
    setstrClientName("");
    setstrAdd1("");
    setstrAdd2("");
    setstrAdd3("");
    setstrAdd4("");
    setstrPinCode("");
    setstrMobile("");
    setstrEmail("");
    setstrGST("");
    setstrUserName("");
    setstrPassword("");
    setstrConfPassword("");
    setiStatus(1);
    setIsDisable(false);
    setActiveDate(new Date().toISOString().split("T")[0]);
    setEnquiryDate(new Date().toISOString().split("T")[0]);
    setisOrganization(false);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const EnquiryColumns = [
    {
      Name: "Name",
      ColName: "companyName",
      Color: "Black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "Status",
      ColName: "statusName",
      Color: "black",
      Width: "120",
      textAlign: "center",
      ColorCondition: "Install Completed",
    },
  ];

  return (
    <div className="Dash-content">
      <div className="Dash-card">
        <Admintable
          Columns={EnquiryColumns}
          Data={arrEntEnquiry}
          View_Data={View_Enquiry}
          IsBackground={true}
        />
      </div>
      <div className="Dash-card">
        <div className="Dash-wrapper">
          <div className="row p-2">
            <div className="col-sm-12 col-md-6">
              <label>Company Name</label>
              <input
                type="text"
                placeholder="Enter Company Name"
                tabIndex={1}
                className="form-control"
                value={strClientName}
                onChange={(e) => setstrClientName(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Status</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Select State"
                select_value={(val) => setiStatus(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iStatus}
                displayName="status"
                disvalue="statusId"
                arrydata={arrEntStatus}
                EmptVal="State"
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Process Date</label>
              <input
                type="date"
                className="form-control"
                value={ActiveDate}
                onChange={(e) => setActiveDate(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Door/Address No</label>
              <input
                type="text"
                placeholder="Enter Door/Address No"
                tabIndex={1}
                className="form-control"
                value={strAdd1}
                onChange={(e) => setstrAdd1(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Street/Area</label>
              <input
                type="text"
                placeholder="Enter Street/Area"
                tabIndex={1}
                className="form-control"
                value={strAdd2}
                onChange={(e) => setstrAdd2(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Village/Town</label>
              <input
                type="text"
                placeholder="Enter Village/Town"
                tabIndex={1}
                className="form-control"
                value={strAdd3}
                onChange={(e) => setstrAdd3(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>City</label>
              <input
                type="text"
                placeholder="Enter City"
                tabIndex={1}
                className="form-control"
                value={strAdd4}
                onChange={(e) => setstrAdd4(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Pincode</label>
              <input
                type="text"
                placeholder="Enter Company Name"
                tabIndex={1}
                className="form-control"
                value={strPinCode}
                onChange={(e) => setstrPinCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Mobile</label>
              <input
                type="text"
                placeholder="Enter Company Name"
                tabIndex={1}
                className="form-control"
                value={strMobile}
                onChange={(e) => setstrMobile(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter Email"
                tabIndex={1}
                className="form-control"
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>GST</label>
              <input
                type="text"
                placeholder="Enter GST No"
                tabIndex={1}
                className="form-control"
                value={strGST}
                onChange={(e) => setstrGST(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Enquiry Date</label>
              <input
                type="date"
                className="form-control"
                defaultValue={EnquiryDate}
                disabled
              />
            </div>
            {isOrganization && (
              <div className="col-sm-12 col-md-3">
                <label>User Name</label>
                <input
                  type="text"
                  placeholder="Enter User Name"
                  tabIndex={6}
                  className="form-control"
                  value={strUserName}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrUserName(e.target.value)}
                />
              </div>
            )}
            {isOrganization && (
              <div className="col-sm-12 col-md-3">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  tabIndex={7}
                  className="form-control"
                  value={strPassword}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrPassword(e.target.value)}
                />
              </div>
            )}
            {isOrganization && (
              <div className="col-sm-12 col-md-3">
                <label>Confirm Password</label>
                <input
                  type="text"
                  placeholder="Enter Password"
                  tabIndex={8}
                  className="form-control"
                  value={strConfPassword}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrConfPassword(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value !== strPassword) {
                      AlertMessage("Password Doesn't Match", "info");
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="Dash-section">
          {!isOrganization && (
            <button
              className="btn-fabgreen"
              disabled={IsDisable}
              onClick={(e) => {
                if (Number(iStatus) === 1)
                  AlertMessage("Please Select Enquiry Status", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: InsUpd_ClientEnquiry,
                      Question: "Do You Want to Update Enquiry Status?",
                      isdialog: true,
                    })
                  );
              }}
            >
              <i className="fas fa-save"></i>&nbsp;Update Enquiry
            </button>
          )}
          {!isOrganization && (
            <button
              disabled={IsDisable}
              className="btn-fabgreen"
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: Delete_ClientEnquiry,
                    Question: "Do You Want to Delete Enquiry?",
                    isdialog: true,
                  })
                );
              }}
            >
              <i className="bx bx-trash-alt"></i>&nbsp;Delete Enquiry
            </button>
          )}
          {isOrganization && (
            <button
              className="btn-fabgreen"
              disabled={IsDisable}
              onClick={(e) => {
                if (strPassword === "")
                  AlertMessage("Please Enter Password", "info");
                else if (strConfPassword === "")
                  AlertMessage("Please Enter Password", "info");
                else if (strConfPassword !== strPassword)
                  AlertMessage("Password Doesn't Match", "info");
                else {
                  dispatch(
                    Show_MsgBox({
                      Button: Create_NewClient,
                      Question: "Do You Want to Add New Client?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              <i className="bx bx-buildings"></i>&nbsp;Create Organization
            </button>
          )}
          {!isOrganization && (
            <button
              disabled={IsDisable}
              className="btn-fabgreen"
              onClick={(e) => {
                setisOrganization(!isOrganization);
                setiStatus(3);
              }}
            >
              <i className="bx bx-user-plus"></i>&nbsp;Add New Client
            </button>
          )}
          <button className="btn-fabgreen ml-2" onClick={ClearClick}>
            <i className="fa-solid fa-broom"></i>&nbsp;Clear Enquiry
          </button>
        </div>
      </div>
    </div>
  );
}

export default EnquiryMaster;
