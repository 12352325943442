import React, { useState } from "react";
function Admintable(props) {
  const [iSelected, setiSelected] = useState(0);
  const Table_Style = (dt, Item, Index, Selected) => {
    const Style = {
      textAlign: dt.textAlign,
      whiteSpace: "wrap",
      color: props.IsBackground
        ? Index === Selected
          ? "white"
          : dt.ColorCondition
          ? Item[dt.ColName] !== dt.ColorCondition
            ? "red"
            : dt.Color
          : dt.Color
        : dt.ColorCondition
        ? Item[dt.ColName] !== dt.ColorCondition
          ? "red"
          : dt.Color
        : dt.Color,
    };
    if (dt.Width.includes("%") || dt.Width.includes("auto")) {
      Style.width = dt.Width;
      Style.minWidth = "130px";
    } else {
      Style.width = dt.Width + "px";
      Style.minWidth = dt.Width + "px";
      Style.maxWidth = dt.Width + "px";
    }
    return Style;
  };
  return (
    <div className="table-admin">
      <table className="option-list">
        <thead>
          <tr>
            {props.Columns.map((ht, Index) => (
              <th key={Index}>{ht.Name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.Data.map((Item, trIndex) => (
            <tr
              key={trIndex}
              tabIndex={trIndex}
              onKeyDown={(e) => {
                const arrlength = props.Data.length - 1;
                if (e.key === "ArrowDown") {
                  if (trIndex === arrlength) setiSelected(0);
                  else setiSelected(iSelected + 1);
                }
                if (e.key === "ArrowUp") {
                  if (trIndex === 0) setiSelected(arrlength);
                  else setiSelected(iSelected - 1);
                }
                if (e.key === "Enter") props.View_Data(props.Data[iSelected]);
              }}
              onClick={() => setiSelected(trIndex)}
              onDoubleClick={(e) => {
                setiSelected(trIndex);
                props.View_Data(props.Data[iSelected]);
              }}
              style={{
                background: props.IsBackground
                  ? trIndex === iSelected
                    ? "darkgreen"
                    : "#fff"
                  : "#fff",
              }}
            >
              {props.Columns.map((dt, Index) =>
                dt.cell ? (
                  <td
                    key={Index}
                    style={Table_Style(dt, Item, trIndex, iSelected)}
                  >
                    <dt.cell user={Item} />
                  </td>
                ) : (
                  <td
                    key={Index}
                    style={Table_Style(dt, Item, trIndex, iSelected)}
                  >
                    {Item[dt.ColName]}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Admintable;
