import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import api from "../Admin/Config";

import {
  Clear_Transaction,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import {
  KeyUpDown,
  Load_Transaction,
  Validate_Properties,
} from "./Transaction";
function TransactionButton(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);

  const Delete_Voucher = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.post(
        `Transaction/Delete_Voucher/${_Common.iScrId}/${_HT.iVouchId}/${localStorage.db}`
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await AlertMessage(
            `${_Common.strScrName} Successfully Deleted`,
            "success"
          );
          await Clear_Click();
        } else {
          await AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      await AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Clear_Click = async () => {
    try {
      await dispatch(Clear_Transaction());
      if (props.Clear) await props.Clear();
      else await Load_Transaction(_Common, dispatch);
    } catch (e) {
      await AlertMessage(e.message, "error");
    }
  };
  return (
    <div className="btn-section">
      <button
        className="btn-fabgreen"
        onClick={() => dispatch(ShowHide_Popup("IsPendinCon"))}
        disabled={_HT.iPartyId === 0 ? true : _Common.btnConvert}
        onKeyDown={(e) => {
          if (e.key === "Enter") dispatch(ShowHide_Popup("IsPendinCon"));
          else KeyUpDown(e);
        }}
      >
        Convert&nbsp;<i className="bx bx-transfer"></i>
      </button>
      <button
        disabled={_Common.btnSave}
        className="btn-fabgreen"
        tabIndex={_Common.arrtaborder.btnSave}
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            if (
              await Validate_Properties(_Common.arrValidation, _HT, dispatch)
            ) {
              dispatch(
                Show_MsgBox({
                  Button: props.Save,
                  Question: `Do You Want to ${
                    _HT.iVouchId > 0 ? "Update" : "Save"
                  } ${_Common.strScrName}?`,
                  isdialog: true,
                })
              );
            }
          } else KeyUpDown(e);
        }}
        onClick={async () => {
          if (await Validate_Properties(_Common.arrValidation, _HT, dispatch)) {
            dispatch(
              Show_MsgBox({
                Button: props.Save,
                Question: `Do You Want to ${
                  _HT.iVouchId > 0 ? "Update" : "Save"
                } ${_Common.strScrName}?`,
                isdialog: true,
              })
            );
          }
        }}
      >
        Save&nbsp;<i className="fas fa-save"></i>
      </button>
      <button
        disabled={_Common.btnView}
        className="btn-fabgreen"
        onClick={() => dispatch(ShowHide_Popup("IsTransView"))}
        tabIndex={_Common.arrtaborder.btnView}
        onKeyDown={(e) => {
          if (e.key === "Enter") dispatch(ShowHide_Popup("IsTransView"));
          else KeyUpDown(e);
        }}
      >
        View &nbsp;<i className="fas fa-eye"></i>
      </button>
      <button
        disabled={_Common.btnDelete}
        className="btn-fabgreen"
        tabIndex={_Common.arrtaborder.btnCancel}
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            if (_HT.iVouchId === 0) {
              AlertMessage("Please Select Voucher to Delete!", "error");
            } else {
              await dispatch(
                Show_MsgBox({
                  Button: Delete_Voucher,
                  Question: `Do You Want to Delete ${_Common.strScrName}?`,
                  isdialog: true,
                })
              );
            }
          } else KeyUpDown(e);
        }}
        onClick={async () => {
          if (_HT.iVouchId === 0) {
            AlertMessage("Please Select Voucher to Delete!", "error");
          } else {
            await dispatch(
              Show_MsgBox({
                Button: Delete_Voucher,
                Question: `Do You Want to Delete ${_Common.strScrName}?`,
                isdialog: true,
              })
            );
          }
        }}
      >
        Delete&nbsp;<i className="bx bx-trash-alt"></i>
      </button>
      <button
        className="btn-fabgreen"
        onClick={(e) => Clear_Click()}
        tabIndex={_Common.arrtaborder.btnClear}
        onKeyDown={(e) => {
          if (e.key === "Enter") Clear_Click();
          else KeyUpDown(e);
        }}
      >
        Clear&nbsp;<i className="fa-solid fa-broom"></i>
      </button>
      <button
        className="btn-fabgreen"
        tabIndex={_Common.arrtaborder.btnClose}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sessionStorage.removeItem("VoucherId");
            sessionStorage.removeItem("VoucherType");
            window.location.href = "/Dashboard";
          } else KeyUpDown(e);
        }}
        onClick={() => {
          sessionStorage.removeItem("VoucherId");
          sessionStorage.removeItem("VoucherType");
          window.location.href = "/Dashboard";
        }}
        disabled={Boolean(Number(localStorage.IsDirect))}
      >
        Close&nbsp;<i className="fas fa-times"></i>
      </button>
    </div>
  );
}
export default TransactionButton;
