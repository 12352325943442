import { useEffect, useState } from "react";
import api from "../Admin/Config";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import { message } from "antd";
function BankSearch(props) {
  const dispatch = useDispatch();
  const [arrEntBank, setarrEntBank] = useState([]);
  const [strSearch, setstrSearch] = useState();

  useEffect(() => {
    Load_BankMaster("");
  }, []);
  const Load_BankMaster = async (strtxt) => {
    try {
      await dispatch(setload_Screen(false));
      setstrSearch(strtxt);
      const objsearch = {
        iBankNameId: 0,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await api.post(`BankMaster/Search_BankMaster`, objsearch).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setarrEntBank(res.data.EntBank);
          } else {
            AlertMessage(res.data.strMessage, message, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e, message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Bank List</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => props.Close()}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="search-card full">
            <div className="m-hsearch">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                autoFocus
                autoComplete="off"
                id="Invoice_Search"
                tabIndex={52}
                name="strSearch"
                value={strSearch}
                onChange={(e) => Load_BankMaster(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown")
                    $(".option-list [tabindex=0]").focus();
                  else if (e.key === "Escape") props.Close();
                }}
              />
              <i
                className="fas fa-search"
                onClick={(e) => Load_BankMaster(strSearch)}
              ></i>
            </div>
          </div>
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Bank Name</th>
                </tr>
              </thead>
              <tbody>
                {arrEntBank.map((bank, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEntBank.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "ArrowLeft" || e.key === "ArrowRight")
                        $("#Item_Search").focus();
                      if (e.key === "Enter") props.View_Data(bank);
                    }}
                    onDoubleClick={(e) => props.View_Data(bank)}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        width: "50px",
                        maxWidth: "50px",
                        color: "black",
                      }}
                    >
                      {Index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        width: "auto",
                        color: "black",
                      }}
                    >
                      {bank.bankNames}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={() => props.NewBank()}>
              Create bank&nbsp;<i className="bx bxs-bank"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BankSearch;
