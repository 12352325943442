import jsPDF from "jspdf";
import NumbertoWord from "../General/NumbertoWord";
import { Show_MsgBox } from "../ReduxStore/Message";
var options = { orientation: "p", unit: "pt", format: [0, 0] };
var doc = new jsPDF(options);

const AssignPrintData = (JsonString, dFormat) => {
  let JsonArray = JsonString === undefined ? [] : JSON.parse(JsonString);

  JsonArray = JsonArray.map((DT) => {
    let ColumName = Object.keys(DT);
    for (let i = 0; i < ColumName.length; i++) {
      if (ColumName[i].includes("Qty")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strQtyFormat
        );
      }
      if (ColumName[i].includes("Per")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strPerFormat
        );
      }
      if (ColumName[i].includes("Amt") || ColumName[i].includes("Rate")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
      if (ColumName[i].includes("GoodsValue")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
    }
    return DT;
  });
  return JsonArray;
};
const VoucherName = (VoucherInfo, ScrName) => {
  var InvoiceNo = "";
  switch (ScrName) {
    case "Payment":
      InvoiceNo = VoucherInfo.payNo;
      break;
    case "Receipt":
      InvoiceNo = VoucherInfo.recNo;
      break;
    case "DebitNote":
      InvoiceNo = VoucherInfo.debNo;
      break;
    case "CreditNote":
      InvoiceNo = VoucherInfo.creNo;
      break;
    default:
      break;
  }
  return ScrName + "-" + InvoiceNo.slice(-5);
};
const fnDrawData = (objEntArea, dtData, objEntLogo) => {
  try {
    if (objEntArea.length === 0) return;
    let objElements = [];
    let objLabels = [];
    let dContentHeight = 0;
    let objPrintParam = "";
    let dPerLineHeightWithGap = 0;
    objElements = objEntArea.filter(
      (DT) => DT.fieldName !== "Label" && !DT.fieldName.startsWith("LogoId")
    );
    objLabels = objEntArea.filter(
      (DT) => DT.fieldName === "Label" && DT.suffix !== "Only"
    );
    objLabels.forEach((entLabel) => {
      let objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
      objPrintParam = fnAlignment(objPrintParam);
      if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
        let SplitTextbyWidth = doc.splitTextToSize(
          objPrintParam.printContent,
          Math.floor(objPrintParam.width)
        );
        objPrintParam.SplittextCount = SplitTextbyWidth.length;
        let Height = objPrintParam.lineAt;
        for (let P = 0; P < SplitTextbyWidth.length; P++) {
          objPrintParam.printContent = SplitTextbyWidth[P];
          objPrintParam.lineAt = Height;
          Height += doc.getTextDimensions(SplitTextbyWidth[P]).h;
          fnDrawString(objPrintParam);
        }
      } else {
        fnDrawString(objPrintParam);
      }
    });

    //#region Draw drItem
    if (dtData.length === 0) return;
    dtData.forEach((drData) => {
      objElements.forEach((entElement) => {
        let objPrintParam = fnGetPrintAreaProperties(entElement);
        objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
        objPrintParam = fnAlignment(objPrintParam);
        if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
          let SplitTextbyWidth = doc.splitTextToSize(
            objPrintParam.printContent,
            Math.floor(objPrintParam.width)
          );
          objPrintParam.SplittextCount = SplitTextbyWidth.length;
          let Height = objPrintParam.lineAt;
          for (let P = 0; P < SplitTextbyWidth.length; P++) {
            objPrintParam.printContent = SplitTextbyWidth[P];
            objPrintParam.lineAt = Height;
            Height += doc.getTextDimensions(SplitTextbyWidth[P]).h;
            fnDrawString(objPrintParam);
          }
        } else {
          fnDrawString(objPrintParam);
        }
      });
    });
    //#endregion

    //#region Amount in words
    if (
      objEntArea.filter(
        (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
      ).length > 0
    ) {
      let objAmountInWords = objEntArea.filter(
        (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
      );
      objAmountInWords.forEach((entAmountInWord) => {
        objPrintParam = fnGetPrintAreaProperties(entAmountInWord, "Label");
        var CntWH = fnMeasureString(objPrintParam);
        dContentHeight = CntWH.dContentHeight;
        objPrintParam = fnAlignment(objPrintParam);

        let strWords = objPrintParam.printContent.replace(
          /^.{1}/g,
          objPrintParam.printContent[0].toLowerCase()
        );
        strWords = dtData[0][strWords];
        //Convet Number to Words
        objPrintParam.printContent = NumbertoWord(strWords);

        dPerLineHeightWithGap = dContentHeight + 0;

        let SplitTextbyWidth = doc.splitTextToSize(
          objPrintParam.printContent,
          Math.floor(objPrintParam.width)
        );
        if (objPrintParam.wordWrap === "wrap") {
          for (let P = 0; P < SplitTextbyWidth.length; P++) {
            objPrintParam.printContent = SplitTextbyWidth[P];
            objPrintParam.lineAt = Number(
              Number(objPrintParam.lineAt) + P * dPerLineHeightWithGap
            );
            fnDrawString(objPrintParam);
          }
        } else {
          fnDrawString(objPrintParam);
        }
      });
    }
    //#endregion

    //#region Draw Logo
    var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
    if (EntLogo.length > 0) {
      EntLogo.forEach((entLogo) => {
        let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
        let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
        fnDrawLogo(entLogo, objentLogoData);
      });
    }
    //#endregion
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
const fnDrawLine = (LineTemp) => {
  for (let i = 0; i < LineTemp.length; i++) {
    let X1 = LineTemp[i].x1Pos;
    let Y1 = LineTemp[i].y1Pos - 10;
    let X2 = LineTemp[i].x2Pos;
    let Y2 = LineTemp[i].y2Pos - 10;
    doc.setDrawColor(LineTemp[i].color);
    doc.line(X1, Y1, X2, Y2);
  }
};
const fnDrawString = (parem) => {
  doc.setFontSize(parem.fontSize);
  doc.setTextColor(parem.color);
  doc.setFont(parem.font, parem.fontStyle, parem.fontWeight);
  doc.text(parem.printContent, parem.startAt, parem.lineAt);
};
const fnDrawLogo = (Design, Logo) => {
  if (Logo.length === 0) return;
  Logo = Logo[0];
  let ImgType = Logo.strLogoName.split(".")[1];
  let IW = Logo.dLogoWidth;
  let IH = Logo.dLogoHeight;
  const blobUrl = URL.createObjectURL(
    new Blob([new Uint8Array(Logo.imgData)], { type: `image/${ImgType}` })
  );
  ImgType = ImgType.toUpperCase();
  ImgType = ImgType === "JPG" ? "JPEG" : ImgType;
  doc.addImage(blobUrl, ImgType, Design.startAt, Design.lineAt, IW, IH);
};
const fnMeasureString = (Content) => {
  let dWidth = Content.width;
  let LineCount = 0;
  let Height = 0;
  let splitText = [];
  doc.setFontSize(Content.fontSize);
  doc.setTextColor(Content.color);
  doc.setFont(Content.font, Content.fontStyle, Content.fontWeight);
  dWidth = doc.getTextWidth(Content.printContent);
  if (Content.width < dWidth) dWidth = Content.width;

  if (Content.wordWrap.toLowerCase() === "wrap") {
    splitText = doc.splitTextToSize(Content.printContent, Content.width);
    splitText.forEach((dt) => {
      Height += doc.getTextDimensions(dt).h;
      LineCount++;
    });
  } else {
    Height = doc.getTextDimensions(Content.printContent).h;
  }
  return {
    dContentWidth: dWidth,
    dContentHeight: Height,
    LineCount: LineCount,
  };
};
const fnGetPrintAreaProperties = (PrintArea, strHelp) => {
  var FieldName = PrintArea.fieldName;
  FieldName = FieldName.replace(/^.{1}/g, FieldName[0].toLowerCase());
  const objPrintParm = {
    designId: PrintArea.designId,
    startAt: PrintArea.startAt,
    lineAt: PrintArea.lineAt,
    width: PrintArea.width,
    fontWeight: PrintArea.fontWeight,
    alignment: PrintArea.alignment,
    fontSize: PrintArea.fontSize,
    fontStyle: PrintArea.fontStyle,
    color: PrintArea.color,
    wordWrap: PrintArea.wordWrap,
    printContent: strHelp === "Label" ? PrintArea.labelName : "",
    fieldName: FieldName,
    font: PrintArea.font,
    dHeightDiff: 0,
    labelName: PrintArea.labelName,
    dMaxHeight: 0,
    dMaxLine: 0,
    dContentWidth: 0,
    dContentHeight: 0,
    strFileName: "",
    iPrintContentLength: 0,
    iWidthLimit: 0,
    iContentWidth: 0,
    iPrintContentValidLength: 0,
    iLineCount: 0,
    dPerElementHeight: 0,
    iPrintContentSplitByWidthCount: 0,
    iCount: 0,
    dDescriptionBegin: 0,
    dDescriptionEnd: 0,

    SplittextCount: 0,
  };
  return objPrintParm;
};
const fnAlignment = (objPrintParm) => {
  let TextWH = fnMeasureString(objPrintParm);
  switch (objPrintParm.alignment.toString().toLowerCase()) {
    case "center":
      objPrintParm.startAt =
        objPrintParm.startAt + (objPrintParm.width - TextWH.dContentWidth) / 2;
      break;
    case "right":
      objPrintParm.startAt =
        objPrintParm.width + objPrintParm.startAt - TextWH.dContentWidth - 10;
      break;
    case "left":
    default:
      objPrintParm.startAt = Number(objPrintParm.startAt); // 10 is a left margin
      break;
  }
  return objPrintParm;
};
const CreateInvocie = (
  PrintValue,
  _iCurrentCopy,
  _iScrId,
  ScrName,
  PrintFor,
  PrintShare
) => {
  let PageWidth = Number(Number(PrintValue.Paper.width * 3.78).toFixed(2)) + 20; //  Convert mm to Pt
  let PageHeight =
    Number(Number(PrintValue.Paper.height * 3.78).toFixed(2)) + 20; //  Convert mm to Pt

  options.format = [PageWidth, PageHeight];
  doc = new jsPDF(options);
  //#region Print Template
  let _objLineTempFirst = PrintValue.objLineTemplate.objFirst;
  //#endregion
  //#region Print Area Design
  let _objCompanyArea = PrintValue.objCompanyArea;
  let _objPartyArea = PrintValue.objVendoreArea;
  let _objAccuntsArea = PrintValue.objAccountsArea;

  //#endregion
  //#region  Print Data
  const DecimalFormate = {
    strAmtFormat: parseInt(PrintValue.strAmtFormat),
    strPerFormat: parseInt(PrintValue.strPerFormat),
    strQtyFormat: parseInt(PrintValue.strQtyFormat),
  };
  let _dtCompany = AssignPrintData(PrintValue.jsonCompany, DecimalFormate);
  let _dtParty = AssignPrintData(PrintValue.jsonVendore, DecimalFormate);
  let _dtAccounts = AssignPrintData(PrintValue.jsonAccounts, DecimalFormate);
  //#endregion

  //#region Draw Line
  fnDrawLine(_objLineTempFirst);
  //#endregion

  fnDrawData(_objCompanyArea, _dtCompany);
  fnDrawData(_objPartyArea, _dtParty);
  fnDrawData(_objAccuntsArea, _dtAccounts);

  var FileName = VoucherName(_dtAccounts[0], ScrName);
  if (PrintShare) {
    var string = doc.output("dataurlstring", FileName);
    const objPdfFile = {
      PdfURL: string,
      FileName: FileName,
      Summary: [],
      CompanyInfo: _dtCompany,
      PartyInfo: _dtParty,
    };
    return objPdfFile;
  } else {
    if (PrintFor === "Print") {
      doc.output("dataurlnewwindow", FileName);
    } else {
      doc.save(FileName + ".pdf");
    }
  }
};
const PrintAccountsDesign = (
  PrintValue,
  iScrId,
  PrintFor,
  ScrName,
  PrintShare,
  dispatch
) => {
  try {
    if (PrintShare) {
      if (PrintValue.PinterType !== 3) {
      }
      return CreateInvocie(
        PrintValue,
        1,
        iScrId,
        ScrName,
        PrintFor,
        PrintShare
      );
    } else {
      CreateInvocie(PrintValue, 1, iScrId, ScrName, PrintFor, PrintShare);
      doc = new jsPDF(options); // Clear Page After Print First Copy
    }
  } catch (e) {
    dispatch(Show_MsgBox({ Message: e.message, Type: "error", isMsg: true }));
  }
};
export default PrintAccountsDesign;
