function numberToWords(num) {
  const singleDigits = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const doubleDigits = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tensMultiple = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const powersOfTen = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero";

  let words = "";

  let numString = num.toString();
  let n = numString.length;
  let groupIndex = 0;

  while (n > 0) {
    let sliceSize = groupIndex === 0 ? 3 : 2;
    let startIndex = Math.max(0, n - sliceSize);
    let groupValue = parseInt(numString.slice(startIndex, n), 10);

    if (groupValue !== 0) {
      let groupWords = convertGroupToWords(
        groupValue,
        singleDigits,
        doubleDigits,
        tensMultiple
      );
      words = groupWords + " " + powersOfTen[groupIndex] + " " + words;
    }

    n -= sliceSize;
    groupIndex += 1;
  }

  return words.trim();
}
const convertGroupToWords = (num, singleDigits, doubleDigits, tensMultiple) => {
  if (num < 10) return singleDigits[num];
  if (num < 20) return doubleDigits[num - 10];
  if (num < 100)
    return tensMultiple[Math.floor(num / 10)] + " " + singleDigits[num % 10];

  let remainder = num % 100;
  let hundreds = Math.floor(num / 100);

  return (
    singleDigits[hundreds] +
    " Hundred " +
    (remainder > 0
      ? "and " +
        convertGroupToWords(remainder, singleDigits, doubleDigits, tensMultiple)
      : "")
  );
};
function NumbertoWord(amount) {
  const [rupees, paisa] = Number(amount).toFixed(2).split(".").map(Number);
  let words = numberToWords(rupees) + " Rupees";
  if (paisa > 0) {
    words += " and " + numberToWords(paisa) + " Paisa";
  }
  return words + " Only /-";
}
export default NumbertoWord;
