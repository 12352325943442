import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import api, { Backup_Data, Backup_Server } from "./Config";
import { useEffect, useState } from "react";
import $ from "jquery";
import Admintable from "./Admintable";
function ClientMaster() {
  const dispatch = useDispatch();

  const [ClientId, setClientId] = useState(0);
  const [strClientName, setstrClientName] = useState("");
  const [strMasterDB, setstrMasterDB] = useState("");
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGST, setstrGST] = useState("");
  const [ActivationDate, setActivationDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ExpiryDate, setExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [Subscription, setSubscription] = useState("");
  const [arrEntClients, setarrEntClients] = useState([]);

  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Load_Clients").then((res) => {
        if (res.data.strStatus === "Success") {
          res.data.EntClients.map((dt) => {
            dt.subscription = calculate_keyexpiry(dt.expiryDate);
            return dt;
          });
          setarrEntClients(res.data.EntClients);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Client = async (client) => {
    try {
      setClientId(client.clientId);
      setstrClientName(client.clientName);
      setstrAdd1(client.add1);
      setstrAdd2(client.add2);
      setstrAdd3(client.add3);
      setstrAdd4(client.add4);
      setstrPinCode(client.pinCode);
      setstrMobile(client.mobile);
      setstrEmail(client.email);
      setstrGST(client.gst);
      setActivationDate(client.activationDate);
      setExpiryDate(client.expiryDate);
      setSubscription(client.subscription);
      setstrMasterDB(client.strMasterDB);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Update_Client = async () => {
    try {
      const UserModel = {
        ClientId: ClientId,
        ClientName: strClientName,
        Add1: strAdd1,
        Add2: strAdd2,
        Add3: strAdd3,
        Add4: strAdd4,
        PinCode: strPinCode,
        Mobile: strMobile,
        Email: strEmail,
        GST: strGST,
        ActivationDate: ActivationDate,
        ExpiryDate: ExpiryDate,
        Subscription: Subscription,
        MasterDB: 0,
        strMasterDB: "",
        strUserName: "",
        strPassword: "",
        iStatus: 1,
        iUserId: 0,
        iRoleId: 0,
      };
      await api
        .post("Admin/InsUpd_ClientMaster", UserModel)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            await Clear_Click();
            await Load_Clients();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_Client = async () => {
    try {
      await dispatch(setload_Screen(true));
      if (Number(ClientId) > 0) {
        await api
          .post(`Admin/Delete_ClientMaster/${Number(ClientId)}`)
          .then(async (res) => {
            if (res.data.strStatus === "Success") {
              AlertMessage(res.data.strMessage, "success");
              await Clear_Click();
              await Load_Clients();
            } else {
              AlertMessage(res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Click = () => {
    setClientId(0);
    setstrClientName("");
    setstrMasterDB("");
    setstrAdd1("");
    setstrAdd2("");
    setstrAdd3("");
    setstrAdd4("");
    setstrPinCode("");
    setstrMobile("");
    setstrEmail("");
    setstrGST("");
    setActivationDate(new Date().toISOString().split("T")[0]);
    setExpiryDate(new Date().toISOString().split("T")[0]);
    setSubscription("");
  };
  const calculate_keyexpiry = (ExpiryDate) => {
    const start = new Date();
    const end = new Date(ExpiryDate);

    // Calculate the difference in milliseconds
    const difference = Math.abs(end - start);

    // Convert milliseconds to days
    const days = Math.ceil(difference / (1000 * 60 * 60 * 24));

    return days + " Days";
  };
  const find_datedifference = () => {
    let date1 = new Date(ActivationDate);
    let date2 = new Date(ExpiryDate);
    var time_difference = date2.getTime() - date1.getTime();
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    setSubscription(days_difference + " Days");
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ClientColumns = [
    {
      Name: "Client Name",
      ColName: "clientName",
      Color: "Black",
      Width: "auto",
      textAlign: "left",
    },
    {
      Name: "License Expiry",
      ColName: "subscription",
      Color: "red",
      Width: "100",
      textAlign: "right",
    },
  ];
  const Restart_App = async () => {
    try {
      await dispatch(setload_Screen(true));
      await api.get("Admin/Restart_App").then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Backup = async () => {
    await Backup_Data({ dispatch: dispatch, Msdb: strMasterDB });
  };
  return (
    <div className="Dash-content">
      <div className="Dash-card">
        <Admintable
          Columns={ClientColumns}
          Data={arrEntClients}
          View_Data={View_Client}
          IsBackground={true}
        />
      </div>
      <div className="Dash-card">
        <div className="Dash-wrapper">
          <div className="dash-header">
            <button
              onClick={() => {
                dispatch(
                  Show_MsgBox({
                    Button: Backup_Server,
                    Question: `Do You Want to Backup Data?`,
                    isdialog: true,
                    Id: dispatch,
                  })
                );
              }}
            >
              Backup Server&nbsp;<i className="bx bx-server"></i>
            </button>
            <button
              onClick={() =>
                dispatch(
                  Show_MsgBox({
                    Button: Restart_App,
                    Question: `Do You Want to Logout All User?`,
                    isdialog: true,
                    Id: dispatch,
                  })
                )
              }
            >
              Logout Users&nbsp;<i className="bx bx-refresh"></i>
            </button>
          </div>
          <div className="row pl-2">
            <div className="col-md-8 col-sm-12">
              <label>Client Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Client Name"
                value={strClientName}
                onChange={(e) => setstrClientName(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Master Database</label>
              <input
                type="text"
                className="form-control"
                tabIndex={1}
                defaultValue={strMasterDB}
                readOnly
                placeholder="Master Database"
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Activation Date</label>
              <input
                type="date"
                className="form-control"
                tabIndex={1}
                value={ActivationDate}
                readOnly
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Expiry Date</label>
              <input
                type="date"
                className="form-control"
                tabIndex={1}
                value={ExpiryDate}
                onChange={(e) => {
                  setExpiryDate(e.target.value);
                }}
                onBlur={() => find_datedifference()}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Subscription</label>
              <input
                type="text"
                className="form-control"
                tabIndex={1}
                value={Subscription}
                readOnly
                placeholder="License Valid Upto"
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Door/Office No</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                type="text"
                className="form-control"
                placeholder="Enter Door/Office No"
                spellCheck={false}
                tabIndex={5}
                value={strAdd1}
                onChange={(e) => setstrAdd1(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Street / Area</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                type="text"
                className="form-control"
                placeholder="Enter Street / Area"
                spellCheck={false}
                tabIndex={5}
                value={strAdd2}
                onChange={(e) => setstrAdd2(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Village / Town</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                type="text"
                className="form-control"
                placeholder="Enter Village / Town"
                spellCheck={false}
                tabIndex={5}
                value={strAdd3}
                onChange={(e) => setstrAdd3(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>City</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                type="text"
                className="form-control"
                tabIndex={4}
                value={strAdd4}
                placeholder="Enter City Name"
                onChange={(e) => setstrAdd4(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Pincode</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                type="text"
                className="form-control"
                tabIndex={4}
                placeholder="Enter Pincode"
                value={strPinCode}
                onChange={(e) => setstrPinCode(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Mobile</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                type="text"
                onClick={(e) => e.target.select()}
                spellCheck={false}
                className="form-control"
                tabIndex={6}
                placeholder="Enter Mobile No"
                value={strMobile}
                onChange={(e) => setstrMobile(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Email</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                type="text"
                onClick={(e) => e.target.select()}
                spellCheck={false}
                className="form-control"
                placeholder="Enter Email"
                tabIndex={6}
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>GST No</label>
              <input
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                type="text"
                className="form-control"
                tabIndex={4}
                placeholder="Enter GST"
                value={strGST}
                onChange={(e) => setstrGST(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="Dash-section">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (ClientId === 0) AlertMessage("Please Select Client", "info");
              else
                dispatch(
                  Show_MsgBox({
                    Button: Update_Client,
                    Question: "Do You Want to Update Client Info?",
                    isdialog: true,
                  })
                );
            }}
          >
            Save&nbsp;<i className="bx bxs-save"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={() => {
              if (ClientId === 0) AlertMessage("Please Select Client", "info");
              else
                dispatch(
                  Show_MsgBox({
                    Button: Delete_Client,
                    Question: "Do You Want to Delete Client?",
                    isdialog: true,
                  })
                );
            }}
          >
            Delete&nbsp;<i className="bx bx-trash-alt"></i>
          </button>
          <button className="btn-fabgreen" onClick={() => Clear_Click()}>
            Clear&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (strMasterDB.length === 0)
                AlertMessage("Please Select Client", "info");
              else
                dispatch(
                  Show_MsgBox({
                    Button: Backup,
                    Question: `Do You Want to Backup Data?`,
                    isdialog: true,
                  })
                );
            }}
          >
            Backup Data&nbsp;<i className="bx bx-server"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientMaster;
