import jsPDF from "jspdf";
import NumbertoWord from "../General/NumbertoWord";
import { Buffer } from "buffer";
import { Show_MsgBox } from "../ReduxStore/Message";
var options = { orientation: "p", unit: "pt", format: [0, 0] };

var doc = new jsPDF(options);
const VoucherName = (VoucherInfo, ScrName) => {
  var InvoiceNo = "";
  switch (ScrName) {
    case "Sales Challan":
      InvoiceNo = VoucherInfo.salDCNo;
      break;
    case "Sales Enquiry":
      InvoiceNo = VoucherInfo.salEnqNo;
      break;
    case "Sales Estimate":
      InvoiceNo = VoucherInfo.eSNo;
      break;
    case "Sales Invoice":
      InvoiceNo = VoucherInfo.salInvNo;
      break;
    case "Sales Order":
      InvoiceNo = VoucherInfo.salOrdNo;
      break;
    case "Sales Quotation":
      InvoiceNo = VoucherInfo.salQuoNo;
      break;
    case "Sales Return":
      InvoiceNo = VoucherInfo.salRetNo;
      break;
    case "Purchase Challan":
      InvoiceNo = VoucherInfo.purDCNo;
      break;
    case "Purchase Enquiry":
      InvoiceNo = VoucherInfo.purEnqNo;
      break;
    case "Purchase Estimate":
      InvoiceNo = VoucherInfo.purESNo;
      break;
    case "Purchase Invoice":
      InvoiceNo = VoucherInfo.purInvNo;
      break;
    case "Purchase Order":
      InvoiceNo = VoucherInfo.purOrdNo;
      break;
    case "Purchase Quotation":
      InvoiceNo = VoucherInfo.purQuoNo;
      break;
    case "Purchase Return":
      InvoiceNo = VoucherInfo.purRetNo;
      break;
    case "Outsource Dc":
      InvoiceNo = VoucherInfo.outSDCNo;
      break;
    case "Insource Dc":
      InvoiceNo = VoucherInfo.inSDCNo;
      break;
    case "Insource Invoice":
      InvoiceNo = VoucherInfo.inSInvNo;
      break;
    case "Inward Dc":
      InvoiceNo = VoucherInfo.inwDCNo;
      break;
    case "Outward Dc":
      InvoiceNo = VoucherInfo.outWDCNo;
      break;
    case "Outward Invoice":
      InvoiceNo = VoucherInfo.outWInvNo;
      break;
    default:
      break;
  }
  return ScrName + "-" + InvoiceNo.slice(-5);
};
const ConvertNulltoArray = (EntData) => {
  return EntData === null ? [] : EntData;
};
const JsontoArray = (JsonString, dFormat) => {
  let JsonArray = JsonString ? JSON.parse(JsonString) : [];
  JsonArray = JsonArray.map((DT) => {
    let ColumName = Object.keys(DT);
    for (let i = 0; i < ColumName.length; i++) {
      if (ColumName[i].includes("Qty")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strQtyFormat
        );
      }
      if (ColumName[i].includes("Per")) {
        DT[ColumName[i]] = isNaN(dFormat.strPerFormat)
          ? DT[ColumName[i]]
          : isNaN(DT[ColumName[i]])
          ? DT[ColumName[i]]
          : Number(DT[ColumName[i]]).toFixed(dFormat.strPerFormat);
      }
      if (ColumName[i].includes("Amt") || ColumName[i].includes("Rate")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
      if (ColumName[i].includes("GoodsValue")) {
        DT[ColumName[i]] = Number(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
    }
    return DT;
  });
  return JsonArray;
};
const Increase_LineAt = (EntDt, Desc, IncHeight) => {
  EntDt = EntDt.map((Data) => {
    if (Data.lineAt > Desc) Data.lineAt = Data.lineAt + IncHeight;
    return Data;
  });
  return EntDt;
};
const fnLineTempDescBegin = (EntTemplate) => {
  if (EntTemplate.length > 0) {
    return EntTemplate.filter((DT) => DT.lineName === "DescriptionBegin")[0]
      .y1Pos;
  } else {
    return 0;
  }
};
const fnLineTempDescEnd = (EntTemplate) => {
  if (EntTemplate.length > 0) {
    return EntTemplate.filter((DT) => DT.lineName === "DescriptionEnd")[0]
      .y1Pos;
  } else {
    return 0;
  }
};
const fnPageCount = (dTotalHeight, DescriptionLine) => {
  let _dDescription = 0;
  let iTotalPages = 0;
  let _dGapPerLine = DescriptionLine.GapPerLine;
  //#region Check one Page is Enough

  _dDescription =
    fnLineTempDescEnd(DescriptionLine.First) -
    fnLineTempDescBegin(DescriptionLine.First) -
    2 * _dGapPerLine;
  if (dTotalHeight <= _dDescription) {
    iTotalPages = 1;
    return iTotalPages;
  }
  //#endregion

  //#region Check two Page is Enough

  let dFirstAlterDescription =
    fnLineTempDescEnd(DescriptionLine.Alter) -
    fnLineTempDescBegin(DescriptionLine.Alter) -
    2 * _dGapPerLine;

  let dLastDescription =
    fnLineTempDescEnd(DescriptionLine.Last) -
    fnLineTempDescBegin(DescriptionLine.Last) -
    2 * _dGapPerLine;

  _dDescription = dFirstAlterDescription + dLastDescription;

  if (dTotalHeight <= _dDescription) {
    iTotalPages = 2;
    return iTotalPages;
  }
  //#endregion

  //#region Calculate exact page count

  let dMiddleDescription =
    fnLineTempDescEnd(DescriptionLine.Middle) -
    fnLineTempDescBegin(DescriptionLine.Middle) -
    2 * _dGapPerLine;

  for (let iCount = 1; iCount <= 50; iCount++) {
    _dDescription =
      dFirstAlterDescription + dMiddleDescription * iCount + dLastDescription;
    if (dTotalHeight <= _dDescription) {
      iTotalPages = 2 + iCount;
      return iTotalPages;
    }
  }

  //#endregion
};
const fnGetCurrentPageDescLimitation = (DescriptionLine) => {
  let dDescriptionBegin = 0;
  let dDescriptionEnd = 0;
  if (DescriptionLine.iCurrPage === 1) {
    if (DescriptionLine.iTotalPages === 1) {
      dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.First);
      dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.First);
    } else {
      dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.Alter);
      dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.Alter);
    }
  } else if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
    dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.Last);
    dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.Last);
  } else {
    dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.Middle);
    dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.Middle);
  }
  const objEntDesc = {
    DescriptionBegin: Number(dDescriptionBegin + DescriptionLine.GapPerLine),
    DescriptionEnd: dDescriptionEnd,
  };
  return objEntDesc;
};
const fnGetThermalCurrentPageDescLimitation = (DescriptionLine) => {
  let dDescriptionBegin = 0;
  let dDescriptionEnd = 0;
  dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.First);
  dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.First);
  const objEntDesc = {
    DescriptionBegin: Number(dDescriptionBegin + DescriptionLine.GapPerLine),
    DescriptionEnd: dDescriptionEnd,
  };
  return objEntDesc;
};
const termalPrinterDesc = (
  _objItemsArea,
  _dtItems,
  _objAddLessArea,
  _dtAddLess
) => {
  //#region Calculate Total Desc Area Height
  let dTotalHeight = 0;
  let dTotalLine = 0;
  let TableWidthHeight = fnGetTableHeight(_objItemsArea, _dtItems);
  dTotalHeight = TableWidthHeight.dTotalHeight;
  dTotalLine = TableWidthHeight.dTotalLine;
  if (_objAddLessArea.length > 0 && _dtAddLess.length > 0) {
    TableWidthHeight = fnGetTableHeight(_objAddLessArea, _dtAddLess);
    dTotalHeight += TableWidthHeight.dTotalHeight;
    dTotalLine += TableWidthHeight.dTotalLine;
  }
  //#endregion
  return {
    dTotalHeight: dTotalHeight,
    dTotalLine: dTotalLine,
  };
};
const fnGetTableHeight = (Design, _dtItems, _dtJobs) => {
  let dTotalHeight = 0;
  let dTotalLine = 0;
  let dMaxHeight = 0;
  let dMaxLine = 0;

  let EntItemsDesign = Design.filter(
    (DT) => DT.fieldName !== "Label" && DT.fieldName !== "AddLessHeaderLabel"
  );
  _dtItems.forEach((drItem) => {
    let EntDesign = [];
    EntItemsDesign.forEach((ItemArea) => {
      EntDesign.push(fnAddItemAggregation(ItemArea, drItem));
    });
    dMaxHeight = Math.max(...EntDesign.map((obj) => obj.contentHeight));
    dMaxLine = Math.max(...EntDesign.map((obj) => obj.contentLineCount));

    //#region Get MaxHeight & Line compare with Same StartAt with Diff LineAt Logics
    let dStartAtList = [];
    dStartAtList = [...new Set(EntDesign.map((item) => item.startAt))];
    dStartAtList.forEach((dStartAt) => {
      let iCount = 0;
      for (let i = 0; i < EntDesign.length; i++) {
        if (EntDesign[i].startAt === dStartAt) iCount++;
      }
      if (iCount > 1) {
        EntDesign = EntDesign.filter((DT) => DT.startAt === dStartAt);
        let SumConHeight = EntDesign.map((item) => item.contentHeight).reduce(
          (prev, curr) => prev + curr,
          0
        );
        let SumConLinCount = EntDesign.map(
          (item) => item.contentLineCount
        ).reduce((prev, curr) => prev + curr, 0);

        dMaxHeight = Math.max(dMaxHeight, SumConHeight);
        dMaxLine = Math.max(dMaxLine, SumConLinCount);
      }
    });
    //#endregion

    dTotalHeight += dMaxHeight;
    dTotalLine += dMaxLine;
  });
  let Header = Design.filter((DT) => DT.fieldName === "AddLessHeaderLabel");
  if (Header.length > 0) {
    let objPrintParm = fnGetPrintAreaProperties(Header[0]);
    if (objPrintParm.printContent === "" || objPrintParm.printContent === null)
      return;
    dTotalHeight += dTotalHeight;
    dTotalLine += 1;
  }
  return {
    dTotalHeight: dTotalHeight,
    dTotalLine: dTotalLine,
  };
};
const fnGetTotalHeightAndLineJobs = (objItemsArea, dtJobs) => {
  if (objItemsArea.length === 0 || dtJobs.length === 0) {
    return {
      dTotalHeightJobs: 0,
      dTotalLineJobs: 0,
    };
  }
  var dMaxHeight = 0;
  let dMaxLine = 0;
  let dTotalHeightJobs = 0;
  let dTotalLineJobs = 0;

  let EntItemsArea = objItemsArea.filter(
    (DT) => DT.fieldName !== "Label" && DT.fieldName !== "AddLessHeaderLabel"
  );
  dtJobs.forEach((drJobs) => {
    let EntDesign = [];
    EntItemsArea.forEach((ItemArea) => {
      EntDesign.push(fnAddItemAggregation(ItemArea, drJobs));
    });
    dMaxHeight = Math.max(...EntDesign.map((obj) => obj.contentHeight));
    dMaxLine = Math.max(...EntDesign.map((obj) => obj.contentLineCount));

    //#region Get MaxHeight & Line compare with Same StartAt with Diff LineAt Logics

    let dStartAtList = [];
    EntDesign.map((item) => {
      var findItem = dStartAtList.find((x) => x.startAt === item.startAt);
      if (!findItem) dStartAtList.push(item);
      return item;
    });
    dStartAtList.forEach((dStartAt) => {
      let iCount = EntDesign.filter((DT) => DT.startAt === dStartAt).length;
      if (iCount > 1) {
        EntDesign = EntDesign.filter((DT) => DT.startAt === dStartAt);
        dMaxHeight = Math.max(...EntDesign.map((obj) => obj.contentHeight));
        dMaxLine = Math.max(...EntDesign.map((obj) => obj.contentLineCount));
      }
    });
    //#endregion

    dTotalHeightJobs += dMaxHeight;
    dTotalLineJobs += dMaxLine;
    EntDesign = [];
  });
  const objENtJTHL = {
    dTotalHeightJobs: dTotalHeightJobs,
    dTotalLineJobs: dTotalLineJobs,
  };
  return objENtJTHL;
};
const fnGetPrintAreaProperties = (PrintArea, strHelp) => {
  var FieldName = PrintArea.fieldName;
  FieldName = FieldName.replace(/^.{1}/g, FieldName[0].toLowerCase());
  const objPrintParm = {
    designId: PrintArea.designId,
    startAt: PrintArea.startAt,
    lineAt: PrintArea.lineAt,
    width: PrintArea.width,
    alignment: PrintArea.alignment,
    fontSize: PrintArea.fontSize,
    fontStyle: PrintArea.fontStyle,
    fontWeight: PrintArea.fontWeight,
    color: PrintArea.color,
    wordWrap: PrintArea.wordWrap,
    printContent: strHelp === "Label" ? PrintArea.labelName : "",
    fieldName: FieldName,
    font: PrintArea.font,
    dHeightDiff: 0,
    labelName: PrintArea.labelName,
    dMaxHeight: 0,
    dMaxLine: 0,
    dContentWidth: 0,
    dContentHeight: 0,
    strFileName: "",
    iPrintContentLength: 0,
    iWidthLimit: 0,
    iContentWidth: 0,
    iPrintContentValidLength: 0,
    iLineCount: 0,
    dPerElementHeight: 0,
    SplittextCount: 0,
    iCount: 0,
    dDescriptionBegin: 0,
    dDescriptionEnd: 0,
  };
  return objPrintParm;
};
const fnAddItemAggregation = (ItemArea, drItem) => {
  let TextWH = "";
  var FieldName = ItemArea.fieldName;
  FieldName = FieldName.replace(/^.{1}/g, FieldName[0].toLowerCase());
  let printContent = drItem[FieldName] ? drItem[FieldName].toString() : "";
  TextWH = fnMeasureString({
    printContent: printContent,
    width: ItemArea.width,
    wordWrap: ItemArea.wordWrap,
    fontSize: ItemArea.fontSize,
    color: ItemArea.color,
    font: ItemArea.font,
    fontStyle: ItemArea.fontStyle,
    fontWeight: ItemArea.fontWeight,
  });
  return {
    designId: ItemArea.designId,
    lineAt: ItemArea.lineAt,
    startAt: ItemArea.startAt,
    width: ItemArea.width,
    fieldName: ItemArea.fieldName,
    contentWidth: TextWH.dContentWidth,
    contentHeight: TextWH.dContentHeight,
    contentLineCount: TextWH.LineCount,
  };
};
const fnAddEachItemAggregation = (Item) => {
  return {
    designId: Item.designId,
    lineAt: Item.lineAt,
    startAt: Item.startAt,
    width: Item.width,
    fieldName: Item.fieldName,
    contentWidth: Item.dContentWidth,
    contentLineCount: 1,
    contentHeight: Item.dContentHeight,
  };
};
const fnMeasureString = (Content) => {
  let dWidth = Content.width;
  let LineCount = 0;
  let Height = 0;
  let splitText = [];
  doc.setFontSize(Content.fontSize);
  doc.setTextColor(Content.color);
  doc.setFont(Content.font, Content.fontStyle, Content.fontWeight);
  dWidth = doc.getTextWidth(Content.printContent);
  if (Content.width < dWidth) dWidth = Content.width;

  if (Content.wordWrap.toLowerCase() === "wrap") {
    splitText = doc.splitTextToSize(Content.printContent, Content.width);
    splitText.forEach((dt) => {
      Height += doc.getTextDimensions(dt).h;
      LineCount++;
    });
  } else {
    Height = doc.getTextDimensions(Content.printContent).h;
  }
  return {
    dContentWidth: dWidth,
    dContentHeight: Height,
    LineCount: LineCount,
  };
};
const fnAlignment = (objPrintParm) => {
  let TextWH = fnMeasureString(objPrintParm);
  switch (objPrintParm.alignment.toString().toLowerCase()) {
    case "center":
      objPrintParm.startAt =
        objPrintParm.startAt + (objPrintParm.width - TextWH.dContentWidth) / 2;
      break;
    case "right":
      objPrintParm.startAt =
        objPrintParm.width + objPrintParm.startAt - TextWH.dContentWidth - 10;
      break;
    case "left":
    default:
      objPrintParm.startAt = Number(objPrintParm.startAt); // 10 is a left margin
      break;
  }
  return objPrintParm;
};
const fnDrawString = (parem) => {
  doc.setFontSize(parem.fontSize);
  doc.setTextColor(parem.color);
  doc.setFont(parem.font, parem.fontStyle, parem.fontWeight);
  doc.text(parem.printContent, parem.startAt, parem.lineAt);
};
const fnDrawLine = (LineTemp) => {
  for (let i = 0; i < LineTemp.length; i++) {
    let X1 = LineTemp[i].x1Pos;
    let Y1 = LineTemp[i].y1Pos - 10;
    let X2 = LineTemp[i].x2Pos;
    let Y2 = LineTemp[i].y2Pos - 10;
    doc.setDrawColor(LineTemp[i].color);
    doc.line(X1, Y1, X2, Y2);
  }
};
const fnDrawLogo = async (Design, Logo) => {
  if (Logo.length === 0) return;
  Logo = Logo[0];
  let ImgType = Logo.strLogoName.split(".")[1];
  let IW = Logo.dLogoWidth;
  let IH = Logo.dLogoHeight;
  const blobUrl = URL.createObjectURL(
    new Blob([new Uint8Array(Logo.imgData)], { type: `image/${ImgType}` })
  );
  ImgType = ImgType.toUpperCase();
  ImgType = ImgType === "JPG" ? "JPEG" : ImgType;
  doc.addImage(blobUrl, ImgType, Design.startAt, Design.lineAt, IW, IH);
};
const fnDrawData = (objEntArea, dtData, DescriptionLine, objEntLogo) => {
  try {
    let iTotalPages = DescriptionLine.iTotalPages;
    let iCurrentPages = DescriptionLine.iCurrPage;
    let _dGapPerLine = DescriptionLine.GapPerLine;
    if (objEntArea.length === 0) return;
    let objElements = [];
    let objLabels = [];
    if (iTotalPages === iCurrentPages) {
      objLabels = objEntArea.filter(
        (DT) =>
          DT.fieldName === "Label" &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
      objElements = objEntArea.filter(
        (DT) =>
          DT.fieldName !== "Label" &&
          !DT.fieldName.startsWith("LogoId") &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
    } else if (iCurrentPages === 1) {
      objLabels = objEntArea.filter(
        (DT) =>
          DT.fieldName === "Label" &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
      objElements = objEntArea.filter(
        (DT) =>
          DT.fieldName !== "Label" &&
          !DT.fieldName.startsWith("LogoId") &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyLast"
      );
    } else {
      objLabels = objEntArea.filter(
        (DT) =>
          DT.fieldName === "Label" &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
      objElements = objEntArea.filter(
        (DT) =>
          DT.fieldName !== "Label" &&
          !DT.fieldName.startsWith("LogoId") &&
          DT.suffix !== "OnlyFirst" &&
          DT.suffix !== "OnlyLast"
      );
    }
    objLabels.forEach((entLabel) => {
      let objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
      objPrintParam = fnAlignment(objPrintParam);
      if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
        let SplitTextbyWidth = doc.splitTextToSize(
          objPrintParam.printContent,
          Math.floor(objPrintParam.width)
        );
        objPrintParam.SplittextCount = SplitTextbyWidth.length;
        let Height = objPrintParam.lineAt;
        for (let P = 0; P < SplitTextbyWidth.length; P++) {
          objPrintParam.printContent = SplitTextbyWidth[P];
          objPrintParam.lineAt = Height;
          Height += _dGapPerLine + doc.getTextDimensions(SplitTextbyWidth[P]).h;
          fnDrawString(objPrintParam);
        }
      } else {
        fnDrawString(objPrintParam);
      }
    });
    //#region Draw drItem
    if (dtData.length === 0) return;
    dtData.forEach((drData) => {
      objElements.forEach((entElement) => {
        let objPrintParam = fnGetPrintAreaProperties(entElement);
        objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
        objPrintParam = fnAlignment(objPrintParam);
        if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
          let SplitTextbyWidth = doc.splitTextToSize(
            objPrintParam.printContent,
            Math.floor(objPrintParam.width)
          );
          objPrintParam.SplittextCount = SplitTextbyWidth.length;
          let Height = objPrintParam.lineAt;
          for (let P = 0; P < SplitTextbyWidth.length; P++) {
            objPrintParam.printContent = SplitTextbyWidth[P];
            objPrintParam.lineAt = Height;
            Height +=
              _dGapPerLine + doc.getTextDimensions(SplitTextbyWidth[P]).h;
            fnDrawString(objPrintParam);
          }
        } else {
          fnDrawString(objPrintParam);
        }
      });
    });

    //#endregion
    //#region Draw Logo
    var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
    EntLogo.forEach((entLogo) => {
      let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
      let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
      fnDrawLogo(entLogo, objentLogoData);
    });
    //#endregion
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
const fnDrawTaxData = (objEntArea, dtData) => {
  let objPrintParam = "";
  if (objEntArea.length === 0) return;
  let objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  let objTotalElements = objEntArea.filter(
    (DT) => DT.fieldName !== "Label" && DT.fieldName.includes("Total")
  );

  let objExceptTotalElements = objEntArea.filter(
    (DT) => DT.fieldName !== "Label" && !DT.fieldName.includes("Total")
  );

  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
  if (dtData.length === 0) return;
  objTotalElements.forEach((entTotalElement) => {
    objPrintParam = fnGetPrintAreaProperties(entTotalElement);
    objPrintParam.printContent = dtData[0][objPrintParam.fieldName].toString();
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
  let fTaxLineGap = 20;
  let iCount = 0;
  dtData.forEach((drData) => {
    objExceptTotalElements.forEach((entExceptTotalElement) => {
      objPrintParam = fnGetPrintAreaProperties(entExceptTotalElement);
      objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
      objPrintParam = fnAlignment(objPrintParam);
      objPrintParam.lineAt = objPrintParam.lineAt + iCount * fTaxLineGap;
      fnDrawString(objPrintParam);
    });
    iCount++;
  });
};
const fnDrawItemsHeader = (
  objEntArea,
  bIsItem,
  objItemHeader,
  DescriptionLine
) => {
  let objLabels = [];
  let iTotalPages = DescriptionLine.iTotalPages;
  let _dGapPerLine = DescriptionLine.GapPerLine;
  if (bIsItem) objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  else
    objLabels = objEntArea.filter(
      (DT) => DT.fieldName === "Label" || DT.fieldName === "AddLessHeaderLabel"
    );
  objItemHeader = [];
  objLabels.forEach((entLabel) => {
    let objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    //#region Get Description Height Diff of First Page
    objPrintParam.dHeightDiff =
      DescriptionLine.dFirstDescriptionBegin - Number(objPrintParam.lineAt);
    if (iTotalPages > 1)
      objPrintParam.dHeightDiff =
        DescriptionLine.dFirstAlterDescriptionBegin -
        Number(objPrintParam.lineAt);
    //#endregion

    objItemHeader = fnAddItemHeader(objItemHeader, objPrintParam);
    objPrintParam = fnAlignment(objPrintParam);
    if (bIsItem) fnDrawString(objPrintParam);
    else {
      objPrintParam.lineAt = Number(DescriptionLine.fCurrentPageDescBegin);
      fnDrawString(objPrintParam);
    }
    if (
      objEntArea.filter((DT) => DT.fieldName === "AddLessHeaderLabel").length >
      0
    ) {
      var TextWH = fnMeasureString(objPrintParam);
      let dContentHeight = TextWH.dContentHeight;
      DescriptionLine.fAddLessHeaderHeight = Number(
        dContentHeight + _dGapPerLine
      );
      fnDrawString(objPrintParam);
    }
  });
  return {
    DescriptionLine: DescriptionLine,
    objItemHeader: objItemHeader,
  };
};
const fnAddItemHeader = (objEntItemHeader, objPrintParm) => {
  const objItemHeader = {
    designId: objPrintParm.designId,
    labelName: objPrintParm.labelName,
    dHeightDiff: objPrintParm.dHeightDiff,
  };
  objEntItemHeader.push(objItemHeader);
  return objEntItemHeader;
};
const fnDrawItemsData = (
  Design,
  Item,
  bIsItem,
  Jobs,
  ALDesign,
  LineDesign,
  iPrintedItemSNo,
  iPrintedAddLessSNo,
  iSubTotalItemsSNo
) => {
  if (Design.length === 0 || Item.length === 0) {
    return {
      iPrintedItemSNo: 0,
      iPrintedAddLessSNo: 0,
      iSubTotalItemsSNo: 0,
    };
  }
  let dTotalItemHeight = 0;
  let dPerLineHeightWithGap = 0;
  let dContentHeight = 0;
  let dContentWidth = 0;
  let dPrevEleHeight = 0;
  let objElements = [];
  let objItemAggregations = [];
  let GapPerLine = LineDesign.GapPerLine;
  var objPrintParam = {};
  let EntItem = [];
  if (bIsItem) {
    objElements = Design.filter((DT) => DT.fieldName !== "Label")
      .sort((a, b) => a.lineAt - b.lineAt)
      .sort((c, d) => c.startAt - d.startAt);
  } else {
    objElements = Design.filter(
      (DT) => DT.fieldName !== "Label" && DT.fieldName !== "AddLessHeaderLabel"
    )
      .sort((a, b) => a.lineAt - b.lineAt)
      .sort((c, d) => c.startAt - d.startAt);
  }
  Item.forEach((drData) => {
    let iSNo = drData["sNo"];
    if (bIsItem) {
      if (iPrintedItemSNo.includes(iSNo)) return;
      let Result = fnCanDrawItem(drData, Design, LineDesign, Jobs);
      dTotalItemHeight = Result.dTotalItemHeight;
      if (!Result.bCanDrawItem) {
        LineDesign.fCurrentPageDescBegin = Number(dTotalItemHeight);
        return;
      }
    } else {
      if (iPrintedAddLessSNo.includes(iSNo)) return;
      let Result = fnCanDrawItem(drData, ALDesign, LineDesign, Jobs);
      dTotalItemHeight = Result.dTotalItemHeight;
      if (!Result.bCanDrawItem) {
        LineDesign.fCurrentPageDescBegin = Number(dTotalItemHeight);
        return;
      }
    }
    objElements.forEach((entElement) => {
      objPrintParam = fnGetPrintAreaProperties(entElement);
      objPrintParam.printContent =
        drData[objPrintParam.fieldName] !== undefined
          ? drData[objPrintParam.fieldName].toString()
          : "";

      let TextWH = fnMeasureString(objPrintParam);
      dContentHeight = TextWH.dContentHeight;
      dContentWidth = TextWH.dContentWidth;
      objPrintParam = fnAlignment(objPrintParam);
      objPrintParam.dContentHeight = dContentHeight;
      objPrintParam.dContentWidth = dContentWidth;
      EntItem.push(fnAddEachItemAggregation(objPrintParam));
      dPrevEleHeight = 0;
      objItemAggregations = EntItem.filter(
        (DT) =>
          DT.lineAt !== objPrintParam.lineAt &&
          DT.startAt === objPrintParam.startAt
      );
      if (objItemAggregations.length > 0) {
        objItemAggregations.forEach((entItemAggregation) => {
          objPrintParam.iLineCount = entItemAggregation.contentLineCount;
          objPrintParam.dPerElementHeight =
            objPrintParam.iLineCount * entItemAggregation.contentHeight +
            objPrintParam.iLineCount * GapPerLine;
          dPrevEleHeight += objPrintParam.dPerElementHeight;
        });
      }
      let SplitTextbyWidth = doc.splitTextToSize(
        objPrintParam.printContent,
        Math.floor(objPrintParam.width)
      );
      objPrintParam.SplittextCount = SplitTextbyWidth.length;
      if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
        let Height = 0;
        for (let P = 0; P < SplitTextbyWidth.length; P++) {
          objPrintParam.printContent = SplitTextbyWidth[P];
          objPrintParam.lineAt =
            LineDesign.fCurrentPageDescBegin +
            dPrevEleHeight +
            Height +
            LineDesign.fAddLessHeaderHeight;
          Height += GapPerLine + doc.getTextDimensions(SplitTextbyWidth[P]).h;
          fnDrawString(objPrintParam);
        }
        EntItem[EntItem.length - 1].contentHeight = dContentHeight;
        EntItem[EntItem.length - 1].contentWidth = dContentWidth;
        EntItem[EntItem.length - 1].contentLineCount = SplitTextbyWidth.length;
      } else {
        objPrintParam.lineAt =
          LineDesign.fCurrentPageDescBegin +
          dPrevEleHeight +
          LineDesign.fAddLessHeaderHeight;
        fnDrawString(objPrintParam);
      }
    });
    if (bIsItem) {
      iPrintedItemSNo.push(iSNo);
      iSubTotalItemsSNo.push(iSNo);
    } else iPrintedAddLessSNo.push(iSNo);
    EntItem = [];
    LineDesign.fCurrentPageDescBegin = Number(dTotalItemHeight);
    //#region Multi-job Print Logic
    if (Jobs.length > 0) {
      Jobs = Jobs.filter((Data) => Data.outWInvSno === drData["SNo"]);
      Jobs.forEach((drJobs) => {
        if (bIsItem) {
          let Result = fnCanDrawItem(drJobs, Design, LineDesign);
          dTotalItemHeight = Result.dTotalItemHeight;
          if (!Result.bCanDrawItem) {
            return;
          }
        } else {
          let Result = fnCanDrawItem(drJobs, ALDesign, LineDesign);
          dTotalItemHeight = Result.dTotalItemHeight;
          if (!Result.bCanDrawItem) {
            return;
          }
        }
        objElements.forEach((entElement) => {
          objPrintParam = fnGetPrintAreaProperties(entElement);
          objPrintParam.printContent = drJobs.includes(objPrintParam.fieldName)
            ? "" + drJobs[objPrintParam.fieldName]
            : "";
          if (objPrintParam.printContent !== "") return;
          let TextWH = fnMeasureString(objPrintParam);
          dContentHeight = TextWH.dContentHeight;
          dContentWidth = TextWH.dContentWidth;
          objPrintParam = fnAlignment(objPrintParam);
          EntItem.push(fnAddEachItemAggregation(objPrintParam));

          dPrevEleHeight = 0;
          dPerLineHeightWithGap = dContentHeight + GapPerLine;
          objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
          objPrintParam.iWidthLimit = parseInt(Math.floor(objPrintParam.width));
          objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
          objPrintParam.iPrintContentValidLength =
            (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
            objPrintParam.iContentWidth;
          if (
            objPrintParam.iPrintContentLength <
            objPrintParam.iPrintContentValidLength
          )
            objPrintParam.iPrintContentValidLength =
              objPrintParam.iPrintContentLength;

          objItemAggregations = EntItem.filter(
            (DT) =>
              DT.lineAt !== objPrintParam.lineAt &&
              DT.startAt === objPrintParam.startAt
          );
          if (objItemAggregations.length > 0) {
            objItemAggregations.forEach((entItemAggregation) => {
              objPrintParam.iLineCount = entItemAggregation.contentLineCount;
              objPrintParam.dPerElementHeight =
                objPrintParam.iLineCount * entItemAggregation.contentHeight +
                objPrintParam.iLineCount * GapPerLine;
              dPrevEleHeight += objPrintParam.dPerElementHeight;
            });
          }

          let SplitTextbyWidth = doc.splitTextToSize(
            objPrintParam.printContent,
            Math.floor(objPrintParam.width)
          );

          objPrintParam.SplittextCount = SplitTextbyWidth.length;
          if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
            for (let P = 0; P < objPrintParam.SplittextCount; P++) {
              objPrintParam.printContent = SplitTextbyWidth[P];

              objPrintParam.lineAt =
                LineDesign.fCurrentPageDescBegin +
                dPrevEleHeight +
                P * dPerLineHeightWithGap +
                LineDesign.fAddLessHeaderHeight;
              fnDrawString(objPrintParam);
            }
            EntItem[EntItem.length - 1].contentHeight = dContentHeight;
            EntItem[EntItem.length - 1].contentWidth = dContentWidth;
            EntItem[EntItem.length - 1].contentLineCount =
              objPrintParam.SplittextCount;
          } else {
            objPrintParam.lineAt =
              LineDesign.fCurrentPageDescBegin +
              dPrevEleHeight +
              LineDesign.fAddLessHeaderHeight;
            fnDrawString(objPrintParam);
          }
        });
        EntItem = [];
        LineDesign.fCurrentPageDescBegin = Number(dTotalItemHeight);
      });
    }
    //#endregion
  });
  return {
    iPrintedItemSNo: iPrintedItemSNo,
    iPrintedAddLessSNo: iPrintedAddLessSNo,
    iSubTotalItemsSNo: iSubTotalItemsSNo,
  };
};
const fnDrawItemsAutoHeader = (objItemHeader, objEntArea, DescriptionLine) => {
  let objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  let objPrintParam = [];
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam.iCount = objItemHeader.filter(
      (DT) => DT.designId === objPrintParam.designId
    ).length;
    if (objPrintParam.iCount > 0) {
      var entItemHeader = objItemHeader.filter(
        (DT) => DT.designId === objPrintParam.designId
      )[0];
      objPrintParam.dHeightDiff = entItemHeader.dHeightDiff;
      if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages)
        objPrintParam.lineAt = Number(
          DescriptionLine.dLastDescriptionBegin - objPrintParam.dHeightDiff
        );
      else
        objPrintParam.lineAt = Number(
          DescriptionLine.dMiddleDescriptionBegin - objPrintParam.dHeightDiff
        );
    }
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
};
const fnCanDrawItem = (drData, Design, LineDesign, dtJobs) => {
  let bCanDrawItem = true;
  let dHeightPerItem = 0;
  let dTotalItemHeight = 0;
  let dLinePerItem = 0;
  let dtItem = [drData];
  let dTotalLinHw = fnGetTableHeight(Design, dtItem, dtJobs);
  dHeightPerItem = dTotalLinHw.dTotalHeight;
  dLinePerItem = dTotalLinHw.dTotalLine;
  dHeightPerItem += dLinePerItem * LineDesign.GapPerLine;
  dTotalItemHeight = LineDesign.fCurrentPageDescBegin + dHeightPerItem;
  if (LineDesign.dCurrentPageDescLimit <= dTotalItemHeight)
    bCanDrawItem = false;
  return {
    bCanDrawItem: bCanDrawItem,
    dTotalItemHeight: dTotalItemHeight,
  };
};
const fnDrawGeneralData = (
  objEntArea,
  _dtItems,
  iSubTotalItemsSNo,
  _dtSummary,
  DescriptionLine,
  objEntLogo,
  DecimalFormate
) => {
  let dContentHeight = 0,
    dContentWidth = 0;
  let objPrintParam = "";
  let dPerLineHeightWithGap = 0;
  try {
    //#region Label's
    let objLabels = objEntArea.filter(
      (DT) =>
        DT.fieldName === "Label" &&
        DT.prefix !== "SubTotal" &&
        DT.suffix !== "Only"
    );
    objLabels.forEach((entLabel) => {
      objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
      objPrintParam = fnAlignment(objPrintParam);
      fnDrawString(objPrintParam);
    });
    //#endregion

    //#region PageNumber
    if (objEntArea.filter((DT) => DT.fieldName === "PageNumber").length > 0) {
      let entPageNumber = objEntArea.filter(
        (DT) => DT.fieldName === "PageNumber"
      )[0];
      objPrintParam = fnGetPrintAreaProperties(entPageNumber);
      objPrintParam = fnAlignment(objPrintParam);
      objPrintParam.printContent =
        DescriptionLine.iCurrPage + " / " + DescriptionLine.iTotalPages;
      fnDrawString(objPrintParam);
    }

    //#endregion

    //#region CopyType
    if (objEntArea.filter((DT) => DT.fieldName === "CopiesName").length > 0) {
      let entPageNumber = objEntArea.filter(
        (DT) => DT.fieldName === "CopiesName"
      )[0];
      objPrintParam = fnGetPrintAreaProperties(entPageNumber);
      objPrintParam = fnAlignment(objPrintParam);
      var entAreas = objEntArea.filter((r) => r.fieldName === "CopiesName")[0];
      let StrLable = entAreas.labelName;
      let StrSuffix = entAreas.suffix;
      let StrPrefix = entAreas.prefix;
      switch (DescriptionLine.iCurrentCopy) {
        case 1:
          objPrintParam.printContent = StrLable;
          break;
        case 2:
          objPrintParam.printContent = StrPrefix;
          break;
        default:
          objPrintParam.printContent = StrSuffix;
          break;
      }
      objPrintParam.font = entPageNumber.font;
      fnDrawString(objPrintParam);
    }
    //#endregion

    //#region SubTotal
    let objSubTotals = objEntArea.filter(
      (DT) => DT.fieldName === "Label" && DT.prefix === "SubTotal"
    );
    objSubTotals.forEach((entSubTotal) => {
      let dSubTotal = 0;
      let dSubTotalDiff = 0;
      objPrintParam = fnGetPrintAreaProperties(entSubTotal, "Label");
      //#region Get SubTotal Height Diff of First Page
      dSubTotalDiff = Number(
        objPrintParam.lineAt - DescriptionLine.dFirstDescriptionEnd
      );
      //#endregion
      var printContent = objPrintParam.printContent;
      printContent = printContent.replace(
        /^.{1}/g,
        printContent[0].toLowerCase()
      );
      iSubTotalItemsSNo.forEach((iSubTotalItemSNo) => {
        let drItem = _dtItems.filter(
          (Data) => Data.sNo === iSubTotalItemSNo
        )[0];
        dSubTotal += Number(drItem[printContent]);
      });
      if (objPrintParam.printContent.includes("Qty")) {
        objPrintParam.printContent = Number(dSubTotal)
          .toFixed(DecimalFormate.strQtyFormat)
          .toString();
      } else {
        objPrintParam.printContent = Number(dSubTotal)
          .toFixed(DecimalFormate.strAmtFormat)
          .toString();
      }
      objPrintParam = fnAlignment(objPrintParam);
      switch (DescriptionLine.iTotalPages) {
        case 1:
          objPrintParam.lineAt = Number(
            DescriptionLine.dFirstDescriptionEnd + dSubTotalDiff
          );
          break;
        case 2:
          if (DescriptionLine.iCurrPage === 1)
            objPrintParam.lineAt = Number(
              DescriptionLine.dFirstAlterDescriptionEnd + dSubTotalDiff
            );
          else
            objPrintParam.lineAt = Number(
              DescriptionLine.dLastDescriptionEnd + dSubTotalDiff
            );
          break;
        default:
          if (DescriptionLine.iCurrPage === 1)
            objPrintParam.lineAt = Number(
              DescriptionLine.dFirstAlterDescriptionEnd + dSubTotalDiff
            );
          else if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages)
            objPrintParam.lineAt = Number(
              DescriptionLine.dLastDescriptionEnd + dSubTotalDiff
            );
          else
            objPrintParam.lineAt = Number(
              DescriptionLine.dMiddleDescriptionEnd + dSubTotalDiff
            );
          break;
      }
      fnDrawString(objPrintParam);
    });
    //#endregion

    //#region Amount in words
    if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
      let objAmountInWords = objEntArea.filter(
        (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
      );
      objAmountInWords.forEach((entAmountInWord) => {
        objPrintParam = fnGetPrintAreaProperties(entAmountInWord, "Label");
        var StringWidthHeight = fnMeasureString(objPrintParam);
        dContentHeight = StringWidthHeight.dContentHeight;
        dContentWidth = StringWidthHeight.dContentWidth;
        objPrintParam = fnAlignment(objPrintParam);
        let strWords = objPrintParam.printContent.replace(
          /^.{1}/g,
          objPrintParam.printContent[0].toLowerCase()
        );
        strWords = _dtSummary[0][strWords];
        //Convet Number to Words
        objPrintParam.printContent = NumbertoWord(strWords);

        dPerLineHeightWithGap = dContentHeight + DescriptionLine.GapPerLine;
        objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
        objPrintParam.iWidthLimit = parseInt(objPrintParam.width);
        objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
        let SplitTextbyWidth = doc.splitTextToSize(
          objPrintParam.printContent,
          Math.floor(objPrintParam.width)
        );
        let SplittextCount = SplitTextbyWidth.length;
        if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
          for (let P = 0; P < SplittextCount; P++) {
            objPrintParam.printContent = SplitTextbyWidth[P];
            objPrintParam.lineAt = Number(
              Number(objPrintParam.lineAt) + P * dPerLineHeightWithGap
            );
            fnDrawString(objPrintParam);
          }
        } else fnDrawString(objPrintParam);
      });
    }
    //#endregion

    //#region Draw Logo
    var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
    EntLogo.forEach((entLogo) => {
      let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
      let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
      fnDrawLogo(entLogo, objentLogoData);
    });
    //#endregion
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
const fnDrawTermalGeneralData = (
  objEntArea,
  _dtItems,
  iSubTotalItemsSNo,
  _dtSummary,
  DescriptionLine,
  objEntLogo,
  DecimalFormate
) => {
  let _dGapPerLine = DescriptionLine.GapPerLine;
  let dContentHeight = 0,
    dContentWidth = 0,
    dPerLineHeightWithGap = 0;
  let objPrintParam = "";
  //#region Label's
  let objLabels = objEntArea.filter(
    (DT) =>
      DT.fieldName === "Label" &&
      DT.prefix !== "SubTotal" &&
      DT.suffix !== "Only"
  );
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam = fnAlignment(objPrintParam);
    if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
      let SplitTextbyWidth = doc.splitTextToSize(
        objPrintParam.printContent,
        Math.floor(objPrintParam.width)
      );
      objPrintParam.SplittextCount = SplitTextbyWidth.length;
      let Height = objPrintParam.lineAt;
      for (let P = 0; P < SplitTextbyWidth.length; P++) {
        objPrintParam.printContent = SplitTextbyWidth[P];
        objPrintParam.lineAt = Height;
        Height += _dGapPerLine + doc.getTextDimensions(SplitTextbyWidth[P]).h;
        fnDrawString(objPrintParam);
      }
    } else {
      fnDrawString(objPrintParam);
    }
  });
  //#endregion

  //#region PageNumber
  if (objEntArea.filter((DT) => DT.fieldName === "PageNumber").length > 0) {
    let entPageNumber = objEntArea.filter(
      (DT) => DT.FieldName === "PageNumber"
    )[0];
    objPrintParam = fnGetPrintAreaProperties(entPageNumber);
    objPrintParam = fnAlignment(objPrintParam);
    objPrintParam.strFont = entPageNumber.Font;
    objPrintParam.printContent =
      DescriptionLine.iCurrPage + " / " + DescriptionLine.iTotalPages;
    fnDrawString(objPrintParam);
  }
  //#endregion

  //#region CopyType
  if (objEntArea.filter((DT) => DT.fieldName === "CopiesName").length > 0) {
    let entPageNumber = objEntArea.filter(
      (DT) => DT.fieldName === "CopiesName"
    )[0];
    objPrintParam = fnGetPrintAreaProperties(entPageNumber);
    objPrintParam = fnAlignment(objPrintParam);
    var entAreas = objEntArea.filter((r) => r.fieldName === "CopiesName");
    let StrLable = entAreas.labelName;
    let StrSuffix = entAreas.suffix;
    let StrPrefix = entAreas.prefix;
    switch (DescriptionLine.iCurrentCopy) {
      case 1:
        objPrintParam.printContent = StrLable;
        break;
      case 2:
        objPrintParam.printContent = StrPrefix;
        break;
      default:
        objPrintParam.printContent = StrSuffix;
        break;
    }
    fnDrawString(objPrintParam);
  }
  //#endregion

  //#region SubTotal
  let objSubTotals = objEntArea.filter(
    (DT) => DT.fieldName === "Label" && DT.prefix === "SubTotal"
  );
  objSubTotals.forEach((entSubTotal) => {
    let dSubTotal = 0,
      dSubTotalDiff = 0;
    objPrintParam = fnGetPrintAreaProperties(entSubTotal, "Label");
    //#region Get SubTotal Height Diff of First Page
    dSubTotalDiff = Number(
      objPrintParam.lineAt - DescriptionLine.dFirstDescriptionEnd
    );
    //#endregion
    var printContent = objPrintParam.printContent;
    printContent = printContent.replace(
      /^.{1}/g,
      printContent[0].toLowerCase()
    );
    iSubTotalItemsSNo.forEach((iSubTotalItemSNo) => {
      let drItem = _dtItems.filter((Data) => Data.sNo === iSubTotalItemSNo)[0];
      dSubTotal += drItem[printContent];
    });

    if (objPrintParam.printContent.includes("Qty")) {
      objPrintParam.printContent = Number(dSubTotal)
        .toFixed(DecimalFormate.strQtyFormat)
        .toString();
    } else {
      objPrintParam.printContent = Number(dSubTotal)
        .toFixed(DecimalFormate.strAmtFormat)
        .toString();
    }
    objPrintParam = fnAlignment(objPrintParam);
    objPrintParam.lineAt = Number(
      DescriptionLine.dFirstDescriptionEnd + dSubTotalDiff
    );
    fnDrawString(objPrintParam);
  });
  //#endregion

  //#region Amount in words
  let objAmountInWords = objEntArea.filter(
    (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
  );
  objAmountInWords.forEach((entAmountInWord) => {
    objPrintParam = fnGetPrintAreaProperties(entAmountInWord, "Label");
    let TxtWH = fnMeasureString(objPrintParam);
    dContentHeight = TxtWH.dContentHeight;
    dContentWidth = TxtWH.dContentWidth;
    objPrintParam = fnAlignment(objPrintParam);

    let strWords = objPrintParam.printContent.replace(
      /^.{1}/g,
      objPrintParam.printContent[0].toLowerCase()
    );
    strWords = _dtSummary[0][strWords];
    //Convet Number to Words
    objPrintParam.printContent = NumbertoWord(strWords);

    dPerLineHeightWithGap = dContentHeight + DescriptionLine.GapPerLine;
    objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
    objPrintParam.iContentWidth = Number(Math.floor(dContentWidth));

    let SplitTextbyWidth = doc.splitTextToSize(
      objPrintParam.printContent,
      Math.floor(objPrintParam.width)
    );
    objPrintParam.SplittextCount = SplitTextbyWidth.length;
    if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
      for (let P = 0; P < objPrintParam.SplittextCount; P++) {
        objPrintParam.printContent = SplitTextbyWidth[P];
        objPrintParam.lineAt = Number(
          Number(objPrintParam.lineAt) + P * dPerLineHeightWithGap
        );
        fnDrawString(objPrintParam);
      }
    } else fnDrawString(objPrintParam);
  });
  //#endregion

  //#region Draw Logo
  var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
  EntLogo.forEach((entLogo) => {
    let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
    let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
    fnDrawLogo(entLogo, objentLogoData);
  });
  //#endregion
};
const fnDrawtermalPageData = (
  objEntArea,
  dtData,
  DescriptionLine,
  objEntLogo
) => {
  let _dGapPerLine = DescriptionLine.GapPerLine;
  let objElements = objEntArea.filter(
    (DT) => DT.fieldName !== "Label" && !DT.fieldName.startsWith("LogoId")
  );
  let objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  let objPrintParam = "";
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam = fnAlignment(objPrintParam);
    if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
      let SplitTextbyWidth = doc.splitTextToSize(
        objPrintParam.printContent,
        Math.floor(objPrintParam.width)
      );
      objPrintParam.SplittextCount = SplitTextbyWidth.length;
      let Height = objPrintParam.lineAt;
      for (let P = 0; P < SplitTextbyWidth.length; P++) {
        objPrintParam.printContent = SplitTextbyWidth[P];
        objPrintParam.lineAt = Height;
        Height += _dGapPerLine + doc.getTextDimensions(SplitTextbyWidth[P]).h;
        fnDrawString(objPrintParam);
      }
    } else {
      fnDrawString(objPrintParam);
    }
  });
  dtData.forEach((drData) => {
    objElements.forEach((entElement) => {
      objPrintParam = fnGetPrintAreaProperties(entElement);
      objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
      objPrintParam = fnAlignment(objPrintParam);
      if (objPrintParam.wordWrap.toLowerCase() === "wrap") {
        let SplitTextbyWidth = doc.splitTextToSize(
          objPrintParam.printContent,
          Math.floor(objPrintParam.width)
        );
        objPrintParam.SplittextCount = SplitTextbyWidth.length;
        let Height = objPrintParam.lineAt;
        for (let P = 0; P < SplitTextbyWidth.length; P++) {
          objPrintParam.printContent = SplitTextbyWidth[P];
          objPrintParam.lineAt = Height;
          Height += _dGapPerLine + doc.getTextDimensions(SplitTextbyWidth[P]).h;
          fnDrawString(objPrintParam);
        }
      } else {
        fnDrawString(objPrintParam);
      }
    });
  });
  //#region Draw Logo
  var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
  EntLogo.forEach((entLogo) => {
    let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
    let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
    fnDrawLogo(entLogo, objentLogoData);
  });
  //#endregion
};
const CreateInvocie = (
  PrintValue,
  _iCurrentCopy,
  _iScrId,
  ScrName,
  PrintFor,
  PrintShare
) => {
  let PageWidth = Number(Number(PrintValue.Paper.width * 3.78).toFixed(2)) + 20; //  Convert mm to Pt
  let PageHeight =
    Number(Number(PrintValue.Paper.height * 3.78).toFixed(2)) + 20; //  Convert mm to Pt

  //#region Print Template
  let _objLineTempFirst = PrintValue.objLineTemplate.objFirst;
  let _objLineTempFirstAlter = PrintValue.objLineTemplate.objFirstAlter;
  let _objLineTempLast = PrintValue.objLineTemplate.objLast;
  let _objLineTempMiddle = PrintValue.objLineTemplate.objMiddle;
  //#endregion
  //#region Print Area Design
  let _objGeneralArea = ConvertNulltoArray(PrintValue.objGeneralArea);
  let _objCompanyArea = ConvertNulltoArray(PrintValue.objCompanyArea);
  let _objPartyArea = ConvertNulltoArray(PrintValue.objPartyArea);
  let _objVoucherArea = ConvertNulltoArray(PrintValue.objVoucherArea);
  let _objRefVouchersArea = ConvertNulltoArray(PrintValue.objRefVouchersArea);
  let _objDeliveryArea = ConvertNulltoArray(PrintValue.objDeliveryArea);
  let _objTransportArea = ConvertNulltoArray(PrintValue.objTransportArea);
  let _objValidityArea = ConvertNulltoArray(PrintValue.objValidityArea);
  let _objRemarksArea = ConvertNulltoArray(PrintValue.objRemarksArea);
  let _objItemsArea = ConvertNulltoArray(PrintValue.objItemsArea);
  let _objAddLessArea = ConvertNulltoArray(PrintValue.objAddLessArea);
  let _objDiscountArea = ConvertNulltoArray(PrintValue.objDiscountArea);
  let _objTaxArea = ConvertNulltoArray(PrintValue.objTaxArea);
  let _objSummaryArea = ConvertNulltoArray(PrintValue.objSummaryArea);
  //#endregion
  //#region  Print Data
  const DecimalFormate = {
    strAmtFormat: parseInt(PrintValue.strAmtFormat),
    strPerFormat: parseInt(PrintValue.strPerFormat),
    strQtyFormat: parseInt(PrintValue.strQtyFormat),
  };
  let _dtCompany = JsontoArray(PrintValue.jsonCompany, DecimalFormate);
  let _dtParty = JsontoArray(PrintValue.jsonParty, DecimalFormate);
  let _dtVoucher = JsontoArray(PrintValue.jsonVoucher, DecimalFormate);
  let _dtRefVouchers = JsontoArray(PrintValue.jsonRefVoucher, DecimalFormate);
  let _dtDelivery = JsontoArray(PrintValue.jsonDelivery, DecimalFormate);
  let _dtTransport = JsontoArray(PrintValue.jsonTransport, DecimalFormate);
  let _dtValidity = JsontoArray(PrintValue.jsonValidity, DecimalFormate);
  let _dtRemarks = JsontoArray(PrintValue.jsonRemarks, DecimalFormate);
  let _dtItems = JsontoArray(PrintValue.jsonOriginalItem, DecimalFormate);
  let _dtTax = JsontoArray(PrintValue.jsonTax, DecimalFormate);
  let _dtDiscount = JsontoArray(PrintValue.jsonDiscount, DecimalFormate);
  let _dtSummary = JsontoArray(PrintValue.jsonSummary, DecimalFormate);
  let _dtAddLess = JsontoArray(PrintValue.jsonAddLess, DecimalFormate);
  let _dtJobs = JsontoArray(PrintValue.jsonJobs, DecimalFormate);
  //#endregion

  let dTotalHeight = 0;
  let dTotalHeightJobs = 0;
  let dTotalLineJobs = 0;
  let dTotalLine = 0;
  let _dGapPerLine = 0;
  let iPrintedItemSNo = [];
  let iPrintedAddLessSNo = [];
  let iSubTotalItemsSNo = [];

  //#region Find GapPerLine
  let dDescBegin = _objLineTempFirst.filter(
    (LT) => LT.lineName === "DescriptionBegin"
  )[0].y1Pos;
  if (_objItemsArea.filter((DT) => DT.fieldName !== "Label").length > 0) {
    let EntDescriptionPrintStart = _objItemsArea.filter(
      (DT) => DT.fieldName !== "Label"
    );
    let MinLineAt = Math.min(
      ...EntDescriptionPrintStart.map((obj) => obj.lineAt)
    );
    _dGapPerLine = MinLineAt - dDescBegin;
  }
  //#endregion

  if (PrintValue.PrinterType === 3) {
    //#region Calculate Total Desc Area Height
    let TableHeightWidth = termalPrinterDesc(
      _objItemsArea,
      _dtItems,
      _objAddLessArea,
      _dtAddLess
    );
    dTotalHeight = TableHeightWidth.dTotalHeight;
    dTotalLine = TableHeightWidth.dTotalLine;

    //Calculate gap height between line's and add it into TotalHeight
    dTotalHeight += (dTotalLine - 1) * _dGapPerLine;
    //#endregion
    //#region Update LineDescriptionEnd Depends on Items Height
    _objLineTempFirst.forEach((entLineTempFirst) => {
      if (entLineTempFirst.y1Pos > dDescBegin) {
        entLineTempFirst.y1Pos = entLineTempFirst.y1Pos + dTotalHeight;
        entLineTempFirst.y2Pos = entLineTempFirst.y2Pos + dTotalHeight;
      }
    });
    //#region  Increase Line At
    _objCompanyArea = Increase_LineAt(
      _objCompanyArea,
      dDescBegin,
      dTotalHeight
    );
    _objPartyArea = Increase_LineAt(_objPartyArea, dDescBegin, dTotalHeight);
    _objVoucherArea = Increase_LineAt(
      _objVoucherArea,
      dDescBegin,
      dTotalHeight
    );
    _objRefVouchersArea = Increase_LineAt(
      _objRefVouchersArea,
      dDescBegin,
      dTotalHeight
    );
    _objDeliveryArea = Increase_LineAt(
      _objDeliveryArea,
      dDescBegin,
      dTotalHeight
    );
    _objTransportArea = Increase_LineAt(
      _objTransportArea,
      dDescBegin,
      dTotalHeight
    );
    _objValidityArea = Increase_LineAt(
      _objValidityArea,
      dDescBegin,
      dTotalHeight
    );
    _objRemarksArea = Increase_LineAt(
      _objRemarksArea,
      dDescBegin,
      dTotalHeight
    );
    _objDiscountArea = Increase_LineAt(
      _objDiscountArea,
      dDescBegin,
      dTotalHeight
    );
    _objGeneralArea = Increase_LineAt(
      _objGeneralArea,
      dDescBegin,
      dTotalHeight
    );
    _objSummaryArea = Increase_LineAt(
      _objSummaryArea,
      dDescBegin,
      dTotalHeight
    );
    //#endregion
    //#endregion

    let dDescriptionEndHeight = _objLineTempFirst.filter(
      (F) => F.lineName === "DescriptionEnd"
    )[0].y1Pos;
    let dend = _objSummaryArea.filter((S) => S.labelName === "***")[0].lineAt;

    let PageHeight = Number(
      dDescBegin + dTotalHeight + (dend - dDescriptionEndHeight)
    );
    let DescriptionLine = {
      First: _objLineTempFirst,
      Alter: [],
      Middle: [],
      Last: [],
      fAddLessHeaderHeight: 0,
      dFirstDescriptionBegin: fnLineTempDescBegin(_objLineTempFirst),
      dFirstAlterDescriptionBegin: [],
      dMiddleDescriptionBegin: [],
      dLastDescriptionBegin: [],
      dFirstDescriptionEnd: fnLineTempDescEnd(_objLineTempFirst),
      dFirstAlterDescriptionEnd: [],
      dLastDescriptionEnd: [],
      dMiddleDescriptionEnd: [],
      fCurrentPageDescBegin: 0,
      GapPerLine: _dGapPerLine,
      iTotalPages: 1,
      iCurrPage: 1,
      iCurrentCopy: _iCurrentCopy,
      dCurrentPageDescLimit: 0,
    };

    options.format = [PageWidth, PageHeight];
    doc = new jsPDF(options);

    //#region Initialize fCurrentPageDescBegin,dCurrentPageDescLimit
    let PageDescBeginEnd =
      fnGetThermalCurrentPageDescLimitation(DescriptionLine);
    DescriptionLine.fCurrentPageDescBegin = PageDescBeginEnd.DescriptionBegin;
    DescriptionLine.dCurrentPageDescLimit = PageDescBeginEnd.DescriptionEnd;
    //#endregion

    //#region Draw Line
    fnDrawLine(_objLineTempFirst);
    //#endregion

    //#region Header Part
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((AR) => AR.fieldName === "Company").length > 0
    )
      fnDrawtermalPageData(
        _objCompanyArea,
        _dtCompany,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Party").length > 0
    )
      fnDrawtermalPageData(
        _objPartyArea,
        _dtParty,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Voucher").length > 0
    )
      fnDrawtermalPageData(
        _objVoucherArea,
        _dtVoucher,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "RefVouchers").length > 0
    )
      fnDrawtermalPageData(
        _objRefVouchersArea,
        _dtRefVouchers,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Delivery").length > 0
    )
      fnDrawtermalPageData(
        _objDeliveryArea,
        _dtDelivery,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Transport").length > 0
    )
      fnDrawtermalPageData(
        _objTransportArea,
        _dtTransport,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Validity").length > 0
    )
      fnDrawtermalPageData(
        _objValidityArea,
        _dtValidity,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Remarks").length > 0
    )
      fnDrawtermalPageData(
        _objRemarksArea,
        _dtRemarks,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    //#endregion

    //#region Footer Part
    fnDrawtermalPageData(
      _objDiscountArea,
      _dtDiscount,
      DescriptionLine,
      PrintValue.objEntLogo
    );
    fnDrawTaxData(_objTaxArea, _dtTax, DescriptionLine);
    fnDrawtermalPageData(
      _objSummaryArea,
      _dtSummary,
      DescriptionLine,
      PrintValue.objEntLogo
    );
    //#endregion

    //#region Item Part
    let objItemHeader = [];
    if (_iCurrentCopy === 1) {
      let HeaderContent = fnDrawItemsHeader(
        _objItemsArea,
        true,
        objItemHeader,
        DescriptionLine
      );
      DescriptionLine = HeaderContent.DescriptionLine;
      objItemHeader = HeaderContent.objItemHeader;
    } else {
      fnDrawItemsAutoHeader(objItemHeader, _objItemsArea, DescriptionLine);
    }
    let PrintedItem = fnDrawItemsData(
      _objItemsArea,
      _dtItems,
      true,
      _dtJobs,
      _objAddLessArea,
      DescriptionLine,
      iPrintedItemSNo,
      iPrintedAddLessSNo,
      iSubTotalItemsSNo,
      PrintValue.objEntLogo
    );
    iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
    iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
    iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
    //#endregion

    //#region AddLess Part
    if (_objAddLessArea.length > 0 && _dtAddLess.length > 0) {
      fnDrawItemsHeader(_objAddLessArea, false, DescriptionLine);
      PrintedItem = fnDrawItemsData(
        _objAddLessArea,
        _dtAddLess,
        false,
        DescriptionLine,
        iPrintedItemSNo,
        iPrintedAddLessSNo,
        iSubTotalItemsSNo,
        PrintValue.objEntLogo
      );
      iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
      iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
      iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
    }
    //#endregion

    //#region General
    fnDrawTermalGeneralData(
      _objGeneralArea,
      _dtItems,
      iSubTotalItemsSNo,
      _dtSummary,
      DescriptionLine,
      PrintValue.objEntLogo,
      DecimalFormate
    );
    //#endregion
  } else {
    options.format = [PageWidth, PageHeight];
    doc = new jsPDF(options);
    //#region Calculate Total Number of page
    //Calculate gap height between line's and add it into TotalHeight
    if (_iScrId === 73) {
      let TotalJobHeightWidth = fnGetTotalHeightAndLineJobs(
        _objItemsArea,
        _dtJobs
      );
      dTotalHeightJobs = TotalJobHeightWidth.dTotalHeightJobs;
      dTotalLineJobs = TotalJobHeightWidth.dTotalLineJobs;
      let TotalHeightWidth = fnGetTableHeight(_objItemsArea, _dtItems, _dtJobs);
      dTotalHeight = TotalHeightWidth.dTotalHeight;
      dTotalLine = TotalHeightWidth.dTotalLine;
    } else {
      let TotalHeightWidth = fnGetTableHeight(_objItemsArea, _dtItems, _dtJobs);
      dTotalHeight = TotalHeightWidth.dTotalHeight;
      dTotalLine = TotalHeightWidth.dTotalLine;
    }
    if (_objAddLessArea.length > 0 && _dtAddLess.length > 0) {
      let TotalHeightWidth = fnGetTableHeight(
        _objAddLessArea,
        _dtAddLess,
        _dtJobs
      );
      dTotalHeight = TotalHeightWidth.dTotalHeight;
      dTotalLine = TotalHeightWidth.dTotalLine;
    }

    //Calculate gap height between line's and add it into TotalHeight
    dTotalHeight += (dTotalLine - 1) * _dGapPerLine;
    dTotalHeightJobs += (dTotalLineJobs - 1) * _dGapPerLine;
    dTotalHeight = dTotalHeight + dTotalHeightJobs;
    let DescriptionLine = {
      First: _objLineTempFirst,
      Alter: _objLineTempFirstAlter,
      Middle: _objLineTempMiddle,
      Last: _objLineTempLast,
      fAddLessHeaderHeight: 0,
      dFirstDescriptionBegin: fnLineTempDescBegin(_objLineTempFirst),
      dFirstAlterDescriptionBegin: fnLineTempDescBegin(_objLineTempFirstAlter),
      dMiddleDescriptionBegin: fnLineTempDescBegin(_objLineTempMiddle),
      dLastDescriptionBegin: fnLineTempDescBegin(_objLineTempLast),
      dFirstDescriptionEnd: fnLineTempDescEnd(_objLineTempFirst),
      dFirstAlterDescriptionEnd: fnLineTempDescEnd(_objLineTempFirstAlter),
      dLastDescriptionEnd: fnLineTempDescEnd(_objLineTempLast),
      dMiddleDescriptionEnd: fnLineTempDescEnd(_objLineTempMiddle),
      fCurrentPageDescBegin: 0,
      GapPerLine: _dGapPerLine,
      iTotalPages: 1,
      iCurrPage: 1,
      iCurrentCopy: _iCurrentCopy,
      dCurrentPageDescLimit: 0,
    };
    //Calculate Page Cound
    DescriptionLine.iTotalPages = fnPageCount(dTotalHeight, DescriptionLine);
    //#endregion
    let objItemHeader = [];
    while (DescriptionLine.iCurrPage <= DescriptionLine.iTotalPages) {
      //#region Draw Line
      if (
        DescriptionLine.iCurrPage === 1 &&
        DescriptionLine.iTotalPages === 1
      ) {
        fnDrawLine(_objLineTempFirst);
      } else if (
        DescriptionLine.iCurrPage === 1 &&
        DescriptionLine.iTotalPages !== 1
      ) {
        fnDrawLine(_objLineTempFirstAlter);
      } else if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
        doc.addPage(options);
        fnDrawLine(_objLineTempLast);
      } else {
        doc.addPage(options);
        fnDrawLine(_objLineTempMiddle);
      }
      //#endregion
      // PageDesc Limitation
      //#region Initialize fCurrentPageDescBegin,dCurrentPageDescLimit
      let PageDescBeginEnd = fnGetCurrentPageDescLimitation(DescriptionLine);
      DescriptionLine.fCurrentPageDescBegin = PageDescBeginEnd.DescriptionBegin;
      DescriptionLine.dCurrentPageDescLimit = PageDescBeginEnd.DescriptionEnd;
      //#endregion

      // #region Header Part
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((AR) => AR.fieldName === "Company").length > 0
      )
        fnDrawData(
          _objCompanyArea,
          _dtCompany,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      else {
        if (
          _objCompanyArea.filter(
            (DT) =>
              DT.suffix.includes("OnlyMiddle") || DT.suffix.includes("OnlyLast")
          ).length > 0
        )
          fnDrawData(
            _objCompanyArea.filter(
              (DT) =>
                DT.suffix.includes("OnlyMiddle") ||
                DT.suffix.includes("OnlyLast")
            ),
            _dtCompany,
            DescriptionLine,
            PrintValue.objEntLogo
          );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Party").length > 0
      )
        fnDrawData(
          _objPartyArea,
          _dtParty,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Voucher").length > 0
      ) {
        fnDrawData(
          _objVoucherArea,
          _dtVoucher,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "RefVouchers").length >
          0
      ) {
        fnDrawData(
          _objRefVouchersArea,
          _dtRefVouchers,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Delivery").length > 0
      ) {
        fnDrawData(
          _objDeliveryArea,
          _dtDelivery,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Transport").length > 0
      ) {
        fnDrawData(
          _objTransportArea,
          _dtTransport,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Validity").length > 0
      ) {
        fnDrawData(
          _objValidityArea,
          _dtValidity,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Remarks").length > 0
      ) {
        fnDrawData(
          _objRemarksArea,
          _dtRemarks,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      } else {
        if (
          _objRemarksArea.filter(
            (DT) =>
              DT.suffix.includes("OnlyMiddle") || DT.suffix.includes("OnlyLast")
          ).length > 0
        )
          fnDrawData(
            _objRemarksArea.filter(
              (DT) =>
                DT.suffix.includes("OnlyMiddle") ||
                DT.suffix.includes("OnlyLast")
            ),
            _dtRemarks,
            DescriptionLine,
            PrintValue.objEntLogo
          );
      }
      //#endregion

      //#region Footer Part
      if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
        fnDrawData(
          _objDiscountArea,
          _dtDiscount,
          DescriptionLine,
          PrintValue.objEntLogo
        );
        fnDrawTaxData(_objTaxArea, _dtTax);
        fnDrawData(
          _objSummaryArea,
          _dtSummary,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      //#endregion

      //#region Item Part
      if (DescriptionLine.iCurrPage === 1) {
        let HeaderContent = fnDrawItemsHeader(
          _objItemsArea,
          true,
          objItemHeader,
          DescriptionLine
        );
        DescriptionLine = HeaderContent.DescriptionLine;
        objItemHeader = HeaderContent.objItemHeader;
      } else {
        fnDrawItemsAutoHeader(
          objItemHeader,
          _objItemsArea,

          DescriptionLine
        );
      }
      let PrintedItem = fnDrawItemsData(
        _objItemsArea,
        _dtItems,
        true,
        _dtJobs,
        _objAddLessArea,
        DescriptionLine,
        iPrintedItemSNo,
        iPrintedAddLessSNo,
        iSubTotalItemsSNo,
        PrintValue.objEntLogo
      );
      iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
      iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
      iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
      //#endregion

      //#region AddLess Part
      if (
        DescriptionLine.iCurrPage === DescriptionLine.iTotalPages &&
        _dtAddLess.length > 0 &&
        _dtAddLess.length > 0
      ) {
        PrintedItem = fnDrawItemsData(
          _objAddLessArea,
          _dtAddLess,
          false,
          _dtJobs,
          DescriptionLine,
          iPrintedItemSNo,
          iPrintedAddLessSNo,
          iSubTotalItemsSNo,
          PrintValue.objEntLogo
        );
        if (PrintedItem.iPrintedItemSNo > 0) {
          iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
          iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
          iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
        }
      }
      //#endregion

      //#region General
      fnDrawGeneralData(
        _objGeneralArea,
        _dtItems,
        iSubTotalItemsSNo,
        _dtSummary,
        DescriptionLine,
        PrintValue.objEntLogo,
        DecimalFormate
      );
      //#endregion

      DescriptionLine.iCurrPage++;
    }
  }
  var FileName = VoucherName(_dtVoucher[0], ScrName);
  if (PrintShare) {
    var string = doc.output("dataurlstring", FileName);
    const objPdfFile = {
      PdfURL: string,
      FileName: FileName,
      Summary: _dtSummary,
      CompanyInfo: _dtCompany,
      PartyInfo: _dtParty,
    };
    return objPdfFile;
  } else {
    if (PrintFor === "Print") {
      doc.autoPrint();
      doc.output("dataurlnewwindow", { filename: FileName });
    } else {
      doc.save(FileName + ".pdf");
    }
  }
};
const PrintInvoicDesign = (
  PrintValue,
  iScrId,
  PrintFor,
  ScrName,
  PrintShare,
  dispatch
) => {
  try {
    let C = 1;
    if (PrintShare) {
      return CreateInvocie(
        PrintValue,
        C,
        iScrId,
        ScrName,
        PrintFor,
        PrintShare
      );
    } else {
      while (C <= PrintValue.NoofCopies) {
        CreateInvocie(PrintValue, C, iScrId, ScrName, PrintFor, PrintShare);
        C++;
        doc = new jsPDF(options); // Clear Page After Print First Copy
      }
    }
  } catch (e) {
    dispatch(Show_MsgBox({ Message: e.message, Type: "error", isMsg: true }));
  }
};
export default PrintInvoicDesign;
